import WizardComponent from '../../../../../../libs/components/wizard-component';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import { checkOriginEcommerce } from '../../../../../../libs/utils';
import './style.scss';

export default class RecoveredEmail extends WizardComponent {
    constructor(name, root) {
        super(name, root);

        this.title = this._dEl('title');
        this.titleTxt = this.title.innerText;

        this.text = this._dEl('txt');
        this.textHtml = this.text.innerHTML;

        this.originEcommerce = checkOriginEcommerce();
        this._addStoreListeners();
    }

    /* override */
    open() {
        super.open();
        if (this.originEcommerce) {
            this.typeOfService = window.accessInfo.typeOfService;
            this.pointOfServiceId = window.accessInfo.pointOfServiceId;
            this.cooperativeId = window.accessInfo.cooperativeId;
            this.userInfo = null;
            if (this.pointOfServiceId && this.cooperativeId) {
                this.userInfo = {
                    coop: this.cooperativeId,
                    store: this.pointOfServiceId,
                };
            }
            trackEvent(
                TRACKABLE_EVENT.pageview,
                FUNNEL_NAME.recoverEmail,
                FUNNEL_STEP.confirmEmailRecovery,
                this.typeOfService,
                null,
                this.userInfo
            );
        } else {
            trackEvent(TRACKABLE_EVENT.pageview, FUNNEL_NAME.recoverEmail, FUNNEL_STEP.confirmEmailRecovery);
        }
    }

    _addStoreListeners() {
        this._addStoreListener('recoveredEmailData', (path, data) => {
            this.title.innerText = this.titleTxt.replace('$email', data.email);
            this.text.innerHTML = this.textHtml.replace('$email', data.email);
        });
    }
}


