import ModalComponent from '../../../../../../libs/components/modal-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { register } from '../../../../../../libs/register';
import './style.scss';


export default class DonateBollini extends ModalComponent {
    constructor(name, root) {
        super(name, root);
        this.submit = this._dEl('submit');
        this.bolliniEl = this._dEl('pointsToTransfer');
        this.bolliniObj = register.getClass(this.bolliniEl);
        this.beneficiaryCardEl = this._dEl('beneficiaryCard');
        this.beneficiaryCardObj = register.getClass(this.beneficiaryCardEl);
        this.form = this._dEl('form');
        this.submit = this._dEl('submit');

        this._addEventListeners();
    }

    /* override */
    _onOpen() {
        this.submit.classList.add('invalid');
        this.form.reset();
        let bollini = flowManager.getDataFromFlow()?.bollini;
        this.bolliniObj.setPermanentText('/' + bollini);

        super._onOpen();
    }

    _addEventListeners() {
        this._addListener(
            'submit',
            (event) => {
                event.preventDefault();

                const formData = new FormData(this.form);
                flowManager.startFlow({
                    flowName: 'donate-bollini-confirm',
                    flowSteps: [
                        { name: 'bollini-confirm-donate', stepIndex: 1, disableBack: true },
                    ],
                    initialData: {
                        numeroPunti: formData.get('pointsToTransfer'),
                        codiceCartaTarget: formData.get('beneficiaryCard'),
                    },
                    onComplete: (name,data) => {
                        if(data?.result) {
                            flowManager.appendDataToFlow({
                                ok: data.result == "ok"
                            });

                            flowManager.next("bollini-donated");
                        }
                    }
                 });
            },
            this.form
        );

        this._addListener('ecInputChanged', () => {
            if (!this.form.checkValidity()) {
                this.submit.classList.add('invalid');
            } else {
                this.submit.classList.remove('invalid');
            }
        });
    }

    /* override */
    _onCancel() {
        flowManager.complete();
    }
}


