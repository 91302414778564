import ValidableComponent from '../../../../../libs/components/validable-component';
import { dictionary } from '../../../../../libs/dictionary-provider';
import { getNextFormFocusableElement } from '../../../../../libs/utils';
import './style.scss';

export default class Textfield extends ValidableComponent {
    constructor(name, root) {
        super(name, root);
        this.input = this._dEl('input');
        this.showPassword = this._dEl('showPassword');
        this.permanentText = this._dEl('permanentText');
        this.hiddenValue = this._dEl('permanentTextHiddenValue');
        this.error = this._dEl('error');
        this.valid = this._dEl('validated');
        this.hiddenValueWidth = 0;
        this._addEventListeners();
    }

    _getInput() {
        return this.input;
    }

    _addEventListeners() {
        if (this.showPassword)
            this.showPassword.addEventListener('click', (event) => {
                event.preventDefault();
                if (this.input.getAttribute('type') == 'text') {
                    this.showPassword.classList.remove(this._elMod('showPassword', 'visible'));
                    this.input.setAttribute('type', 'password');
                } else {
                    this.showPassword.classList.add(this._elMod('showPassword', 'visible'));
                    this.input.setAttribute('type', 'text');
                }
            });

        ['keyup', 'change', 'input'].forEach((e) => {
            this.input.addEventListener(e, () => {
                this._checkState();
                this._changedInput();
                if(this.permanentText) {
                    this.hiddenValue.innerHTML = this.input.value;
                    this.hiddenValueWidth = this.hiddenValue.offsetWidth;
                }
            });
        });
        this.input.addEventListener('focusout', async () => {
            if (this.input.required && this.input.value == '') {
                if (!this.root.classList.contains(this._mod('errorState'))) {
                    this.trackFormError(dictionary.getFEMessage('requiredField'));
                }
                this.setState('error');
                await this._requireField();
            }
            if (this.input.value !== '' && this.root.classList.contains(this._mod('errorState'))) {
                this.trackFormError(this.errorText);
            }
        });

        /* hide default browser error popup on enter pressed */
        this.root.addEventListener(
            'invalid',
            () => {
                if (this.input.required && this.input.value == '') {
                    this.setState('error');
                    this._requireField();
                }
            },
            true
        );

        //go to next focusable element when enter pressed
        this.input.addEventListener('keydown', (event) => {
            if (event.which == 13) {
                event.preventDefault();
                if (this.isValid()) {
                    const next = getNextFormFocusableElement(event.target);
                    if (next) next.focus();
                }
            }
        });
    }

    _requireField() {
        this.setErrorText(dictionary.getFEMessage('requiredField'));
    }

    /* override */
    setErrorText(errorText) {
        super.setErrorText(errorText);
        if (typeof errorText !== 'string' || errorText === '') return;
        this.error.innerText = errorText;
    }

    setValidText(validText) {
        if (typeof validText !== 'string' || validText === '') return;
        this.valid.innerText = validText;
    }

    setPermanentText(text) {
        if (typeof text !== 'string' || text === '') return;
        this.permanentText.innerText = text;
    }

    reset() {
       super.reset();
    }
}


