import WizardComponent from '../../../../../../libs/components/wizard-component';
import { apiProvider } from '../../../../../../libs/api-provider';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import { delay } from '../../../../../../libs/utils';
import { dictionary } from '../../../../../../libs/dictionary-provider';
import { flowManager } from '../../../../../../libs/flow-manager';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import './style.scss';

export default class AssociateSocial extends WizardComponent {
    constructor(name, root) {
        super(name, root);

        this.loginPageUrl = this.root.dataset.loginPage;

        this.okContainer = this._dElMod('container', 'ok');
        this.errorContainer = this._dElMod('container', 'error');
    
        this.title = this._dEl('title');
        this.text = this._dEl('text');
        this.error = this._dEl('error');
        this.associate = this._dEl('associate');
        this.cancel = this._dEl('cancel');

        this._addEventListeners();
        this._addStoreListeners();

        this._runLogic();
    }

    _addEventListeners() {
        this.associate.addEventListener('click', async (event) => {
            event.preventDefault();
            /* TODO: understand when type of service, prefstore coop and prefstore id are available (never ?) */
            trackEvent(TRACKABLE_EVENT.associateSocial, FUNNEL_NAME.login, FUNNEL_STEP.confirmSocialAssociation);
            await this._associateAccount();
        });
        this.cancel.addEventListener('click', (event) => {
            event.preventDefault();
            /* TODO: understand when type of service, prefstore coop and prefstore id are available (never ?) */
            trackEvent(TRACKABLE_EVENT.cancelSocial, FUNNEL_NAME.login, FUNNEL_STEP.cancelSocialAssociation);
            this._redirectCallback();
        });
    }

    _addStoreListeners() {}

    _getParams() {
        const urlParams = new URLSearchParams(window.location.search);
        const params = { isSocialTokenJwt: urlParams.get('isSocialTokenJwt'), cb: urlParams.get('cb') };
        return params;
    }

    _parseJwt (token) {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            return JSON.parse(jsonPayload);
        } catch (error) {
            console.error('Error while parsing the social token Jwt');
            return null;
        }
    }

    _setTitle() {
        /* NOTE: assumes the presence of a data-title whose text must contain a placeholder {{social-type}} */
        if (!this.title.dataset?.title 
            || !this.title.dataset?.title?.includes('{{social-type}}')
        ) {
            console.warn('Missing placeholder {{social-type}} in configured title');
            return;
        }
        this.title.innerText = this.title.dataset.title
            ? this.title.dataset.title
                .replace('{{social-type}}', this._capitalize(this.userData.socialType))
            : '';
    }

    _setText() {
        /* NOTE: assumes the presence of a data-text whose text must contain the placeholders {{social-type}} and {{email}} */
        if (!this.text.dataset?.text 
            || !this.text.dataset?.text?.includes('{{social-type}}') 
            || !this.text.dataset?.text?.includes('{{email}}')
        ) {
            console.warn('Missing placeholders {{social-type}} and/or {{email}} in configured text');
            return;
        }
        this.text.innerText = this.text?.dataset?.text 
            ? this.text.dataset.text
                .replace('{{social-type}}', this._capitalize(this.userData.socialType))
                .replace('{{email}}', this.userData.email)
            : '';
    }

    _capitalize(s) {
        return typeof s == 'string'
            ? s.charAt(0).toUpperCase() + s.slice(1)
            : '';
    }

    async _runLogic() {
        openLoader('main');
        await delay(2000);

        // save and check params
        this.params = this._getParams();
        if (!this.params.isSocialTokenJwt || !this.params.cb) {
            console.warn('Invalid request to request social account association');
            this._showError();
            closeLoader('main');
            return;
        }
        // parse and save isSocialTokenJwt
        if (this.params.isSocialTokenJwt) this.isSocialTokenJwt = this._parseJwt(this.params.isSocialTokenJwt);
        const sub = this.isSocialTokenJwt?.sub;
        // save user data
        this.userData = {
            nome: this.isSocialTokenJwt?.nome,
            cognome: this.isSocialTokenJwt?.cognome,
            email: this.isSocialTokenJwt?.email,
            socialType: this._capitalize(sub?.split('.')[sub?.split('.').length-1].toLowerCase())
        };

        this._setTitle();
        this._setText();
        this._showOk();
        closeLoader('main');

        /* TODO: understand when type of service, prefstore coop and prefstore id are available (never ?) */
        trackEvent(TRACKABLE_EVENT.pageview, FUNNEL_NAME.login, FUNNEL_STEP.socialProfileAssociationScreen);
    }

    _showOk() {
        this.okContainer.classList.add(this._elMod('container', 'show'));
    }

    _showError() {
        this.errorContainer.classList.add(this._elMod('container', 'show'));
    }

    async _associateAccount() {
        const data = {
            socialToken: this.params.isSocialTokenJwt,
            cb: this.params.cb
        };
        try {
            openLoader('main');
            const result = await apiProvider.requestSocialUserAssociation(data);
            closeLoader('main');
            console.log(result);
            flowManager.next('check-email');
        } catch (error) {
            const message = error && error.frontendMessage ? error.frontendMessage : this.root.dataset.genericError;
            this.error.innerHTML = message;
            this.error.classList.remove(this._elMod('error', 'hidden'));
            closeLoader('main');
        }
    }

    _redirectCallback() {
        if (!this.params.cb) {
            console.warn('Callback not found');
            return;
        }
        const cancelledAssociationText = this.root.dataset?.cancelledAssociationError 
            ? this.root.dataset.cancelledAssociationError 
            : this.root.dataset.genericError;
        const errorText = `${cancelledAssociationText} (<a href="${this.params.cb}"><u>${dictionary.get('back')}</u></a>)`;
        window.location = `${this.loginPageUrl}?error=${encodeURIComponent(errorText)}`;
    }
}


