import Component from '../../../../../../libs/components/component';
import './style.scss';

export default class WizardLayout extends Component {
    constructor(name, root) {
        super(name, root);
        this.items = this._dEl('item', true);
        this._addEventListeners();
    }

    _addEventListeners() {}

    showItemOf(el) {
        const itemEl = el.closest(this._el('item', true));
        if (itemEl.classList.contains(this._elMod('item', 'show'))) return;

        for (const item of this.items) {
            item.classList.remove(this._elMod('item', 'show'));
        }
        itemEl.classList.add(this._elMod('item', 'show'));
        window.scrollTo(0, 0);
        // set focus on first button, link, or input
        const firstFocusable = el?.querySelector('button:not([data-flow-step]):not([data-step-back]),a,input');
        firstFocusable?.focus();
    }

    isOpen(el) {
        const itemEl = el.closest(this._el('item', true));
        return itemEl?.classList.contains(this._elMod('item', 'show'));
    }
}
