import Component from '../../../../../libs/components/component';
import PerfectScrollbar from 'perfect-scrollbar';
import './style.scss';

export default class OrderStatesPopup extends Component {
    constructor(name, root) {
        super(name, root);
        this.states = this.root.querySelector(`${this._el('content', true)} > div`);
        const scrollbarOptions = {
            swipeEasing: true,
            suppressScrollX: true,
        };
        this.ps = new PerfectScrollbar(this.states, scrollbarOptions);
        this._updateHeight();
        this._addEventListeners();
    }

    _addEventListeners() {
        this._addListener(
            'resize',
            () => {
                this._updateHeight();
            },
            window
        );
        this._addListener(
            'scroll',
            () => {
                this._updateHeight();
            },
            document
        );
    }

    _updateHeight() {
        /* keep 5px free on top/bottom when innerHeight is used */
        this.root.style.height = `${Math.min(window.innerHeight - 10, 536)}px`;
    }
}
