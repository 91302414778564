import PopupComponent from '../../../../../../libs/components/popup-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { FUNNEL_NAME, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import './style.scss';

export default class UndeletableProfile extends PopupComponent {
    constructor(name, root) {
        super(name, root);
    }

    _onOpen() {
        super._onOpen();

        trackEvent(TRACKABLE_EVENT.pageview, FUNNEL_NAME.disablingUser, 'Impossibilità di eliminare profilo');
    }

    _onCancel() {
        flowManager.backOrComplete();
    }
}
