import Component from '../../../../../../libs/components/component';
import { register } from '../../../../../../libs/register';
import { openLoader, closeLoader } from '../../../templates/mt11-loader/script';
import { delay } from '../../../../../../libs/utils';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import { ecCartProvider } from '../../../../../../libs/ecommerce-cart-provider';
import './style.scss';

export default class Wishlist extends Component {
    constructor(name, root) {
        super(name, root);

        this.loaderEndpoint = this.root.dataset.endpoint;
        this.initLength = parseInt(this.root.dataset.initLength);
        this.currentSize = this.initLength;
        this.downloadLength = parseInt(this.root.dataset.downloadLength);
        this.numProducts = this.root.querySelector(`${this._el('numProducts', true)} > span`);
        this.loadMore = this._dEl('loadMore');
        this.noProducts = this._dEl('noProducts');
        this.content = this._dEl('content');
        this.filter = register.getClass(this._dEl('filter'));
        this.wishlistSize = 0;
        this.wishlistScrollHeight = 100;
        trackEvent(TRACKABLE_EVENT.pageview, FUNNEL_NAME.favoriteProducts, FUNNEL_STEP.ecommerceFavoriteProductsList);

        this.iframe = this._dEl('iframe');

        const iframeSrc = this.iframe.dataset.src;
        this.messageOrigin = iframeSrc.match(/(http[s]?:\/\/.[^/]+)/)[1];

        this._addEventListeners();
        this._init();
    }

    async _init() {
        openLoader('main');
        this._loadIframe(this.currentSize);
        await delay(2000);
        closeLoader('main');
    }

    _addEventListeners() {
        this._addListener(
            'message',
            (event) => {
                console.log('event origin', event.origin, 'messageOrigin', this.messageOrigin);
                if (event.origin != this.messageOrigin) return;

                switch (event.data.type) {
                    case 'resize':
                        this.wishlistScrollHeight = event.data.scrollHeight;
                        this._updateView();
                        break;
                    case 'load':
                        this.wishlistSize = event.data.size;
                        this._updateView();
                        break;
                    case 'changeCart':
                        ecCartProvider.resetCache(); //next reload cart will be updated
                        break;
                }
            },
            window
        );
        this.loadMore.addEventListener('click', async () => {
            /* update current size (the one requested, might be adjusted later if less products are returned) */
            this.currentSize = this.currentSize + this.downloadLength;
            openLoader('main');
            this._loadIframe(this.currentSize);
            await delay(2000);
            closeLoader('main');
        });
        this.root.addEventListener('mt10FilterChanged', async () => {
            /* reset current size on new filter applied */
            this.currentSize = this.initLength;
            openLoader('main');
            this._loadIframe(this.currentSize);
            await delay(2000);
            closeLoader('main');
        });
    }

    _loadIframe(num) {
        /* check filter value */
        let price = null;
        const filterValue = this.filter.getValue();
        switch (filterValue) {
            case 'incr':
            case 'decr': {
                price = filterValue;
                break;
            }
            default:
                break;
        }
        try {
            const rawSrc = this.iframe.dataset.src;
            const isAuthor = this.root.hasAttribute('data-is-author');
            const modifiedSrc = `${rawSrc}?num=${num}${price ? '&price=' + price : ''}${
                isAuthor ? '&wcmmode=disabled' : ''
            }`;
            this.iframe.src = modifiedSrc;
        } catch (error) {
            console.error(error);
        }
    }

    async _updateView() {
        /* update wishlist size */
        if (this.wishlistSize <= 0) {
            /* show no products text, hide iframe */
            this.noProducts.classList.remove(this._elMod('noProducts', 'hidden'));
            if (!this.content.classList.contains(this._elMod('content', 'hidden')))
                this.content.classList.add(this._elMod('content', 'hidden'));
            /* update number of products */
            this.numProducts.textContent = '0';
            /* hide load more */
            if (!this.loadMore.classList.contains(this._elMod('loadMore', 'hidden')))
                this.loadMore.classList.add(this._elMod('loadMore', 'hidden'));
        } else {
            /* show iframe, hide no products text */
            this.content.classList.remove(this._elMod('content', 'hidden'));
            if (!this.noProducts.classList.contains(this._elMod('noProducts', 'hidden')))
                this.noProducts.classList.add(this._elMod('noProducts', 'hidden'));
            /* enable filter */
            this.filter.enable();
            /* update number of products */
            this.numProducts.textContent = this.wishlistSize;
            /* show/hide load more if */
            if (this.wishlistSize > this.currentSize) {
                this.loadMore.classList.remove(this._elMod('loadMore', 'hidden'));
            } else {
                if (!this.loadMore.classList.contains(this._elMod('loadMore', 'hidden')))
                    this.loadMore.classList.add(this._elMod('loadMore', 'hidden'));
                /* adjust current size */
                this.currentSize = this.wishlistSize;
            }
        }
        await delay(500);
        /* set iframe height = content height */
        this.iframe.style.height = `${this.wishlistScrollHeight}px`;
    }
}
