import { getStoreManager } from '../store-manager';

export default class Component {
    constructor(name, root) {
        this.name = name;
        if (this.name.startsWith('.')) {
            this.name = this.name.substring(1);
        }
        this.root = root;

        this.coopPages = window.coopPages || [];
        const coopPagesRegex = window.coopPagesRegex || [];
        this.coopPagesRegex = coopPagesRegex.map((item) => new RegExp(item));

        this.storeManager = getStoreManager();
        this.listeners = [];
        this.storeListeners = [];
    }

    getName() {
        return this.name;
    }

    getRoot() {
        return this.root;
    }

    getSelector() {
        return '.' + this.name;
    }

    dispose() {
        this._removeAllListeners();
        this._removeAllStoreListeners();
    }

    //Listeners (standard)
    /**
     * Add listener to this component
     * @param {string} ev
     * @param {(event) => void} cb
     * @param {string | Element | Document | Window} el
     * @param {*} options
     * @returns
     */
    _addListener(ev, cb, el = 'root', options = {}) {
        //load events
        let events = [];
        if (typeof ev == 'string') {
            events = [ev];
        } else if (ev instanceof Array) {
            events = ev;
        } else {
            console.warn('Invalid addListener call');
            return;
        }

        //load elements if necessary
        let element = null;
        if (el == 'root') {
            element = this.root;
        } else if (typeof el == 'string') {
            element = this._dEl(el);
        } else if (el instanceof Element || el instanceof Document || el instanceof Window) {
            element = el;
        }

        //check element
        if (!element) {
            console.debug('Event listener not added due to missing element', el);
            return;
        }

        for (const event of events) {
            this._internalAddListenerTo(element, event, cb, options);
        }
    }

    _internalAddListenerTo(element, event, callback, options = {}) {
        element.addEventListener(event, callback, options);
        this.listeners.push({
            element: element,
            event: event,
            callback: callback,
            options: options,
        });
    }

    _removeAllListeners() {
        for (const listener of this.listeners) {
            listener.element.removeEventListener(listener.event, listener.callback, listener.options);
        }
    }

    // Store
    _emit(path, data, merge = false) {
        this.storeManager.emit(path, data, merge);
    }

    _emitIncrement(path) {
        this.storeManager.emitIncrement(path);
    }

    _emitDecrement(path) {
        this.storeManager.emitDecrement(path);
    }

    _emitToggle(path) {
        this.storeManager.emitToggle(path);
    }

    _addStoreListener(match, cb) {
        this.storeManager.addListener(match, cb);
        this.storeListeners.push({
            match,
            callback: cb,
        });
    }

    _removeAllStoreListeners() {
        for (const listener of this.storeListeners) {
            this.storeManager.removeListener(listener.match, listener.callback);
        }
    }

    //elements methods
    _elMod(elName, modifier, addDot = false) {
        return `${addDot ? '.' : ''}${this.name}__${elName}--${modifier}`;
    }

    _mod(modifier, addDot = false) {
        return `${addDot ? '.' : ''}${this.name}--${modifier}`;
    }

    _el(elName, addDot = false) {
        return `${addDot ? '.' : ''}${this.name}__${elName}`;
    }

    _dEl(elName, all = false) {
        if (all) {
            return Array.from(this.root.querySelectorAll(this._el(elName, true)));
        } else {
            return this.root.querySelector(this._el(elName, true));
        }
    }

    _dMod(modifier, all = false) {
        if (all) {
            return Array.from(this.root.querySelectorAll(this._mod(modifier, true)));
        } else {
            return this.root.querySelector(this._mod(modifier, true));
        }
    }

    _dElMod(elName, modifier, all = false) {
        if (all) {
            return Array.from(this.root.querySelectorAll(this._elMod(elName, modifier, true)));
        } else {
            return this.root.querySelector(this._elMod(elName, modifier, true));
        }
    }
}
