/*
 * Copyright 2018 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

/* eslint-disable */

module.exports = function main($) {

  const htl = $.globals;
  let data = $.globals["data"];

  let render;
  render = function* _template_global_render(args) { 
    let image = args[1]['image'] || '';
    let title = args[1]['title'] || '';
    let text = args[1]['text'] || '';
    let refreshtext = args[1]['refreshText'] || '';
    let continuelink = args[1]['continueLink'] || '';
    let $t, $n = args[0];
    $.dom.text($n,"\n\n    ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mw22-confirmed-profile__wrapper", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n\n        ");
    $t = $.dom.create("img",true,true);
    $.dom.attr($t, 'class', "mw22-confirmed-profile__image", 'attribute');
    const var_attrValue0 = image["url"];
    if (!$.col.empty(var_attrValue0)) {
      $.dom.attr($t, 'src', var_attrValue0, 'attribute');
    }
    const var_attrValue1 = image["alt"];
    if (!$.col.empty(var_attrValue1)) {
      $.dom.attr($t, 'alt', var_attrValue1, 'attribute');
    }
    $n = $.dom.push($n,$t);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n        ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mw22-confirmed-profile__result", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n            ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mw22-confirmed-profile__title", 'attribute');
    $n = $.dom.push($n,$t);
    const var_2 = yield $.xss(title, "html");
    $.dom.append($n, var_2);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mw22-confirmed-profile__txt", 'attribute');
    $n = $.dom.push($n,$t);
    const var_3 = yield $.xss(text, "html");
    $.dom.append($n, var_3);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    const var_testVariable4 = refreshtext;
    if (var_testVariable4) {
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "mw22-confirmed-profile__refreshTxt", 'attribute');
      $n = $.dom.push($n,$t);
      const var_5 = yield $.xss(refreshtext, "html");
      $.dom.append($n, var_5);
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n            ");
    const link = continuelink;
    if (link) {
      $.dom.text($n,"\n                ");
      const var_tagVar6 = yield $.xss(link["element"], "elementNameNoFallback");
      if (var_tagVar6) {
        $t = $.dom.create(var_tagVar6,false,false);
      }
      if (!var_tagVar6) {
        $t = $.dom.create("a",false,false);
      }
      const var_attrMap7 = link["hashMap"];
      const var_attrName_id8 = "id";
      const var_mapContains_id9 = var_attrMap7["id"];
      if (!$.col.empty(var_mapContains_id9)) {
        $.dom.attr($t, var_attrName_id8, var_mapContains_id9, 'attribute');
      }
      if ((var_mapContains_id9) == (null)) {
        const var_attrValue10 = link["uniqueId"];
        if (!$.col.empty(var_attrValue10)) {
          $.dom.attr($t, 'id', var_attrValue10, 'attribute');
        }
      }
      const var_attrValue11 = ("mw22-confirmed-profile__link ") + (link["classes"]);
      if (!$.col.empty(var_attrValue11)) {
        $.dom.attr($t, 'class', var_attrValue11, 'attribute');
      }
      const var_ignoredAttributes12 = {"id": true, "class": true, };
      for (const var_attrIndex15 of $.col.keys(var_attrMap7)) {
        const var_attrName13 = $.col.get(var_attrMap7, var_attrIndex15);
        const var_attrNameEscaped14 = yield $.xss(var_attrName13, "attributeName");
        if (var_attrNameEscaped14) {
          if (!var_ignoredAttributes12[var_attrName13]) {
            const var_attrContent16 = var_attrMap7[var_attrName13];
            if (!$.col.empty(var_attrContent16)) {
              $.dom.attr($t, var_attrNameEscaped14, var_attrContent16, 'attribute');
            }
          }
        }
      }
      $n = $.dom.push($n,$t);
      const var_17 = yield $.xss(link["label"], "html");
      $.dom.append($n, var_17);
      $.dom.text($n,"\n                ");
      if (var_tagVar6) {
        $n = $.dom.pop($n);
      }
      if (!var_tagVar6) {
        $n = $.dom.pop($n);
      }
      $.dom.text($n,"\n            ");
    }
    $.dom.text($n,"\n        ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n    ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n");
  };
  $.template('global', 'render', render);

  return $.run(function* () {
    let $t, $n = $.dom.start();
    return $.dom.end();

  });
};
