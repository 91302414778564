import PopupComponent from '../../../../../../libs/components/popup-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import './style.scss';

export default class PasswordChanged extends PopupComponent {
    constructor(name, root) {
        super(name, root);
        this.ctaAction = this._dEl('ctaAction');
        this._addEventListeners();
    }

    _addEventListeners() {
        this._addListener(
            'click',
            (event) => {
                event.preventDefault();
                flowManager.complete();
            },
            this.ctaAction
        );
    }

    /* override */
    _onCancel() {
        flowManager.complete();
    }
}
