import PopupComponent from '../../../../../../libs/components/popup-component';
import { runTemplate } from '../../../../../../libs/htl-runtime/HTMLRuntime';
import { flowManager } from '../../../../../../libs/flow-manager';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import popupTpl from '../../../templates/mt13-coupon-popup/mt13-coupon-popup.html';
import './style.scss';

export default class CouponPopup extends PopupComponent {
    constructor(name, root) {
        super(name, root);

        this.barcode = this._dEl('barcode');
    }

    _onBeforeOpen() {
        this.root.innerHTML = '';
        let data = flowManager.getDataFromFlow('coupon-popup');
        data.labels = this.root.hasAttribute('data-labels-json') ? JSON.parse(this.root.dataset.labelsJson) : null;

        let popupContent = runTemplate(popupTpl, data);
        if (popupContent) {
            this.root.appendChild(popupContent);
            if (data.coupon.codiceCoupon) {
                let clonedNode = this.barcode.cloneNode(true);
                clonedNode.dataset.barcode = data.coupon.codiceCoupon;
                clonedNode.classList.remove('hidden');
                clonedNode.classList.add('mt13-coupon-popup__barcode');
                clonedNode.classList.remove('mp22-coupon-popup__barcode');

                let barcodeWrapper = this.root.querySelector('.mt13-coupon-popup__barcodeWrapper');
                if (barcodeWrapper) {
                    barcodeWrapper.appendChild(clonedNode);
                    this._renderBarcode(clonedNode);
                }
            }
        }
    }

    /* override */
    _onOpen() {
        super._onOpen();
        trackEvent(TRACKABLE_EVENT.pageview, FUNNEL_NAME.couponsWallet, FUNNEL_STEP.couponDetail);
        this.root.closest('.mt4-popup').classList.add('mp22-coupon-popup__wrapperPop');
    }

    async _renderBarcode(clonedNode) {
        if (!clonedNode || !clonedNode.dataset.barcode) return;
        const barcode = clonedNode.dataset.barcode;
        const barcodeWithSpaces = barcode.split('').reduce((prev, curr) => prev + ' ' + curr, '');
        (await import('jsbarcode'))
            .default('.mt13-coupon-popup__barcode')
            .EAN13(barcode, {
                flat: true,
                fontSize: 14,
                fontOptions: 'regular',
                textMargin: 7,
                height: 71,
                width: 3,
                text: barcodeWithSpaces,
            })
            .render();
    }

    /* override */
    _onCancel() {
        flowManager.complete();
        this.root.removeChild(this.root.querySelector('.mt13-coupon-popup'));
        const popup = this.root.closest('.mt4-popup');
        if (popup) {
            popup.classList.remove('mp22-coupon-popup__wrapperPop');
        }
    }
}


