import { apiProvider } from '../../../../../../libs/api-provider';
import WizardComponent from '../../../../../../libs/components/wizard-component';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import { delay } from '../../../../../../libs/utils';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import { storeManager } from '../../../../../../libs/store-manager';
import contentOkTpl from '../mw22-confirmed-profile/partials/content-ok.html';
import contentErrorTpl from '../mw22-confirmed-profile/partials/content-error.html';
import { runTemplate } from '../../../../../../libs/htl-runtime/HTMLRuntime';
import './style.scss';

export default class ConfirmedProfile extends WizardComponent {
    constructor(name, root) {
        super(name, root);

        this.okContainer = this._dElMod('container', 'ok');
        this.errorContainer = this._dElMod('container', 'error');
        this.removeLink = false;

        this._runLogic();
    }

    async _runLogic() {
        // get configuration map
        try {
            this.configurationMap = JSON.parse(this.root.dataset.configurationMap);
            console.log('configurationMap confirmed profile', this.configurationMap);
        } catch (error) {
            console.warn('Could not parse configuration map mw22');
            return;
        }

        openLoader('main');
        await delay(2000);

        // get params and render content according to the touchpoint
        const params = this._getParams();
        this._renderOk(params.domain, params.regType != 'standard');
        this._renderError(params.domain, params.regType != 'standard');

        // emit params to generate mc7-strillo-app and mc8-cards-icon-text dynamically for author
        if (window.isAuthor) {
            storeManager.emit('touchpointParams', {
                regType: params.regType,
                domain: params.domain,
            });
        }

        // check params
        if (this._invalidParams(params)) return;

        // update continue link href
        this.link = this._dEl('link');
        if (this.link && params.cb) {
            const urlParams = new URLSearchParams({ cb: params.cb });
            this.link.href = this.link.href + '?' + urlParams.toString();
        }

        // call service
        try {
            await apiProvider.confirmProfile(params);
            trackEvent(TRACKABLE_EVENT.pageview, FUNNEL_NAME.registration, FUNNEL_STEP.confirmProfileActivation);

            // emit params to generate mc7-strillo-app and mc8-cards-icon-text dynamically
            storeManager.emit('touchpointParams', {
                regType: params.regType,
                domain: params.domain,
            });
            this._showOk();
        } catch (error) {
            this._showError();
        } finally {
            closeLoader('main');
        }
    }

    _renderOk(domain = 'myconad', withCard = false) {
        // load configuration
        const tmpConf = this.configurationMap[domain];
        let configuration;
        if (tmpConf) {
            configuration = tmpConf[withCard ? 'card' : 'nocard'];
        } else {
            configuration = this.configurationMap['myconad'][withCard ? 'card' : 'nocard'];
        }

        // set container aria-label
        if (configuration.title) this.okContainer?.setAttribute('aria-label', configuration.title);
        // render content
        const contentOkData = {
            image: configuration.image,
            title: configuration.title,
            text: configuration.text,
            refreshText: configuration.refreshText
        };
        if (window.isAuthor || !this.removeLink) contentOkData.continueLink = configuration.continueLink;
        const contentOkEl = runTemplate(contentOkTpl, contentOkData, this._el('wrapper', true));
        this.okContainer.append(contentOkEl);
        let linksElement = this.okContainer.querySelectorAll('[data-link-content-ref]');

        linksElement.forEach((element) => {
            let url = element.dataset.linkExternalRef;
            let target = element.dataset.linkWindowTarget;
            let title = element.dataset.linkTitle;

            element.setAttribute('href', url);
            element.setAttribute('target', target);
            element.setAttribute('title', title);
        });
    }

    _renderError(domain = 'myconad', withCard = false) {
        // load configuration
        const tmpConf = this.configurationMap[domain];
        let configuration;
        if (tmpConf) {
            configuration = tmpConf[withCard ? 'card' : 'nocard'];
        } else {
            configuration = this.configurationMap['myconad'][withCard ? 'card' : 'nocard'];
        }

        // set container aria-label
        if (configuration.errorTitle) this.errorContainer?.setAttribute('aria-label', configuration.errorTitle);
        // render content
        const contentErrorData = {
            errorImage: configuration.errorImage,
            errorTitle: configuration.errorTitle,
            errorText: configuration.errorText,
        };
        const contentErrorEl = runTemplate(contentErrorTpl, contentErrorData, this._el('wrapper', true));
        this.errorContainer.append(contentErrorEl);
    }

    _showOk() {
        this.okContainer?.classList?.add(this._elMod('container', 'show'));
    }

    _showError() {
        this.errorContainer?.classList?.add(this._elMod('container', 'show'));
    }

    _getParams() {
        const urlParams = new URLSearchParams(window.location.search);
        const params = {
            cce: urlParams.get('cce'),
            da: urlParams.get('da'),
            a: urlParams.get('a'),
            cca: urlParams.get('cca'),
            socialToken: urlParams.get('socialToken'),
            cb: urlParams.get('cb'),
            updateGccMail: urlParams.get('updateGccMail'),
        };

        // get domain and cb
        if (params.cb) {
            params.regType = 'standard';
            params.domain = 'myconad';

            try {
                let url = new URL(params.cb);

                // get regType
                const regType = url.searchParams.get('regType');
                if (regType) params.regType = regType;
                if (params.regType !== 'standard') {
                    /* remove link if registration with card, because it will be shown by the mc8-cards-icon-text component */
                    this.removeLink = true;
                }

                // get domain
                let domain;
                for (const ref in window.allowedReferrers) {
                    if (!window.allowedReferrers[ref]) continue;
                    if (url.host.indexOf(window.allowedReferrers[ref]) >= 0) {
                        domain = ref;
                        break;
                    }
                }
                if (domain) params.domain = domain;
            } catch (error) {
                console.warn(error);
            }
        }
        return params;
    }

    _invalidParams(params) {
        if (!params.cce || !params.da) {
            console.warn('Invalid request to confirm profile');
            this._showError();
            closeLoader('main');
            return true;
        }
        return false;
    }
}
