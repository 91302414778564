import Component from '../../../../../libs/components/component';
import { flowManager } from '../../../../../libs/flow-manager';
import { storeManager } from '../../../../../libs/store-manager';
import { FUNNEL_NAME, TRACKABLE_EVENT, trackEvent } from '../../../../../libs/tracking-manager-old';
import './style.scss';

export default class Order extends Component {
    constructor(name, root) {
        super(name, root);

        this.page = document.querySelector('.ms1-page');
        this.manageOrder = this._dEl('manageOrder');
        this.orderAgain = this._dEl('orderAgain');
        this.manageOrderDropdown = this._dEl('manageOrderDropdown');
        this.modifyDayTime = this._dEl('modifyDayTime');
        this.addRemoveProducts = this._dEl('addRemoveProducts');
        this.orderTitle = this._dEl('order');
        this.cancelOrder = this._dEl('cancelOrder');
        this.orderDetail = this._dEl('orderDetail');
        this.address = this._dEl('address');
        this.addressText = this.address?.querySelector(`button${this._el('labelText', true)}`);
        this.addressDetailTooltip = this._dEl('addressDetailTooltip');
        if (this.root.dataset.pdv) this.pdv = JSON.parse(this.root.dataset.pdv);
        if (this.root.dataset.order) this.order = JSON.parse(this.root.dataset.order);

        this._addEventListeners();
    }

    _addEventListeners() {
        this.orderAgain?.addEventListener('click', (event) => {
            event.preventDefault();
            this._orderAgain();
        });
        this.manageOrder?.addEventListener('click', (event) => {
            event.preventDefault();
            if (this._amendExpired()) return;
            this._toggleManageOrderDropdown();
        });
        this.manageOrder?.addEventListener('keydown', (event) => {
            if (event.key !== 'Escape') return;
            this.closeManageOrderDropdown();
        });
        this.modifyDayTime?.addEventListener('click', (event) => {
            event.preventDefault();
            if (this._amendExpired()) return;
            trackEvent(TRACKABLE_EVENT.clickManageOrder, FUNNEL_NAME.orders, this.modifyDayTime.innerText);
            this.manageOrderDropdown?.classList.remove(this._elMod('manageOrderDropdown', 'show'));
            this.manageOrder?.classList.remove(this._elMod('manageOrder', 'open'));
            this._modifyOrder('change-timeslot');
        });
        this.addRemoveProducts?.addEventListener('click', (event) => {
            event.preventDefault();
            if (this._amendExpired()) return;
            trackEvent(TRACKABLE_EVENT.clickManageOrder, FUNNEL_NAME.orders, this.addRemoveProducts.innerText);
            this.manageOrderDropdown?.classList.remove(this._elMod('manageOrderDropdown', 'show'));
            this.manageOrder?.classList.remove(this._elMod('manageOrder', 'open'));
            this._modifyOrder('change-products');
        });
        this.cancelOrder?.addEventListener('click', (event) => {
            event.preventDefault();
            if (this._amendExpired()) return;
            trackEvent(TRACKABLE_EVENT.clickManageOrder, FUNNEL_NAME.orders, this.cancelOrder.innerText);
            this.manageOrderDropdown?.classList.remove(this._elMod('manageOrderDropdown', 'show'));
            this.manageOrder?.classList.remove(this._elMod('manageOrder', 'open'));
            this._cancelOrder();
        });
        /* close on click outside */
        this._addListener(
            'click',
            (event) => {
                const target = event.target;
                const clickedOrder = target.closest(this.getSelector());
                const clickedOutsideAnyOrder = !clickedOrder;
                const clickedInsideAnotherOrder = !!clickedOrder && this.root.id !== clickedOrder.id;
                const clickedInsideSelf = !!clickedOrder && this.root.id === clickedOrder.id;
                const clickedInsideAnyButtonOrMenu =
                    !!target.closest(this._el('manageOrder', true)) ||
                    !!target.closest(this._el('manageOrderDropdown', true));
                const closeDropdown =
                    this._isManageOrderDropdownOpen() &&
                    (clickedOutsideAnyOrder ||
                        clickedInsideAnotherOrder ||
                        (clickedInsideSelf && !clickedInsideAnyButtonOrMenu));
                if (closeDropdown) this.closeManageOrderDropdown();
            },
            document
        );

        /* below event listeners are for non-migrated orders only */
        if (this.root.classList.contains(this._mod('migrated'))) return;

        this.orderDetail?.addEventListener('click', (event) => {
            event.preventDefault();
            /* redirect to order detail page with code and bEcommerce parameters in query string */
            const orderDetailPage = this.orderDetail.getAttribute('href');
            const url = new URL(orderDetailPage, window.location.origin);
            if (orderDetailPage && this.order.code && this.pdv.becommerce) {
                url.searchParams.set('code', this.order.code);
                url.searchParams.set('bEcommerce', this.pdv.becommerce);
                window.location = url.href;
            }
        });

        this.addressText?.addEventListener('mouseover', (event) => {
            event.preventDefault();
            this._openAddressTooltip();
        });
        this.addressText?.addEventListener('mouseout', (event) => {
            event.preventDefault();
            this._closeAddressTooltip();
        });
        this.addressText?.addEventListener(
            'focusin',
            (event) => {
                event.preventDefault();
                this._openAddressTooltip();
            },
            true
        );
        this.addressText?.addEventListener(
            'focusout',
            (event) => {
                event.preventDefault();
                this._closeAddressTooltip();
            },
            true
        );
        this.addressText?.addEventListener('keydown', (event) => {
            if (event.key !== 'Escape') return;
            this._closeAddressTooltip();
        });
    }

    _amendExpired() {
        /* check amend expired real time */
        if (this.order.amendTimestamp && new Date().getTime() >= this.order.amendTimestamp) {
            flowManager.startFlow({
                flowName: 'unmodifiable-order',
                flowSteps: [{ name: 'unmodifiable-order' }],
            });
            return true;
        }
        return false;
    }

    _emitSelectedOrder() {
        if (this.pdv && this.order && this.order.typeOfService) {
            const selectedOrder = {
                becommerce: this.pdv.becommerce?.toLowerCase(),
                pointOfServiceId: this.pdv.pointOfServiceId,
                typeOfService: this.order.typeOfService,
                orderCode: this.order.code,
                amendTimestamp: this.order.amendTimestamp,
                timeslotId: this.order.timeslotId,
                deliveryAddress: this.addressText.textContent.trim(),
                deliveryDate: new Date(this.root.dataset.orderDeliveryDate).getTime(),
            };
            if (this.pdv.deliveryArea) selectedOrder.deliveryArea = this.pdv.deliveryArea;
            storeManager.emit('selectedOrder', selectedOrder);
        } else {
            console.warn(`Missing pdv info for order "${this.order.code}"`);
        }
    }

    _modifyOrder(modifyOrderOption) {
        this._emitSelectedOrder();
        const flowData = {
            flowName: 'modify-order',
            options: {
                modifyOrder: modifyOrderOption,
            },
        };
        switch (modifyOrderOption) {
            case 'change-timeslot':
                flowData.flowSteps = [{ name: 'modify-order' }];
                break;
            case 'change-products':
                flowData.flowSteps = [{ name: 'modify-order' }];
                break;
            default:
                console.warn(`Unhandled modify order option "${modifyOrderOption}" provided in ${this.getName()}`);
                break;
        }
        flowManager.startFlow(flowData);
    }

    _cancelOrder() {
        this._emitSelectedOrder();
        flowManager.startFlow({
            flowName: 'cancel-order',
            flowSteps: [{ name: 'cancel-order' }],
        });
    }

    _orderAgain() {
        this._emitSelectedOrder();
        flowManager.startFlow({
            flowName: 'repeat-order',
            flowSteps: [{ name: 'repeat-order' }, { name: 'reorder-error', disableBack: true}],
        });
    }

    _isManageOrderDropdownOpen() {
        return this?.manageOrderDropdown?.classList.contains(this._elMod('manageOrderDropdown', 'show'));
    }
    _toggleManageOrderDropdown() {
        if (!this.manageOrderDropdown) return;
        this._isManageOrderDropdownOpen() ? this.closeManageOrderDropdown() : this.openManageOrderDropdown();
    }
    openManageOrderDropdown() {
        if (!this.manageOrder || !this.manageOrderDropdown || this._isManageOrderDropdownOpen()) return;
        this.manageOrderDropdown.classList.add(this._elMod('manageOrderDropdown', 'show'));
        this.manageOrder.setAttribute('aria-expanded', 'true');
        this.manageOrder.classList.add(this._elMod('manageOrder', 'open'));
    }
    closeManageOrderDropdown() {
        if (!this.manageOrder || !this.manageOrderDropdown || !this._isManageOrderDropdownOpen()) return;
        this.manageOrderDropdown.classList.remove(this._elMod('manageOrderDropdown', 'show'));
        this.manageOrder.setAttribute('aria-expanded', 'false');
        this.manageOrder.classList.remove(this._elMod('manageOrder', 'open'));
    }

    _openAddressTooltip() {
        this?.address?.classList.add(this._elMod('address', 'showDetail'));
    }
    _closeAddressTooltip() {
        this?.address?.classList.remove(this._elMod('address', 'showDetail'));
    }
}
