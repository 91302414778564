import { apiProvider } from '../../../../../../libs/api-provider';
import ModalComponent from '../../../../../../libs/components/modal-component';
import { dictionary } from '../../../../../../libs/dictionary-provider';
import { flowManager } from '../../../../../../libs/flow-manager';
import { formToJSON } from '../../../../../../libs/form-to-json';
import { informativaManager } from '../../../../../../libs/informativa-manager';
import { register } from '../../../../../../libs/register';
import { storeManager } from '../../../../../../libs/store-manager';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import { isPositive, checkFormWithoutValidation } from '../../../../../../libs/utils';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import './style.scss';

export default class RequestCardStep2 extends ModalComponent {
    constructor(name, root) {
        super(name, root);

        this.form = this._dEl('form');
        this.submit = this._dEl('submit');
        this.error = this._dEl('error');
        if (this.error.dataset.recoverPwd) this.recoverPwd = JSON.parse(this.error.dataset.recoverPwd);
        this.currentPrivacy = null;
        this.modality = 'associate-dm';

        this._initPrivacyText();
        this._initPrivacyCheckbox();
        //this._checkFormValid();
        this._initRegolamentoText();
        this._addEventListeners();
        this._addStoreListeners();
    }

    /* override */
    async _onOpen() {
        await super._onOpen();
        //this._checkFormValid();

        const ecRegistrationData = storeManager.get('ecRegistrationData');
        const pointOfServiceId = ecRegistrationData?.pointOfServiceId;
        const cooperativeId = ecRegistrationData?.cooperativeId;
        const selectedPdv = storeManager.get('selectedPdv');
        this.userInfo = null;
        if (pointOfServiceId && cooperativeId) {
            /* case pdv selected on ecommerce (registration flow from ecommerce) */
            this.userInfo = {
                coop: pointOfServiceId,
                store: cooperativeId,
            };
        } else if (selectedPdv) {
            /* case pdv selected during registration flow */
            this.userInfo = {
                coop: selectedPdv.codiceCooperativa,
                store: selectedPdv.anacanId,
            };
        }
        if (storeManager.get('currentFlow').includes('conad-register')) {
            this._trackRegistrationFunnel(TRACKABLE_EVENT.pageview, FUNNEL_STEP.consentsScreen, this.userInfo);
        }
        if (!this.submit.classList.contains(this._elMod('submit', 'disabled'))) {
            this.submit.classList.add(this._elMod('submit', 'disabled'));
        }
    }

    _addEventListeners() {
        this._dEl('box', true).forEach((box) => {
            box.addEventListener('ecInputChanged', (event) => {
                this._checkRadio(event.target.closest(this._el('box', true)), isPositive(event.data.value));
                this.ps?.update();
                if (checkFormWithoutValidation(this.form)) {
                    this.submit.classList.remove(this._elMod('submit', 'disabled'));
                } else {
                    this.submit.classList.add(this._elMod('submit', 'disabled'));
                }
            });
        });
        this._dEl('checkbox', true).forEach((checkbox) => {
            checkbox.addEventListener('ecInputChanged', () => {
                if (checkFormWithoutValidation(this.form)) {
                    this.submit.classList.remove(this._elMod('submit', 'disabled'));
                } else {
                    this.submit.classList.add(this._elMod('submit', 'disabled'));
                }
            });
        });
        /*this.form.addEventListener('ecInputChanged', () => {
            this._checkFormValid();
        });*/
        this.form.addEventListener('submit', (event) => {
            event.preventDefault();
        });
        this.submit.addEventListener('click', async (event) => {
            event.preventDefault();
            /*if (this.submit.classList.contains('invalid')) {
                return;
            }*/
            if (!this.form.checkValidity() || !this._validRadios()) {
                const first = this.form.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
                return;
            }

            // update userData with privacy consents
            const userData = {
                ...storeManager.get('userData'),
                ...formToJSON(this.form),
            };
            storeManager.emit('userData', userData);

            let result = false;
            openLoader('main');

            switch (this.modality) {
                case 'associate-dm':
                    result = await this._associateDM();
                    break;
                case 'registration':
                    closeLoader('main');
                    flowManager.next('card-added');
                    return;
                default:
                    break;
            }

            closeLoader('main');

            if (!result) {
                this.error.innerHTML = this.root.dataset.genericError;
                trackEvent(
                    TRACKABLE_EVENT.formError,
                    FUNNEL_NAME.insiemeCard,
                    FUNNEL_STEP.requestInsiemeCardForm,
                    null,
                    null,
                    null,
                    {
                        errorField: dictionary.getFEMessage('formSubmitError'),
                        errorText: dictionary.getFEMessage('genericError'),
                    }
                );
                this.error.classList.remove(this._elMod('error', 'hidden'));
            }
        });

        /* form errors */
        this.form.addEventListener('ecFormError', (event) => {
            event.preventDefault();
            if (!event.data.errorField) return;
            trackEvent(
                TRACKABLE_EVENT.formError,
                FUNNEL_NAME.insiemeCard,
                FUNNEL_STEP.requestInsiemeCardForm,
                null,
                null,
                null,
                {
                    errorField: event.data.errorField,
                    errorText: event.data.errorText,
                }
            );
            event.stopPropagation();
        });
    }

    _addStoreListeners() {
        this._addStoreListener('selectedPdv', () => {
            this._setRegolamento();
        });

        this._addStoreListener('userData', async (path, data) => {
            const selectedPdv = storeManager.get('selectedPdv');
            this.currentPrivacy = await informativaManager.getInformativa(data, selectedPdv);
            this._setInformativa();
        });

        //intercept flow options and get modality if defined
        this._addStoreListener(/flow\/[^/]+$/g, (path, flow) => {
            if (flow.options?.requestCardModality) this.modality = flow.options?.requestCardModality;
        });
    }

    async _associateDM() {
        let result = false;
        try {
            const selectedPdv = storeManager.get('selectedPdv');
            const userData = {
                ...storeManager.get('userData'),
                ...storeManager.get('requestCardData'),
                ...formToJSON(this.form),
                dmCard: true,
            };
            const data = {
                ...userData,
                pdvAnacanId: selectedPdv.anacanId,
            };
            result = await apiProvider.isDmValid(data);
            if (result) {
                result = await apiProvider.associateDM(data);
                if (result) {
                    flowManager.next('card-added');
                }
            }
        } catch (error) {
            console.warn('Cannot call dm validation check or dm validation add', error);
        }

        return result;
    }

    _initPrivacyText() {
        const privacyText = this._dEl('privacyText');
        const text = privacyText.dataset.text;
        if (!text || text == '') return;
        const linkLabel = privacyText.dataset.linkLabel || dictionary.get('privacy policy');
        if (!text.includes('$privacy')) {
            privacyText.innerText = text;
            return;
        }
        const split = text.split('$privacy');
        const link = document.createElement('a');
        link.href = '#';
        link.classList.add(this._el('privacyLink'));
        link.innerText = linkLabel;
        privacyText.append(split[0], link, split[1]);
    }

    _initPrivacyCheckbox() {
        const privacyCheckbox = this._dEl('privacy');
        if (!privacyCheckbox) return;

        const privacyText = privacyCheckbox.querySelector('.mt17-checkbox__labelText');
        if (!privacyText) return;

        const privacyHtml = privacyText.innerHTML;
        if (!privacyHtml) return;

        const linkLabel = this.root.dataset.privacyLabel || dictionary.get('privacy label');
        if (!privacyHtml.includes('$privacy')) {
            privacyText.innerHTML = privacyHtml;
            return;
        }
        const split = privacyHtml.split('$privacy');
        const link = document.createElement('a');
        link.href = '#';
        link.classList.add(this._el('privacyLink'));
        link.innerText = linkLabel;

        privacyText.innerHTML = split[0] + link.outerHTML + split[1];
    }

    _initRegolamentoText() {
        const regolamentoCheckbox = this._dEl('presavisione');
        if (!regolamentoCheckbox) return;

        const regolamentoText = regolamentoCheckbox.querySelector('.mt17-checkbox__labelText');
        if (!regolamentoText) return;

        const regolamentoHtml = regolamentoText.innerHTML;
        if (!regolamentoHtml) return;

        const linkLabel = this.root.dataset.regolamentoLabel || dictionary.get('regolamento label');
        if (!regolamentoHtml.includes('$regolamento')) {
            regolamentoText.innerHTML = regolamentoHtml;
            return;
        }
        const split = regolamentoHtml.split('$regolamento');
        const link = document.createElement('a');
        link.href = '#';
        link.classList.add(this._el('regolamentoLink'));
        link.innerText = linkLabel;

        regolamentoText.innerHTML = split[0] + link.outerHTML + split[1];
    }

    _setInformativa() {
        const privacyLinks = this._dEl('privacyLink', true);
        if (!privacyLinks) {
            console.warn('Missing privacy links.');
            return;
        }

        for (const privacyLink of privacyLinks) {
            privacyLink.href = this.currentPrivacy.linkPdfInformativa;
            privacyLink.title = `informativa ${this.currentPrivacy.version}`;
            privacyLink.target = '_blank';
        }
    }

    _setRegolamento() {
        const regolamentoLink = this._dEl('regolamentoLink');
        if (!regolamentoLink) {
            console.warn('Missing regolamento link.');
            return;
        }

        //get current pdv
        const pdv = storeManager.get('selectedPdv');
        if (!pdv) return;

        //set regolamento link
        const coop = pdv.codiceCooperativa;
        regolamentoLink.href = apiProvider.getRegolamentoLink(coop);
        regolamentoLink.title = `regolamento carta insieme`;
        regolamentoLink.target = '_blank';
    }

    _resetFormInputs() {
        if (!this.form) return;
        ['mt17-checkbox', 'mt19-radio'].forEach((el) => {
            this.form.querySelectorAll(`.${el}`).forEach((field) => {
                register.getClass(field).reset();
            });
        });
    }

    _checkRadio(box, valid) {
        const boxNotSelected = box.querySelector(this._el('boxNotSelected', true));
        if (!valid) {
            boxNotSelected.classList.add(this._elMod('boxNotSelected', 'show'));
        } else {
            boxNotSelected.classList.remove(this._elMod('boxNotSelected', 'show'));
        }
    }

    _validRadios() {
        return Array.from(this.form.querySelectorAll(`.mt19-radio`))
            .map((radio) => register.getClass(radio))
            .map((obj) => !!obj.isValid())
            .reduce((x, y) => x && y);
    }

    _checkFormValid() {
        if (!this.form.checkValidity() || !this._validRadios()) {
            this.submit.classList.add('invalid');
            this.submit.setAttribute('title', dictionary.getFEMessage('fillInFields'));
        } else {
            this.submit.classList.remove('invalid');
            this.submit.removeAttribute('title');
        }
    }

    /* override */
    _onCancel() {
        flowManager.complete();
    }

    _trackRegistrationFunnel(event, funnelStep, userInfoObj = null) {
        const typeOfService = storeManager.get('ecRegistrationData')?.typeOfService;
        const originEcommerce = storeManager.get('ecRegistrationData')?.originEcommerce;
        const userInfo =
            userInfoObj && userInfoObj.coop && userInfoObj.store
                ? { coop: userInfoObj.coop, store: userInfoObj.store }
                : null;
        if (storeManager.get('currentFlow').includes('conad-register')) {
            if (originEcommerce && typeOfService) {
                trackEvent(event, FUNNEL_NAME.registration, funnelStep, typeOfService, null, userInfo);
            } else {
                trackEvent(event, FUNNEL_NAME.registration, funnelStep, null, null, userInfo);
            }
        }
    }
}
