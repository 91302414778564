import ValidableComponent from '../../../../../libs/components/validable-component';
import { dictionary } from '../../../../../libs/dictionary-provider';
import './style.scss';

export default class Toggle extends ValidableComponent {
    constructor(name, root) {
        super(name, root);
        this.label = this._dEl('label');
        this.input = this._dEl('input');
        this._addEventListeners();
    }

    _getInput() {
        return this.input;
    }

    _addEventListeners() {
        this.input.addEventListener('change', (event) => {
            event.preventDefault();
            this.input.checked
                ? this.root.classList.add(this._mod('checked'))
                : this.root.classList.remove(this._mod('checked'));
            this._checkState();
            this._changedInput();
        });

        this.root.addEventListener(
            'invalid',
            () => {
                this.setState('error');
                this._requireField();
            },
            true
        );
    }

    isValid() {
        return this.input.required
            ? this.input.validity.valid && !this.input.validity.valueMissing
            : this.input.validity.valid || this.input.validity.valueMissing;
    }

    _requireField() {
        this.setErrorText(dictionary.getFEMessage('requiredField'));
    }

    setChecked(checked) {
        if (checked !== true && checked !== false) return;
        this.input.checked = checked;
        this.input.checked
            ? this.root.classList.add(this._mod('checked'))
            : this.root.classList.remove(this._mod('checked'));
        this._checkState();
        this._changedInput();
    }

    enable() {
        this.label.classList.remove(this._elMod('label', 'disabled'));
        this.label.setAttribute('tabindex', '0');
    }

    disable() {
        this.label.classList.add(this._elMod('label', 'disabled'));
        this.label.setAttribute('tabindex', '-1');
    }

    isDisabled() {
        return this.label.classList.contains(this._elMod('label', 'disabled'));
    }

    reset() {
        this.input.checked = false;
        this.setState('');
    }

    /* override */
    setErrorText(errorText) {
        super.setErrorText(errorText);
        if (typeof errorText !== 'string' || errorText === '') return;
        this.error.innerText = errorText;
    }
}
