import ModalComponent from '../../../../../../libs/components/modal-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import './style.scss';


export default class BolliniDonateThankYou extends ModalComponent {
    constructor(name, root) {
        super(name, root);

        this.positiveContent = this._dEl('positive');
        this.negativeContent = this._dEl('negative');
        this.submit = this._dEl('submit');
        this._addEventListeners();
    }

    _addEventListeners() {
        this.submit.addEventListener('click', (event) => {
            event.preventDefault();
            flowManager.complete();
            window.location.reload();
        });
    }

    _onOpen() {
        const data = flowManager.getDataFromFlow();
        if (data.ok) {
            this.positiveContent.classList.remove('hidden');
            this.negativeContent.classList.add('hidden');
            localStorage.setItem("temporaryBolliniTransferBlockAmount", JSON.stringify({ date: new Date()}));
        } else {
            this.positiveContent.classList.add('hidden');
            this.negativeContent.classList.remove('hidden');
        }
    }

    /* override */
    _onCancel() {
        flowManager.complete();
    }
}


