import { apiProvider } from '../../../../../../libs/api-provider';
import ModalComponent from '../../../../../../libs/components/modal-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import './style.scss';


export default class DeleteAddressModal extends ModalComponent {
    constructor(name, root) {
        super(name, root);

        this.cancel = this._dEl('cancel');
        this.delete = this._dEl('delete');

        this._addEventListeners();
    }

    _addEventListeners() {
        this._addStoreListener('deleteAddress', (path, data) => {
            /* save address data */
            this.address = {
                type: data?.type,
                id: data?.addressId
            }
        });
        this.cancel.addEventListener('click', (event) => {
            event.preventDefault();
            flowManager.back(`${this.address.type}-address`, `${this.address.type}-add`);
        });
        this.delete.addEventListener('click', async (event) => {
            event.preventDefault();
            await this._deleteAddress();
        });
    }

    async _deleteAddress() {
        console.log(`deleting ${this.address.type} address with id ${this.address.id}`);
        const data = {
            id: this?.address?.id || ''
        };
        try {
            openLoader('main');
            switch (this?.address?.type || '') {
                case 'delivery':
                    await apiProvider.deleteDeliveryAddress(data);
                    break;
                case 'billing':
                    await apiProvider.deleteBillingAddress(data);
                    break;
                default:
                    console.warn(`tried to delete an address of unknown type "${this?.address?.type}"`);
                    break;
            }
            flowManager.complete(`${this.address.type}-add`);
            let url = new URL(window.location.href);
            url.searchParams.set('tab', this.address.type);
            window.location.href = url.href;
        } catch (error) {
            console.warn(error);
        } finally {
            closeLoader('main');
        }
    }
}


