import axios from 'axios';

class ApiProvider {
    constructor() {
        const serviceLang = document.documentElement.dataset.serviceLang || 'it-it';
        this.jsonLoader = document.documentElement.dataset.jsonLoader == 'true';
        this.baseUrl = `/api/myconad/${serviceLang}`;

        this.urlMap = {
            all: this.baseUrl + '.%%.json',
            regolamentolink: this.baseUrl + '.regolamentolink.pdf',
            privacyLink: this.baseUrl + '.privacylink.pdf',
            downloadCaseAttachment: this.baseUrl + '.downloadCaseAttachment',
            //checkDmEnabled: 'https://run.mocky.io/v3/5dd5a5c0-bba4-482f-9482-5d7d29654edd', //this.baseUrl + '.checkDmEnabled.json',
        };

        this.complDataServletsMap = {
            defaultServlet: {
                get: 'getComplementaryData',
                add: 'associateComplementaryData',
                remove: 'removeComplementaryData',
            },
            favoritesFlyer: {
                get: 'disProductGetComplementaryDataServlet',
                add: 'disProductAssociateComplementaryDataServlet',
                remove: 'removeComplementaryData',
            },
            savedStores: {
                get: 'getComplementaryData',
                add: 'storesOfInterestAssociateComplementaryData',
                remove: 'removeComplementaryData',
            },
        };

        this.cached = {};
    }

    /**
     * Return the regolamento link
     * @param {*} coop The cooperative code
     * @returns
     */
    getRegolamentoLink(coop) {
        return `${this._getEndpoint('regolamentolink')}?coop=${coop.toLowerCase()}`;
    }

    /**
     * Return the regolamento link
     * @param {*} coop The cooperative code
     * @returns
     */
    getPrivacyLink(coop = 'cnd', card = false, coopUserWithoutCard = false, ecommerce = false) {
        return `${this._getEndpoint(
            'privacyLink'
        )}?coop=${coop}&card=${card}&ecommerce=${ecommerce}&noCard=${coopUserWithoutCard}`;
    }

    /**
     * Login user
     * @param {*} param0
     * @returns
     */
    async login({ email, password, mantain, cb, gRecaptchaResponse }) {
        const data = {
            email,
            password,
            mantain,
            cb,
            gRecaptchaResponse,
        };
        return await this._doPost(this._getEndpoint('login'), data);
    }

    /**
     * Confirm login user
     * @param {*} param0
     * @returns
     */
    async confirmLogin({ email, password, cb, gRecaptchaResponse }) {
        const data = {
            email,
            password,
            cb,
            gRecaptchaResponse,
        };
        return await this._doPost(this._getEndpoint('confirmLogin'), data);
    }

    /**
     * Logout user
     * @param {*} param0
     * @returns
     */
    async logout() {
        const data = {};
        return await this._doGet(this._getEndpoint('logout'), data);
    }

    /**
     * Social login user
     * @param {*} param0
     * @returns
     */
    async socialLogin({ socialType, cb }) {
        const data = {
            socialType,
            cb,
        };
        return await this._doPost(this._getEndpoint('social-login'), data);
    }

    /**
     * Search stores
     * @param {*} param0
     * @returns
     */
    async stores({ latitudine, longitudine, partial, typeOfService = 'HOME_DELIVERY' }) {
        const data = {
            latitudine,
            longitudine,
            partial,
            typeOfService,
        };
        return await this._doPost(this._getEndpoint('stores'), data);
    }

    /**
     * Search stores on IS
     */
    async getPointOfService({ latitudine, longitudine, partial }) {
        const data = {
            latitudine,
            longitudine,
            partial,
        };
        return await this._doPost(this._getEndpoint('getPointOfService'), data);
    }

    /**
     * Get current user
     */
    async getUser() {
        return await this._doGet(this._getEndpoint('user'), {});
    }

    /**
     * Get number of coupons and number of unread messages
     */
    async getDashboardCounts() {
        return await this._doGet(this._getEndpoint('getDashboardCounts'), {});
    }

    /**
     * Get store from IS
     */
    async getPointOfServiceByAnacanId({ anacanId }, failSilently = false) {
        const data = {
            anacanId,
        };
        return await this._doGet(
            this._getEndpoint('getPointOfServiceByAnacanId'),
            data,
            { unwrap: true },
            failSilently
        );
    }

    /**
     * Get store from ESB
     */
    async getPointOfServiceByIdFromESB({ id }) {
        const data = {
            id,
        };
        return await this._doGet(this._getEndpoint('getPointOfServiceByIdFromESB'), data);
    }

    /**
     * Register user without card
     */
    async registerUserWithoutCard({
        nome,
        cognome,
        password,
        dataNascita,
        email,
        cf,
        privacy1,
        privacy2,
        privacy3,
        privacyTZ,
        versioneInformativaPrivacy,
        flagRichiestaCartaDematerializzata,
        genere,
        cellulare,
        comuneNascita,
        comuneNascitaLabel,
        nCivicoResidenza,
        indirizzoResidenza,
        capResidenza,
        cittaResidenza,
        cittaResidenzaLabel,
        provinciaResidenza,
        pdvAnacanId,
        nazioneResidenza,
        fonteRegistrazione,
        anacanCooperativeCode,
        cb,
    }) {
        const data = {
            nome,
            cognome,
            password,
            sesso: genere,
            cellulare,
            email,
            privacy1,
            privacy2,
            privacy3,
            privacyTZ,
            dataNascita,
            comuneNascita: comuneNascitaLabel,
            codiceComuneNascita: comuneNascita,
            indirizzoResidenza,
            numeroCivico: nCivicoResidenza,
            cap: capResidenza,
            citta: cittaResidenzaLabel,
            codiceCitta: cittaResidenza,
            codiceProvincia: provinciaResidenza,
            codiceNazione: nazioneResidenza,
            anacanId: pdvAnacanId,
            codiceFiscale: cf,
            versioneInformativaPrivacy,
            flagRichiestaCartaDematerializzata,
            fonteRegistrazione,
            anacanCooperativeCode,
            cb,
        };
        return await this._doPost(this._getEndpoint('registerUserWithoutCard'), data);
    }

    /**
     * Register social user without card
     */
    async registerSocialUserWithoutCard({
        nome,
        cognome,
        socialToken,
        dataNascita,
        email,
        cf,
        privacy1,
        privacy2,
        privacy3,
        privacyTZ,
        versioneInformativaPrivacy,
        flagRichiestaCartaDematerializzata,
        genere,
        cellulare,
        comuneNascita,
        comuneNascitaLabel,
        nCivicoResidenza,
        indirizzoResidenza,
        capResidenza,
        cittaResidenza,
        cittaResidenzaLabel,
        provinciaResidenza,
        pdvAnacanId,
        nazioneResidenza,
        fonteRegistrazione,
        anacanCooperativeCode,
    }) {
        const data = {
            nome,
            cognome,
            socialToken,
            sesso: genere,
            cellulare,
            email,
            privacy1,
            privacy2,
            privacy3,
            privacyTZ,
            dataNascita,
            comuneNascita: comuneNascitaLabel,
            codiceComuneNascita: comuneNascita,
            indirizzoResidenza,
            numeroCivico: nCivicoResidenza,
            cap: capResidenza,
            citta: cittaResidenzaLabel,
            codiceCitta: cittaResidenza,
            codiceProvincia: provinciaResidenza,
            codiceNazione: nazioneResidenza,
            anacanId: pdvAnacanId,
            codiceFiscale: cf,
            versioneInformativaPrivacy,
            flagRichiestaCartaDematerializzata,
            fonteRegistrazione,
            anacanCooperativeCode,
        };
        return await this._doPost(this._getEndpoint('registerSocialUserWithoutCard'), data);
    }

    /**
     * Register user with card
     */
    async registerUserWithCard({
        nome,
        cognome,
        password,
        dataNascita,
        email,
        codiceCarta,
        privacy1,
        privacy2,
        privacy3,
        privacyTZ,
        versioneInformativaPrivacy,
        pdvAnacanId,
        firmaDigitale,
        fonteRegistrazione,
        anacanCooperativeCode,
        cb,
        updateGccMail,
    }) {
        const data = {
            nome,
            cognome,
            password,
            email,
            codiceCarta,
            privacy1,
            privacy2,
            privacy3,
            privacyTZ,
            dataNascita,
            anacanId: pdvAnacanId,
            versioneInformativaPrivacy,
            firmaDigitale,
            fonteRegistrazione,
            anacanCooperativeCode,
            cb,
            updateGccMail,
        };
        return await this._doPost(this._getEndpoint('registerUserWithCard'), data);
    }

    /**
     * Get Firma digitale
     */
    async getFirmaDigitale() {
        const data = await this._doPost(this._getEndpoint('getFirmaDigitale'), {});
        return data && data.firmaDigitale ? data.firmaDigitale : '';
    }

    /**
     * Register social user with card
     */
    async registerSocialUserWithCard({
        nome,
        cognome,
        socialToken,
        dataNascita,
        email,
        codiceCarta,
        privacy1,
        privacy2,
        privacy3,
        privacyTZ,
        versioneInformativaPrivacy,
        pdvAnacanId,
        firmaDigitale,
        fonteRegistrazione,
        anacanCooperativeCode,
        cb,
        updateGccMail,
    }) {
        const data = {
            nome,
            cognome,
            socialToken,
            email,
            codiceCarta,
            privacy1,
            privacy2,
            privacy3,
            privacyTZ,
            dataNascita,
            anacanId: pdvAnacanId,
            versioneInformativaPrivacy,
            firmaDigitale,
            fonteRegistrazione,
            anacanCooperativeCode,
            cb,
            updateGccMail,
        };
        return await this._doPost(this._getEndpoint('registerSocialUserWithCard'), data);
    }

    /**
     * Request social user association
     * @param {*} param0
     * @returns
     */
    async requestSocialUserAssociation({ socialToken, cb }) {
        const data = {
            socialToken,
            cb,
        };
        return await this._doPost(this._getEndpoint('requestSocialUserAssociation'), data);
    }

    /**
     * Get Store
     * @param {*} param0
     * @returns
     */
    async store({ name, typeOfService = 'HOME_DELIVERY' }) {
        const data = {
            name,
            typeOfService,
        };
        return await this._doPost(this._getEndpoint('store'), data);
    }

    /**
     * Check if a store has dematerialized card emission enabled
     * @param {*} param0
     * @returns
     */
    async isDmValid({
        nome,
        cognome,
        dataNascita,
        cf,
        genere,
        cellulare,
        comuneNascita,
        comuneNascitaLabel,
        nCivicoResidenza,
        indirizzoResidenza,
        capResidenza,
        cittaResidenza,
        cittaResidenzaLabel,
        provinciaResidenza,
        pdvAnacanId,
        nazioneResidenza,
    }) {
        const data = {
            nome,
            cognome,
            sesso: genere,
            cellulare,
            dataNascita,
            comuneNascita: comuneNascitaLabel,
            codiceComuneNascita: comuneNascita,
            indirizzoResidenza,
            numeroCivico: nCivicoResidenza,
            cap: capResidenza,
            citta: cittaResidenzaLabel,
            codiceCitta: cittaResidenza,
            codiceProvincia: provinciaResidenza,
            codiceNazione: nazioneResidenza,
            anacanId: pdvAnacanId,
            codiceFiscale: cf,
        };
        return await this._doPost(this._getEndpoint('isDmValid'), data);
    }

    /**
     * Associate a new DM card
     * @param {*} param0
     * @returns
     */
    async associateDM({
        nome,
        cognome,
        dataNascita,
        cf,
        genere,
        cellulare,
        comuneNascita,
        comuneNascitaLabel,
        nCivicoResidenza,
        indirizzoResidenza,
        capResidenza,
        cittaResidenza,
        cittaResidenzaLabel,
        provinciaResidenza,
        pdvAnacanId,
        nazioneResidenza,
        privacy1,
        privacy2,
        privacy3,
        privacyTZ,
    }) {
        const data = {
            nome,
            cognome,
            sesso: genere,
            cellulare,
            dataNascita,
            comuneNascita: comuneNascitaLabel,
            codiceComuneNascita: comuneNascita,
            indirizzoResidenza,
            numeroCivico: nCivicoResidenza,
            cap: capResidenza,
            citta: cittaResidenzaLabel,
            codiceCitta: cittaResidenza,
            codiceProvincia: provinciaResidenza,
            codiceNazione: nazioneResidenza,
            anacanId: pdvAnacanId,
            codiceFiscale: cf,
            privacy1,
            privacy2,
            privacy3,
            privacyTZ,
        };
        return await this._doPost(this._getEndpoint('associateDM'), data);
    }

    /**
     * Add card
     * @param {*} param0
     * @returns
     */
    async addCard({
        codiceCarta,
        dataNascita,
        versioneInformativaPrivacy,
        email,
        privacy1,
        privacy2,
        privacy3,
        privacyTZ,
        firmaDigitale,
    }) {
        const data = {
            codiceCarta,
            dataNascita,
            versioneInformativaPrivacy,
            email,
            privacy1,
            privacy2,
            privacy3,
            privacyTZ,
            firmaDigitale,
        };
        return await this._doPost(this._getEndpoint('associateCard'), data);
    }

    /**
     * Verify card
     * @param {*} param0
     * @returns
     */
    async verifyCard({ codiceCarta, nome, cognome, dataNascita }) {
        const data = {
            codiceCarta,
            nome,
            cognome,
            dataNascita,
        };
        return await this._doPost(this._getEndpoint('verifyCard'), data);
    }

    /**
     * Verify Conad card
     * @param {*} param0
     * @returns
     */
    async verifyConadCard({ codiceCarta, processo, email }) {
        const data = {
            codiceCarta,
            processo,
            email,
        };
        return await this._doPost(this._getEndpoint('verifyConadCard'), data);
    }

    /**
     * Change user account email
     * @param {*} param0
     * @returns
     */
    async changeEmailRequest({ ccp, email, password, updateGccMail }) {
        const data = {
            ccp,
            email,
            password,
            updateGccMail,
        };
        return await this._doPost(this._getEndpoint('changeEmailRequest'), data);
    }

    /**
     * Change user account email for refresh campaign
     * @param {*} param0
     * @returns
     */
    async changeEmailRequestV2({ email, updateGccMail }) {
        const data = {
            email,
            updateGccMail,
        };
        return await this._doPost(this._getEndpoint('changeEmailRequestV2'), data);
    }

    /**
     * Change user account password
     * @param {*} param0
     * @returns
     */
    async changePassword({ ccp, email, password }) {
        const data = {
            ccp,
            email,
            password,
        };
        return await this._doPost(this._getEndpoint('changePassword'), data);
    }

    /**
     * Change user account password v2
     * @param {*} param0
     * @returns
     */
    async changePasswordV2({ ccp, email, oldPassword, password }) {
        const data = {
            ccp,
            email,
            passwordAttuale: oldPassword,
            password,
        };
        return await this._doPost(this._getEndpoint('changePasswordV2'), data);
    }

    /**
     * Verify whether user deletion is possible (no active ecommerce orders in the last 14 days).
     * @returns
     */
    async hasActiveOrders() {
        const data = {};
        return await this._doGet(this._getEndpoint('activeOrders'), data);
    }

    /**
     * Delete user account
     * @param {*} param0
     * @returns
     */
    async deleteUser() {
        const data = {};
        return await this._doDelete(this._getEndpoint('deleteUser'), data);
    }

    /**
     * Edit user data
     * @param {*} param0
     * @returns
     */
    async editUserData({
        nome,
        cognome,
        stato,
        password,
        dataNascita,
        comuneNascita,
        sesso,
        telefonoCellulare,
        telefonoFisso,
        indirizzo,
        numeroCivico,
        cap,
        citta,
        codiceProvincia,
        codiceFiscale,
        dataNascitaFiglio1,
        dataNascitaFiglio2,
        dataNascitaFiglio3,
        privacy1,
        privacy2,
        privacy3,
        privacyTZ,
        numeroComponentiNucleoFamiliare,
        dataNascitaConiuge,
        animaliDomestici,
        abitudiniAlimentariFamiglia,
        hobbyFamiglia,
        acquistiOnline,
        preferenzeComunicazioneNewsletter,
        preferenzeComunicazioneSoloPerTe,
        accessoMinisito,
        invioMailEstrattoContoConadCard,
        rendicontazioneConadCard,
        attivazioneServizioOnlineConadCard,
        versioneInformativaPrivacy,
        mailOcdb,
        emailOCDB,
        firmaDigitale,
        codiceCitta,
        codiceComuneNascita,
        codiceNazione,
        canaliDiContatto,
        negozioPreferito,
    }) {
        const data = {
            nome,
            cognome,
            stato,
            password,
            dataNascita,
            comuneNascita,
            sesso,
            telefonoCellulare,
            telefonoFisso,
            indirizzo,
            numeroCivico,
            cap,
            citta,
            codiceProvincia,
            codiceFiscale,
            dataNascitaFiglio1,
            dataNascitaFiglio2,
            dataNascitaFiglio3,
            privacy1,
            privacy2,
            privacy3,
            privacyTZ,
            numeroComponentiNucleoFamiliare,
            dataNascitaConiuge,
            animaliDomestici,
            abitudiniAlimentariFamiglia,
            hobbyFamiglia,
            acquistiOnline,
            preferenzeComunicazioneNewsletter,
            preferenzeComunicazioneSoloPerTe,
            accessoMinisito,
            invioMailEstrattoContoConadCard,
            rendicontazioneConadCard,
            attivazioneServizioOnlineConadCard,
            versioneInformativaPrivacy,
            mailOcdb,
            emailOCDB,
            firmaDigitale,
            codiceCitta,
            codiceComuneNascita,
            codiceNazione,
            canaliDiContatto,
            negozioPreferito,
        };
        return await this._doPost(this._getEndpoint('editUserData'), data);
    }

    /**
     * Confirm profile
     * @param {*} param0
     * @returns
     */
    async confirmProfile({ cce, da, updateGccMail }) {
        const data = {
            cce,
            da,
            updateGccMail,
        };
        return await this._doPost(this._getEndpoint('confirmProfile'), data);
    }

    /**
     * Confirm social user association
     * @param {*} param0
     * @returns
     */
    async confirmSocialUserAssociation({ cca, socialToken, cb }) {
        const data = {
            cca,
            socialToken,
            cb,
        };
        return await this._doPost(this._getEndpoint('confirmSocialUserAssociation'), data);
    }

    /**
     * Confirm email address
     * @param {*} param0
     * @returns
     */
    async confirmEmailAddress({ cce, da, a, firmaDigitale, updateGccMail }) {
        const data = {
            cce,
            da,
            a,
            firmaDigitale,
            updateGccMail,
        };
        return await this._doPost(this._getEndpoint('confirmEmailAddress'), data);
    }

    /**
     * Validate password change
     * @param {*} param0
     * @returns
     */
    async validatePasswordChange({ ccp, email }) {
        const data = {
            ccp,
            email,
        };
        return await this._doPost(this._getEndpoint('validatePasswordChange'), data);
    }

    /**
     * Change password recovery request
     * @param {*} param0
     * @returns
     */
    async changePasswordRecReq({ ccp, email, password }) {
        const data = {
            ccp,
            email,
            password,
        };
        return await this._doPost(this._getEndpoint('changePasswordRecReq'), data);
    }

    /**
     * Add delivery address
     * @param {*} param0
     * @returns
     */
    async addDeliveryAddress({
        nickname,
        latitude,
        longitude,
        formattedAddress,
        firstName,
        lastName,
        cellphone,
        floor,
        doorbell,
        lift,
        reception,
        apartment,
        building,
        remarks,
        defaultAddress,
        country,
        town,
        line1,
        line2,
        district,
        postalCode,
        notCompleted,
    }) {
        const data = {
            nickname,
            latitude,
            longitude,
            formattedAddress,
            firstName,
            lastName,
            cellphone,
            floor,
            doorbell,
            lift,
            reception,
            apartment,
            building,
            remarks,
            defaultAddress,
            country,
            town,
            line1,
            line2,
            district,
            postalCode,
            notCompleted,
        };
        return await this._doPost(this._getEndpoint('addDeliveryAddress'), data);
    }

    /**
     * Add billing address
     * @param {*} param0
     * @returns
     */
    async addBillingAddress({
        type,
        nickname,
        firstName,
        lastName,
        fiscalCode,
        cellphone,
        email,
        pec,
        phone,
        sdiCode,
        businessName,
        vat,
        defaultBillingAddress,
        country,
        town,
        line1,
        district,
        postalCode,
    }) {
        const data = {
            type,
            nickname,
            firstName,
            lastName,
            fiscalCode,
            cellphone,
            email,
            pec,
            phone,
            sdiCode,
            businessName,
            vat,
            defaultBillingAddress,
            country,
            town,
            line1,
            district,
            postalCode,
        };
        return await this._doPost(this._getEndpoint('addBillingAddress'), data);
    }

    /**
     * Edit delivery address
     * @param {*} param0
     * @returns
     */
    async editDeliveryAddress({
        id,
        nickname,
        latitude,
        longitude,
        formattedAddress,
        firstName,
        lastName,
        cellphone,
        floor,
        doorbell,
        lift,
        reception,
        apartment,
        building,
        remarks,
        defaultAddress,
        country,
        town,
        line1,
        line2,
        district,
        postalCode,
        notCompleted,
    }) {
        const data = {
            id,
            nickname,
            latitude,
            longitude,
            formattedAddress,
            firstName,
            lastName,
            cellphone,
            floor,
            doorbell,
            lift,
            reception,
            apartment,
            building,
            remarks,
            defaultAddress,
            country,
            town,
            line1,
            line2,
            district,
            postalCode,
            notCompleted,
        };
        return await this._doPost(this._getEndpoint('editDeliveryAddress'), data);
    }

    /**
     * Edit billing address
     * @param {*} param0
     * @returns
     */
    async editBillingAddress({
        id,
        type,
        nickname,
        firstName,
        lastName,
        fiscalCode,
        cellphone,
        email,
        pec,
        phone,
        sdiCode,
        businessName,
        vat,
        defaultBillingAddress,
        country,
        town,
        line1,
        district,
        postalCode,
    }) {
        const data = {
            id,
            type,
            nickname,
            firstName,
            lastName,
            fiscalCode,
            cellphone,
            email,
            pec,
            phone,
            sdiCode,
            businessName,
            vat,
            defaultBillingAddress,
            country,
            town,
            line1,
            district,
            postalCode,
        };
        return await this._doPost(this._getEndpoint('editBillingAddress'), data);
    }

    /**
     * Delete delivery address
     * @param {*} param0
     * @returns
     */
    async deleteDeliveryAddress({ id }) {
        const params = {
            addressId: id,
        };
        return await this._doDelete(this._getEndpoint('deleteDeliveryAddress'), params);
    }

    /**
     * Delete billing address
     * @param {*} param0
     * @returns
     */
    async deleteBillingAddress({ id }) {
        const params = {
            addressId: id,
        };
        return await this._doDelete(this._getEndpoint('deleteBillingAddress'), params);
    }

    /**
     * Set preferred store
     * @param {*} param0
     * @returns
     */
    async setPreferredStore({ anacanId, cb }) {
        const body = {
            anacanId,
        };
        const params = {
            cb,
        };
        return await this._doPost(this._getEndpoint('setPreferredStore'), body, params);
    }

    /**
     * Set user coupon choice for refresh campaign
     * @param {*} param0
     * @returns
     */
    async setCouponChoice({ choice }) {
        const payload = {
            choice: choice,
        };
        return await this._doPost(this._getEndpoint('setCouponChoice'), payload);
    }

    /**
     * Associate complementary data.
     * @param {*} param0
     * @returns
     */
    async associateComplementaryData(path, type = 'defaultServlet', data = {}) {
        const payload = type == 'defaultServlet' ? { path } : data;
        return await this._doPost(this._getEndpoint(this.complDataServletsMap[type]['add']), payload);
    }

    /**
     * Remove complementary data.
     * @param {*} param0
     * @returns
     */
    async removeComplementaryData(id, type = 'defaultServlet', data = {}) {
        const payload = type == 'defaultServlet' ? { id } : data;
        return await this._doPost(this._getEndpoint(this.complDataServletsMap[type]['remove']), payload);
    }

    /**
     * GET complementary data.
     * @param {*} param0
     * @returns
     */
    async getComplementaryData(type = 'defaultServlet') {
        return await this._doGet(this._getEndpoint(this.complDataServletsMap[type]['get']));
    }

    /**
     * Edit complementary data
     * @param {*} param0
     * @returns
     */
    async editComplementaryData({ id, valore }) {
        const data = {
            id,
            valore,
        };
        return await this._doPost(this._getEndpoint('editComplementaryData'), data);
    }

    /**
     * Get Conad Card (Insieme più) payments PDF
     * @param {*} param0
     * @returns
     */
    async getCardPaymentsPdf({ dataInizio, dataFine }) {
        const data = {
            dataInizio,
            dataFine,
        };
        return await this._doBlobPost(this._getEndpoint('getCardPaymentsPdf'), data);
    }

    /**
     * Cancel order of given code
     * @param {*} param0
     * @returns
     */
    async cancelOrder({ code, becommerce, typeOfService }) {
        const data = {
            code,
            becommerce,
            typeOfService,
        };
        return await this._doPost(this._getEndpoint('cancelOrder'), data);
    }

    /**
     * Reserve a new timeslot on the given cart
     * @param {*} param0
     * @returns
     */
    async reserveTimeslotOnCart({ cartId, timeSlotId, oldTimeSlotId, becommerce }) {
        const data = {
            cartId,
            timeSlotId,
            oldTimeSlotId,
            becommerce,
        };
        return await this._doPost(this._getEndpoint('reserveTimeslotOnCart'), data);
    }

    /**
     * Create or restore a cart for the given storeId: modify order with parentOrderId, repeat order with reorderId.
     * @param {*} param0
     * @returns
     */
    async addCart({ pointOfServiceId, parentOrderId, reorderId, becommerce, deliveryArea }) {
        const data = {
            pointOfServiceId,
            parentOrderId,
            reorderId,
            becommerce,
            deliveryArea,
        };
        return await this._doPost(this._getEndpoint('addCart'), data);
    }

    /**
     * Perform the first step of a user migration.
     * @param {*} param0
     * @returns
     */
    async migrationStep1({ nome, cognome, dataNascita, codiceCarta, firmaDigitale }) {
        const data = {
            nome,
            cognome,
            dataNascita,
            codiceCarta,
            firmaDigitale,
        };
        return await this._doPost(this._getEndpoint('migrationStep1'), data);
    }

    /**
     * Perform the second step of a user migration.
     * @param {*} param0
     * @returns
     */
    async migrationStep2({
        privacy1,
        privacy2,
        privacy3,
        privacyTZ,
        email,
        password,
        versioneInformativa,
        cb,
        firmaDigitale,
    }) {
        const data = {
            privacy1,
            privacy2,
            privacy3,
            privacyTZ,
            email,
            password,
            versioneInformativa,
            cb,
            firmaDigitale,
        };
        return await this._doPost(this._getEndpoint('migrationStep2'), data);
    }

    /**
     * Activates the currently logged user that's not in status 'ATTIVATA'.
     * @returns
     */
    async activateUser({ firmaDigitale }) {
        const data = {
            firmaDigitale,
        };
        return await this._doPost(this._getEndpoint('activateUser'), data);
    }

    /**
     * Resend profile activation email to the given email (no login token required)
     * @param {*} param0
     * @returns
     */
    async resendActivationEmail({ email, cb }) {
        const data = {
            email,
            cb,
        };
        return await this._doPost(this._getEndpoint('resendActivationEmail'), data);
    }

    /**
     * Performs the first step of the GCC flow.
     * @param {*} param0
     * @returns
     */
    async formGccStep1({ privacy1, privacy2, privacy3, firmaDigitale }) {
        const data = {
            privacy1,
            privacy2,
            privacy3,
            firmaDigitale,
        };
        return await this._doPost(this._getEndpoint('GCCFormStep1'), data);
    }

    /**
     * Verifies the IBAN for the Conad Card request.
     * @param {*} param0
     * @returns
     */
    async verifyIban({ iban, codiceCooperativa }) {
        const data = {
            iban,
            codiceCooperativa,
        };
        return await this._doPost(this._getEndpoint('validateIBAN'), data);
    }

    /**
     * Performs the second step of the GCC flow. Requests a Conad Card for the user.
     * @param {*} param0
     * @returns
     */
    async formGccStep2({
        codiceCarta,
        nome,
        cognome,
        dataNascita,
        sesso,
        comuneNascita,
        codiceProvinciaNascita,
        codiceFiscale,
        email,
        telefonoCellulare,
        telefonoFisso,
        indirizzo,
        cap,
        citta,
        codiceProvincia,
        tipoDocumento,
        numeroDocumento,
        numeroCivico,
        dataRilascioDocumento,
        enteRilascioDocumento,
        iban,
        titoloDiStudio,
        professione,
        statoCivile,
        codicePrivacyUsiAbitudini,
        codicePrivacyMarketing,
        codicePrivacyNazionale,
        codiceCooperativa,
    }) {
        const data = {
            codiceCarta,
            nome,
            cognome,
            dataNascita,
            sesso,
            comuneNascita,
            codiceProvinciaNascita,
            codiceFiscale,
            email,
            telefonoCellulare,
            telefonoFisso,
            indirizzo,
            cap,
            citta,
            codiceProvincia,
            tipoDocumento,
            numeroDocumento,
            numeroCivico,
            dataRilascioDocumento,
            enteRilascioDocumento,
            iban,
            titoloDiStudio,
            professione,
            statoCivile,
            codicePrivacyUsiAbitudini,
            codicePrivacyMarketing,
            codicePrivacyNazionale,
            codiceCooperativa,
        };
        return await this._doPost(this._getEndpoint('GCCFormStep2'), data);
    }

    /**
     * Recover email address
     * @param {*} param0
     * @returns
     */
    async recoverEmailAddress({ dataNascita, codiceCarta }) {
        const data = {
            dataNascita,
            codiceCarta,
        };
        return await this._doPost(this._getEndpoint('recoverEmailAddress'), data);
    }

    /**
     * Recover password
     * @param {*} param0
     * @returns
     */
    async recoverPassword({ email, gRecaptchaResponse }) {
        const data = {
            email,
            gRecaptchaResponse,
        };
        return await this._doPost(this._getEndpoint('recoverPsw'), data);
    }

    /**
     * Get province
     * @returns
     */
    async province() {
        const province = await this._doCachedGet(this._getEndpoint('province'));
        return province.map((item) => ({
            selected: false,
            label: item.nome,
            value: item.codice,
        }));
    }

    /**
     * Get comuni from provincia
     * @returns
     */
    async comuni({ provinciaId, isBirth = false }) {
        const data = {
            provinciaId: provinciaId,
            isBirth: isBirth,
        };
        const comuni = await this._doCachedGet(this._getEndpoint('comuni'), data);
        return comuni.map((item) => ({
            selected: false,
            label: item.nome,
            value: item.codice,
        }));
    }

    /**
     * Get stati
     * @returns
     */
    async stati() {
        const response = await this._doCachedGet(this._getEndpoint('stati'));
        const nations = [];
        for (const nation in response) {
            if (Object.prototype.hasOwnProperty.call(response, nation)) {
                const selected = nation == 'IT' ? true : false;
                nations.push({
                    selected: selected,
                    label: response[nation],
                    value: nation,
                });
            }
        }
        return nations;
    }

    /**
     * Get informativa privacy for cooperativa
     * @param {*} param0
     * @returns
     */
    async getInformativa({ cooperativa, ecommerce, card, coopUserWithoutCard = false }) {
        const data = {
            cooperativa: cooperativa,
            ecommerce: ecommerce,
            card: card ? 'true' : 'false',
            noCard: coopUserWithoutCard === true ? 'true' : 'false',
        };
        return await this._doCachedGet(this._getEndpoint('informativa'), data);
    }

    /**
     * Get validation config for form fields
     * @returns
     */
    async getValidationConfig() {
        return await this._doCachedGet(this._getEndpoint('validationConfig'));
    }

    /**
     * Check the otp address
     * @param {*} param0
     * @returns
     */
    async checkOtpAddress({ addressId, otpCode }) {
        const data = {
            addressId,
            otpCode,
        };
        return await this._doPost(this._getEndpoint('checkDeliveryAddress'), data);
    }

    /**
     * Return the regolamento link
     * @param {*} coop The cooperative code
     * @returns
     */
    getCustomerCareAttachmentLink({ attachmentId, extension, fileName }) {
        const queryParams = {
            attachmentId,
            extension,
            fileName,
        };
        const params = new URLSearchParams(queryParams);
        return `${this._getEndpoint('downloadCaseAttachment')}.${extension.toLowerCase()}?${params.toString()}`;
    }

    /**
     * Send customer care request.
     * @param {*} data FormData
     * @returns
     */
    async customerCare(data) {
        return await this._doPost(this._getEndpoint('customerCare'), data);
    }

    /**
     * Get customer care list requests.
     * @returns
     */
    async getCaseList({ userId }) {
        return await this._doCachedGet(this._getEndpoint('getCustomerCareRequest'), { userId });
    }

    /**
     * Close customer care request.
     * @returns
     */
    async closeCase({ code }) {
        const data = {
            code: code,
        };
        return await this._doPost(this._getEndpoint('closeCustomerCareRequest'), data);
    }

    /**
     * Get Attachments list case request.
     * @returns
     */
    async getAttachmentsList({ code }) {
        const data = {
            code: code,
        };
        return await this._doGet(this._getEndpoint('getCaseAttachmentsList'), data);
    }

    /*
     * Do HTTP GET for loader.
     * Internally uses _doTextGet & _doGet
     * @param {*} url
     * @param {*} queryParams
     * @returns
     */
    async loaderGet(endpoint, queryParams) {
        if (this.jsonLoader) {
            // replace selector and extension
            let split = endpoint.split('.');
            split.splice(1, 0, 'jloader');
            const newEndpoint = split.join('.').replace('.html', '.json');
            const result = await this._doGet(newEndpoint, queryParams);
            return result.html;
        } else {
            return await this._doTextGet(endpoint, queryParams);
        }
    }

    /**
     * Do HTTP GET for text content.
     * Internally uses _doTextGet
     * @param {*} url
     * @param {*} queryParams
     * @returns
     */
    async textGet(endpoint, queryParams) {
        return await this._doTextGet(endpoint, queryParams);
    }

    /**
     * Do HTTP GET with response type "text"
     * Used for download html content
     * @param {*} url
     * @param {*} queryParams
     * @returns
     */
    async _doTextGet(endpoint, queryParams) {
        try {
            const params = new URLSearchParams(queryParams);
            const result = await axios({
                method: 'get',
                url: endpoint,
                responseType: 'text',
                params: params,
            });
            return result?.data;
        } catch (error) {
            console.warn(error);
            throw 'Cannot download page';
        }
    }

    /**
     * Add selected cart from conflicts popup
     * @param {*} param0
     * @returns
     */
    async getCarts({ guid, bEcommerce }) {
        const data = {
            guid,
            bEcommerce,
        };
        return await this._doPost(this._getEndpoint('get-carts'), data);
    }

    /**
     * Add selected cart from conflicts popup
     * @param {*} param0
     * @returns
     */
    async selectConflictCart({ code, bEcommerce, guest }) {
        const data = {
            code,
            bEcommerce,
            guest,
        };
        return await this._doPost(this._getEndpoint('choose-cart'), data);
    }

    async getEnabledPdvsBollini() {
        return await this._doCachedGet(this._getEndpoint('getEnabledPdvsBollini'), {});
    }

    async getCardMovementsWithCollection(data) {
        return await this._doPost(this._getEndpoint('getCardMovementsWithCollection'), data);
    }

    async sendBollini(data) {
        return await this._doPost(this._getEndpoint('donateBollini'), data);
    }

    async getUserBalanceBollini() {
        return await this._doPost(this._getEndpoint('getUserBalanceBollini'), {});
    }

    _getEndpoint(name) {
        const url = this.urlMap[name];
        if (url) return url;
        return this.urlMap['all'].replace('%%', name);
    }

    async _doBlobPost(endpoint, body = {}, queryParams) {
        try {
            const params = new URLSearchParams(queryParams);
            const result = await axios.post(endpoint, body, {
                params,
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return result.data;
        } catch (error) {
            console.warn(error);
            throw 'Cannot download blob';
        }
    }
    async setSavedStore(anacanId) {
        const payload = {
            anacanId: anacanId,
        };
        return await this._doPost(this._getEndpoint('setSavedStore'), payload);
    }

    async removeSavedStore(anacanId) {
        const payload = {
            anacanId: anacanId,
        };
        return await this._doPost(this._getEndpoint('removeSavedStore'), payload);
    }

    async removeDisProduct(idProdottoNegozioUtente) {
        const payload = {
            idProdottoNegozioUtente,
        };
        return await this._doPost(this._getEndpoint('removeDisProduct'), payload);
    }

    async checkUserIfRegistered(data) {
        return await this._doPost(this._getEndpoint('checkUser'), data);
    }

    async getInsuranceProducts() {
        return await this._doGet(this._getEndpoint('insurances'));
    }

    async _doPost(endpoint, body = {}, queryParams, { unwrap } = { unwrap: true }) {
        try {
            const params = new URLSearchParams(queryParams);
            const result = await axios.post(endpoint, body, {
                params,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (unwrap) {
                return result?.data?.data;
            } else {
                return result?.data;
            }
        } catch (error) {
            console.warn(error);
            if (unwrap) {
                throw error?.response?.data?.data;
            } else {
                throw error?.response?.data;
            }
        }
    }

    async _doDelete(endpoint, body = {}, queryParams, { unwrap } = { unwrap: true }) {
        try {
            const params = new URLSearchParams(queryParams);
            const result = await axios.delete(endpoint, {
                params,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: body,
            });
            if (unwrap) {
                return result?.data?.data;
            } else {
                return result?.data;
            }
        } catch (error) {
            console.warn(error);
            if (unwrap) {
                throw error?.response?.data?.data;
            } else {
                throw error?.response?.data;
            }
        }
    }

    async _doCachedGet(endpoint, queryParams, { unwrap } = { unwrap: true }, failSilently = false) {
        const params = new URLSearchParams(queryParams);
        const paramsUrl = params.toString();
        const key = endpoint + '?' + paramsUrl;

        const loc = window.localStorage?.getItem(key);
        if (loc) {
            const deserialized = JSON.parse(loc);
            if (deserialized && deserialized.update > Date.now() - 300000) {
                return deserialized.data;
            }
        }

        // if a promise already running, wait on it
        if (this.cached[key]) return await this.cached[key];

        this.cached[key] = this._doGet(endpoint, queryParams, { unwrap }, failSilently);
        const data = await this.cached[key];
        window.localStorage?.setItem(key, JSON.stringify({ update: Date.now(), data }));
        setTimeout(() => {
            this.cached[key] = null; // clean promises
        }, 100);
        return data;
    }

    async _doGet(endpoint, queryParams, { unwrap } = { unwrap: true }, failSilently = false) {
        try {
            const params = new URLSearchParams(queryParams);
            const result = await axios.get(endpoint, { params });
            if (unwrap) {
                return result?.data?.data;
            } else {
                return result?.data;
            }
        } catch (error) {
            if (failSilently) return;
            console.warn(error);
            if (unwrap) {
                throw error?.response?.data?.data;
            } else {
                throw error?.response?.data;
            }
        }
    }
}

const defaultApiProvider = new ApiProvider();
window.apiProvider = defaultApiProvider;

export const getApiProvider = () => {
    return window.apiProvider;
};

export const apiProvider = defaultApiProvider;
