/* poi types: fuel, margherita, pet */
const DEFAULT_POI_TYPE = 'margherita';

/**
 * Get the types of pdv
 * @param {*} pdv
 * @returns
 */
export const getPoiType = (pdv) => {
    switch (pdv.descrizioneInsegna) {
        // case 'OTTICO CONAD':
        //     return 'eye';
        // case 'PARAFARMACIA CONAD':
        //     return 'pharmacy';
        // case 'CONAD SELF 24h':
        //     return 'fuel';
        // case 'PET STORE CONAD':
        //     return 'pet';
        default:
            return DEFAULT_POI_TYPE;
    }
};
