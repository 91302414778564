import { flowManager } from '../flow-manager';
import { register } from '../register';
import { storeManager } from '../store-manager';
import { htmlToElement } from '../utils';
import Component from './component';

export default class WizardComponent extends Component {
    constructor(name, root) {
        super(name, root);
        this.stepsIcons = this._dEl('stepsIcons');
        this.title = this._dEl('title');
        this.backBtn = this.title?.querySelector('button:first-child');
        this.wizardLayoutEl = this.root.closest('.ml3-wizardlayout');
        if (!this.wizardLayoutEl) {
            console.error('A wizard component need to be inserted into a wizard layout');
            return;
        }
        this.wizardLayout = register.getClass(this.wizardLayoutEl);

        this._initFlowEvents();
        this._initSteps();
    }

    _initFlowEvents() {
        /* flow buttons events */
        this.backBtn?.addEventListener('click', (event) => {
            if (!event.target.matches(`${this._el('title', true)} > button:first-child`)) return;
            flowManager.back();
        });
        this.stepsIcons?.addEventListener('click', (event) => {
            if (!event.target.matches('[data-flow-step]')) return;
            flowManager.back(event.target.dataset.flowStep);
        });
    }

    _initSteps() {
        storeManager.on(/flow\/[^/]+\/backStack/g, (path, backStack) => {
            if (!this.title) return;
            const flowName = flowManager.getFlowNameByStorePath(path);
            const activeStep = storeManager.get(`flow/${flowName}/activeStep`);
            if (activeStep.el != this.root) {
                return;
            }

            if (activeStep.disableBack || !backStack || backStack.length <= 0) {
                this.title.classList.remove(this._elMod('title', 'back'));
            } else {
                this.title.classList.add(this._elMod('title', 'back'));
            }
        });

        storeManager.on(/flow\/[^/]+\/state/g, (path, steps) => {
            if (!this.stepsIcons) return;
            const flowName = flowManager.getFlowNameByStorePath(path);
            const activeStep = storeManager.get(`flow/${flowName}/activeStep`);
            if (activeStep.el != this.root) {
                return;
            }

            if (!activeStep.index || storeManager.get('flow')?.options?.hideStepIcons) {
                return;
            }

            while (this.stepsIcons.firstChild) {
                this.stepsIcons.removeChild(this.stepsIcons.firstChild);
            }

            if (!steps || steps.length <= 0) {
                this.stepsIcons.classList.remove(this._elMod('stepsIcons', 'show'));
                return;
            }

            for (const index in steps) {
                if (steps[index]) {
                    const icStep = steps[index]
                        .filter((item) => item.status != 'disabled')
                        .reduce((total, item) => (total != null && total.status == 'active' ? total : item), null);
                    if (icStep) {
                        this.stepsIcons.appendChild(
                            htmlToElement(
                                `<button class="step ${icStep.status}" type="button" aria-label="Step ${index}" data-flow-step="${icStep.name}"></button>`
                            )
                        );
                    }
                }
            }
            this.stepsIcons.classList.add(this._elMod('stepsIcons', 'show'));
        });

        storeManager.on(/flow\/[^/]+\/activeStep/g, (path, activeStep) => {
            if (activeStep.el != this.root) return;
            this.wizardLayout.showItemOf(this.root);
        });
    }

    isOpen() {
        this.wizardLayout.isOpen(this.root);
    }

    open() {}

    close() {}
}
