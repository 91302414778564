import WizardComponent from '../../../../../../libs/components/wizard-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { register } from '../../../../../../libs/register';
import { isPositive } from '../../../../../../libs/utils';
import { formToJSON, jsonToForm } from '../../../../../../libs/form-to-json';
import { dictionary } from '../../../../../../libs/dictionary-provider';
import { apiProvider } from '../../../../../../libs/api-provider';
import { storeManager } from '../../../../../../libs/store-manager';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import './style.scss';

export default class FormGccStep1 extends WizardComponent {
    constructor(name, root) {
        super(name, root);

        this.back = this._dEl('back');
        this.error = this._dEl('error');
        this.form = this._dEl('form');

        if (this.form) {
            this.cfProvinceSelect = this._dEl('cfProvince');
            if (this.cfProvinceSelect) {
                this._loadProvince(this.cfProvinceSelect, false);
                this.cfProvinceFirstLoad = true;
            }
            this.cf = this._dEl('cf');
            this.provinceSelect = this._dEl('province');
            this.citySelect = this._dEl('city');
            if (this.provinceSelect) {
                this._loadProvince(this.provinceSelect);
                this.firstLoad = true;
            }
            if (this.root.dataset.user) {
                this.user = JSON.parse(this.root.dataset.user);
                jsonToForm(this.form, this.user, [this.cfProvinceSelect, this.cf]);
            }
            this.privacyBoxes = this._dEl('box', true);
            this.next = this._dEl('next');
        }

        this._addEventListeners();
    }

    _addEventListeners() {
        /* handle custom back cta */
        this._addStoreListener(/flow\/[^/]+\/backStack/g, (path, backStack) => {
            if (!this.title) return;
            const flowName = flowManager.getFlowNameByStorePath(path);
            const activeStep = storeManager.get(`flow/${flowName}/activeStep`);
            if (activeStep.el != this.root) {
                return;
            }
            if (activeStep.disableBack || !backStack || backStack.length <= 0) {
                this.back?.classList.remove(this._elMod('back', 'show'));
            } else {
                this.back?.classList.add(this._elMod('back', 'show'));
            }
        });
        this.back?.addEventListener('click', (event) => {
            event.preventDefault();
            window.history.back();
        });
        /* avoid adding the rest if form not present */
        if (!this.form) {
            return;
        }
        this.form.addEventListener('submit', (event) => {
            event.preventDefault();
            event.stopPropagation();
        });
        /*this.form.addEventListener('ecInputChanged', (event) => {
            event.preventDefault();
            !event.data.valid
                ? this._disableSubmit()
                : this._getFormValidity()
                ? this._enableSubmit()
                : this._disableSubmit();
            event.stopPropagation();
        });*/
        this.cfProvinceSelect?.addEventListener('ecInputChanged', (event) => {
            event.preventDefault();
            if (!event.data.value || !event.data.label) return;
            event.stopPropagation();
            
            //compute codice fiscale
            (async () => {
                const cf = await this._computeCF(event.data.value);
                const cfObj = register.getClass(this.cf);
                cfObj.setValue(cf);
                cfObj._checkState();
            })();
        });
        this.provinceSelect?.addEventListener('ecInputChanged', async (event) => {
            if (this.citySelect) {
                const selCityObj = register.getClass(this.citySelect);
                await this._loadComuni(event.data.value, selCityObj);
                if (this.firstLoad && event.data.value === this.user?.codiceProvincia) {
                    this.firstLoad = false;
                    if (this.user.codiceCitta) selCityObj.setSelected(this.user.codiceCitta);
                }
            }
        });
        this.privacyBoxes?.forEach((box) => {
            box?.addEventListener('ecInputChanged', (event) => {
                this._checkRadio(box, isPositive(event.data.value));
            });
        });
        this.next.addEventListener('click', (event) => {
            event.preventDefault();
            if (!this._getFormValidity()) {
                const first = this.form.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
                return;
            }
            /*if (this._submitDisabled()) {
                return;
            }*/
            this._formGccStep1();
        });
    }

    async _computeCF(birthplaceProvincia) {
        if (!this.user || !this.cf || !birthplaceProvincia) return;
        const birthdate = this.user.dataNascita.split('-');
        const cfData = {
            name: this.user.nome,
            surname: this.user.cognome,
            gender: this.user.sesso,
            day: birthdate[0],
            month: birthdate[1],
            year: birthdate[2],
            birthplace: this.user.comuneNascita,
            birthplaceProvincia: birthplaceProvincia,
        };
        let cf = null;
        try {
            cf = (await import("codice-fiscale-js")).default.compute(cfData);
        } catch (error) {
            console.warn('Could not compute fiscal code due to data mismatch');
        }
        return cf;
    }

    async _loadProvince(select, preselect = true) {
        const provinceSelect = register.getClass(select);
        try {
            const province = await apiProvider.province();
            if (preselect) {
                province.forEach((prov) => {
                    if (prov.value === this.user?.codiceProvincia) {
                        prov.selected = true;
                        return;
                    }
                });
            }
            await provinceSelect.setItems(province);
        } catch (error) {
            console.error('Cannot set province');
        }
    }

    async _loadComuni(value, select) {
        try {
            select.reset();
            const comuni = await apiProvider.comuni({ provinciaId: value });
            await select.setItems(comuni);
        } catch (error) {
            console.error('Cannot set comuni');
        }
    }

    _getFormValidity() {
        if (!this.form.checkValidity()) {
            return false;
        }
        return true;
    }

    /*_enableSubmit() {
        this.next?.classList?.add(this._elMod('next', 'active'));
        this.next?.removeAttribute('title');
    }
    async _disableSubmit() {
        this.next?.classList?.remove(this._elMod('next', 'active'));
        this.next?.setAttribute('title', dictionary.getFEMessage('fillInFields'));
    }
    _submitDisabled() {
        return !this.next.classList.contains(this._elMod('next', 'active'));
    }*/

    _checkRadio(box, valid) {
        if (!box) return;
        const boxNotSelected = box.querySelector(this._el('boxNotSelected', true));
        if (!valid) {
            boxNotSelected.classList.add(this._elMod('boxNotSelected', 'show'));
        } else {
            boxNotSelected.classList.remove(this._elMod('boxNotSelected', 'show'));
        }
    }

    async _formGccStep1() {
        let result = false;
        try {
            openLoader('main');
            const data = {
                ...this.user,
                ...formToJSON(this.form),
            };
            /* if radios not selected, default to 'N' */
            if (this.privacyBoxes) {
                if (!data.privacy1) data.privacy1 = 'N';
                if (!data.privacy2) data.privacy2 = 'N';
                if (!data.privacy3) data.privacy3 = 'N';
            }
            if (data.cartaFedelta) data.codiceCarta = data.cartaFedelta;
            const firmaDigitale = await apiProvider.getFirmaDigitale();
            data.firmaDigitale = firmaDigitale;
            storeManager.emit('formGccData', data);
            result = await apiProvider.formGccStep1(data);
            if (result) {
                storeManager.emit('userData', result);
            }
        } catch (error) {
            this.ISError = error;
            console.warn(error);
        } finally {
            closeLoader('main');
        }

        if (!result) {
            this.error.innerHTML = this?.ISError?.codice
                ? await (dictionary.getFEMessage(this.ISError.codice) || this.ISError.errore)
                : this.root.dataset.genericError;
            this.error.classList.remove(this._elMod('error', 'hidden'));
            window.scrollTo(0, 0);
        } else {
            if (!this.error.classList.contains(this._elMod('error', 'hidden')))
                this.error.classList.add(this._elMod('error', 'hidden'));
            flowManager.next('form-gcc-step2');
        }
        return result;
    }
}


