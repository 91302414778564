import ValidableComponent from '../../../../../libs/components/validable-component';
import { dictionary } from '../../../../../libs/dictionary-provider';
import './style.scss';


export default class Radio extends ValidableComponent {
    constructor(name, root) {
        super(name, root);

        this.inputs = Array.from(this.root.querySelectorAll(this._el('input', true)));
        this.labels = this._dEl('radioLabel', true);
        this.emptyInput = this._dEl('empty');
        setTimeout(() => {
            if (this.emptyInput) this.emptyInput.checked = false;
        }, 500);
        this.groupLabel = this._dEl('label');
        this.error = this._dEl('error');
        
        this._initEmptyInput();
        this._addEventListeners();
    }

    _getInput() {
        return this.inputs.filter((radio) => radio.checked)[0];
    }
    
    _initEmptyInput() {
        if (!this._getInput()) this.emptyInput.checked = true;
    }

    _addEventListeners() {
        this.labels.forEach((label) => {
            label.addEventListener('keydown', (event) => {
                if (!(event.key == 'Enter')) return;
                label.click();
                label.focus();
            });
        });

        this.inputs.forEach((input) => {
            input.addEventListener('change', (event) => {
                event.preventDefault();
                this._checkState();
                this._changedInput();
            });
            input.addEventListener(
                'invalid',
                () => {
                    this.setState('error');
                    this._requireField();
                },
                true
            );
        });

        /* hide default browser error popup on enter pressed */
        this.root.addEventListener('invalid', () => {
            this._checkState();
        }, true);
    }

    getLabel() {
        return this.groupLabel;
    }

    getName() {
        return this.root.dataset.name;
    }

    getValue() {
        const checked = this.inputs.filter((radio) => radio.checked);
        return checked.length <= 0 ? '' : checked[0].value;
    }

    isValid() {
        if (this.emptyInput.required && this.emptyInput.checked) return false;
        return !this.emptyInput.required || (this.emptyInput.required && this.getValue());
    }

    reset() {
        this.inputs.forEach((input) => {
            input.checked = false;
        });
        this.emptyInput.checked = true;
        this.setState('');
    }

    enable() {
        this.root.classList.remove(this._mod('disabled'));
        this.labels.forEach((label) => {
            label.setAttribute('tabindex', '0');
        });
    }
    disable() {
        if (this.root.classList.contains(this._mod('disabled'))) return;
        this.root.classList.add(this._mod('disabled'));
        this.labels.forEach((label) => {
            label.setAttribute('tabindex', '-1');
        });
    }

    _requireField() {
        this.setErrorText(dictionary.getFEMessage('requiredField'));
    }

    /* override */
    setErrorText(errorText) {
        super.setErrorText(errorText);
        if (typeof errorText !== 'string' || errorText === '') return;
        this.error.innerText = errorText;
    }

    isDisabled() {
        return this.root.classList.contains(this._mod('disabled'));
    }
}


