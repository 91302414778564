import { ecApiProvider } from './ecommerce-api-provider';
import { storeManager } from './store-manager';
import { buildWaitForEvent, isReferrerCurrentDomain } from './utils';

export class EcommerceCartProvider {
    constructor() {
        this.reg = [];
        this.storeManager = storeManager;
        this.apiProvider = ecApiProvider;

        this.cartLoadedReady = buildWaitForEvent('cartLoadedReady');
        this.cartLoaded = 0;
    }

    /**
     *
     * @returns The user if is logged, null otherwise
     */
    async getCart() {
        switch (this.cartLoaded) {
            case 0:
                await this._loadCart();
                return this._getCart();
            case 1:
                await this.cartLoadedReady(document.body);
                return this._getCart();
            case 2:
                return this._getCart();
            default:
                console.error('Could not retrieve cart');
                break;
        }
        return null;
    }

    async getCartNoCache() {
        try {
            return await this.apiProvider.getCart();
        } catch (error) {
            return null;
        }
    }

    resetCache() {
        window.sessionStorage?.removeItem('cart-info-fe-cache');
    }

    reset() {
        window.sessionStorage?.removeItem('cart-info-fe-cache');
        this.storeManager.emit('cartData', null);
        this.cartLoaded = 0;
    }

    /////////////////////////////// INTERNAL

    _getCart() {
        return this.storeManager.get('cartData');
    }

    async _loadCart() {
        this.cartLoaded = 1;

        // skip getting cart if not present the code
        if (!window.accessInfo.cart) {
            this.cartLoaded = 2;
            return;
        }

        let cart;
        const loc = window.sessionStorage?.getItem('cart-info-fe-cache');
        if (loc) {
            const deserialized = JSON.parse(loc);
            if (
                deserialized &&
                deserialized.update > Date.now() - 60000 &&
                isReferrerCurrentDomain() &&
                !window.cartCacheDisabled &&
                window.accessInfo.cart == deserialized.data.code // only if id is the same take from cache
            ) {
                cart = deserialized.data;
            }
        }

        if (!cart) {
            try {
                cart = await this.apiProvider.getCart();
                window.sessionStorage?.setItem(
                    'cart-info-fe-cache',
                    JSON.stringify({ update: Date.now(), data: cart })
                );
            } catch (e) {
                console.warn(e);
            }
        }

        //wait store manager event
        this.storeManager.on('cartData', () => {
            this.cartLoaded = 2;
            document.body.dispatchEvent(new CustomEvent('cartLoadedReady'));
        });

        this.storeManager.emit('cartData', cart);
    }
}

const ecommerceCartProvider = new EcommerceCartProvider();
window.ecCartProvider = ecommerceCartProvider;
export const ecCartProvider = ecommerceCartProvider;
