import ValidableComponent from '../../../../../libs/components/validable-component';
import { dictionary } from '../../../../../libs/dictionary-provider';
import { gMapsHelper } from '../../../../../libs/gmaps-helper';
import { storeManager } from '../../../../../libs/store-manager';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../libs/tracking-manager-old';
import { delay, getNextFormFocusableElement } from '../../../../../libs/utils';
import { closeLoader, openLoader } from '../mt11-loader/script';
import './style.scss';

export default class AddressField extends ValidableComponent {
    constructor(name, root) {
        super(name, root);

        this.input = this._dEl('input');
        this.searchBtn = this._dEl('searchBtn');
        this.cancel = this._dEl('cancel');
        this.error = this._dEl('error');
        this.detectLocationContainer = this._dEl('detectLocationContainer');
        this.detectLocationItem = this._dEl('detectLocationItem');

        this.country = this.root.dataset.country;
        if (this.country.indexOf(',') >= 0) {
            this.country = this.country.split(',');
        }
        
        this.checkStreet = this.root.dataset.checkStreet == 'true';
        this.value = null;
        this.searchType = null;

        this._initGoogleAutocomplete();
        this._addEventListeners();
    }

    async _initGoogleAutocomplete() {
        const gmaps = await gMapsHelper.getGMaps();
        const options = {
            componentRestrictions: {
                country: this.country,
            },
        };
        if (this.checkStreet) options.types = ['address'];
        this.autocomplete = new gmaps.places.Autocomplete(this.input, options);
        gmaps.event.addListener(this.autocomplete, 'place_changed', () => {
            const currentPlace = this.autocomplete.getPlace();
            if (!this._manageState(currentPlace)) {
                return;
            }
            this.value = currentPlace;
            this._changedInput();
            this.searchType = 'Geolocalizzazione';
            storeManager.emit('mt2AddressSearchType', { searchType: this.searchType });
            this._trackRegistrationFunnel(TRACKABLE_EVENT.searchAddress, FUNNEL_STEP.searchAddress, {
                type: this.searchType,
                address: currentPlace.formatted_address,
            });
            this._toggleCancelAddress();
        });
        /* init gmaps services */
        this.autocompleteService = new gmaps.places.AutocompleteService();
        this.geocoder = new gmaps.Geocoder();
    }

    _addEventListeners() {
        ['input', 'change'].forEach((e) => {
            this.input.addEventListener(e, () => {
                if (this.input.value === '' && !this.cancel.classList.contains(this._elMod('cancel', 'hidden')))
                    this.cancel.classList.add(this._elMod('cancel', 'hidden'));
                if (this.input.value != '') {
                    this.error.innerText = '';
                    this._showCancelAddress();
                }
            });
        });
        ['input', 'change', 'focus'].forEach((e) => {
            this.input.addEventListener(e, () => {
                this._showDetectLocation(this.input.value === '');
            });
        });
        this.detectLocationItem?.addEventListener('click', () => {
            this._detectLocation();
        });
        this.input.addEventListener('keydown', async (event) => {
            if (!(event.key == 'Enter')) return;

            //do search
            await this._manualSearch();

            //go to next focusable element when enter pressed
            event.preventDefault();
            const next = getNextFormFocusableElement(event.target);
            if (next) next.focus();
        });
        this.searchBtn.addEventListener('click', async () => {
            await this._manualSearch();
        });
        this.cancel.addEventListener('click', (event) => {
            event.preventDefault();
            this._trackRegistrationFunnel(TRACKABLE_EVENT.deleteAddress, FUNNEL_STEP.deleteAddress, {
                type: this.searchType || '',
                address: this.value?.formatted_address || this.input.value,
            });
            this.reset();
            this._changedInput();
        });
        this.input.addEventListener('focusout', async () => {

            /* add little delay because focusout preceeds click on detectLocationItem */
            await delay(100);
            this._showDetectLocation(false);
            if (this.input.required && this.input.value == '') {
                if (!this.root.classList.contains(this._mod('errorState'))) {
                    this.trackFormError(dictionary.getFEMessage('requiredField'));
                }
                this.setState('error');
                await this._requireField();
                return;
            }

            this._manageState(this.value);
        });

        this.root.addEventListener(
            'invalid',
            () => {
                if (this.input.required && this.input.value == '') {
                    this.setState('error');
                    this._requireField();
                }
            },
            true
        );
    }

    _showDetectLocation(show = true) {
        if (!this.detectLocationContainer) return;
        if (show) {
            if (!this.detectLocationContainer.classList.contains(this._elMod('detectLocationContainer', 'active')))
                this.detectLocationContainer.classList.add(this._elMod('detectLocationContainer', 'active'));
        } else {
            this.detectLocationContainer.classList.remove(this._elMod('detectLocationContainer', 'active'));
        }
    }
    
    _detectLocation() {
        if (window.navigator.geolocation) {
            openLoader('main');
            window.navigator.geolocation.getCurrentPosition(
                (pos) => {
                    this._locationDetectionSuccess(pos);
                },
                (error) => {
                    this._locationDetectionFailed(error);
                },
                {
                    maximumAge: 3600000 /* 1 hour */,
                    timeout: 3000 /* 3 seconds */,
                    enableHighAccuracy: true,
                }
            );
            closeLoader('main');
        } else {
            console.warn('geolocation not found in navigator object');
        }
    }
    
    _locationDetectionSuccess(pos) {
        const coords = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
        };
        this.geocoder.geocode({ location: coords }, (results, status) => {
            if (status === 'OK') {
                const place = results[0];
                this.input.value = place.formatted_address;
                if (!this._manageState(place)) {
                    return;
                }
                this.value = place;
                this._changedInput();
                this.searchType = 'Geolocalizzazione';
                storeManager.emit('mt2AddressSearchType', { searchType: this.searchType });
                this._trackRegistrationFunnel(TRACKABLE_EVENT.searchAddress, FUNNEL_STEP.searchAddress, {
                    type: this.searchType,
                    address: place.formatted_address,
                });
                this._toggleCancelAddress();
            } else {
                console.warn(`Geocoder failed with code ${status}`);
            }
        });
    }
    _locationDetectionFailed(error) {
        switch (error.code) {
            case window.GeolocationPositionError.PERMISSION_DENIED:
                console.warn(`Geolocation permission denied. ${error.message}`);
                break;
            case window.GeolocationPositionError.POSITION_UNAVAILABLE:
                console.warn(`Geolocation position not available. ${error.message}`);
                break;
            case window.GeolocationPositionError.TIMEOUT:
                console.warn(`Geolocation request went in timeout. ${error.message}`);
                break;
            default:
                console.warn(`Unknown error ${error.code}: ${error.message}`);
                break;
        }
    }

    _requireField() {
        this.setErrorText(dictionary.getFEMessage('requiredField'));
    }

    _toggleCancelAddress() {
        if (this.input.value !== '') {
            this._showCancelAddress();
        } else {
            this._hideCancelAddress();
        }
    }
    _hideCancelAddress() {
        if (!this.cancel.classList.contains(this._elMod('cancel', 'hidden'))) {
            this.input.style.paddingRight = '1rem';
            this.cancel.classList.add(this._elMod('cancel', 'hidden'));
            this.cancel.setAttribute('tabindex', '-1');
        }
    }
    _showCancelAddress() {
        this.cancel.classList.remove(this._elMod('cancel', 'hidden'));
        this.cancel.setAttribute('tabindex', '0');
        this.input.style.paddingRight = '2rem';
    }

    _trackRegistrationFunnel(event, funnelStep, { type, address }) {
        const typeOfService = storeManager.get('ecRegistrationData')?.typeOfService;
        const originEcommerce = storeManager.get('ecRegistrationData')?.originEcommerce;
        const search = { type, address };
        if (storeManager.get('currentFlow').includes('conad-register')) {
            if (originEcommerce && typeOfService) {
                trackEvent(event, FUNNEL_NAME.registration, funnelStep, typeOfService || '', search);
            } else {
                trackEvent(event, FUNNEL_NAME.registration, funnelStep, '', search);
            }
        }
    }

    async _manualSearch() {
        const gmaps = await gMapsHelper.getGMaps();
        this.token = new gmaps.places.AutocompleteSessionToken();
        /* retrieve predictions */
        const placePredictionsRequest = {
            input: this.input.value,
            sessionToken: this.token,
        };
        this.autocompleteService.getPlacePredictions(placePredictionsRequest, (predictions, status) => {
            if (status != window.google.maps.places.PlacesServiceStatus.OK || !predictions || predictions.length <= 0) {
                console.warn(`No predictions found for value "${placePredictionsRequest.input}"`);
                return;
            }
            /* retrieve first prediction details by place id via geocoder */
            const firstPrediction = predictions[0];
            const geocodeRequest = { placeId: firstPrediction.place_id };
            this.geocoder.geocode(geocodeRequest, (results, status) => {
                if (status != window.google.maps.GeocoderStatus.OK || !results || results.length <= 0) {
                    console.warn('No place found with geocoder for the given place id');
                    return;
                }
                const place = results[0];
                this.input.value = place.formatted_address;
                if (!this._manageState(place)) {
                    return;
                }
                this.value = place;
                this._changedInput();
                this.searchType = 'Manuale';
                storeManager.emit('mt2AddressSearchType', { searchType: this.searchType });
                this._trackRegistrationFunnel(TRACKABLE_EVENT.searchAddress, FUNNEL_STEP.searchAddress, {
                    type: this.searchType,
                    address: place.formatted_address,
                });
                this._toggleCancelAddress();
            });
        });
    }

    _manageState(currentPlace) {
        this.setValidationContext({
            addressObj: currentPlace,
        });
        this._checkState();
        return !this.root.classList.contains(this._mod('errorState'));
    }

    /* override */
    setErrorText(errorText) {
        super.setErrorText(errorText);
        if (typeof errorText !== 'string' || errorText === '') return;
        this.error.innerText = errorText;
    }

    _getInput() {
        return this.input;
    }

    /* override */
    _changedInput() {
        const data = {
            valid: this.isValid(),
            value: this.getAddressValue(),
            label: this.getLabel(),
        };

        const event = new CustomEvent('ecInputChanged', { bubbles: true });
        event.data = data;
        this.root.dispatchEvent(event);

        //console.debug(`Changed data for ${this.root.getAttribute('id')}`, data);
    }

    getAddressValue() {
        return this.value;
    }

    reset() {
        this._getInput().value = '';
        this._hideCancelAddress();
        this.value = null;
        this.setState('');
    }
}


