import { apiProvider } from '../../../../../../../libs/api-provider';
import ModalComponent from '../../../../../../../libs/components/modal-component';
import { flowManager } from '../../../../../../../libs/flow-manager';
import { runTemplate } from '../../../../../../../libs/htl-runtime/HTMLRuntime';
import { register } from '../../../../../../../libs/register';
import { storeManager } from '../../../../../../../libs/store-manager';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../../libs/tracking-manager-old';
import { emptyElement } from '../../../../../../../libs/utils';
import { closeLoader, openLoader } from '../../../../templates/mt11-loader/script';
import storeTpl from '../../../../templates/mt3-store/mt3-store.html';
import Store from '../../../../templates/mt3-store/script';

export default class SelectPdv extends ModalComponent {
    constructor(name, root) {
        super(name, root);

        this.BREAKPOINT_L = 1024;
        this.title = this._dEl('title');
        this.topBar = this._dEl('topBar');
        this.addressfield = this._dEl('addressfield');
        this.wrapper = this._dEl('wrapper');
        this.selectTop = this._dEl('selectTop');
        this.selectContent = this._dEl('selectContent');
        this.mapMob = this._dEl('mapMob');
        this.id = this.root.getAttribute('id');
        this.selectBtn = this._dEl('select');
        this.showMapBtn = this._dEl('showMap');
        this.showListBtn = this._dEl('showList');
        this.pointOfServices = [];
        this.currentStore = null;
        this.modality = null;
        this.extendedVersion = this.root.hasAttribute('data-extended-version');

        this.mapEl = this.modal.getRoot().querySelector('.mm6-select-pdv-map');
        if (this.mapEl) {
            this.map = register.getClass(this.mapEl);
            if (this.extendedVersion && window.innerWidth < this.BREAKPOINT_L) {
                this.mapMob.appendChild(this.mapEl);
                this.mapEl = this.mapMob?.querySelector('.mm6-select-pdv-map');
            }
        }
        this._addEventListeners();
        this._addStoreListeners();
    }

    _addEventListeners() {
        this.addressfield.addEventListener('ecInputChanged', (event) => {
            this._loadStores(event.data.value);
        });
        ['click', 'keydown'].forEach((e) => {
            this.root.addEventListener(e, (event) => {
                if (event instanceof KeyboardEvent && event.key !== 'Enter' && event.key !== ' ') return;
                const storeEl = event.target.closest(this._el('store', true));
                if (!storeEl) return;

                this.selectPof(storeEl.dataset.storeName, false, true);
            });
        });

        this.selectBtn.addEventListener('click', async () => {
            if (this.modality === 'modifyStore') {
                await this._modifyPrefStore(this.currentStore?.anacanId);
                flowManager.complete();
                window.location.reload();
            } else if (this.modality == 'selectPdv') {
                this._emit('selectedStore', this.currentStore);
                flowManager.complete();
            } else {
                // For registration
                storeManager.emit('selectedPdv', this.currentStore);
                const search = {
                    type: `${this.searchType}`,
                    address: `${this.currentStore.indirizzo}, ${this.currentStore.cap} ${this.currentStore.nomeComune}`,
                };
                const userInfo = {
                    coop: this.currentStore.codiceCooperativa,
                    store: this.currentStore.anacanId,
                };
                this._trackRegistrationFunnel(TRACKABLE_EVENT.selectStore, FUNNEL_STEP.selectStore, search, userInfo);
                flowManager.backOrComplete();
                if (this.originEcommerce) {
                    storeManager.emit('ecRegistrationData', { selectedPdv: this.currentStore }, true);
                }
            }
        });

        this.showMapBtn?.addEventListener('click', () => {
            if (this.map) {
                if (window.innerWidth >= this.BREAKPOINT_L) {
                    this.modal.showRight();
                } else {
                    this._showMobileMap();
                }
                this.map.panSelected();
            }
        });

        this.showListBtn?.addEventListener('click', () => {
            this._showMobileMap(false);
            this.selectPof(this.currentStore.anacanId, true, false);
        });

        /* form errors */
        this.topBar.addEventListener('ecFormError', (event) => {
            event.preventDefault();
            if (!event.data.errorField) return;
            trackEvent(TRACKABLE_EVENT.formError, FUNNEL_NAME.storeChoice, FUNNEL_STEP.storeChoice, null, null, null, {
                errorField: event.data.errorField,
                errorText: event.data.errorText,
            });
            event.stopPropagation();
        });
    }

    _addStoreListeners() {
        this._addStoreListener('setModality', (path, data) => {
            this.modality = data.modality || null;
        });
        this._addStoreListener('setSelectPdvTitle', (path, data) => {
            this.title.innerText = data.title;
        });
        this._addStoreListener('mt2AddressSearchType', (path, data) => {
            this.searchType = data.searchType;
        });
    }

    _showMobileMap(show = true) {
        if (show) {
            if (!this.root.classList.contains(this._mod('mapMob'))) this.root.classList.add(this._mod('mapMob'));
        } else {
            this.root.classList.remove(this._mod('mapMob'));
        }
    }

    setSelectedPof(anacanId) {
        this.currentStore = this.pointOfServices.filter((item) => item.anacanId == anacanId)[0];
    }

    selectPof(anacanId, scrollTo = true, updateMap = true) {
        const storeEl = this.selectContent.querySelector(`[data-store-name="${anacanId}"]`);
        if (!storeEl) {
            console.warn(`cannot select store ${anacanId}`);
            return;
        }

        const otherStores = this._dEl('store', true);
        for (const oldStoreEl of otherStores) {
            register.getClass(oldStoreEl).unsetSelected();
        }

        register.getClass(storeEl).setSelected();
        this.selectBtn.classList.remove('invalid');
        this.currentStore = this.pointOfServices.filter((item) => item.anacanId == storeEl.dataset.storeName)[0];

        if (scrollTo) {
            this.wrapper.scrollTop = storeEl.offsetTop - 20;
        }

        if (this.map && updateMap) {
            this.map.selectPoi(anacanId);
        }
    }

    async _loadStores(address) {
        if (!address || !address.geometry || !address.types) return;
        const body = {
            latitudine: address.geometry.location.lat(),
            longitudine: address.geometry.location.lng(),
            partial: address.types.indexOf('street_address') == -1,
        };

        try {
            openLoader('main');

            const response = await apiProvider.getPointOfService(body);
            this.pointOfServices = response;
            if (!this.pointOfServices || this.pointOfServices.length <= 0) {
                this.selectTop.classList.add(this._elMod('selectTop', 'show'));
                closeLoader('main');
                return;
            }

            /* show pof */
            await this._addPofs();
        } catch (error) {
            console.error(error);
        } finally {
            closeLoader('main');
        }
    }

    async _addPofs() {
        emptyElement(this.selectContent);

        this.selectTop.classList.add(this._elMod('selectTop', 'show'));
        this.selectTop.querySelector('.count').innerHTML = this.pointOfServices.length;

        for (const st of this.pointOfServices) {
            const data = Store.getStoreData(
                this.id + '-' + Math.floor(Math.random() * 100000),
                `${this.name}__store`,
                st
            );
            const storeEl = runTemplate(storeTpl, data);
            this.selectContent.appendChild(storeEl);
        }

        await this._addResultsToMap();
    }

    async _addResultsToMap() {
        if (!this.map) return;
        await this.map.addResults(this.pointOfServices);
    }

    async _modifyPrefStore(anacanId) {
        const data = {
            anacanId: anacanId || '',
        };
        try {
            openLoader('main');
            await apiProvider.setPreferredStore(data);
            //await this.userService.addSavedStore(this.storeId);
        } catch (error) {
            console.warn(error);
            closeLoader('main');
        }
    }

    _trackRegistrationFunnel(event, funnelStep, searchObj = null, userInfoObj = null) {
        const typeOfService = storeManager.get('ecRegistrationData')?.typeOfService;
        const originEcommerce = storeManager.get('ecRegistrationData')?.originEcommerce;
        const search =
            searchObj && searchObj.type && searchObj.address
                ? { type: searchObj.type, address: searchObj.address }
                : null;
        const userInfo =
            userInfoObj && userInfoObj.coop && userInfoObj.store
                ? { coop: userInfoObj.coop, store: userInfoObj.store }
                : null;
        if (storeManager.get('currentFlow').includes('conad-register')) {
            if (originEcommerce && typeOfService) {
                trackEvent(event, FUNNEL_NAME.registration, funnelStep, typeOfService, search, userInfo);
            } else {
                trackEvent(event, FUNNEL_NAME.registration, funnelStep, null, search, userInfo);
            }
        }
    }

    /* override */
    _onOpen() {
        super._onOpen();
        if (this.map) {
            this.map.init(this);
            /* add resize event handler only after initizlising the map */
            if (this.extendedVersion) {
                this._addListener(
                    'resize',
                    () => {
                        if (!this.modal.rightContent) return;
                        if (window.innerWidth >= this.BREAKPOINT_L) {
                            this.modal.rightContent.append(this.mapEl);
                            this.mapEl = this.modal.getRoot().querySelector('.mm6-select-pdv-map');
                            this.modal.showRight();
                        } else {
                            this.mapMob?.append(this.mapEl);
                            this.mapEl = this.mapMob?.querySelector('.mm6-select-pdv-map');
                            this.modal.hideRight();
                            /* condition to avoid showing the map wrongfully when you started with the list in mobile, 
                           then rotated the screen and showed the map in desktop, and then rotated back into mobile */
                            if (this.root.classList.contains(this._mod('mapMob'))) this._showMobileMap();
                        }
                    },
                    window
                );
            }
        }
        this._trackRegistrationFunnel(TRACKABLE_EVENT.pageview, FUNNEL_STEP.storeChoice);
    }

    _onClose() {
        super._onClose();
        if (this.map) this.map.destroy();
        this._trackRegistrationFunnel(TRACKABLE_EVENT.closeModal, FUNNEL_STEP.closeModal);
    }

    _onCancel() {
        flowManager.backOrComplete();
    }
}
