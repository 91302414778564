/*
 * Copyright 2018 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

/* eslint-disable */

module.exports = function main($) {

  const htl = $.globals;
  let data = $.globals["data"];

  let render;
  render = function* _template_global_render(args) { 
    let icon = args[1]['icon'] || '';
    let title = args[1]['title'] || '';
    let text = args[1]['text'] || '';
    let textmob = args[1]['textMob'] || '';
    let qrcodetext = args[1]['qrCodeText'] || '';
    let qrcodeimage = args[1]['qrCodeImage'] || '';
    let desktoplink = args[1]['desktopLink'] || '';
    let applestoreimage = args[1]['appleStoreImage'] || '';
    let applestorelink = args[1]['appleStoreLink'] || '';
    let googleplayimage = args[1]['googlePlayImage'] || '';
    let googleplaylink = args[1]['googlePlayLink'] || '';
    let $t, $n = args[0];
    $.dom.text($n,"\n\n    ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mc7-strillo-app__wrapper", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n\n        ");
    $t = $.dom.create("div",false,false);
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n            ");
    $t = $.dom.create("span",false,false);
    const var_attrValue0 = ("mc7-strillo-app__icon ") + (icon);
    if (!$.col.empty(var_attrValue0)) {
      $.dom.attr($t, 'class', var_attrValue0, 'attribute');
    }
    $n = $.dom.push($n,$t);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    $t = $.dom.create("div",false,false);
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mc7-strillo-app__title", 'attribute');
    $n = $.dom.push($n,$t);
    const var_1 = yield $.xss(title, "html");
    $.dom.append($n, var_1);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n                ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mc7-strillo-app__text mc7-strillo-app__text--dsk", 'attribute');
    $n = $.dom.push($n,$t);
    const var_2 = yield $.xss(text, "html");
    $.dom.append($n, var_2);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n                ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mc7-strillo-app__text mc7-strillo-app__text--mob", 'attribute');
    $n = $.dom.push($n,$t);
    const var_3 = yield $.xss(textmob ? textmob : text, "html");
    $.dom.append($n, var_3);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n        ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n        ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mc7-strillo-app__ctasMob", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n            ");
    const var_testVariable4 = applestoreimage;
    if (var_testVariable4) {
      $.dom.text($n,"\n                \n                ");
      const applestorelinkobj = applestorelink;
      if (applestorelinkobj) {
        $.dom.text($n,"\n                    ");
        const var_tagVar5 = yield $.xss(applestorelinkobj["element"], "elementNameNoFallback");
        if (var_tagVar5) {
          $t = $.dom.create(var_tagVar5,false,false);
        }
        if (!var_tagVar5) {
          $t = $.dom.create("a",false,false);
        }
        const var_attrMap6 = applestorelinkobj["hashMap"];
        const var_attrName_id7 = "id";
        const var_mapContains_id8 = var_attrMap6["id"];
        if (!$.col.empty(var_mapContains_id8)) {
          $.dom.attr($t, var_attrName_id7, var_mapContains_id8, 'attribute');
        }
        if ((var_mapContains_id8) == (null)) {
          const var_attrValue9 = applestorelinkobj["uniqueId"];
          if (!$.col.empty(var_attrValue9)) {
            $.dom.attr($t, 'id', var_attrValue9, 'attribute');
          }
        }
        const var_attrValue10 = ("mc7-strillo-app__cta ") + (applestorelinkobj["classes"]);
        if (!$.col.empty(var_attrValue10)) {
          $.dom.attr($t, 'class', var_attrValue10, 'attribute');
        }
        const var_ignoredAttributes11 = {"id": true, "class": true, };
        for (const var_attrIndex14 of $.col.keys(var_attrMap6)) {
          const var_attrName12 = $.col.get(var_attrMap6, var_attrIndex14);
          const var_attrNameEscaped13 = yield $.xss(var_attrName12, "attributeName");
          if (var_attrNameEscaped13) {
            if (!var_ignoredAttributes11[var_attrName12]) {
              const var_attrContent15 = var_attrMap6[var_attrName12];
              if (!$.col.empty(var_attrContent15)) {
                $.dom.attr($t, var_attrNameEscaped13, var_attrContent15, 'attribute');
              }
            }
          }
        }
        $n = $.dom.push($n,$t);
        $.dom.text($n,"\n                        ");
        $t = $.dom.create("img",true,true);
        const var_attrValue16 = applestoreimage["url"];
        if (!$.col.empty(var_attrValue16)) {
          $.dom.attr($t, 'src', var_attrValue16, 'attribute');
        }
        const var_attrValue17 = applestoreimage["alt"];
        if (!$.col.empty(var_attrValue17)) {
          $.dom.attr($t, 'alt', var_attrValue17, 'attribute');
        }
        $n = $.dom.push($n,$t);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                    ");
        if (var_tagVar5) {
          $n = $.dom.pop($n);
        }
        if (!var_tagVar5) {
          $n = $.dom.pop($n);
        }
        $.dom.text($n,"\n                ");
      }
      $.dom.text($n,"\n            ");
    }
    $.dom.text($n,"\n            ");
    const var_testVariable18 = googleplayimage;
    if (var_testVariable18) {
      $.dom.text($n,"\n                \n                ");
      const googleplaylinkobj = googleplaylink;
      if (googleplaylinkobj) {
        $.dom.text($n,"\n                    ");
        const var_tagVar19 = yield $.xss(googleplaylinkobj["element"], "elementNameNoFallback");
        if (var_tagVar19) {
          $t = $.dom.create(var_tagVar19,false,false);
        }
        if (!var_tagVar19) {
          $t = $.dom.create("a",false,false);
        }
        const var_attrMap20 = googleplaylinkobj["hashMap"];
        const var_attrName_id21 = "id";
        const var_mapContains_id22 = var_attrMap20["id"];
        if (!$.col.empty(var_mapContains_id22)) {
          $.dom.attr($t, var_attrName_id21, var_mapContains_id22, 'attribute');
        }
        if ((var_mapContains_id22) == (null)) {
          const var_attrValue23 = googleplaylinkobj["uniqueId"];
          if (!$.col.empty(var_attrValue23)) {
            $.dom.attr($t, 'id', var_attrValue23, 'attribute');
          }
        }
        const var_attrValue24 = ("mc7-strillo-app__cta ") + (googleplaylinkobj["classes"]);
        if (!$.col.empty(var_attrValue24)) {
          $.dom.attr($t, 'class', var_attrValue24, 'attribute');
        }
        const var_ignoredAttributes25 = {"id": true, "class": true, };
        for (const var_attrIndex28 of $.col.keys(var_attrMap20)) {
          const var_attrName26 = $.col.get(var_attrMap20, var_attrIndex28);
          const var_attrNameEscaped27 = yield $.xss(var_attrName26, "attributeName");
          if (var_attrNameEscaped27) {
            if (!var_ignoredAttributes25[var_attrName26]) {
              const var_attrContent29 = var_attrMap20[var_attrName26];
              if (!$.col.empty(var_attrContent29)) {
                $.dom.attr($t, var_attrNameEscaped27, var_attrContent29, 'attribute');
              }
            }
          }
        }
        $n = $.dom.push($n,$t);
        $.dom.text($n,"\n                        ");
        $t = $.dom.create("img",true,true);
        const var_attrValue30 = googleplayimage["url"];
        if (!$.col.empty(var_attrValue30)) {
          $.dom.attr($t, 'src', var_attrValue30, 'attribute');
        }
        const var_attrValue31 = googleplayimage["alt"];
        if (!$.col.empty(var_attrValue31)) {
          $.dom.attr($t, 'alt', var_attrValue31, 'attribute');
        }
        $n = $.dom.push($n,$t);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                    ");
        if (var_tagVar19) {
          $n = $.dom.pop($n);
        }
        if (!var_tagVar19) {
          $n = $.dom.pop($n);
        }
        $.dom.text($n,"\n                ");
      }
      $.dom.text($n,"\n            ");
    }
    $.dom.text($n,"\n        ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n        ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mc7-strillo-app__ctasDsk", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n            ");
    const var_testVariable32 = qrcodeimage;
    if (var_testVariable32) {
      $.dom.text($n,"\n                ");
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "mc7-strillo-app__qrCode", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                    \n                    ");
      const var_testVariable33 = qrcodetext;
      if (var_testVariable33) {
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "mc7-strillo-app__qrCodeText", 'attribute');
        $n = $.dom.push($n,$t);
        $.dom.text($n,"\n                        ");
        const var_34 = yield $.xss(qrcodetext, "html");
        $.dom.append($n, var_34);
        $.dom.text($n,"\n                        ");
        $t = $.dom.create("span",false,false);
        $.dom.attr($t, 'class', "ec-icon-next", 'attribute');
        $n = $.dom.push($n,$t);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                    ");
        $n = $.dom.pop($n);
      }
      $.dom.text($n,"\n                    \n                    ");
      $t = $.dom.create("img",true,true);
      $.dom.attr($t, 'class', "mc7-strillo-app__qrCodeImage", 'attribute');
      const var_attrValue35 = qrcodeimage["url"];
      if (!$.col.empty(var_attrValue35)) {
        $.dom.attr($t, 'src', var_attrValue35, 'attribute');
      }
      const var_attrValue36 = qrcodeimage["alt"];
      if (!$.col.empty(var_attrValue36)) {
        $.dom.attr($t, 'alt', var_attrValue36, 'attribute');
      }
      $n = $.dom.push($n,$t);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n                ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n            ");
    }
    $.dom.text($n,"\n            ");
    const var_testVariable37 = !qrcodeimage;
    if (var_testVariable37) {
      $.dom.text($n,"\n                \n                ");
      const desktoplinkobj = desktoplink;
      if (desktoplinkobj) {
        $.dom.text($n,"\n                    ");
        const var_tagVar38 = yield $.xss(desktoplinkobj["element"], "elementNameNoFallback");
        if (var_tagVar38) {
          $t = $.dom.create(var_tagVar38,false,false);
        }
        if (!var_tagVar38) {
          $t = $.dom.create("a",false,false);
        }
        const var_attrMap39 = desktoplinkobj["hashMap"];
        const var_attrName_id40 = "id";
        const var_mapContains_id41 = var_attrMap39["id"];
        if (!$.col.empty(var_mapContains_id41)) {
          $.dom.attr($t, var_attrName_id40, var_mapContains_id41, 'attribute');
        }
        if ((var_mapContains_id41) == (null)) {
          const var_attrValue42 = desktoplinkobj["uniqueId"];
          if (!$.col.empty(var_attrValue42)) {
            $.dom.attr($t, 'id', var_attrValue42, 'attribute');
          }
        }
        const var_attrValue43 = ("mc7-strillo-app__desktopLink ") + (desktoplinkobj["classes"]);
        if (!$.col.empty(var_attrValue43)) {
          $.dom.attr($t, 'class', var_attrValue43, 'attribute');
        }
        const var_ignoredAttributes44 = {"id": true, "class": true, };
        for (const var_attrIndex47 of $.col.keys(var_attrMap39)) {
          const var_attrName45 = $.col.get(var_attrMap39, var_attrIndex47);
          const var_attrNameEscaped46 = yield $.xss(var_attrName45, "attributeName");
          if (var_attrNameEscaped46) {
            if (!var_ignoredAttributes44[var_attrName45]) {
              const var_attrContent48 = var_attrMap39[var_attrName45];
              if (!$.col.empty(var_attrContent48)) {
                $.dom.attr($t, var_attrNameEscaped46, var_attrContent48, 'attribute');
              }
            }
          }
        }
        $n = $.dom.push($n,$t);
        const var_49 = yield $.xss(desktoplinkobj["label"], "html");
        $.dom.append($n, var_49);
        if (var_tagVar38) {
          $n = $.dom.pop($n);
        }
        if (!var_tagVar38) {
          $n = $.dom.pop($n);
        }
        $.dom.text($n,"\n                ");
      }
      $.dom.text($n,"\n            ");
    }
    $.dom.text($n,"\n        ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n    ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n");
  };
  $.template('global', 'render', render);

  return $.run(function* () {
    let $t, $n = $.dom.start();
    return $.dom.end();

  });
};
