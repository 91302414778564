import { apiProvider } from '../../../../../../libs/api-provider';
import WizardComponent from '../../../../../../libs/components/wizard-component';
import { storeManager } from '../../../../../../libs/store-manager';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import { delay, getCallback } from '../../../../../../libs/utils';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import './style.scss';

export default class ThankYou extends WizardComponent {
    constructor(name, root) {
        super(name, root);

        this.title = this._dEl('title');
        this.titleTxt = this.title.innerText;

        this.text = this._dEl('thankTxt');
        this.textHtml = this.text.innerHTML;

        this.thankResendBtn = this._dEl('thankResendBtn');
        this.resendedMessage = this._dEl('resendedMessage');

        this._addEventListeners();
        this._addStoreListeners();
    }

    /* override */
    open() {
        super.open();
        const ecRegistrationData = storeManager.get('ecRegistrationData');
        const pointOfServiceId = ecRegistrationData?.pointOfServiceId;
        const cooperativeId = ecRegistrationData?.cooperativeId;
        const selectedPdv = storeManager.get('selectedPdv');
        this.userInfo = null;
        if (pointOfServiceId && cooperativeId) {
            /* case pdv selected on ecommerce (registration flow from ecommerce) */
            this.userInfo = {
                coop: cooperativeId,
                store: pointOfServiceId,
            };
        } else if (selectedPdv) {
            /* case pdv selected during registration flow */
            this.userInfo = {
                coop: selectedPdv.codiceCooperativa,
                store: selectedPdv.anacanId
            };
        }
        this._trackRegistrationFunnel(TRACKABLE_EVENT.pageview, FUNNEL_STEP.registrationPerformed, this.userInfo);
        this.link = this._dEl('continueNavigation');
        const urlParams = new URLSearchParams(window.location.search);
        const cb = urlParams.get('cb');
        if (this.link && cb) {
            const url = new URL(cb);
            this.link.href = url.href;
        }
    }

    _addEventListeners() {
        this.thankResendBtn.addEventListener('click', (event) => {
            event.preventDefault();
            this.thankResendBtn.classList.add(this._elMod('thankResendBtn', 'disabled'));
            this._trackRegistrationFunnel(TRACKABLE_EVENT.resendActivationEmailCta, FUNNEL_STEP.resendActivationEmailCta, this.userInfo);
            this._resendActivationEmail();
        });
    }

    _addStoreListeners() {
        this._addStoreListener('userData', (path, data) => {
            this.title.innerText = this.titleTxt.replace(/\$([a-zA-Z0-9]+)/g, (match, group) => {
                return data[group];
            });
            this.text.innerHTML = this.textHtml.replace(/\$([a-zA-Z0-9]+)/g, (match, group) => {
                return data[group];
            });
            this.email = data.email;
        });
        this._addStoreListener('generatedUserId', (path, data) => {
            if (this.userInfo) this.userInfo.id = data.userId;
        });
    }

    async _resendActivationEmail() {
        if (!this.email || this.email === '') {
            console.warn('Missing email parameter for resend activation email request');
            return;
        }
        try {
            openLoader('main');
            await apiProvider.resendActivationEmail({ email: this.email, cb: getCallback() });
            await delay(500);
        } catch (error) {
            console.warn(error);
        } finally {
            closeLoader('main');
            this.resendedMessage.classList.remove(this._elMod('resendedMessage', 'hidden'));
            await delay(30000);
            this.thankResendBtn.classList.remove(this._elMod('thankResendBtn', 'disabled'));
            this.resendedMessage.classList.add(this._elMod('resendedMessage', 'hidden'));
        }
    }

    _trackRegistrationFunnel(event, funnelStep, userInfoObj = null) {
        const typeOfService = storeManager.get('ecRegistrationData')?.typeOfService;
        const originEcommerce = storeManager.get('ecRegistrationData')?.originEcommerce;
        const userInfo = (userInfoObj && userInfoObj.coop && userInfoObj.store) ? { coop: userInfoObj.coop, store: userInfoObj.store } : null;
        if (storeManager.get('currentFlow').includes('conad-register')) {
            if (originEcommerce && typeOfService) {
                trackEvent(event, FUNNEL_NAME.registration, funnelStep, typeOfService, null, userInfo);
            } else {
                trackEvent(event, FUNNEL_NAME.registration, funnelStep, null, null, userInfo);
            }
        }
    }
}


