import PopupComponent from '../../../../../../libs/components/popup-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import './style.scss';

export default class SaveProfile extends PopupComponent {
    constructor(name, root) {
        super(name, root);

        this.action = this._dEl('ctaAction');
        this._init();
    }

    _init() {
        this.action.addEventListener('click', () => {
            flowManager.appendDataToFlow({ ok: true });
            flowManager.complete();
        });
    }

    /* override */
    _onCancel() {
        flowManager.backOrComplete();
    }
}
