import PopupComponent from '../../../../../../libs/components/popup-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import './style.scss';

export default class BolliniInfo extends PopupComponent {
    constructor(name, root) {
        super(name, root);
    }

    _onCancel() {
        flowManager.complete();
    }
}
