import PopupComponent from '../../../../../../libs/components/popup-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import './style.scss';

export default class ReorderError extends PopupComponent {
    constructor(name, root) {
        super(name, root);
    }

    _onBeforeOpen() {
        super._onBeforeOpen();
        const data = flowManager.getDataFromFlow();
        console.log(data.errorType);
        if (data.errorType === "OC") {
            this._dEl("oc",true).forEach((item)=> {
                item.style.display = "block"
            });
        } else {
            this._dEl("hd",true).forEach((item)=> {
                item.style.display = "block"
            });
        }
    }

    _onClose() {
        super._onClose();
        this._dEl("oc",true).forEach((item)=> {
            item.style.display = "none"
        });
        this._dEl("hd",true).forEach((item)=> {
            item.style.display = "none"
        });
    }

    /* override */
    _onCancel() {
        flowManager.complete();
    }
}