import Component from '../../../../../../libs/components/component';
import './style.scss';


export default class Preheader extends Component {
    constructor(name, root) {
        super(name, root);

        if (this.root.dataset.referrerMap) this.referrerMap = JSON.parse(this.root.dataset.referrerMap);
        this.back = this._dEl('back');
        this.origin = this._dEl('origin');
        this.goto = this._dEl('goto');
        this.gotoDropdown = this._dEl('gotoDropdown');
        this.BREAKPOINT_L = 1024;

        this._setGoBackLink();
        this._addEventListeners();
    }

    _addEventListeners() {
        this.goto?.addEventListener('click', (event) => {
            event.preventDefault();
            this.isDropdownOpen() ? this.closeDropdown() : this.openDropdown();
        });
        this.goto?.addEventListener('keydown', (event) => {
            if (event.key !== 'Escape') return;
            this.closeDropdown();
        });
    }

    isDropdownOpen() {
        return this.gotoDropdown.classList.contains(this._elMod('gotoDropdown', 'open'));
    }

    openDropdown() {
        if (window.innerWidth >= this.BREAKPOINT_L || this.isDropdownOpen()) 
            return;
        this.gotoDropdown.style.visibility = 'visible';
        this.gotoDropdown.classList.add(this._elMod('gotoDropdown', 'open'));
        this._updateAriaAttributes();
    }

    closeDropdown() {
        this.gotoDropdown.classList.remove(this._elMod('gotoDropdown', 'open'));
        this._updateAriaAttributes();
        setTimeout(() => {
            this.gotoDropdown.style.visibility = 'hidden';
        }, 300);
    }

    _updateAriaAttributes() {
        const isOpen = this.isDropdownOpen();
        this.goto.setAttribute('aria-expanded', `${isOpen ? 'true' : 'false'}`);
        this.gotoDropdown.setAttribute('aria-hidden', `${isOpen ? 'false' : 'true'}`);
    }

    _setGoBackLink() {
        /* get current referrer */
        let referrer = document.referrer;
        /* get allowed referrers */
        const allowedReferrers = window.allowedReferrers ? Object.values(window.allowedReferrers) : null;
        if (!allowedReferrers) {
            
            return;
        }
        /* check allowed referrers for a match */
        let matchReferrer = false;
        let referrerURL;
        if (referrer && referrer != '') {
            referrerURL = new URL(referrer);
            matchReferrer = allowedReferrers.includes(referrerURL.hostname);
        }
        if (!matchReferrer) {
            /* referrer not matching, check if a last valid referrer is saved in the current day */
            const lastValidReferrer = window.localStorage.getItem('last-valid-referrer');
            const today = new Date();
            today.setHours(0, 0, 0);
            if (lastValidReferrer && JSON.parse(lastValidReferrer).timestamp >= today.getTime()) {
                /* usable last-valid-referrer */
                referrer = JSON.parse(lastValidReferrer).value;
                referrerURL = new URL(referrer);
            } else {
                /* missing/expired last valid referrer */
                return;
            }
        } else {
            /* referrer matching one of the allowed ones */
            if (referrerURL && !referrerURL.hostname.includes('myconad')) {
                /* referrer matches and is NOT myconad */
                /* save new valid referrer only if not from myconad */
                const newLastValidReferrer = JSON.stringify({
                    value: referrer,
                    timestamp: new Date().getTime()
                });
                window.localStorage.setItem('last-valid-referrer', newLastValidReferrer);
            } else {
                /* referrer matches but IS myconad */
                /* keep last valid referrer, or empty */
                const lastValidReferrer = window.localStorage.getItem('last-valid-referrer');
                if (lastValidReferrer && lastValidReferrer != '') {
                    referrer = JSON.parse(lastValidReferrer).value;
                    referrerURL = new URL(referrer);
                } else {
 
                    return;
                }
            }
        }
        const labelKey = Object.keys(window.allowedReferrers).find((key) => window.allowedReferrers[key] == referrerURL.hostname);
        if (labelKey && this.referrerMap && this.referrerMap[labelKey]) {
            this.origin.innerText = this.referrerMap[labelKey];
            this.back.href = referrer;
            this.back.classList.add(this._elMod('back', 'show'));
        } else {
            console.warn(`Referrer "${referrer}" is allowed but does not match any label!`);
        }
    }
}


