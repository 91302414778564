import Component from '../../../../../libs/components/component';
import './style.scss';


export default class IconCards extends Component {
    constructor(name, root) {
        super(name, root);
        this.carousel = this._dEl('carousel');
        this._init();
    }

    async _init() {
        const Swiper = (await import('swiper')).Swiper;
        this.swiper = new Swiper(this.carousel, {
            slidesPerView: 'auto',
            wrapperClass: this._el('track'),
            slideClass: this._el('card'),
            spaceBetween: 16,
            slidesOffsetBefore: 1,
            slidesOffsetAfter: 1,
        });
    }
}


