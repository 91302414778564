class Dictionary {
    constructor() {
        this._loadDictionary();
        this._loadFrontendMessages();
    }

    _loadDictionary() {
        try {
            const data = window.dictionary;
            this.dictionary = { update: Date.now(), content: data };
        } catch (error) {
            console.error('Cannot get dictionary', error);
        }
    }

    /**
     * Get dictionary byg key
     * @param {*} key
     * @returns The label
     */
    get(key) {
        const res = this.dictionary?.content && this.dictionary?.content[key];
        if (res) return res;
        return key;
    }

    /**
     * Get multiple dictionary keys
     * @param {*} key
     * @returns The label
     */
    getAll(keys) {
        const result = [];
        for (let key of keys) {
            result.push(this.get(key));
        }
        return result;
    }

    _loadFrontendMessages() {
        try {
            const data = window.frontendMessages;
            this.frontendMessages = { update: Date.now(), content: data };
        } catch (error) {
            console.error('Cannot get frontendMessages', error);
        }
    }

    /**
     * Get frontend message from key
     * @param {*} key
     * @returns The label
     */
    getFEMessage(key) {
        const res = this.frontendMessages?.content && this.frontendMessages?.content[key];
        if (res) return this.get(res);
        return key;
    }

    /**
     * Get multiple frontend messages from keys
     * @param {*} key
     * @returns The labels
     */
    getAllFEMessages(keys) {
        const result = [];
        for (let key of keys) {
            result.push(this.getFEMessage(key));
        }
        return result;
    }
}

const defaultDictionary = new Dictionary();
window.ecDictionary = defaultDictionary;
export const dictionary = defaultDictionary;
