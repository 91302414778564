import { apiProvider } from '../../../../../../libs/api-provider';
import ModalComponent from '../../../../../../libs/components/modal-component';
import { dictionary } from '../../../../../../libs/dictionary-provider';
import { flowManager } from '../../../../../../libs/flow-manager';
import { formToJSON } from '../../../../../../libs/form-to-json';
import { informativaManager } from '../../../../../../libs/informativa-manager';
import { register } from '../../../../../../libs/register';
import { storeManager } from '../../../../../../libs/store-manager';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import { isPositive } from '../../../../../../libs/utils';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import './style.scss';

export default class PrivacyConsents extends ModalComponent {
    constructor(name, root) {
        super(name, root);

        this.form = this._dEl('form');
        this.submit = this._dEl('submit');
        this.error = this._dEl('error');
        this.alert = this._dEl('alert');

        this._addEventListeners();
        this._initPrivacy();
    }

    /* override */
    _onOpen() {
        super._onOpen();

        trackEvent(TRACKABLE_EVENT.pageview, FUNNEL_NAME.cdnCoopResetPrivacy, FUNNEL_STEP.cdnCoopResetPrivacy);
    }

    _initPrivacy() {
        //init link
        this._initPrivacyText();

        this.user = storeManager.get('userData');
        this.pdv = storeManager.get('selectedPdv');
        //add store listener for privacy
        this._addStoreListener('selectedPdv', (path, data) => {
            this.pdv = data;
            this._setPrivacyLink();
        });

        this._addStoreListener('userData', (path, data) => {
            this.user = data;
            this._setPrivacyLink();
        });

        this._setPrivacyLink();
    }

    _addEventListeners() {
        this._dEl('box', true).forEach((box) => {
            box.addEventListener('ecInputChanged', (event) => {
                this._checkRadio(event.target.closest(this._el('box', true)), isPositive(event.data.value));
                this.ps?.update();
            });
        });
        this.form.addEventListener('submit', (event) => {
            event.preventDefault();
        });
        this.submit.addEventListener('click', async (event) => {
            event.preventDefault();
            if (!this._getFormValidity()) {
                this._showAlert();
                return;
            }

            this._hideAlert();
            const privacyData = formToJSON(this.form);

            // click tracking
            trackEvent(
                'clickSalvaConsensoUtenteLight',
                'Richiesta Consensi Customer per Utente Light',
                'Click salva consenso utente light'
            );

            // edit privacy consents
            openLoader('main');
            let result = await this._callEditUserData(privacyData);
            if (result) {
                window.location.reload(); // reload page
            } else {
                this.error.innerHTML = this.root.dataset.genericError;
                trackEvent(
                    TRACKABLE_EVENT.formError,
                    FUNNEL_NAME.insiemeCard,
                    FUNNEL_STEP.requestInsiemeCardForm,
                    null,
                    null,
                    null,
                    {
                        errorField: dictionary.getFEMessage('formSubmitError'),
                        errorText: dictionary.getFEMessage('genericError'),
                    }
                );
                this.error.classList.remove(this._elMod('error', 'hidden'));
                closeLoader('main');
            }
        });
    }

    async _callEditUserData(data) {
        let result = false;
        try {
            const firmaDigitale = await apiProvider.getFirmaDigitale();
            data.firmaDigitale = firmaDigitale;
            await apiProvider.editUserData(data);
            result = true;
        } catch (error) {
            console.warn(error);
            result = false;
        }
        return result;
    }

    _initPrivacyText() {
        const privacyText = this._dEl('privacyText');
        const privacyCoop = privacyText.dataset.privacyCoop;
        let text = privacyText.dataset.text;
        if (!text || text == '') return;
        const linkLabel = privacyText.dataset.linkLabel || dictionary.get('privacy policy');
        if (text.includes('{{cooperativa}}')) {
            text = text.replace('{{cooperativa}}', dictionary.get(privacyCoop));
        }
        if (!text.includes('{{privacy}}')) {
            privacyText.innerText = text;
            return;
        }
        const split = text.split('{{privacy}}');
        const link = document.createElement('a');
        link.href = '#';
        link.target = '_blank';
        link.classList.add(this._el('privacyLink'));
        link.innerText = linkLabel;
        privacyText.append(split[0], link, split[1]);
    }

    async _setPrivacyLink() {
        const privacyLink = this._dEl('privacyLink');
        if (!privacyLink) {
            console.warn('Cannot find privacy link');
            return;
        }

        const result = await informativaManager.getInformativa(this.user, this.pdv, true);
        return privacyLink.setAttribute('href', result.linkPdfInformativa);
    }

    _resetFormInputs() {
        if (!this.form) return;
        ['mt19-radio'].forEach((el) => {
            this.form.querySelectorAll(`.${el}`).forEach((field) => {
                register.getClass(field).reset();
            });
        });
    }

    _checkRadio(box, valid) {
        const boxNotSelected = box.querySelector(this._el('boxNotSelected', true));
        if (!valid) {
            boxNotSelected.classList.add(this._elMod('boxNotSelected', 'show'));
        } else {
            boxNotSelected.classList.remove(this._elMod('boxNotSelected', 'show'));
        }
    }

    _validRadios() {
        const validities = Array.from(this.form.querySelectorAll(`.mt19-radio`))
            .map((radio) => register.getClass(radio))
            .map((obj) => !!obj.isValid());
        const result = validities.reduce((_, v) => _ && v);
        return result;
    }

    _getFormValidity() {
        if (!this.form.checkValidity() || !this._validRadios()) {
            return false;
        }
        return true;
    }

    _showAlert() {
        this.alert.innerText = this.root.dataset.alertText;
        this.alert.classList.remove(this._elMod('alert', 'hidden'));
    }

    _hideAlert() {
        this.alert.innerText = '';
        this.alert.classList.add(this._elMod('alert', 'hidden'));
    }

    /* override */
    _onCancel() {
        flowManager.complete();
    }
}
