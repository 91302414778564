import Component from '../../../../../../libs/components/component';
import { register } from '../../../../../../libs/register';
import { openLoader, closeLoader } from '../../../templates/mt11-loader/script';
import { showPopup } from '../../../templates/mt4-popup/script';
import { apiProvider } from '../../../../../../libs/api-provider';
import { delay } from '../../../../../../libs/utils';
import { FUNNEL_NAME, FUNNEL_STEP, trackEvent, TRACKABLE_EVENT } from '../../../../../../libs/tracking-manager-old';
import './style.scss';

export default class Orders extends Component {
    constructor(name, root) {
        super(name, root);

        this.orderStatesInfo = this._dEl('orderStatesInfo');
        this._replaceTextDoubleCurlyBrackets(this.orderStatesInfo, 'button', 'orderStatesCta', (button) => {
            button.setAttribute('id', `${this.root.id}-states`);
            button.setAttribute('type', 'button');
        });
        this.orderStatesCta = this._dEl('orderStatesCta');
        this.orderStates = this._dEl('orderStates').content.querySelector('.mt22-order-states-popup');

        this.types = ['HOME_DELIVERY', 'ORDER_AND_COLLECT'];
        this.tabList = this._dEl('tabs');
        this.tab = {
            HOME_DELIVERY: this._dEl('homeDeliveryTab'),
            ORDER_AND_COLLECT: this._dEl('orderCollectTab'),
        };
        this.tabs = Object.values(this.tab);
        this.content = {
            HOME_DELIVERY: this._dEl('homeDeliveryContent'),
            ORDER_AND_COLLECT: this._dEl('orderCollectContent'),
        };
        this.tabPanels = Object.values(this.content);
        this.loaderEndpoint = {
            HOME_DELIVERY: this.root.dataset.endpointHomeDelivery,
            ORDER_AND_COLLECT: this.root.dataset.endpointOrderCollect,
        };
        this.currentSize = {
            HOME_DELIVERY: 0,
            ORDER_AND_COLLECT: 0,
        };
        this.downloadLength = {
            HOME_DELIVERY: parseInt(this.root.dataset.downloadLengthHomeDelivery),
            ORDER_AND_COLLECT: parseInt(this.root.dataset.downloadLengthOrderCollect),
        };
        this.filter = {
            HOME_DELIVERY: this.content['HOME_DELIVERY'].querySelector(this._el('filter', true)),
            ORDER_AND_COLLECT: this.content['ORDER_AND_COLLECT'].querySelector(this._el('filter', true)),
        };
        this.orders = {
            HOME_DELIVERY: this.content['HOME_DELIVERY'].querySelector(this._el('orders', true)),
            ORDER_AND_COLLECT: this.content['ORDER_AND_COLLECT'].querySelector(this._el('orders', true)),
        };
        this.countOrders = {
            HOME_DELIVERY: this.content['HOME_DELIVERY'].querySelector(`${this._el('countOrders', true)} > span`),
            ORDER_AND_COLLECT: this.content['ORDER_AND_COLLECT'].querySelector(
                `${this._el('countOrders', true)} > span`
            ),
        };
        this.loadMore = {
            HOME_DELIVERY: this.content['HOME_DELIVERY'].querySelector(this._el('loadMore', true)),
            ORDER_AND_COLLECT: this.content['ORDER_AND_COLLECT'].querySelector(this._el('loadMore', true)),
        };
        this.noOrders = {
            HOME_DELIVERY: this.content['HOME_DELIVERY'].querySelector(this._el('noOrders', true)),
            ORDER_AND_COLLECT: this.content['ORDER_AND_COLLECT'].querySelector(this._el('noOrders', true)),
        };

        /* load initial orders dynamically */
        (async () => {
            openLoader('main');
            this.tabFocus = this.types.indexOf(this.root.dataset.activeTab) || 0;
            const firstOpenTab = this.tab[this.root.dataset.activeTab || 'HOME_DELIVERY'];
            await this._downloadOrders('HOME_DELIVERY');
            firstOpenTab.setAttribute('tabindex', '0');
            this._selectTab(firstOpenTab, this._getTabPanelFromTab(firstOpenTab), true);
            await this._downloadOrders('ORDER_AND_COLLECT');
            closeLoader('main');
        })();

        this._addEventListeners();
        this._addStoreListeners();
        trackEvent(TRACKABLE_EVENT.pageview, FUNNEL_NAME.orders, FUNNEL_STEP.ordersList);
    }

    _addEventListeners() {
        this.tabs.forEach((tab) => {
            tab.addEventListener('click', () => {
                if (this._isTabActive(tab)) return;
                // Remove all current selected tabs
                const selectedTabs = [...this.tabList.querySelectorAll('[aria-selected="true"]')];
                selectedTabs?.forEach((t) => {
                    this._selectTab(t, this._getTabPanelFromTab(t), false);
                });
                // Set this tab as selected
                this._selectTab(tab, this._getTabPanelFromTab(tab), true);
            });
        });
        this.tabList.addEventListener('keydown', (e) => {
            if (e.key !== 'ArrowRight' && e.key !== 'ArrowLeft') return;
            this.tabs[this.tabFocus].setAttribute('tabindex', '-1');
            if (e.key === 'ArrowRight') {
                this.tabFocus++;
                // if at the end, go to the start
                if (this.tabFocus >= this.tabs.length) {
                    this.tabFocus = 0;
                }
            } else if (e.key === 'ArrowLeft') {
                this.tabFocus--;
                // if at the start, move to the end
                if (this.tabFocus < 0) {
                    this.tabFocus = this.tabs.length - 1;
                }
            }
            this.tabs[this.tabFocus].setAttribute('tabindex', '0');
            this.tabs[this.tabFocus]?.focus();
        });

        this.types.forEach((type) => {
            this.loadMore[type]?.addEventListener('click', async () => {
                openLoader('main');
                const lastFocusedOrder = this.orders[type].querySelector(`${this._el('order', true)}:last-child`);
                await this._downloadOrders(type);
                await delay(500);
                closeLoader('main');
                lastFocusedOrder?.querySelector('a')?.focus();
            });
            this.content[type].addEventListener('mt10FilterChanged', async (event) => {
                openLoader('main');
                this.currentSize[type] = 0;
                this._emptyOrders(type);
                await this._downloadOrders(type);
                await delay(500);
                closeLoader('main');
                event.stopPropagation();
            });
        });
        this.orderStatesCta?.addEventListener('click', (event) => {
            event.preventDefault();
            const clone = this.orderStates.cloneNode(true);
            showPopup('main', clone);
        });
    }

    _addStoreListeners() {
        this._addStoreListener('reloadOrders', (path, data) => {
            this._reloadOrders(data.type);
        });
    }

    _getTabPanelFromTab(tab) {
        return this.root.querySelector(`#${tab.getAttribute('aria-controls')}`);
    }

    _isTabActive(tab) {
        return tab.classList.contains(this._el('activeTab'));
    }

    _selectTab(tab, tabPanel, select = true) {
        if (select == true) {
            tab.setAttribute('aria-selected', 'true');
            tab.classList.add(this._el('activeTab'));
            tabPanel.removeAttribute('hidden');
            tabPanel.setAttribute('aria-hidden', 'false');
        } else {
            tab.setAttribute('aria-selected', 'false');
            tab.classList.remove(this._el('activeTab'));
            tabPanel.setAttribute('hidden', 'true');
            tabPanel.setAttribute('aria-hidden', 'true');
        }
    }

    async _reloadOrders(type) {
        if (!type || !this.types.includes(type)) return;
        openLoader('main');
        this._emptyOrders(type);
        /* reset current size before downloading orders again */
        this.currentSize[type] = 0;
        await this._downloadOrders(type);
        await delay(500);
        closeLoader('main');
    }

    async _downloadOrders(type) {
        if (!type || !this.types.includes(type)) return;
        const url = this.loaderEndpoint[type];
        const filter = register.getClass(this.filter[type]).getValue();
        let pageNumberOp = this.currentSize[type] / this.downloadLength[type];
        const data = {
            filter: filter,
            pageNumber: pageNumberOp,
            pageSize: this.downloadLength[type],
            currentSize: this.currentSize[type],
        };
        try {
            const html = await apiProvider.loaderGet(url, data);
            this._appendOrders(html, type);
        } catch (error) {
            console.error(error);
        }
    }

    _appendOrders(orders, type) {
        if (!type || !this.types.includes(type)) return;
        /* parse new orders */
        const parser = new DOMParser();
        const doc = parser.parseFromString(orders, 'text/html');
        const newOrders = doc.querySelectorAll('.mt21-order');
        const countOrders = doc.querySelector(this._el('loader', true)).dataset.countOrders;

        /* hide/show loadMore */
        if (this.currentSize[type] != 0 && newOrders.length < this.downloadLength[type]) {
            /* no more orders to download */
            this.loadMore[type]?.classList.add(this._elMod('loadMore', 'hidden'));
        } else {
            /* possibly more orders to download */
            this.loadMore[type]?.classList.remove(this._elMod('loadMore', 'hidden'));
        }

        /* hide/show orders */
        if (this.currentSize[type] + newOrders.length <= 0) {
            /* no orders */
            this.orders[type].classList.add(this._elMod('orders', 'hidden'));
            this.noOrders[type].classList.add(this._elMod('noOrders', 'show'));
            this.countOrders[type].textContent = '0';
            this.loadMore[type]?.classList.add(this._elMod('loadMore', 'hidden'));
        } else {
            /* orders */
            this.orders[type].classList.remove(this._elMod('orders', 'hidden'));
            this.noOrders[type].classList.remove(this._elMod('noOrders', 'show'));
            /* append new orders */
            this.orders[type].append(...newOrders);
            /* update current size */
            this.currentSize[type] += newOrders.length;
            /* hide/show load more */
            if (this.currentSize[type] >= countOrders) {
                this.loadMore[type]?.classList.add(this._elMod('loadMore', 'hidden'));
            }
            /* update total number of orders */
            this.countOrders[type].textContent = countOrders;
        }
    }

    _emptyOrders(type) {
        if (!type || !this.types.includes(type) || !this.orders[type].hasChildNodes()) return;
        for (let i = this.orders[type].childElementCount; i > 0; i--) {
            this.orders[type].removeChild(this.orders[type].children[i - 1]);
        }
    }

    _notType(type) {
        if (!type || !this.types.includes(type)) return;
        return type === 'HOME_DELIVERY' ? 'ORDER_AND_COLLECT' : 'HOME_DELIVERY';
    }

    _replaceTextDoubleCurlyBrackets(container, el, clazz, cb = null) {
        if (!container.dataset.text) return;
        const split = container.dataset.text.split(/({{)(.*)(}})/);
        container.innerText = '';
        container.append(split[0]);
        const replacedEl = document.createElement(el);
        if (clazz) replacedEl.classList.add(`${this.getName()}__${clazz}`);
        replacedEl.innerText = split[2];
        if (cb != null) cb(replacedEl);
        container.appendChild(replacedEl);
        container.append(split[4]);
    }
}
