import { apiProvider } from '../../../../../../libs/api-provider';
import PopupComponent from '../../../../../../libs/components/popup-component';
import { ecApiProvider } from '../../../../../../libs/ecommerce-api-provider';
import { flowManager } from '../../../../../../libs/flow-manager';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import './style.scss';

export default class ModifyOrder extends PopupComponent {
    constructor(name, root) {
        super(name, root);

        this.ctaAction = this._dEl('ctaAction');
        this.ecDetailCart = this.root.dataset.ecDetailCart;
        this.ecCheckout = this.root.dataset.ecCheckout;
        this.currentCartId = window.accessInfo.cart;

        this._addEventListeners();
        this._addStoreListeners();
    }

    _addEventListeners() {
        this.ctaAction.addEventListener('click', (event) => {
            event.preventDefault();
            this._modifyOrder();
        });
    }

    _addStoreListeners() {
        this._addStoreListener('selectedTimeslot', (path, data) => {
            this.selectedTimeslot = data;
        });
        this._addStoreListener('selectedOrder', (path, data) => {
            this.selectedOrder = data;
        });
        /* intercept flow options and get modifyOrder if defined (accepted values: {change-timeslot, change-products}) */
        this._addStoreListener(/flow\/[^/]+$/g, (path, flow) => {
            if (flow.options?.modifyOrder) this.modifyOrder = flow.options?.modifyOrder;
        });
    }

    async _deleteCart() {
        if (!this.currentCartId || this.currentCartId == '') {
            console.log('cart id not provided, skipping delete cart');
            return;
        }
        try {
            await ecApiProvider.deleteCart();
        } catch (error) {
            console.warn(error);
        }
    }

    async _addCartFromOrder() {
        const data = {
            pointOfServiceId: this.selectedOrder.pointOfServiceId,
            becommerce: this.selectedOrder.becommerce,
            parentOrderId: this.selectedOrder.orderCode,
            deliveryArea: this.selectedOrder.deliveryArea,
            typeOfService: this.selectedOrder.typeOfService,
        };
        const cartFromOrder = await apiProvider.addCart(data);
        if (cartFromOrder) {
            console.log(`Created new cart "${cartFromOrder.code}" with parent order "${this.selectedOrder.orderCode}"`);
            this.cartIdFromOrder = cartFromOrder.code;
        } else {
            throw "Couldn't create cart from order";
        }
    }

    _redirectEcommerceDetailCart() {
        if (this.ecDetailCart) {
            window.location = `${this.ecDetailCart}`;
        } else {
            console.warn('Ecommerce detail cart page url not found');
        }
    }

    _redirectEcommerceCheckout() {
        if (this.ecCheckout) {
            window.location = `${this.ecCheckout}?oldTimeSlotId=` + this.selectedOrder.timeslotId;
        } else {
            console.warn('Ecommerce checkout page url not found');
        }
    }

    async _modifyOrder() {
        if (!this.modifyOrder) {
            console.warn('Cannot modify order. Null or unhandled modality.');
            return;
        }
        try {
            openLoader('main');
            switch (this.modifyOrder) {
                case 'change-timeslot':
                    await this._deleteCart();
                    await this._addCartFromOrder();
                    this._redirectEcommerceCheckout();
                    break;
                case 'change-products':
                    await this._deleteCart();
                    await this._addCartFromOrder();
                    this._redirectEcommerceDetailCart();
                    break;
            }
        } catch (error) {
            console.warn(error);
            closeLoader('main');
        }
    }

    _onCancel() {
        this.modifyOrder && this.modifyOrder === 'change-timeslot'
            ? flowManager.complete()
            : flowManager.backOrComplete();
    }
}
