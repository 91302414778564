/*
 * Copyright 2018 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

/* eslint-disable */

module.exports = function main($) {

  const htl = $.globals;
  let data = $.globals["data"];

  let render;
  render = function* _template_global_render(args) { 
    let uniqueid = args[1]['uniqueId'] || '';
    let extraclasses = args[1]['extraClasses'] || '';
    let pinid = args[1]['pinId'] || '';
    let favorite = args[1]['favorite'] || '';
    let type = args[1]['type'] || '';
    let selected = args[1]['selected'] || '';
    let aggregator = args[1]['aggregator'] || '';
    let textcontent = args[1]['textContent'] || '';
    let specialized = args[1]['specialized'] || '';
    let $t, $n = args[0];
    $.dom.text($n,"\n\n    ");
    const typeclass = $.exec("format", type ? "mt34-map-pin--{0}" : "{1}", {"format": [type, ""], });
    if (typeclass) {
    }
    $.dom.text($n,"\n    ");
    const selectedclass = selected ? "mt34-map-pin--selected" : "";
    if (selectedclass) {
    }
    $.dom.text($n,"\n    ");
    const specializedclass = specialized ? "mt34-map-pin--specialized" : "";
    if (specializedclass) {
    }
    $.dom.text($n,"\n    ");
    const aggregatorclass = aggregator ? "mt34-map-pin--aggregator" : "";
    if (aggregatorclass) {
    }
    $.dom.text($n,"\n    ");
    const finalclasses = $.exec("format", aggregatorclass ? "{0}" : (typeclass ? "{1} {2}" : "{2}"), {"format": [aggregatorclass, typeclass, (selectedclass) || ("")], });
    if (finalclasses) {
    }
    $.dom.text($n,"\n\n    ");
    $t = $.dom.create("div",false,false);
    const var_attrValue2 = uniqueid;
    if (!$.col.empty(var_attrValue2)) {
      $.dom.attr($t, 'id', var_attrValue2, 'attribute');
    }
    const var_attrValue3 = ("mt34-map-pin ") + (finalclasses) + (" ") + (extraclasses) + (" ") + (specializedclass);
    if (!$.col.empty(var_attrValue3)) {
      $.dom.attr($t, 'class', var_attrValue3, 'attribute');
    }
    $.dom.attr($t, 'data-component', null, 'attribute');
    const var_attrValue_data_pin_id0 = pinid;
    if (!$.col.empty(var_attrValue_data_pin_id0)) {
      $.dom.attr($t, 'data-pin-id', var_attrValue_data_pin_id0, 'attribute');
    }
    const var_attrValue_data_favorite1 = favorite;
    if (!$.col.empty(var_attrValue_data_favorite1)) {
      $.dom.attr($t, 'data-favorite', var_attrValue_data_favorite1, 'attribute');
    }
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n        ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mt34-map-pin__pin", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n            ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mt34-map-pin__pinContent", 'attribute');
    $n = $.dom.push($n,$t);
    const var_4 = yield $.xss(aggregator ? textcontent : "", "html");
    $.dom.append($n, var_4);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n        ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n    ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n    \n");
  };
  $.template('global', 'render', render);

  return $.run(function* () {
    let $t, $n = $.dom.start();
    $.dom.text($n,"\n");
    $.dom.text($n,"\n");
    return $.dom.end();

  });
};
