import Component from '../../../../../libs/components/component';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../libs/tracking-manager-old';
import { flowManager } from '../../../../../libs/flow-manager';
import { copyText } from '../../../../../libs/utils';
import './style.scss';

export default class Coupon extends Component {
    constructor(name, root) {
        super(name, root);
        this.heading = this._dEl('heading');
        this.reusableTimes = this._dEl('total') ? parseInt(this._dEl('total').textContent) : -1;
        this.timesUsed = this._dEl('partial') ? parseInt(this._dEl('partial').textContent) : -1;
        this.progressBar = this.root.querySelector(`${this._el('timesUsedProgressBar', true)} > div`);
        this.code = this._dEl('couponCode');
        this.SHOW_TOOLTIP = this._mod('showTooltip');
        this.status = this.root.dataset?.status;
        this.couponCode = this.root.dataset?.code;
        this.usage = this.root.dataset?.numeroUtilizzi;
        this.maxUsage = this.root.dataset?.numeroMassimoUtilizzi;

        this.onlineBottom = this._dEl('onlineBottom');
        this.setProgressBarWidth(this.progressBar, this.timesUsed, this.reusableTimes);
        this._addEventListeners();
    }

    _addEventListeners() {
        const isTappable =
            (this.status &&
                ['ACTIVE', 'DUE_TO_EXPIRE'].includes(this.status) &&
                (this.maxUsage == 0 || this.usage < this.maxUsage)) ||
            window.parent.location.href.includes('/content/dam/');

        if (isTappable)
            ['click', 'keydown'].forEach((e) => {
                this.heading.addEventListener(e, (event) => {
                    if (event instanceof KeyboardEvent && event.key !== 'Enter' && event.key !== ' ') return;
                    this.openCouponPopup();
                });
            });
        else this._dEl('heading').classList.add(this._elMod('heading', 'noClick'));

        // add copy
        this.onlineBottom?.addEventListener('click', (event) => {
            event.preventDefault();
            trackEvent(
                TRACKABLE_EVENT.copyCouponCode,
                FUNNEL_NAME.couponsWallet,
                FUNNEL_STEP.copyCouponCode,
                null,
                null,
                null,
                null,
                null,
                null,
                this.couponCode
            );

            copyText(this.code.textContent);
            this.root.classList.add(this.SHOW_TOOLTIP);
            setTimeout(() => this.root.classList.remove(this.SHOW_TOOLTIP), 6300);
        });
    }

    openCouponPopup() {
        trackEvent(
            TRACKABLE_EVENT.clickCoupon,
            FUNNEL_NAME.couponsWallet,
            FUNNEL_STEP.clickCoupon,
            null,
            null,
            null,
            null,
            null,
            null,
            this.couponCode
        );
        let couponData = JSON.parse(this.root.dataset.couponJson);

        flowManager.startFlow({
            flowName: 'coupon-popup',
            flowSteps: [{ name: 'coupon-popup' }],
            initialData: {
                uniqueId: this.root.id,
                coupon: couponData.coupon,
                focusAfterCloseId: this.root.dataset.focusAfterCloseId,
            },
        });
    }

    setProgressBarWidth(progressBar, partial, total) {
        if (!progressBar || total <= 0 || partial < 0) return;
        progressBar.style.width = `${(partial / total) * 100}%`;
    }
}


