/*
 * Copyright 2018 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

/* eslint-disable */

module.exports = function main($) {

  const htl = $.globals;
  let data = $.globals["data"];

  (function _template_src_main_webpack_repository_apps_conad_myconad_templates_mt16_select_mt16_select_item_html(){ 
    let render;
    render = function* _template_src_main_webpack_repository_apps_conad_myconad_templates_mt16_select_mt16_select_item_html_render(args) { 
      let selected = args[1]['selected'] || '';
      let value = args[1]['value'] || '';
      let label = args[1]['label'] || '';
      let $t, $n = args[0];
      $.dom.text($n,"\n    ");
      $t = $.dom.create("li",false,false);
      const var_attrValue0 = ("mt16-select__item ") + (selected ? "selected" : "");
      if (!$.col.empty(var_attrValue0)) {
        $.dom.attr($t, 'class', var_attrValue0, 'attribute');
      }
      $.dom.attr($t, 'tabindex', "0", 'attribute');
      const var_attrValue1 = value;
      if (!$.col.empty(var_attrValue1)) {
        $.dom.attr($t, 'data-value', var_attrValue1, 'attribute');
      }
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n        ");
      $t = $.dom.create("span",false,false);
      $.dom.attr($t, 'class', "mt16-select__option", 'attribute');
      $n = $.dom.push($n,$t);
      const var_2 = yield $.xss(label, "html");
      $.dom.append($n, var_2);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n        ");
      $t = $.dom.create("span",false,false);
      $.dom.attr($t, 'class', "mt16-select__check ec-icon-check", 'attribute');
      $n = $.dom.push($n,$t);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n    ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n");
    };
    $.template('src/main/webpack/repository/apps/conad-myconad/templates/mt16-select/mt16-select-item.html', 'render', render);
  })();

  return $.run(function* () {
    let $t, $n = $.dom.start();
    $.dom.text($n,"\n");
    let item = $.template('src/main/webpack/repository/apps/conad-myconad/templates/mt16-select/mt16-select-item.html');
    $.dom.text($n,"\n");
    yield $.call(item["render"], [$n, {"selected": data["selected"], "value": data["value"], "label": data["label"], }]);
    $.dom.text($n,"\n");
    return $.dom.end();

  });
};
