import { apiProvider } from '../../../../../libs/api-provider';
import Component from '../../../../../libs/components/component';
import { closeLoader, openLoader } from '../mt11-loader/script';
import './style.scss';


export default class ContentCard extends Component {
    constructor(name, root) {
        super(name, root);
        this.contentId = this.root.dataset.contentId;
        this.bookmark = this._dEl('bookmark');
        this._addEventListeners();
    }

    _addEventListeners() {
        this.bookmark?.addEventListener('click', async (event) => {
            event.preventDefault();
            await this._removeFavoriteContent();
            window.location.reload();
        })
    }

    async _removeFavoriteContent() {
        if (!this.contentId) return;
        let result = false;
        try {
            openLoader('main');
            const params = {
                id: this.contentId,
            };
            result = await apiProvider.removeComplementaryData(params);
        } catch (error) {
            console.warn(error);
        } finally {
            closeLoader('main');
        }
        return result;
    }
}


