/*
 * Copyright 2018 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

/* eslint-disable */

module.exports = function main($) {

  const htl = $.globals;
  let data = $.globals["data"];

  let render;
  render = function* _template_global_render(args) { 
    let uniqueid = args[1]['uniqueId'] || '';
    let focusaftercloseid = args[1]['focusAfterCloseId'] || '';
    let coupon = args[1]['coupon'] || '';
    let labels = args[1]['labels'] || '';
    let $t, $n = args[0];
    $.dom.text($n,"\n\n    ");
    $t = $.dom.create("div",false,false);
    const var_attrValue1 = uniqueid;
    if (!$.col.empty(var_attrValue1)) {
      $.dom.attr($t, 'id', var_attrValue1, 'attribute');
    }
    $.dom.attr($t, 'class', "mt13-coupon-popup", 'attribute');
    const var_attrValue_data_focus_after_close0 = focusaftercloseid;
    if (!$.col.empty(var_attrValue_data_focus_after_close0)) {
      $.dom.attr($t, 'data-focus-after-close', var_attrValue_data_focus_after_close0, 'attribute');
    }
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n        ");
    $t = $.dom.create("div",false,false);
    const var_attrValue2 = ("mt13-coupon-popup__content ") + (coupon["utilizzata"] ? "mt13-coupon-popup__content--fade" : "") + (" ") + (!coupon["urlCoverImage"] ? "mt13-coupon-popup__content--altClose" : "");
    if (!$.col.empty(var_attrValue2)) {
      $.dom.attr($t, 'class', var_attrValue2, 'attribute');
    }
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n\n            ");
    const var_testVariable3 = (coupon["numeroUtilizzi"]) !== (0);
    if (var_testVariable3) {
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "mt13-coupon-popup__usageCount", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                ");
      $t = $.dom.create("span",false,false);
      $.dom.attr($t, 'class', "mt13-coupon-popup__usageTitleCount", 'attribute');
      $n = $.dom.push($n,$t);
      const var_4 = yield $.xss((labels["used"]) || ("Utilizzato"), "html");
      $.dom.append($n, var_4);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n                ");
      $t = $.dom.create("span",false,false);
      $.dom.attr($t, 'class', "mt13-coupon-popup__usageTextCount", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                    ");
      const var_5 = yield $.xss(coupon["numeroUtilizzi"], "html");
      $.dom.append($n, var_5);
      $.dom.text($n,"\n                    ");
      const var_testVariable6 = (coupon["numeroMassimoUtilizzi"]) !== (0);
      if (var_testVariable6) {
        $.dom.text($n,"\n                        ");
        const var_7 = yield $.xss($.exec("format", " / {0}", {"format": [coupon["numeroMassimoUtilizzi"]], }), "html");
        $.dom.append($n, var_7);
        $.dom.text($n," \n                        ");
        const var_8 = yield $.xss((coupon["numeroMassimoUtilizzi"]) === (1) ? ((labels["time"]) || ("VOLTA")) : ((labels["times"]) || ("VOLTE")), "html");
        $.dom.append($n, var_8);
        $.dom.text($n,"\n                    ");
      }
      $.dom.text($n,"\n                    ");
      const var_testVariable9 = (coupon["numeroMassimoUtilizzi"]) === (0);
      if (var_testVariable9) {
        $.dom.text($n,"\n                        ");
        const var_10 = yield $.xss((coupon["numeroUtilizzi"]) === (1) ? ((labels["time"]) || ("VOLTA")) : ((labels["times"]) || ("VOLTE")), "html");
        $.dom.append($n, var_10);
        $.dom.text($n,"\n                    ");
      }
      $.dom.text($n,"\n                ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n            ");
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n\n            ");
    $t = $.dom.create("span",false,false);
    $.dom.attr($t, 'class', "mt13-coupon-popup__closeButton", 'attribute');
    $.dom.attr($t, 'data-close-popup', null, 'attribute');
    $n = $.dom.push($n,$t);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n            ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mt13-coupon-popup__valueTag", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mt13-coupon-popup__valueText", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                    ");
    const var_11 = yield $.xss(coupon["couponValue"], "html");
    $.dom.append($n, var_11);
    $.dom.text($n,"\n                    ");
    $t = $.dom.create("span",false,false);
    const var_attrValue12 = coupon["isPunti"] ? "mt13-coupon-popup__punti" : "mt13-coupon-popup--forLG";
    if (!$.col.empty(var_attrValue12)) {
      $.dom.attr($t, 'class', var_attrValue12, 'attribute');
    }
    $n = $.dom.push($n,$t);
    const var_13 = yield $.xss(coupon["couponUnit"], "html");
    $.dom.append($n, var_13);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n                ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n                ");
    $t = $.dom.create("span",false,false);
    $.dom.attr($t, 'class', "mt13-coupon-popup__triangle", 'attribute');
    $n = $.dom.push($n,$t);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    $.dom.rem($n," if it is travel coupon, hide EAN barcode or if it is only online coupon (it has no description in shop section)");
    $.dom.text($n,"\n            ");
    const eantohide = (coupon["travelCoupon"]) || ((((coupon["type"]) === ("online")) && (!coupon["couponTutorialDescription"])));
    if (eantohide) {
    }
    $.dom.text($n,"\n            ");
    const var_testVariable14 = !eantohide;
    if (var_testVariable14) {
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "mt13-coupon-popup__barcodeWrapper", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n\n            ");
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n\n            ");
    $t = $.dom.create("div",false,false);
    const var_attrValue15 = ("mt13-coupon-popup__bodyContainer ") + (eantohide ? "pt-[30px]" : "");
    if (!$.col.empty(var_attrValue15)) {
      $.dom.attr($t, 'class', var_attrValue15, 'attribute');
    }
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mt13-coupon-popup__body", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n\n                    ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mt13-coupon-popup__bodyHead", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                        ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mt13-coupon-popup__status", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                            ");
    const var_testVariable16 = (coupon["status"]) === ("DUE_TO_EXPIRE");
    if (var_testVariable16) {
      $t = $.dom.create("span",false,false);
      $.dom.attr($t, 'class', "mt13-coupon-popup__statusText", 'attribute');
      $n = $.dom.push($n,$t);
      const var_17 = yield $.xss((labels["expiring"]) || ("IN SCADENZA"), "html");
      $.dom.append($n, var_17);
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n                            ");
    const var_testVariable18 = (coupon["status"]) === ("DUE_TO_EXPIRE");
    if (var_testVariable18) {
      $t = $.dom.create("span",false,false);
      $.dom.attr($t, 'class', "mt13-coupon-popup__statusPoint", 'attribute');
      $n = $.dom.push($n,$t);
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n                        ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n                        ");
    $t = $.dom.create("span",false,false);
    $.dom.attr($t, 'class', "mt13-coupon-popup__title", 'attribute');
    $n = $.dom.push($n,$t);
    const var_19 = yield $.xss(coupon["couponTitle"], "html");
    $.dom.append($n, var_19);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n                        ");
    $t = $.dom.create("span",false,false);
    $.dom.attr($t, 'class', "mt13-coupon-popup__text", 'attribute');
    $n = $.dom.push($n,$t);
    const var_20 = yield $.xss(coupon["couponDescription"], "html");
    $.dom.append($n, var_20);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n                    ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n                    \n                    ");
    let instructions = $.template('src/main/webpack/repository/apps/conad-myconad/templates/mt14-coupon-instructions/mt14-coupon-instructions.html');
    $.dom.text($n,"\n                    ");
    const couponinstructionsid = $.exec("format", "{0}-{1}", {"format": [uniqueid, "instructions"], });
    if (couponinstructionsid) {
    }
    $.dom.text($n,"\n                    ");
    const instructionsextraclass = coupon["utilizzata"] ? "mt14-coupon-instructions--fade" : "";
    if (instructionsextraclass) {
    }
    $.dom.text($n,"\n                    ");
    yield $.call(instructions["render"], [$n, {"uniqueId": couponinstructionsid, "extraClasses": instructionsextraclass, "forOnlineShopping": ((coupon["type"]) === ("online")) && (coupon["codiceCouponEcommerce"]), "travelCoupon": coupon["travelCoupon"], "couponCode": coupon["codiceCouponEcommerce"], "title": coupon["couponTutorialHeading"], "storeTitle": coupon["couponTutorialTitle"], "storeText": coupon["couponTutorialDescription"], "onlineTitle": coupon["couponTutorialTitleOnline"], "onlineText": coupon["couponTutorialDescriptionOnline"], }]);
    $.dom.text($n,"\n\n                    ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mt13-coupon-popup__usageConditions", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                        ");
    $t = $.dom.create("h2",false,false);
    $.dom.attr($t, 'class', "mt13-coupon-popup__usageConditionsTitle", 'attribute');
    $n = $.dom.push($n,$t);
    const var_21 = yield $.xss((coupon["termsAndConditionsTitle"]) || (labels["termsAndConditionsTitle"]), "html");
    $.dom.append($n, var_21);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n                        ");
    $t = $.dom.create("p",false,false);
    $.dom.attr($t, 'class', "mt13-coupon-popup__usageConditionsText", 'attribute');
    $n = $.dom.push($n,$t);
    const var_22 = yield $.xss(coupon["termsAndConditionsDescription"], "html");
    $.dom.append($n, var_22);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n                        ");
    const var_testVariable23 = coupon["urlTermsAndConditions"];
    if (var_testVariable23) {
      $t = $.dom.create("a",false,false);
      $.dom.attr($t, 'class', "mt13-coupon-popup__usageConditionsDiscover", 'attribute');
      const var_attrValue24 = coupon["urlTermsAndConditions"];
      if (!$.col.empty(var_attrValue24)) {
        $.dom.attr($t, 'href', var_attrValue24, 'attribute');
      }
      $n = $.dom.push($n,$t);
      const var_25 = yield $.xss(((coupon["labelTermsAndConditions"]) || (labels["discovermore"])) || ("Scopri di più"), "html");
      $.dom.append($n, var_25);
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n                    ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n                    ");
    const var_testVariable26 = coupon["mechanicsOfferTitle"];
    if (var_testVariable26) {
      $t = $.dom.create("span",false,false);
      $.dom.attr($t, 'class', "mt13-coupon-popup__mecTitle", 'attribute');
      $n = $.dom.push($n,$t);
      const var_27 = yield $.xss(coupon["mechanicsOfferTitle"], "html");
      $.dom.append($n, var_27);
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n                    ");
    const var_testVariable28 = coupon["mechanicsOfferDescription"];
    if (var_testVariable28) {
      $t = $.dom.create("span",false,false);
      $.dom.attr($t, 'class', "mt13-coupon-popup__mecText", 'attribute');
      $n = $.dom.push($n,$t);
      const var_29 = yield $.xss(coupon["mechanicsOfferDescription"], "html");
      $.dom.append($n, var_29);
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n\n                ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n                ");
    const var_testVariable30 = coupon["urlMechanicsOfferImage"];
    if (var_testVariable30) {
      $t = $.dom.create("img",true,true);
      $.dom.attr($t, 'class', "mt13-coupon-popup__image", 'attribute');
      const var_attrValue31 = coupon["urlMechanicsOfferImage"];
      if (!$.col.empty(var_attrValue31)) {
        $.dom.attr($t, 'src', var_attrValue31, 'attribute');
      }
      $n = $.dom.push($n,$t);
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n\n                ");
    $t = $.dom.create("button",false,false);
    $.dom.attr($t, 'class', "mt13-coupon-popup__bottomClose", 'attribute');
    $.dom.attr($t, 'type', "button", 'attribute');
    $.dom.attr($t, 'data-close-popup', null, 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n," ");
    const var_32 = yield $.xss((labels["close"]) || ("Chiudi"), "html");
    $.dom.append($n, var_32);
    $.dom.text($n," ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n        ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n    ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n");
  };
  $.template('global', 'render', render);
  (function _template_src_main_webpack_repository_apps_conad_myconad_templates_mt14_coupon_instructions_mt14_coupon_instructions_html(){ 
    let render;
    render = function* _template_src_main_webpack_repository_apps_conad_myconad_templates_mt14_coupon_instructions_mt14_coupon_instructions_html_render(args) { 
      let uniqueid = args[1]['uniqueId'] || '';
      let extraclasses = args[1]['extraClasses'] || '';
      let foronlineshopping = args[1]['forOnlineShopping'] || '';
      let travelcoupon = args[1]['travelCoupon'] || '';
      let couponcode = args[1]['couponCode'] || '';
      let title = args[1]['title'] || '';
      let storetitle = args[1]['storeTitle'] || '';
      let storetext = args[1]['storeText'] || '';
      let onlinetitle = args[1]['onlineTitle'] || '';
      let onlinetext = args[1]['onlineText'] || '';
      let $t, $n = args[0];
      $.dom.text($n,"\n\n    ");
      $t = $.dom.create("div",false,false);
      const var_attrValue0 = uniqueid;
      if (!$.col.empty(var_attrValue0)) {
        $.dom.attr($t, 'id', var_attrValue0, 'attribute');
      }
      const var_attrValue1 = ("mt14-coupon-instructions ") + (extraclasses);
      if (!$.col.empty(var_attrValue1)) {
        $.dom.attr($t, 'class', var_attrValue1, 'attribute');
      }
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n        ");
      $t = $.dom.create("hr",false,true);
      $.dom.attr($t, 'class', "mt14-coupon-instructions__bar", 'attribute');
      $n = $.dom.push($n,$t);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n        ");
      const var_testVariable2 = title;
      if (var_testVariable2) {
        $t = $.dom.create("h1",false,false);
        $.dom.attr($t, 'class', "mt14-coupon-instructions__title", 'attribute');
        $n = $.dom.push($n,$t);
        const var_3 = yield $.xss(title, "html");
        $.dom.append($n, var_3);
        $n = $.dom.pop($n);
      }
      $.dom.text($n,"\n        ");
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "mt14-coupon-instructions__types", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n            ");
      $.dom.rem($n," coupon in shop (icon shop) ");
      $.dom.text($n,"\n            ");
      const var_testVariable4 = (storetext) && (((!travelcoupon) || (foronlineshopping)));
      if (var_testVariable4) {
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "mt14-coupon-instructions__inStore", 'attribute');
        $n = $.dom.push($n,$t);
        $.dom.text($n,"\n                ");
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "mt14-coupon-instructions__inStoreIcon", 'attribute');
        $n = $.dom.push($n,$t);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                ");
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "mt14-coupon-instructions__inStoreContent", 'attribute');
        $n = $.dom.push($n,$t);
        $.dom.text($n,"\n                    ");
        $t = $.dom.create("h3",false,false);
        $.dom.attr($t, 'class', "mt14-coupon-instructions__inStoreTitle", 'attribute');
        $n = $.dom.push($n,$t);
        const var_5 = yield $.xss((storetitle) || ("In store"), "html");
        $.dom.append($n, var_5);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                    ");
        $t = $.dom.create("p",false,false);
        $.dom.attr($t, 'class', "mt14-coupon-instructions__inStoreText", 'attribute');
        $n = $.dom.push($n,$t);
        const var_6 = yield $.xss(storetext, "html");
        $.dom.append($n, var_6);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                ");
        $n = $.dom.pop($n);
        $.dom.text($n,"\n            ");
        $n = $.dom.pop($n);
      }
      $.dom.text($n,"\n            ");
      $.dom.rem($n," coupon online but not travel ");
      $.dom.text($n,"\n            ");
      const var_testVariable7 = (onlinetext) && (((foronlineshopping) && (!travelcoupon)));
      if (var_testVariable7) {
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "mt14-coupon-instructions__onlineShopping", 'attribute');
        $n = $.dom.push($n,$t);
        $.dom.text($n,"\n                ");
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "mt14-coupon-instructions__onlineShoppingIcon", 'attribute');
        $n = $.dom.push($n,$t);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                ");
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "mt14-coupon-instructions__onlineShoppingContent", 'attribute');
        $n = $.dom.push($n,$t);
        $.dom.text($n,"\n                    ");
        $t = $.dom.create("h3",false,false);
        $.dom.attr($t, 'class', "mt14-coupon-instructions__onlineShoppingTitle", 'attribute');
        $n = $.dom.push($n,$t);
        const var_8 = yield $.xss((onlinetitle) || ("Online shopping"), "html");
        $.dom.append($n, var_8);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                    ");
        $t = $.dom.create("p",false,false);
        $.dom.attr($t, 'class', "mt14-coupon-instructions__onlineShoppingText", 'attribute');
        $n = $.dom.push($n,$t);
        const var_9 = yield $.xss(onlinetext, "html");
        $.dom.append($n, var_9);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                    ");
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "mt14-coupon-instructions__instructionsOnlineShoppingWrapper", 'attribute');
        $n = $.dom.push($n,$t);
        $.dom.text($n,"\n                        ");
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "mt14-coupon-instructions__instructionsTooltip", 'attribute');
        $n = $.dom.push($n,$t);
        $.dom.text($n,"\n                            ");
        $t = $.dom.create("span",false,false);
        $.dom.attr($t, 'class', "mt14-coupon-instructions__instructionsTooltipText", 'attribute');
        $n = $.dom.push($n,$t);
        $.dom.text($n,"Codice coupon copiato correttamente");
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                        ");
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                        ");
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "mt14-coupon-instructions__instructionsOnlineShoppingCode", 'attribute');
        $.dom.attr($t, 'tabindex', "0", 'attribute');
        $.dom.attr($t, 'role', "button", 'attribute');
        $.dom.attr($t, 'title', "Copia", 'attribute');
        $n = $.dom.push($n,$t);
        $.dom.text($n,"\n                            ");
        $t = $.dom.create("span",false,false);
        $.dom.attr($t, 'class', "mt14-coupon-instructions__instructionsCouponCode", 'attribute');
        $n = $.dom.push($n,$t);
        const var_10 = yield $.xss(couponcode, "html");
        $.dom.append($n, var_10);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                            ");
        $t = $.dom.create("span",false,false);
        $.dom.attr($t, 'class', "ec-icon-copy relative top-[2px]", 'attribute');
        $n = $.dom.push($n,$t);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                        ");
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                    ");
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                ");
        $n = $.dom.pop($n);
        $.dom.text($n,"\n            ");
        $n = $.dom.pop($n);
      }
      $.dom.text($n,"\n            ");
      const var_testVariable11 = travelcoupon;
      if (var_testVariable11) {
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "mt14-coupon-instructions__travelCoupon", 'attribute');
        $n = $.dom.push($n,$t);
        $.dom.text($n,"\n                ");
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "mt14-coupon-instructions__travelCouponIcon", 'attribute');
        $n = $.dom.push($n,$t);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                ");
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "mt14-coupon-instructions__travelCouponContent", 'attribute');
        $n = $.dom.push($n,$t);
        $.dom.text($n,"\n                    ");
        $t = $.dom.create("h3",false,false);
        $.dom.attr($t, 'class', "mt14-coupon-instructions__travelCouponTitle", 'attribute');
        $n = $.dom.push($n,$t);
        const var_12 = yield $.xss(foronlineshopping ? ((onlinetitle) || ("Su HeyConad Viaggi o in Agenzia")) : ((storetitle) || ("Su HeyConad Viaggi o in Agenzia")), "html");
        $.dom.append($n, var_12);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                    ");
        $t = $.dom.create("p",false,false);
        $.dom.attr($t, 'class', "mt14-coupon-instructions__travelCouponText", 'attribute');
        $n = $.dom.push($n,$t);
        const var_13 = yield $.xss(foronlineshopping ? onlinetext : storetext, "html");
        $.dom.append($n, var_13);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                    ");
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "mt14-coupon-instructions__instructionsTravelWrapper", 'attribute');
        $n = $.dom.push($n,$t);
        $.dom.text($n,"\n                        ");
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "mt14-coupon-instructions__instructionsTooltip", 'attribute');
        $n = $.dom.push($n,$t);
        $.dom.text($n,"\n                            ");
        $t = $.dom.create("span",false,false);
        $.dom.attr($t, 'class', "mt14-coupon-instructions__instructionsTooltipText", 'attribute');
        $n = $.dom.push($n,$t);
        $.dom.text($n,"Codice coupon copiato correttamente");
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                        ");
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                        ");
        const var_testVariable14 = couponcode;
        if (var_testVariable14) {
          $t = $.dom.create("div",false,false);
          $.dom.attr($t, 'class', "mt14-coupon-instructions__instructionsTravelCode", 'attribute');
          $.dom.attr($t, 'tabindex', "0", 'attribute');
          $.dom.attr($t, 'role', "button", 'attribute');
          $.dom.attr($t, 'title', "Copia", 'attribute');
          $n = $.dom.push($n,$t);
          $.dom.text($n,"\n                            ");
          $t = $.dom.create("span",false,false);
          $.dom.attr($t, 'class', "mt14-coupon-instructions__instructionsCouponCode", 'attribute');
          $n = $.dom.push($n,$t);
          const var_15 = yield $.xss(couponcode, "html");
          $.dom.append($n, var_15);
          $n = $.dom.pop($n);
          $.dom.text($n,"\n                            ");
          $t = $.dom.create("span",false,false);
          $.dom.attr($t, 'class', "ec-icon-copy relative top-[2px]", 'attribute');
          $n = $.dom.push($n,$t);
          $n = $.dom.pop($n);
          $.dom.text($n,"\n                        ");
          $n = $.dom.pop($n);
        }
        $.dom.text($n,"\n                    ");
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                ");
        $n = $.dom.pop($n);
        $.dom.text($n,"\n            ");
        $n = $.dom.pop($n);
      }
      $.dom.text($n,"\n        ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n    ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n\n");
    };
    $.template('src/main/webpack/repository/apps/conad-myconad/templates/mt14-coupon-instructions/mt14-coupon-instructions.html', 'render', render);
  })();

  return $.run(function* () {
    let $t, $n = $.dom.start();
    return $.dom.end();

  });
};
