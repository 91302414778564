import PopupComponent from '../../../../../../libs/components/popup-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { getTrackingManager } from '../../../../../../libs/tracking-manager';
import './style.scss';
import PerfectScrollbar from 'perfect-scrollbar';

export default class RefreshPrizeChoice extends PopupComponent {
    constructor(name, root) {
        super(name, root);
        this.user;
        this.trackingManager = getTrackingManager();
        this.content = this._dEl('content');
        this.choice = this._dEl('choice');
        this.shoppingCoupon = this.choice.children[0];
        this.travelCoupon = this.choice.children[1];
        this.goNextButton = this._dEl('goNextButton');
        this.backButton = this._dEl('backButton');

        this._addListeners();
    }

    /* override */
    async _onOpen() {
        super._onOpen();

        // reset popup
        this.travelCoupon.removeAttribute('data-selected');
        this.shoppingCoupon.removeAttribute('data-selected');
        this.goNextButton.classList.remove(this._elMod('goNextButton', 'active'));

        await this.trackingManager.track(this.root, {
            event: 'pageview',
            funnel: {
                nomeFunnel: 'Conad Refresh',
                stepFunnel: 'Pop up scelta premio',
            },
        });

        // create ps scrollbar
        const scrollbarOptions = {
            swipeEasing: true,
            suppressScrollX: true,
        };
        this.ps = new PerfectScrollbar(this.content, scrollbarOptions);

        const options = flowManager.getOptionsFromFlow();
        if (options?.hideBack) {
            this.backButton.classList.add('invisible');
        } else {
            this.backButton.classList.remove('invisible');
        }
    }

    _addListeners() {
        this.shoppingCoupon.addEventListener('click', () => {
            this.shoppingCoupon.setAttribute('data-selected', 'true');
            this.travelCoupon.removeAttribute('data-selected');

            this.goNextButton.classList.add(this._elMod('goNextButton', 'active'));
        });

        this.travelCoupon.addEventListener('click', () => {
            this.travelCoupon.setAttribute('data-selected', 'true');
            this.shoppingCoupon.removeAttribute('data-selected');

            this.goNextButton.classList.add(this._elMod('goNextButton', 'active'));
        });

        this.goNextButton.addEventListener('click', () => {
            if (this.goNextButton.classList.contains(this._elMod('goNextButton', 'active'))) {
                let choice;
                if (
                    this.shoppingCoupon.hasAttribute('data-selected') &&
                    this.shoppingCoupon.getAttribute('data-selected') === 'true'
                ) {
                    choice = 'shoppingCoupon';
                } else {
                    if (
                        this.travelCoupon.hasAttribute('data-selected') &&
                        this.travelCoupon.getAttribute('data-selected') === 'true'
                    ) {
                        choice = 'travelCoupon';
                    }
                }

                flowManager.appendDataToFlow({ choice: choice });
                flowManager.complete();
            }
        });
        this.backButton.addEventListener('click', () => {
            flowManager.back();
        });
    }
}