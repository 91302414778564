import Component from '../../../../../libs/components/component';
import './style.scss';


export default class ReservationCard extends Component {
    
    constructor(name, root) {
        super(name, root);
    }

}

