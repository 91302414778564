import Component from '../../../../../libs/components/component';
import './style.scss';

export default class Cards extends Component {
    constructor(name, root) {
        super(name, root);
        this.carousel = this._dEl('carousel');
        this.swiperConfig = {
            slidesPerView: 'auto',
            wrapperClass: this._el('track'),
            slideClass: this._el('card'),
            spaceBetween: 16,
            slidesOffsetBefore: 1,
            slidesOffsetAfter: 1
        };
        this.breakpoint = window.matchMedia('(min-width:768px)');
        this._addEventListeners();
        this._breakpointChecker();
    }

    _addEventListeners() {
        this.breakpoint.addEventListener('change', () => {
            this._breakpointChecker();
        });
    }

    _breakpointChecker() {
        // if narrower viewport and column layout needed
        if (this.breakpoint.matches === false) {
            if (this.swiper !== undefined) this.swiper.destroy(true, true);
            return;
        } 
        // else if large viewport and swiper layout needed
        else if (this.breakpoint.matches === true) {
            return this._enableSwiper();
        }
    }

    async _enableSwiper() {
        const Swiper = await (await import('swiper')).Swiper;
        this.swiper = new Swiper(this.carousel, this.swiperConfig);
    }
}

