import ModalComponent from '../../../../../../libs/components/modal-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { storeManager } from '../../../../../../libs/store-manager';
import './style.scss';


export default class ModalThankYou extends ModalComponent {
    constructor(name, root) {
        super(name, root);
        this.submit = this._dEl('submit');
        this._addEventListeners();
    }

    _addEventListeners() {
        this.submit.addEventListener('click', (event) => {
            event.preventDefault();
            this._done();
        });
    }
    
    /* override */
    _onCancel() {
        this._done();
    }

    _done() {
        switch (this.modalName) {
            case 'delivery-address-added':
                storeManager.emit('newDeliveryAddress', {});
                break;
            case 'billing-address-added':
                storeManager.emit('newBillingAddress', {});
                break;
            default:
                flowManager.complete();
                break;
        }
    }
}


