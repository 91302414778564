import PopupComponent from '../../../../../../libs/components/popup-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import { apiProvider } from '../../../../../../libs/api-provider';
import './style.scss';


export default class Logout extends PopupComponent {
    constructor(name, root) {
        super(name, root);

        this.ctaAction = this._dEl('ctaAction');

        this._addEventListeners();
    }

    _addEventListeners() {
        this.ctaAction.addEventListener('click', (event) => {
            event.preventDefault();
            this._logout();
        });
    }

    async _logout() {
        try {
            openLoader('main');
            await apiProvider.logout();
        } catch (error) {
            console.warn(error);
        } finally {
            closeLoader('main');
            window.location.reload();
        }
    }

    _onCancel() {
        flowManager.backOrComplete();
    }
}


