import Component from '../../../../../../libs/components/component';
import { dictionary } from '../../../../../../libs/dictionary-provider';
import './style.scss';

export default class Iframe extends Component {
    constructor(name, root) {
        super(name, root);
        this.iframe = this._dEl('iframe');
    }

    setIframeSrc(src) {
        this.iframe.setAttribute('src', src);
    }

    setIframeTitle(title) {
        this.iframe.setAttribute('title', dictionary.get(title));
    }
}
