import Component from '../../../../../../libs/components/component';
import { openLoader, closeLoader } from '../../../templates/mt11-loader/script';
import { flowManager } from '../../../../../../libs/flow-manager';
import { apiProvider } from '../../../../../../libs/api-provider';
import './style.scss';

export default class InsiemeCardDetail extends Component {
    constructor(name, root) {
        super(name, root);

        this.addInsiemeCardCta = this._dEl('addInsiemeCardCta');
        this.requestInsiemeCardCta = this._dEl('requestInsiemeCardCta');
        this.noInsiemePlusCardCta = this._dEl('noInsiemePlusCardCta');

        this.cardInfoPoints = this._dEl('insiemeCardInfoPoints');

        this.insiemeCardPaymentsTable = this._dEl('insiemeCardPaymentsTable');
        this.loaderEndpoint = this.insiemeCardPaymentsTable?.dataset.loaderEndpoint;
        this.currentSize = parseInt(this.insiemeCardPaymentsTable?.dataset.initLength);
        this.downloadLength = parseInt(this.insiemeCardPaymentsTable?.dataset.downloadLength);
        this.insiemeCardPaymentsLoadMore = this._dEl('insiemeCardPaymentsLoadMore');
        this.insiemeCardNoPayments = this._dEl('insiemeCardNoPayments');

        /* set dynamic years */
        const now = new Date();
        this.insiemeCardPaymentsText = this._dEl('insiemeCardPaymentsText');
        if (this.insiemeCardPaymentsText)
            this._replaceTextDoubleCurlyBrackets(this.insiemeCardPaymentsText, 'span', 'insiemeCardPaymentsYear');
        this.insiemeCardPaymentsYear = this._dEl('insiemeCardPaymentsYear');
        if (this.insiemeCardPaymentsYear) this.insiemeCardPaymentsYear.innerText = now.getFullYear();

        this.insiemeCardPaymentsFrom = this._dEl('insiemeCardPaymentsFrom')?.querySelector('span');
        if (this.insiemeCardPaymentsFrom) this.insiemeCardPaymentsFrom.innerText = `01/01/${now.getFullYear()}`;

        this.insiemeCardNoPaymentsText = this._dEl('insiemeCardNoPaymentsText');
        if (this.insiemeCardNoPaymentsText)
            this._replaceTextDoubleCurlyBrackets(this.insiemeCardNoPaymentsText, 'span', 'insiemeCardNoPaymentsYear');
        this.insiemeCardNoPaymentsYear = this._dEl('insiemeCardNoPaymentsYear');
        if (this.insiemeCardNoPaymentsYear) this.insiemeCardNoPaymentsYear.innerText = now.getFullYear();

        this._addEventListeners();
    }

    async _onOpen() {
        await super._onOpen();
        this._initDynamicTexts();
    }

    _addEventListeners() {
        this.addInsiemeCardCta?.addEventListener('click', (event) => {
            event.preventDefault();
            this._addInsiemeCard();
        });
        this.requestInsiemeCardCta?.addEventListener('click', (event) => {
            event.preventDefault();
            this._requestInsiemeCard();
        });
        this.noInsiemePlusCardCta?.addEventListener('click', (event) => {
            event.preventDefault();
            flowManager.startFlow({
                flowName: 'request-conad-card',
                flowSteps: [{ name: 'request-conad-card' }],
            });
        });
        this.insiemeCardPaymentsLoadMore?.addEventListener('click', async (event) => {
            event.preventDefault();
            openLoader('main');
            await this._downloadPayments(this.currentSize, this.downloadLength);
            setTimeout(() => {
                closeLoader('main');
            }, 500);
        });
    }

    _replaceTextDoubleCurlyBrackets(container, el, clazz) {
        const split = container.innerText.split(/({{)(.*)(}})/);
        if (split.length == 1) return;
        container.innerText = '';
        container.append(split[0]);
        const replacedEl = document.createElement(el);
        if (clazz) replacedEl.classList.add(`${this.getName()}__${clazz}`);
        replacedEl.innerText = split[2];
        container.appendChild(replacedEl);
        container.append(split[4]);
    }

    async _downloadPayments(init, length) {
        const url = this.loaderEndpoint;
        const data = {
            init: init,
            length: length,
            currentSize: this.currentSize,
        };
        try {
            const html = await apiProvider.loaderGet(url, data);
            this._appendPayments(html);
        } catch (error) {
            console.error(error);
        }
    }

    _appendPayments(payments) {
        /* parse new payments */
        const parser = new DOMParser();
        const doc = parser.parseFromString(payments, 'text/html');
        const newPayments = doc.querySelectorAll('tr');
        const countPmt = doc.querySelector(this._el('loader', true)).dataset.countPmt;

        const class_hideLoadMore = this._elMod('insiemeCardPaymentsLoadMore', 'hidden');
        let classList_loadMore = this.insiemeCardPaymentsLoadMore?.classList;
        const class_hideContent = this._elMod('insiemeCardPaymentsTable', 'hidden');
        let classList_content = this.insiemeCardPaymentsTable?.classList;
        const class_showNoPayments = this._elMod('insiemeCardNoPayments', 'show');
        let classList_noPayments = this.insiemeCardNoPayments.classList;

        /* hide/show loadMore */
        if (this.currentSize != 0 && newPayments.length < this.downloadLength) {
            /* case no more payments to download next */
            classList_loadMore.add(class_hideLoadMore);
        } else {
            /* case possibly more payments to download next */
            classList_loadMore.remove(class_hideLoadMore);
        }

        /* hide/show payments */
        if (this.currentSize + newPayments.length <= 0) {
            /* case no payments */
            classList_content.add(class_hideContent);
            classList_noPayments.add(class_showNoPayments);
            classList_loadMore.add(class_hideLoadMore);
        } else {
            /* case payments */
            classList_content.remove(class_hideContent);
            classList_noPayments.remove(class_showNoPayments);
            /* append new payments */
            this.insiemeCardPaymentsTable?.querySelector('tbody').append(...newPayments);
            /* update current size */
            this.currentSize += newPayments.length;
            /* hide/show loadMore */
            if (this.currentSize >= countPmt) {
                classList_loadMore.add(class_hideLoadMore);
            }
        }
    }

    _setPointsBalanceCurrentYear() {
        const label = this.cardInfoPoints?.querySelector(this._el('insiemeCardInfoLabel', true));
        if (label) label.innerText = label.innerText + ' ' + new Date().getFullYear();
    }

    _addInsiemeCard() {
        flowManager.startFlow({
            flowName: 'add-card',
            flowSteps: [{ name: 'add-card' }, { name: 'add-card-step2' }, { name: 'card-added', disableBack: true }],
            options: {
                requestCardModality: 'add-card',
            },
        });
    }

    _requestInsiemeCard() {
        flowManager.startFlow({
            flowName: 'request-card',
            flowSteps: [
                { name: 'request-card-step1', stepIndex: 1, disableBack: true },
                { name: 'request-card-step2', stepIndex: 2, disableBack: true },
                { name: 'card-added', disableBack: true },
            ],
            options: {
                requestCardModality: 'associate-dm',
            },
        });
    }
}
