import Component from '../../../../../libs/components/component';
import { openLoader, closeLoader } from '../mt11-loader/script';
import { apiProvider } from '../../../../../libs/api-provider';
import { storeManager } from '../../../../../libs/store-manager';
import './style.scss';

export default class FavoriteFlyerProduct extends Component {
    constructor(name, root) {
        super(name, root);
        this.idProdottoNegozioUtente = this.root.dataset.idProdottoNegozioUtente;
        this.defaultImage = this.root.dataset.defaultImage;

        this.image = this._dEl('image');
        this.heart = this._dEl('heart');

        this._setDefaultImage();
        this._addEventListeners();
    }

    _setDefaultImage() {
        this.image.onerror = () => {
            if (this.defaultImage) this.image.src = this.defaultImage;
        };
    }

    _addEventListeners() {
        this.heart?.addEventListener('click', (event) => this._removeFavoriteFlyer(event));
    }

    async _removeFavoriteFlyer(event) {
        event.preventDefault();
        let result = false;
        try {
            openLoader('main');
            result = await apiProvider.removeDisProduct(this.idProdottoNegozioUtente);
            if (result) {
                storeManager.emit('OnefavoriteRemoved', {});
                this.root.classList.add(this._mod('fadeOut'));
                setTimeout(() => (this.root.style = 'display: none;'), 600);
            }
        } catch (error) {
            console.warn(error);
        } finally {
            closeLoader('main');
        }
        return result;
    }
}


