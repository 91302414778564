import Component from '../../../../../libs/components/component';
import { register } from '../../../../../libs/register';
import './style.scss';

export default class Modal extends Component {
    constructor(name, root) {
        super(name, root);
        this.page = register.getClass(document.querySelector('.ms1-page'));
        this.modalName = this.root.dataset.name;
        this.backdrop = this._dEl('backdrop');
        this.content = this._dEl('content');
        this.rightContent = this._dEl('rightContent');

        this._addEventListeners();
    }

    _addEventListeners() {
        /* close on click outside */
        this._addListener(
            'click',
            (event) => {
                if (!event.target.matches(`[data-open-modal='${this.modalName}']`)) return;
                event.preventDefault();
                this.open();
            },
            document
        );
        this.backdrop?.addEventListener('click', (event) => {
            event.preventDefault();
            this.cancel();
        });
        this.root.addEventListener('click', (event) => {
            if (!event.target.matches('[data-close-modal]')) return;
            event.preventDefault();
            if (
                window.innerWidth < 1024 &&
                this.rightContent &&
                this.rightContent.classList.contains(this._elMod('rightContent', 'forceShow'))
            ) {
                this.rightContent.classList.remove(this._elMod('rightContent', 'forceShow'));
                return;
            }
            this.cancel();
        });
        /* close modal by pressing the Escape key */
        this.root.addEventListener('keydown', (event) => {
            if (!this.isOpen() || event.key !== 'Escape' || this.isUnclosable()) return;
            this.close();
        });
    }

    open() {
        const otherModalEl = document.querySelector(this._mod('open', true));
        if (otherModalEl) {
            const otherModal = register.getClass(otherModalEl);
            otherModal.close(true);
            this.root.classList.add(this._mod('noTransition'));
            this.root.classList.add(this._mod('open'));
            this.root.offsetHeight;
            this.root.classList.remove(this._mod('noTransition'));
        } else {
            this.root.classList.add(this._mod('open'));
        }
        this.page.setBlockScroll();
        this._sendOpenEvent();
    }

    close(force = false) {
        if (!force) {
            this.root.classList.remove(this._mod('open'));
        } else {
            this.root.classList.add(this._mod('noTransition'));
            this.root.classList.remove(this._mod('open'));
            this.root.offsetHeight;
            this.root.classList.remove(this._mod('noTransition'));
        }
        this.page.removeBlockScroll();
        this._sendCloseEvent();
    }

    cancel() {
        this._sendCancelEvent();
        this.close();
    }

    isOpen() {
        return this.root.classList.contains(this._mod('open'));
    }

    isUnclosable() {
        return this.backdrop.classList.contains(this._elMod('backdrop', 'unclosable'));
    }

    isVertical() {
        return this.root.classList.contains(this._mod('vertical'));
    }

    showRight() {
        if (!this.rightContent.classList.contains(this._elMod('rightContent', 'forceShow')))
            this.rightContent.classList.add(this._elMod('rightContent', 'forceShow'));
    }

    hideRight() {
        this.rightContent.classList.remove(this._elMod('rightContent', 'forceShow'));
    }

    _sendOpenEvent() {
        const event = new CustomEvent('ecModalOpen', { bubbles: true });
        this.root.dispatchEvent(event);
    }

    _sendCloseEvent() {
        const event = new CustomEvent('ecModalClose', { bubbles: true });
        this.root.dispatchEvent(event);
    }

    _sendCancelEvent() {
        const event = new CustomEvent('ecModalCancel', { bubbles: true });
        this.root.dispatchEvent(event);
    }

    toggle() {
        if (this.root.classList.contains(this._mod('open'))) {
            this.close();
        } else {
            this.open();
        }
    }

    getModalName() {
        return this.modalName;
    }

    static getModalObjByName(name) {
        const modal = document.querySelector(`.mt1-modal[data-name=${name}]`);
        if (!modal) return null;
        const modalObj = register.getClass(modal);
        if (!modalObj) return null;

        return modalObj;
    }

    static getCurrentModal(element) {
        const modalEl = element.closest('.mt1-modal');
        if (!modalEl) return;
        const modalObj = register.getClass(modalEl);
        if (!modalObj) return;
        return modalObj;
    }
}
