/*
 * Copyright 2018 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

/* eslint-disable */

module.exports = function main($) {

  const htl = $.globals;
  let data = $.globals["data"];

  let render;
  render = function* _template_global_render(args) { 
    let labels = args[1]['labels'] || '';
    let request = args[1]['request'] || '';
    let $t, $n = args[0];
    $.dom.text($n,"\n\n    ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mt40-customer-care-request__contentWrapper", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n\n        ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mt40-customer-care-request__contentHeading", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n            ");
    const var_testVariable0 = request["origin"];
    if (var_testVariable0) {
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "mt40-customer-care-request__contentHeadingSection", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                ");
      $t = $.dom.create("h4",false,false);
      $n = $.dom.push($n,$t);
      const var_1 = yield $.xss((labels["requestorigin"]) || ("Request origin"), "html");
      $.dom.append($n, var_1);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n                ");
      $t = $.dom.create("p",false,false);
      $n = $.dom.push($n,$t);
      $t = $.dom.create("a",false,false);
      const var_attrValue2 = request["origin"];
      if (!$.col.empty(var_attrValue2)) {
        $.dom.attr($t, 'href', var_attrValue2, 'attribute');
      }
      $n = $.dom.push($n,$t);
      const var_3 = yield $.xss(request["site"], "html");
      $.dom.append($n, var_3);
      $n = $.dom.pop($n);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n            ");
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n            ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mt40-customer-care-request__contentHeadingSeparator", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                ");
    $t = $.dom.create("div",false,false);
    $n = $.dom.push($n,$t);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    const var_testVariable4 = request["caseNumber"];
    if (var_testVariable4) {
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "mt40-customer-care-request__contentHeadingSection", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                ");
      $t = $.dom.create("h4",false,false);
      $n = $.dom.push($n,$t);
      const var_5 = yield $.xss((labels["requestid"]) || ("Request ID"), "html");
      $.dom.append($n, var_5);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n                ");
      $t = $.dom.create("p",false,false);
      $n = $.dom.push($n,$t);
      $.dom.text($n,"#");
      const var_6 = yield $.xss(request["caseNumber"], "html");
      $.dom.append($n, var_6);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n            ");
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n            ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mt40-customer-care-request__contentHeadingSeparator", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                ");
    $t = $.dom.create("div",false,false);
    $n = $.dom.push($n,$t);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    const var_testVariable7 = request["recordType"];
    if (var_testVariable7) {
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "mt40-customer-care-request__contentHeadingSection", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                ");
      $t = $.dom.create("h4",false,false);
      $n = $.dom.push($n,$t);
      const var_8 = yield $.xss((labels["requesttype"]) || ("Request type"), "html");
      $.dom.append($n, var_8);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n                ");
      $t = $.dom.create("p",false,false);
      $n = $.dom.push($n,$t);
      const var_9 = yield $.xss(request["recordType"], "html");
      $.dom.append($n, var_9);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n            ");
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n        ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n        ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mt40-customer-care-request__contentBody", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n\n            \n            ");
    const var_testVariable10 = request["description"];
    if (var_testVariable10) {
      $.dom.text($n,"\n                ");
      $t = $.dom.create("h4",false,false);
      $.dom.attr($t, 'class', "mt40-customer-care-request__descriptionTitle", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                    ");
      const var_11 = yield $.xss((labels["requestdescription"]) || ("Request description"), "html");
      $.dom.append($n, var_11);
      $.dom.text($n,"\n                ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n                ");
      $t = $.dom.create("p",false,false);
      $.dom.attr($t, 'class', "mt40-customer-care-request__descriptionText", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                    ");
      const var_12 = yield $.xss(request["description"], "html");
      $.dom.append($n, var_12);
      $.dom.text($n,"\n                ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n            ");
    }
    $.dom.text($n,"\n\n            \n            ");
    $t = $.dom.create("div",false,false);
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mt40-customer-care-request__documents", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                    ");
    $t = $.dom.create("h4",false,false);
    $.dom.attr($t, 'class', "mt40-customer-care-request__documentsTitle", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                        ");
    const var_13 = yield $.xss((labels["requestattachments"]) || ("Attachments"), "html");
    $.dom.append($n, var_13);
    $.dom.text($n,"\n                    ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n                    ");
    const var_testVariable14 = request["documents"];
    if (var_testVariable14) {
      $t = $.dom.create("ul",false,false);
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                        ");
      const var_collectionVar15 = $.col.init(request["documents"]);
      const var_size16 = $.col.len(var_collectionVar15);
      if (var_size16) {
        for (const var_index17 of $.col.keys(var_collectionVar15)) {
          const doc = $.col.get(var_collectionVar15, var_index17);
          const doclist = $.listInfo(var_index17, var_size16);
          $t = $.dom.create("li",false,false);
          $.dom.attr($t, 'class', "mt40-customer-care-request__document", 'attribute');
          $n = $.dom.push($n,$t);
          $.dom.text($n,"\n                            ");
          $t = $.dom.create("a",false,false);
          const var_attrValue18 = doc["url"];
          if (!$.col.empty(var_attrValue18)) {
            $.dom.attr($t, 'href', var_attrValue18, 'attribute');
          }
          const var_attrValue19 = doc["title"];
          if (!$.col.empty(var_attrValue19)) {
            $.dom.attr($t, 'title', var_attrValue19, 'attribute');
          }
          $.dom.attr($t, 'target', "_blank", 'attribute');
          $n = $.dom.push($n,$t);
          $.dom.text($n,"\n                                ");
          $t = $.dom.create("span",false,false);
          $.dom.attr($t, 'class', "ec-icon-file-text", 'attribute');
          $n = $.dom.push($n,$t);
          $n = $.dom.pop($n);
          $.dom.text($n,"\n                                ");
          $t = $.dom.create("span",false,false);
          $.dom.attr($t, 'class', "mt40-customer-care-request__documentName", 'attribute');
          $n = $.dom.push($n,$t);
          const var_20 = yield $.xss(doc["title"], "html");
          $.dom.append($n, var_20);
          $n = $.dom.pop($n);
          $.dom.text($n,"\n                                ");
          $t = $.dom.create("span",false,false);
          $.dom.attr($t, 'class', "ec-icon-next", 'attribute');
          $n = $.dom.push($n,$t);
          $n = $.dom.pop($n);
          $.dom.text($n,"\n                            ");
          $n = $.dom.pop($n);
          $.dom.text($n,"\n                        ");
          $n = $.dom.pop($n);
        }
      }
      $.dom.text($n,"\n                    ");
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n                    ");
    const var_testVariable21 = !request["documents"];
    if (var_testVariable21) {
      $t = $.dom.create("p",false,false);
      $.dom.attr($t, 'class', "mt40-customer-care-request__noDocuments", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                        ");
      const var_22 = yield $.xss((labels["nodocumentattached"]) || ("No documents attached"), "html");
      $.dom.append($n, var_22);
      $.dom.text($n,"\n                    ");
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n                ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n                ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mt40-customer-care-request__linkedOrder", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                    ");
    $t = $.dom.create("h4",false,false);
    $.dom.attr($t, 'class', "mt40-customer-care-request__linkedOrderTitle", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                        ");
    const var_23 = yield $.xss((labels["linkedordercode"]) || ("Linked order No°"), "html");
    $.dom.append($n, var_23);
    $.dom.text($n,"\n                    ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n                    ");
    const var_testVariable24 = request["orderCode"];
    if (var_testVariable24) {
      $t = $.dom.create("a",false,false);
      const var_attrValue25 = request["ordersPage"];
      if (!$.col.empty(var_attrValue25)) {
        $.dom.attr($t, 'href', var_attrValue25, 'attribute');
      }
      $.dom.attr($t, 'target', "_blank", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                        ");
      $t = $.dom.create("span",false,false);
      $.dom.attr($t, 'class', "mt40-customer-care-request__linkedOrderCode", 'attribute');
      $n = $.dom.push($n,$t);
      const var_26 = yield $.xss((labels["orderno"]) || ("Order No°"), "html");
      $.dom.append($n, var_26);
      $.dom.text($n," ");
      const var_27 = yield $.xss(request["orderCode"], "html");
      $.dom.append($n, var_27);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n                        ");
      $t = $.dom.create("span",false,false);
      $.dom.attr($t, 'class', "ec-icon-chevron-right", 'attribute');
      $n = $.dom.push($n,$t);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n                    ");
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n                    ");
    const var_testVariable28 = !request["orderCode"];
    if (var_testVariable28) {
      $t = $.dom.create("p",false,false);
      $.dom.attr($t, 'class', "mt40-customer-care-request__noLinkedOrder", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                        ");
      const var_29 = yield $.xss((labels["nolinkedorders"]) || ("No linked orders"), "html");
      $.dom.append($n, var_29);
      $.dom.text($n,"\n                    ");
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n                ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n            \n            ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mt40-customer-care-request__ctas", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                ");
    $t = $.dom.create("button",false,false);
    $.dom.attr($t, 'class', "mt40-customer-care-request__minimize", 'attribute');
    $.dom.attr($t, 'type', "button", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                    ");
    const var_30 = yield $.xss((labels["minimizecard"]) || ("Minimize card"), "html");
    $.dom.append($n, var_30);
    $.dom.text($n,"\n                ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n                ");
    const var_testVariable31 = (request["status"]) !== ("CLOSED");
    if (var_testVariable31) {
      $t = $.dom.create("button",false,false);
      $.dom.attr($t, 'class', "mt40-customer-care-request__closeRequest", 'attribute');
      $.dom.attr($t, 'type', "button", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                    ");
      const var_32 = yield $.xss((labels["closerequest"]) || ("Close request"), "html");
      $.dom.append($n, var_32);
      $.dom.text($n,"\n                ");
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n            ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n        ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n    ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n");
  };
  $.template('global', 'render', render);

  return $.run(function* () {
    let $t, $n = $.dom.start();
    return $.dom.end();

  });
};
