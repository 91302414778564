/*
 * Copyright 2018 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

/* eslint-disable */

module.exports = function main($) {

  const htl = $.globals;
  let data = $.globals["data"];

  let render;
  render = function* _template_global_render(args) { 
    let errorimage = args[1]['errorImage'] || '';
    let errortitle = args[1]['errorTitle'] || '';
    let errortext = args[1]['errorText'] || '';
    let $t, $n = args[0];
    $.dom.text($n,"\n\n    ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mw22-confirmed-profile__wrapper", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n\n        ");
    $t = $.dom.create("img",true,true);
    $.dom.attr($t, 'class', "mw22-confirmed-profile__image", 'attribute');
    const var_attrValue0 = errorimage["url"];
    if (!$.col.empty(var_attrValue0)) {
      $.dom.attr($t, 'src', var_attrValue0, 'attribute');
    }
    const var_attrValue1 = errorimage["alt"];
    if (!$.col.empty(var_attrValue1)) {
      $.dom.attr($t, 'alt', var_attrValue1, 'attribute');
    }
    $n = $.dom.push($n,$t);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n        ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mw22-confirmed-profile__result", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n            ");
    $t = $.dom.create("h4",false,false);
    $.dom.attr($t, 'class', "mw22-confirmed-profile__title", 'attribute');
    $n = $.dom.push($n,$t);
    const var_2 = yield $.xss(errortitle, "html");
    $.dom.append($n, var_2);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mw22-confirmed-profile__txt", 'attribute');
    $n = $.dom.push($n,$t);
    const var_3 = yield $.xss(errortext, "html");
    $.dom.append($n, var_3);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n        ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n    ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n");
  };
  $.template('global', 'render', render);

  return $.run(function* () {
    let $t, $n = $.dom.start();
    return $.dom.end();

  });
};
