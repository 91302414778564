/*
 * Copyright 2018 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

/* eslint-disable */

module.exports = function main($) {

  const htl = $.globals;
  let data = $.globals["data"];

  let render;
  render = function* _template_global_render(args) { 
    let labels = args[1]['labels'] || '';
    let siteicon = args[1]['siteIcon'] || '';
    let statuslabel = args[1]['statusLabel'] || '';
    let creationdate = args[1]['creationDate'] || '';
    let request = args[1]['request'] || '';
    let $t, $n = args[0];
    $.dom.text($n,"\n\n    ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mm15-cc-request-details__contentWrapper", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n\n        \n        ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mm15-cc-request-details__heading", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n            ");
    $t = $.dom.create("h3",false,false);
    $.dom.attr($t, 'class', "mm15-cc-request-details__reqTitle", 'attribute');
    $n = $.dom.push($n,$t);
    const var_0 = yield $.xss(request["type"] ? request["type"] : request["recordType"], "html");
    $.dom.append($n, var_0);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    const var_testVariable1 = request["site"];
    if (var_testVariable1) {
      $t = $.dom.create("p",false,false);
      $.dom.attr($t, 'class', "mm15-cc-request-details__site", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                ");
      $t = $.dom.create("span",false,false);
      const var_attrValue2 = siteicon;
      if (!$.col.empty(var_attrValue2)) {
        $.dom.attr($t, 'class', var_attrValue2, 'attribute');
      }
      $n = $.dom.push($n,$t);
      $n = $.dom.pop($n);
      const var_3 = yield $.xss(request["site"], "html");
      $.dom.append($n, var_3);
      $.dom.text($n,"\n            ");
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n            ");
    const var_testVariable4 = creationdate;
    if (var_testVariable4) {
      $t = $.dom.create("p",false,false);
      $.dom.attr($t, 'class', "mm15-cc-request-details__created", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                ");
      const var_5 = yield $.xss((labels["createdon"]) || ("Created on"), "html");
      $.dom.append($n, var_5);
      $.dom.text($n,"\n                ");
      $t = $.dom.create("span",false,false);
      $n = $.dom.push($n,$t);
      const var_6 = yield $.xss(creationdate, "html");
      $.dom.append($n, var_6);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n            ");
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n            ");
    const var_testVariable7 = request["status"];
    if (var_testVariable7) {
      $t = $.dom.create("p",false,false);
      $.dom.attr($t, 'class', "mm15-cc-request-details__status", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                ");
      const var_8 = yield $.xss((labels["requeststatus"]) || ("Request status"), "html");
      $.dom.append($n, var_8);
      $.dom.text($n,"\n                ");
      $t = $.dom.create("span",false,false);
      const var_attrValue9 = request["status"];
      if (!$.col.empty(var_attrValue9)) {
        $.dom.attr($t, 'data-status', var_attrValue9, 'attribute');
      }
      $n = $.dom.push($n,$t);
      const var_10 = yield $.xss(statuslabel, "html");
      $.dom.append($n, var_10);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n            ");
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n        ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n        \n        ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mm15-cc-request-details__info", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n            ");
    const var_testVariable11 = request["origin"];
    if (var_testVariable11) {
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "mm15-cc-request-details__infoSection", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                ");
      $t = $.dom.create("h4",false,false);
      $n = $.dom.push($n,$t);
      const var_12 = yield $.xss((labels["requestorigin"]) || ("Request origin"), "html");
      $.dom.append($n, var_12);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n                ");
      $t = $.dom.create("p",false,false);
      $n = $.dom.push($n,$t);
      $t = $.dom.create("a",false,false);
      const var_attrValue13 = request["origin"];
      if (!$.col.empty(var_attrValue13)) {
        $.dom.attr($t, 'href', var_attrValue13, 'attribute');
      }
      $n = $.dom.push($n,$t);
      const var_14 = yield $.xss((request["originLabel"]) || (request["origin"]), "html");
      $.dom.append($n, var_14);
      $n = $.dom.pop($n);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n            ");
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n            ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mm15-cc-request-details__infoSeparator", 'attribute');
    $n = $.dom.push($n,$t);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    const var_testVariable15 = request["caseNumber"];
    if (var_testVariable15) {
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "mm15-cc-request-details__infoSection", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                ");
      $t = $.dom.create("h4",false,false);
      $n = $.dom.push($n,$t);
      const var_16 = yield $.xss((labels["requestid"]) || ("Request ID"), "html");
      $.dom.append($n, var_16);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n                ");
      $t = $.dom.create("p",false,false);
      $n = $.dom.push($n,$t);
      $.dom.text($n,"#");
      const var_17 = yield $.xss(request["caseNumber"], "html");
      $.dom.append($n, var_17);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n            ");
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n            ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mm15-cc-request-details__infoSeparator", 'attribute');
    $n = $.dom.push($n,$t);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    const var_testVariable18 = request["recordType"];
    if (var_testVariable18) {
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "mm15-cc-request-details__infoSection", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                ");
      $t = $.dom.create("h4",false,false);
      $n = $.dom.push($n,$t);
      const var_19 = yield $.xss((labels["requesttype"]) || ("Request type"), "html");
      $.dom.append($n, var_19);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n                ");
      $t = $.dom.create("p",false,false);
      $n = $.dom.push($n,$t);
      const var_20 = yield $.xss(request["recordType"], "html");
      $.dom.append($n, var_20);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n            ");
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n        ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n        \n        ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mm15-cc-request-details__description", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n            ");
    $t = $.dom.create("h4",false,false);
    $.dom.attr($t, 'class', "mm15-cc-request-details__descriptionTitle", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                ");
    const var_21 = yield $.xss((labels["requestdescription"]) || ("Request description"), "html");
    $.dom.append($n, var_21);
    $.dom.text($n,"\n            ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    $t = $.dom.create("p",false,false);
    $.dom.attr($t, 'class', "mm15-cc-request-details__descriptionText", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                ");
    const var_22 = yield $.xss(request["description"], "html");
    $.dom.append($n, var_22);
    $.dom.text($n,"\n            ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n        ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n        \n        ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mm15-cc-request-details__documents", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n            ");
    $t = $.dom.create("h4",false,false);
    $.dom.attr($t, 'class', "mm15-cc-request-details__documentsTitle", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                ");
    const var_23 = yield $.xss((labels["requestattachments"]) || ("Attachments"), "html");
    $.dom.append($n, var_23);
    $.dom.text($n,"\n            ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    const var_testVariable24 = request["documents"];
    if (var_testVariable24) {
      $t = $.dom.create("ul",false,false);
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                ");
      const var_collectionVar25 = $.col.init(request["documents"]);
      const var_size26 = $.col.len(var_collectionVar25);
      if (var_size26) {
        for (const var_index27 of $.col.keys(var_collectionVar25)) {
          const doc = $.col.get(var_collectionVar25, var_index27);
          const doclist = $.listInfo(var_index27, var_size26);
          $t = $.dom.create("li",false,false);
          $n = $.dom.push($n,$t);
          $.dom.text($n,"\n                    ");
          $t = $.dom.create("a",false,false);
          const var_attrValue28 = doc["url"];
          if (!$.col.empty(var_attrValue28)) {
            $.dom.attr($t, 'href', var_attrValue28, 'attribute');
          }
          const var_attrValue29 = doc["title"];
          if (!$.col.empty(var_attrValue29)) {
            $.dom.attr($t, 'title', var_attrValue29, 'attribute');
          }
          $.dom.attr($t, 'target', "_blank", 'attribute');
          $n = $.dom.push($n,$t);
          $.dom.text($n,"\n                        ");
          $t = $.dom.create("span",false,false);
          $.dom.attr($t, 'class', "ec-icon-file-text", 'attribute');
          $n = $.dom.push($n,$t);
          $n = $.dom.pop($n);
          $.dom.text($n,"\n                        ");
          $t = $.dom.create("span",false,false);
          $n = $.dom.push($n,$t);
          const var_30 = yield $.xss(doc["title"], "html");
          $.dom.append($n, var_30);
          $n = $.dom.pop($n);
          $.dom.text($n,"\n                        ");
          $t = $.dom.create("span",false,false);
          $.dom.attr($t, 'class', "ec-icon-next", 'attribute');
          $n = $.dom.push($n,$t);
          $n = $.dom.pop($n);
          $.dom.text($n,"\n                    ");
          $n = $.dom.pop($n);
          $.dom.text($n,"\n                ");
          $n = $.dom.pop($n);
        }
      }
      $.dom.text($n,"\n            ");
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n            ");
    const var_testVariable31 = !request["documents"];
    if (var_testVariable31) {
      $t = $.dom.create("p",false,false);
      $.dom.attr($t, 'class', "mm15-cc-request-details__noDocuments", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                ");
      const var_32 = yield $.xss((labels["nodocumentattached"]) || ("No documents attached"), "html");
      $.dom.append($n, var_32);
      $.dom.text($n,"\n            ");
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n        ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n        \n        ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mm15-cc-request-details__linkedOrder", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n            ");
    $t = $.dom.create("h4",false,false);
    $.dom.attr($t, 'class', "mm15-cc-request-details__linkedOrderTitle", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                ");
    const var_33 = yield $.xss((labels["linkedordercode"]) || ("Linked order No°"), "html");
    $.dom.append($n, var_33);
    $.dom.text($n,"\n            ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    const var_testVariable34 = request["orderCode"];
    if (var_testVariable34) {
      $t = $.dom.create("a",false,false);
      const var_attrValue35 = request["ordersPage"];
      if (!$.col.empty(var_attrValue35)) {
        $.dom.attr($t, 'href', var_attrValue35, 'attribute');
      }
      $.dom.attr($t, 'target', "_blank", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                ");
      $t = $.dom.create("span",false,false);
      $n = $.dom.push($n,$t);
      const var_36 = yield $.xss((labels["orderno"]) || ("Order No°"), "html");
      $.dom.append($n, var_36);
      $.dom.text($n," ");
      const var_37 = yield $.xss(request["orderCode"], "html");
      $.dom.append($n, var_37);
      $n = $.dom.pop($n);
      $t = $.dom.create("span",false,false);
      $.dom.attr($t, 'class', "ec-icon-chevron-right", 'attribute');
      $n = $.dom.push($n,$t);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n            ");
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n            ");
    const var_testVariable38 = !request["orderCode"];
    if (var_testVariable38) {
      $t = $.dom.create("p",false,false);
      $.dom.attr($t, 'class', "mm15-cc-request-details__noLinkedOrder", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                ");
      const var_39 = yield $.xss((labels["nolinkedorders"]) || ("No linked orders"), "html");
      $.dom.append($n, var_39);
      $.dom.text($n,"\n            ");
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n        ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n        \n        ");
    const var_testVariable40 = (request["status"]) !== ("CLOSED");
    if (var_testVariable40) {
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "mm15-cc-request-details__ctas", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n            ");
      $t = $.dom.create("button",false,false);
      $.dom.attr($t, 'class', "mm15-cc-request-details__closeRequest", 'attribute');
      $.dom.attr($t, 'type', "button", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                ");
      const var_41 = yield $.xss((labels["closerequest"]) || ("Close request"), "html");
      $.dom.append($n, var_41);
      $.dom.text($n,"\n            ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n        ");
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n\n    ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n");
  };
  $.template('global', 'render', render);

  return $.run(function* () {
    let $t, $n = $.dom.start();
    return $.dom.end();

  });
};
