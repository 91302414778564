import Component from '../../../../../libs/components/component';
import PerfectScrollbar from 'perfect-scrollbar';
import './style.scss';

export default class MessagePopup extends Component {
    constructor(name, root) {
        super(name, root);
        this.messageText = this._dEl('text');
        const scrollbarOptions = {
            swipeEasing: true,
            suppressScrollX: true
        };
        this.ps = new PerfectScrollbar(this.messageText, scrollbarOptions);
    }
}

