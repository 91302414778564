import { apiProvider } from '../../../../../../libs/api-provider';
import Component from '../../../../../../libs/components/component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { storeManager } from '../../../../../../libs/store-manager';
import { FUNNEL_NAME, FUNNEL_STEP, trackEvent, TRACKABLE_EVENT } from '../../../../../../libs/tracking-manager-old';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import './style.scss';

export default class Addresses extends Component {
    constructor(name, root) {
        super(name, root);

        this.loaderDeliveryEndpoint = this.root.dataset.endpointDelivery;
        this.loaderBillingEndpoint = this.root.dataset.endpointBilling;

        this.types = ['delivery', 'billing'];
        this.deliveryTab = this._dEl('deliveryTab');
        this.billingTab = this._dEl('billingTab');
        this.tabs = [this.deliveryTab, this.billingTab];
        this.tabList = this._dEl('tabs');
        this.deliveryContent = this._dEl('deliveryContent');
        this.billingContent = this._dEl('billingContent');
        this.tabPanels = [this.deliveryContent, this.billingContent];
        this.activeTab = this.root.dataset.activeTab || 'delivery';
        this.tabFocus = this.types.indexOf(this.activeTab) || 0;
        const firstOpenTab = this.tabs[this.tabFocus];
        firstOpenTab.setAttribute('tabindex', '0');
        this._selectTab(firstOpenTab, this._getTabPanelFromTab(firstOpenTab), true);
        trackEvent(TRACKABLE_EVENT.pageview, FUNNEL_NAME.addressHandling, FUNNEL_STEP[`${this.activeTab}AddressList`]);

        this.deliveryAddresses = this._dEl('deliveryAddresses');
        this.billingAddresses = this._dEl('billingAddresses');
        this.btnAddBillingAddress = this._dEl('btnAddBillingAddress');
        this.btnAddDeliveryAddress = this._dEl('btnAddDeliveryAddress');

        this._addEventListeners();
        this._addStoreListeners();
    }

    _addEventListeners() {
        this.tabs.forEach((tab) => {
            tab.addEventListener('click', () => {
                if (this._isTabActive(tab)) return;
                // Remove all current selected tabs
                const selectedTabs = [...this.tabList.querySelectorAll('[aria-selected="true"]')];
                selectedTabs?.forEach((t) => {
                    this._selectTab(t, this._getTabPanelFromTab(t), false);
                });
                // Set this tab as selected
                this._selectTab(tab, this._getTabPanelFromTab(tab), true);
                trackEvent(
                    TRACKABLE_EVENT.pageview,
                    FUNNEL_NAME.addressHandling,
                    FUNNEL_STEP[`${tab.dataset.tab}AddressList`]
                );
            });
        });
        this.tabList.addEventListener('keydown', (e) => {
            if (e.key !== 'ArrowRight' && e.key !== 'ArrowLeft') return;
            this.tabs[this.tabFocus].setAttribute('tabindex', '-1');
            if (e.key === 'ArrowRight') {
                this.tabFocus++;
                // if at the end, go to the start
                if (this.tabFocus >= this.tabs.length) {
                    this.tabFocus = 0;
                }
            } else if (e.key === 'ArrowLeft') {
                this.tabFocus--;
                // if at the start, move to the end
                if (this.tabFocus < 0) {
                    this.tabFocus = this.tabs.length - 1;
                }
            }
            this.tabs[this.tabFocus].setAttribute('tabindex', '0');
            this.tabs[this.tabFocus]?.focus();
        });

        this.btnAddDeliveryAddress.addEventListener('click', () => {
            this._refreshEcTime();
            if (this.ecTime && new Date().getTime() - this.ecTime > this.ecTimeThreshold) {
                this._confirmLogin('addDeliveryAddress');
                return;
            }
            trackEvent(TRACKABLE_EVENT.addAddress, FUNNEL_NAME.addressHandling, FUNNEL_STEP.addDeliveryAddress);
            storeManager.emit('currentDeliveryAddress', { newAddress: true, address: {} });
            flowManager.startFlow({
                flowName: 'delivery-add',
                flowSteps: [{ name: 'delivery-address' }, { name: 'delivery-address-added' }],
            });
        });
        this.btnAddBillingAddress.addEventListener('click', () => {
            this._refreshEcTime();
            if (this.ecTime && new Date().getTime() - this.ecTime > this.ecTimeThreshold) {
                this._confirmLogin('addBillingAddress');
                return;
            }
            trackEvent(TRACKABLE_EVENT.addAddress, FUNNEL_NAME.addressHandling, FUNNEL_STEP.addBillingAddress);
            storeManager.emit('currentBillingAddress', { newAddress: true, address: { type: 'persfis' } });
            flowManager.startFlow({
                flowName: 'billing-add',
                flowSteps: [{ name: 'billing-address' }, { name: 'billing-address-added' }],
            });
        });
    }

    _addStoreListeners() {
        this._addStoreListener('newDeliveryAddress', () => {
            this._reloadAddresses('delivery');
            flowManager.complete('delivery-add');
        });
        this._addStoreListener('newBillingAddress', () => {
            this._reloadAddresses('billing');
            flowManager.complete('billing-add');
        });
        this._addStoreListener('mm10LoginConfirmed', (path, data) => {
            if (!data.confirmed) {
                console.warn('Login confirmation failed');
                return;
            }
            const submitEl = data.submitEl;
            if (!submitEl) {
                console.warn('Missing submitEl on confirmed login');
                return;
            }
            switch (submitEl) {
                case 'addDeliveryAddress': {
                    trackEvent(TRACKABLE_EVENT.addAddress, FUNNEL_NAME.addressHandling, FUNNEL_STEP.addDeliveryAddress);
                    storeManager.emit('currentDeliveryAddress', { newAddress: true, address: {} });
                    flowManager.startFlow({
                        flowName: 'delivery-add',
                        flowSteps: [{ name: 'delivery-address' }, { name: 'delivery-address-added' }],
                    });
                    break;
                }
                case 'addBillingAddress': {
                    trackEvent(TRACKABLE_EVENT.addAddress, FUNNEL_NAME.addressHandling, FUNNEL_STEP.addBillingAddress);
                    storeManager.emit('currentBillingAddress', { newAddress: true, address: { type: 'persfis' } });
                    flowManager.startFlow({
                        flowName: 'billing-add',
                        flowSteps: [{ name: 'billing-address' }, { name: 'billing-address-added' }],
                    });
                    break;
                }
                default:
                    break;
            }
        });
    }

    _switchTab(type) {
        if (!type) return;
        if (type === 'delivery') {
            this._disablePointerEvents(this.billingTab);
            this.toggleActiveTab(this.deliveryTab);
            this._cleanStyle(this.billingTab);
        }
        if (type === 'billing') {
            this._disablePointerEvents(this.deliveryTab);
            this.toggleActiveTab(this.billingTab);
            this._cleanStyle(this.deliveryTab);
        }
    }

    toggleActiveTab(target) {
        if (!target || target.classList.contains(class_activeTab)) return;

        const class_activeTab = this._el('activeTab');
        const class_activeContent = this._el('activeContent');
        const class_delivery = this._el('deliveryTab');
        const class_billing = this._el('billingTab');

        /* remove active class on the opposite tab */
        if (target.classList.contains(class_delivery)) {
            this.billingTab.classList.remove(class_activeTab);
            this.billingContent.classList.remove(class_activeContent);
            /* toggle active content */
            this.deliveryContent.classList.add(class_activeContent);
        } else if (target.classList.contains(class_billing)) {
            this.deliveryTab.classList.remove(class_activeTab);
            this.deliveryContent.classList.remove(class_activeContent);
            /* toggle active content */
            this.billingContent.classList.add(class_activeContent);
        }
        /* add class active to the clicked tab */
        target.classList.add(class_activeTab);
    }

    _disablePointerEvents(target) {
        if (!target) return;
        target.style.pointerEvents = 'none';
    }

    _cleanStyle(target) {
        if (!target) return;
        target.removeAttribute('style');
    }

    async _reloadAddresses(type) {
        openLoader('main');
        if (!type || !['delivery', 'billing'].includes(type)) return;
        if (type === 'delivery') {
            this._emptyAddresses(this.deliveryAddresses);
            await this._downloadAddresses(this.loaderDeliveryEndpoint, this.deliveryAddresses);
        }
        if (type === 'billing') {
            this._emptyAddresses(this.billingAddresses);
            await this._downloadAddresses(this.loaderBillingEndpoint, this.billingAddresses);
        }
        closeLoader('main');
    }

    async _downloadAddresses(endpoint, container) {
        const url = endpoint;
        try {
            const html = await apiProvider.loaderGet(url);
            this._appendAddresses(html, container);
        } catch (error) {
            console.error(error);
        }
    }

    _appendAddresses(addresses, container) {
        /* parse new addresses */
        const parser = new DOMParser();
        const doc = parser.parseFromString(addresses, 'text/html');
        const newAddresses = doc.querySelectorAll('.mt15-address');
        /* append new addresses */
        container.append(...newAddresses);
    }

    _emptyAddresses(container) {
        if (!container || container.objReference || !container.hasChildNodes()) return;
        for (let i = container.childElementCount; i > 0; i--) {
            container.removeChild(container.children[i - 1]);
        }
    }

    _refreshEcTime() {
        this.ecTime = storeManager.get('perslogData')?.ecTime || null;
        this.ecTimeThreshold = storeManager.get('perslogData').ecTimeThreshold;
    }

    _confirmLogin(submitEl) {
        flowManager.startFlow({
            flowName: 'confirm-login',
            flowSteps: [{ name: 'confirm-login' }],
            options: { submitEl: submitEl },
        });
    }

    _getTabPanelFromTab(tab) {
        return this.root.querySelector(`#${tab.getAttribute('aria-controls')}`);
    }

    _isTabActive(tab) {
        return tab.classList.contains(this._el('activeTab'));
    }

    _selectTab(tab, tabPanel, select = true) {
        if (select == true) {
            tab.setAttribute('aria-selected', 'true');
            tab.classList.add(this._el('activeTab'));
            tabPanel.removeAttribute('hidden');
            tabPanel.setAttribute('aria-hidden', 'false');
        } else {
            tab.setAttribute('aria-selected', 'false');
            tab.classList.remove(this._el('activeTab'));
            tabPanel.setAttribute('hidden', 'true');
            tabPanel.setAttribute('aria-hidden', 'true');
        }
    }
}
