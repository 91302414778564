import ModalComponent from '../../../../../../libs/components/modal-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { storeManager } from '../../../../../../libs/store-manager';
import contentMobTpl from './partials/content-mob-tpl.html';
import { runTemplate } from '../../../../../../libs/htl-runtime/HTMLRuntime';
import { emptyElement } from '../../../../../../libs/utils';
import './style.scss';


export default class CCRequestDetails extends ModalComponent {
    constructor(name, root) {
        super(name, root);
        this._doLogic();
    }

    _doLogic() {
        this._addStoreListeners();
    }

    _addStoreListeners() {
        this._addStoreListener('renderCCRequest', (path, data) => {
            this.request = data.request;
            emptyElement(this.wrapper);
            this._renderRequest(data);
            this._addEventListeners();
            this.ps.update();
        });
        this._addStoreListener('closedCCRequest', () => {
            const currentFlow = storeManager.get('currentFlow');
            if (currentFlow == 'cc-request-details') {
                flowManager.complete('cc-request-details');
            }
            emptyElement(this.wrapper);
            this.modal.close();
        });
    }

    _renderRequest({ request, labels, creationDate, siteIcon, statusLabel }) {
        const contentMobData = {
            request,
            labels,
            creationDate,
            siteIcon,
            statusLabel
        };
        const contentMobEl = runTemplate(
            contentMobTpl,
            contentMobData,
            this._el('contentWrapper', true)
        );
        this.wrapper.append(contentMobEl);
    }

    _addEventListeners() {
        this.closeRequest = this._dEl('closeRequest');
        this.closeRequest?.addEventListener('click', () => {
            flowManager.startFlow({
                flowName: 'close-cc-request',
                flowSteps: [{ name: 'close-cc-request' }],
                options: {
                    request: this.request,
                },
            });
        });
    }

    /* override */
    _onCancel() {
        flowManager.complete();
    }
}


