import { apiProvider } from '../../../../../../libs/api-provider';
import Component from '../../../../../../libs/components/component';
import { register } from '../../../../../../libs/register';
import { openLoader, closeLoader } from '../../../templates/mt11-loader/script';
import { formToJSON, jsonToForm } from '../../../../../../libs/form-to-json';
import { delay } from '../../../../../../libs/utils';
import './style.scss';


export default class InsiemePlusCardExpenses extends Component {
    constructor(name, root) {
        super(name, root);

        this.paymentsMonthlyPdf = Array.from(this._dEl('paymentsMonthlyPdf', true));

        this.servicesDisable = this._dEl('servicesDisable');

        this.filter = register.getClass(this._dEl('filter'));
        this.paymentsTitle = this._dEl('paymentsTitle').querySelector('span');
        this.paymentsFrom = this._dEl('paymentsFrom').querySelector('span');
        this.noPaymentsText = this._dEl('noPaymentsText').querySelector('span');

        this.payments = this._dEl('payments');
        this.paymentsTable = this._dEl('paymentsTable');
        this.loaderEndpoint = this.paymentsTable?.dataset.loaderEndpoint;
        this.noPayments = this._dEl('noPayments');

        this.services = this._dEl('services');
        this.servicesForm = this._dEl('servicesForm');
        this.servicesSubmit = this._dEl('servicesSubmit');
        //this._disableSubmit(this.servicesSubmit, 'servicesSubmit');

        this._initPayments();
        const buttonCb = (button) => {
            button.setAttribute('type', 'button');
        };
        if (this.paymentsMonthlyPdf && this.paymentsMonthlyPdf[0]) {
            this._replaceTextDoubleCurlyBrackets(
                this.paymentsMonthlyPdf[0],
                'button',
                'downloadPaymentsSelectedMonthPdf',
                buttonCb
            );
            this.downloadPaymentsSelectedMonthPdf = this._dEl('downloadPaymentsSelectedMonthPdf');
        }
        if (this.paymentsMonthlyPdf && this.paymentsMonthlyPdf[1]) {
            this._replaceTextDoubleCurlyBrackets(
                this.paymentsMonthlyPdf[1],
                'button',
                'downloadPaymentsCurrentMonthPdf',
                buttonCb
            );
            this.downloadPaymentsCurrentMonthPdf = this._dEl('downloadPaymentsCurrentMonthPdf');
        }
        if (this.servicesDisable) {
            this._replaceTextDoubleCurlyBrackets(this.servicesDisable, 'button', 'disableServices', buttonCb);
            this.disableServices = this._dEl('disableServices');
        }
        if (this.servicesForm) jsonToForm(this.servicesForm, JSON.parse(this.root.dataset.user), []);

        this._addEventListeners();
    }

    _addEventListeners() {
        this.root.addEventListener('mt10FilterChanged', async (event) => {
            console.info(`[${this.getName()}] selected filter: ${event.data.label} (${event.data.value})`);
            openLoader('main');
            this._emptyPayments(this.paymentsTable.querySelector('tbody'));
            await this._downloadPayments();
            this._setPaymentsMonthYear(event.data.value, event.data.label.match(/\d+/)[0], event.data.label);
            await delay(500);
            closeLoader('main');
            event.stopPropagation();
        });

        this.downloadPaymentsSelectedMonthPdf?.addEventListener('click', (event) => {
            event.preventDefault();
            this._downloadPaymentsMonthlyPdf(this.filter.getValue());
        });

        this.downloadPaymentsCurrentMonthPdf?.addEventListener('click', (event) => {
            event.preventDefault();
            this._downloadPaymentsMonthlyPdf(this.filter.getValue());
        });

        this.servicesDisable?.addEventListener('click', (event) => {
            event.preventDefault();
            this._disableServices();
        });

        this.servicesSubmit?.addEventListener('click', async (event) => {
            event.preventDefault();
            if (!this._getFormValidity(this.servicesForm)) {
                const first = this.servicesForm.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
                return;
            }
            this._submitNewServices(this.servicesForm);
            await delay(1000);
        });
    }

    _initPayments() {
        const now = new Date();
        this.filter.setSelected(now.getMonth() + 1 + '-' + now.getFullYear());
        this._setPaymentsMonthYear(
            now.getMonth() + 1,
            ('' + now.getFullYear()).match(/\d+/)[0],
            `${this.filter.getLabel()}`
        );
    }

    _setPaymentsMonthYear(month, year, label) {
        /* NOTE: requires month zero-based index and full year */
        const m = parseInt('' + month);
        const y = '' + year;
        this.paymentsTitle.innerText = '' + label;
        this.paymentsFrom.innerText = `01/${m < 10 ? 0 : ''}${m}/${y.substring(2)}`;
        this.noPaymentsText.innerText = '' + label;
    }

    _replaceTextDoubleCurlyBrackets(container, el, clazz, cb = null) {
        const split = container.innerText.split(/({{)(.*)(}})/);
        container.innerText = '';
        container.append(split[0]);
        const replacedEl = document.createElement(el);
        if (clazz) replacedEl.classList.add(`${this.getName()}__${clazz}`);
        replacedEl.innerText = split[2];
        if (cb != null) cb(replacedEl);
        container.appendChild(replacedEl);
        container.append(split[4]);
    }

    async _downloadPayments() {
        const url = this.loaderEndpoint;
        const filter = this.filter.getValue();
        try {
            const html = await apiProvider.loaderGet(url, {
                filter,
            });
            /* parse response data */
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');
            const countPmt = parseInt(doc.querySelector(this._el('loader', true)).dataset.countPmt);
            if (countPmt > 0) {
                this._toggleNoPayments(false);
                this.paymentsTable?.querySelector('tbody').append(...doc.querySelectorAll('tr'));
            } else {
                this._toggleNoPayments(true);
            }
        } catch (error) {
            console.error(error);
        }
    }

    _toggleNoPayments(noPayments) {
        if (noPayments) {
            /* hide payments section */
            this.payments.classList.remove(this._elMod('payments', 'show'));
            /* hide services section */
            /*this.services.classList.remove(this._elMod('services', 'show'));*/
            /* show no payments section */
            if (!this.noPayments.classList.contains(this._elMod('noPayments', 'show'))) {
                this.noPayments.classList.add(this._elMod('noPayments', 'show'));
            }
        } else {
            /* show payments section */
            if (!this.payments.classList.contains(this._elMod('payments', 'show'))) {
                this.payments.classList.add(this._elMod('payments', 'show'));
            }
            /* show services section */
            /*if (!this.services.classList.contains(this._elMod('services', 'show'))) {
                this.services.classList.add(this._elMod('services', 'show'));
            }*/
            /* hide no payments section */
            this.noPayments.classList.remove(this._elMod('noPayments', 'show'));
        }
    }

    _emptyPayments(container) {
        if (!container || container.objReference || !container.hasChildNodes()) return;
        for (let i = container.childElementCount; i > 0; i--) {
            container.removeChild(container.children[i - 1]);
        }
    }

    _getFormValidity(form) {
        if (!form.checkValidity()) return false;
        return true;
    }

    async _downloadPaymentsMonthlyPdf(value) {
        const split = value.split('-', 2);
        const currentMonth = parseInt(split[0]);
        const year = parseInt(split[1]);

        const dataInizio = `01-${currentMonth < 10 ? 0 : ''}${currentMonth}-${year}`;
        const lastDay = new Date(year, currentMonth, 0).getDate(); //use day 0 of next month ( date use monthIndex )
        const dataFine = `${lastDay < 10 ? 0 : ''}${lastDay}-${currentMonth < 10 ? 0 : ''}${currentMonth}-${year}`;

        const data = {
            dataInizio: dataInizio,
            dataFine: dataFine,
        };
        try {
            openLoader('main');
            const result = await apiProvider.getCardPaymentsPdf(data);
            const blob = new Blob([result], { type: 'application/pdf; charset=utf-8' });
            const url = URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.download = `movimenti_${dataInizio}_${dataFine}.pdf`;
            link.href = url;
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            }, 100);
        } catch (error) {
            console.warn(error);
        } finally {
            closeLoader('main');
        }
    }

    _disableServices() {
        const data = {
            attivazioneServizioOnlineConadCard: 'N',
        };
        this._callEditUserData(data);
    }

    _submitNewServices(form) {
        const data = formToJSON(form);
        if (!data.rendicontazioneConadCard || !data.invioMailEstrattoContoConadCard) {
            console.error(
                `[${this.getName()}] Missing fields in submit new services ("rendicontazioneConadCard", "invioMailEstrattoContoConadCard")`
            );
        }
        this._callEditUserData(data);
    }

    async _callEditUserData(data) {
        try {
            openLoader('main');
            const firmaDigitale = await apiProvider.getFirmaDigitale();
            data.firmaDigitale = firmaDigitale;
            const result = await apiProvider.editUserData(data);
            console.log(result);
            window.location.reload();
        } catch (error) {
            console.warn(error);
        } finally {
            closeLoader('main');
        }
    }
}


