import { apiProvider } from '../../../../../../libs/api-provider';
import WizardComponent from '../../../../../../libs/components/wizard-component';
import { dictionary } from '../../../../../../libs/dictionary-provider';
import { flowManager } from '../../../../../../libs/flow-manager';
import { runWithData } from '../../../../../../libs/htl-runtime/HTMLRuntime';
import { informativaManager } from '../../../../../../libs/informativa-manager';
import { storeManager } from '../../../../../../libs/store-manager';
import { emptyElement } from '../../../../../../libs/utils';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import storeTpl from '../../../templates/mt3-store/jscall.html';
import Store from '../../../templates/mt3-store/script';
import './style.scss';

export default class CompleteData extends WizardComponent {
    constructor(name, root) {
        super(name, root);

        this.submit = this._dEl('submit');
        this.continueButton = this._dEl('continueButton');

        this.refPdvButton = this._dEl('referencePdvButton');
        this.pdvSelect = this._dEl('pdvSelect');
        this.changePdvButton = this._dEl('changePdvButton');
        this.storeWrapper = this._dEl('storeWrapper');
        this.policy = this._dEl('policy');
        this.policyHTML = this.policy.innerHTML;
        this.radio = this._dEl('policyRadio');

        this._addEventListeners();
        this._addStoreListeners();
    }

    _addEventListeners() {
        this.refPdvButton.addEventListener('click', () => {
            flowManager.startFlow({
                flowName: 'select-pdv-extended',
                flowSteps: [{ name: 'select-pdv-extended' }],
            });
        });
        this.changePdvButton.addEventListener('click', () => {
            flowManager.startFlow({
                flowName: 'select-pdv-extended',
                flowSteps: [{ name: 'select-pdv-extended' }],
            });
        });
        this.continueButton.addEventListener('click', async () => {
            try {
                openLoader('main');
                const urlParams = new URLSearchParams(window.location.search);
                const result = await apiProvider.setPreferredStore({
                    anacanId: storeManager.get('selectedPdv')['anacanId'],
                    cb: urlParams.get('cb') || storeManager.get('redirectUrl').redirectUrl,
                });
                if (result?.cb) {
                    window.location = result.cb;
                } else {
                    window.location.reload();
                }
            } catch (error) {
                console.warn(error);
                closeLoader('main');
            }

            flowManager.complete();
        });
        this.policy.addEventListener('ecInputChanged', () => {
            this.continueButton.classList.remove('invalid');
        });
    }

    _addStoreListeners() {
        this._addStoreListener('selectedPdv', async (path, data) => {
            const userData = storeManager.get('userData');
            const result = await informativaManager.getInformativa(userData, data);
            this.currentPrivacy = result;
            this._setInformativa();
            this._addPdv(data);
            this.policy.classList.add(this._elMod('policy', 'show'));
        });
    }

    _setInformativa() {
        const str = `<a href="${this.currentPrivacy.linkPdfInformativa}" title="informativa ${
            this.currentPrivacy.version
        }" target="_blank">${dictionary.get('click here')}</a>`;

        this.policy.innerHTML = this.policyHTML.replace('{{linkInformativa}}', () => {
            return str;
        });
    }

    _addPdv(st) {
        emptyElement(this.storeWrapper);
        const data = Store.getStoreData(this.id + '-' + Math.floor(Math.random() * 100000), `${this.name}__store`, st);
        data.selected = true;
        const storeEl = runWithData(storeTpl, data);
        this.storeWrapper.appendChild(storeEl);
        this.refPdvButton.classList.add(this._elMod('referencePdvButton', 'hide'));
        this.storeWrapper.classList.add(this._elMod('storeWrapper', 'show'));
        this.changePdvButton.classList.add(this._elMod('changePdvButton', 'show'));
    }
}


