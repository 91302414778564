import PopupComponent from '../../../../../../libs/components/popup-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import { apiProvider } from '../../../../../../libs/api-provider';
import './style.scss';

export default class BolliniConfirmDonate extends PopupComponent {
    constructor(name, root) {
        super(name, root);
        this.title = this.root.dataset.title;
        this.titleElement = this._dEl('title');
        this.ctaAction = this._dEl('ctaAction');
        this.content = this._dEl('content');

        this._addEventListeners();
    }

    _onOpen() {
        super._onOpen();
        const data = flowManager.getDataFromFlow();
        this.bolliniToDonate = data.numeroPunti;
        this.cartToDonate = data.codiceCartaTarget;

        // insert into html
        this.titleElement.innerHTML = this.title.replace('{0}', this.bolliniToDonate).replace('{1}', this.cartToDonate);
        this.content.classList.remove(this._mod('hidden'));
        this.ctaAction.disabled = false;
    }

    _addEventListeners() {
        this._addListener(
            'click',
            async (event) => {
                event.preventDefault();
                const ok = await this._donate();
                flowManager.appendDataToFlow({ result: ok ? 'ok' : 'ko' });
                flowManager.complete();
            },
            this.ctaAction
        );
    }

    _hideContent() {
        this.content.classList.add(this._mod('hidden'));
        this.ctaAction.disabled = true;
    }

    async _donate() {
        try {
            openLoader('main');
            const data = flowManager.getDataFromFlow();
            let dataForDonate = {
                numeroPunti: data.numeroPunti,
                codiceCartaTarget: data.codiceCartaTarget,
            };
            await apiProvider.sendBollini(dataForDonate);
            return true;
        } catch (error) {
            console.warn(error);
            return false;
        } finally {
            closeLoader('main');
        }
    }

    /* override */
    _onCancel() {
        flowManager.backOrComplete();
    }

    _onClose() {
        this._hideContent();
    }
}
