import { apiProvider } from '../../../../../../libs/api-provider';
import PopupComponent from '../../../../../../libs/components/popup-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { storeManager } from '../../../../../../libs/store-manager';
import { delay } from '../../../../../../libs/utils';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import './style.scss';

export default class CancelOrder extends PopupComponent {
    constructor(name, root) {
        super(name, root);

        this.ctaAction = this._dEl('ctaAction');

        this._addEventListeners();
        this._addStoreListeners();
    }

    _addEventListeners() {
        this.ctaAction.addEventListener('click', async (event) => {
            event.preventDefault();
            await this._cancelOrder();
            flowManager.backOrComplete();
        });
    }

    _addStoreListeners() {
        this._addStoreListener('selectedOrder', (path, data) => {
            this.selectedOrder = data;
        });
    }

    async _cancelOrder() {
        try {
            openLoader('main');
            const data = {
                code: this.selectedOrder.orderCode,
                becommerce: this.selectedOrder.becommerce,
                typeOfService: this.selectedOrder.typeOfService,
            };
            await apiProvider.cancelOrder(data);
            await delay(200);
            storeManager.emit('reloadOrders', { type: this.selectedOrder.typeOfService });
            storeManager.emit('reloadOrderDetail', { reload: true });
        } catch (error) {
            console.warn(error);
        } finally {
            closeLoader('main');
        }
    }

    _onCancel() {
        flowManager.backOrComplete();
    }
}


