import { apiProvider } from '../../../../../../libs/api-provider';
import WizardComponent from '../../../../../../libs/components/wizard-component';
import { dictionary } from '../../../../../../libs/dictionary-provider';
import { flowManager } from '../../../../../../libs/flow-manager';
import { formToJSON } from '../../../../../../libs/form-to-json';
import { register } from '../../../../../../libs/register';
import { storeManager } from '../../../../../../libs/store-manager';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import './style.scss';

export default class MigrationStep1 extends WizardComponent {
    constructor(name, root) {
        super(name, root);

        this.form = this._dEl('form');
        this.verify = this._dEl('verify');
        this.skip = this._dEl('skip');
        this.error = this._dEl('error');

        this._addEventListeners();
    }

    /* override */
    open() {
        super.open();
        if (this.root.hasAttribute('data-user-light')) {
            flowManager.next('migration-step2');
        }
    }

    _addEventListeners() {
        this.form.addEventListener('submit', (event) => {
            event.preventDefault();
            event.stopPropagation();
        });
        /*this.form.addEventListener('ecInputChanged', (event) => {
            event.preventDefault();
            !event.data.valid
                ? this._disableSubmit()
                : this._getFormValidity()
                    ? this._enableSubmit()
                    : this._disableSubmit();
            event.stopPropagation();
        });*/
        this.verify.addEventListener('click', (event) => {
            event.preventDefault();
            if (!this._getFormValidity()) {
                const first = this.form.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
                return;
            }
            /*if (this._submitDisabled()) {
                return;
            }*/
            this._migrationStep1();
        });
        this.skip.addEventListener('click', (event) => {
            event.preventDefault();
            flowManager.next('migration-step2');
        });
    }

    _getFormValidity() {
        if (!this.form.checkValidity()) {
            return false;
        }
        return this._validDates(this.form);
    }
    _validDates(form) {
        if (!form) return false;
        const validities = Array.from(form.querySelectorAll('.mt23-datepicker'))
            .map((el) => { 
                const elObj = register.getClass(el);
                return {
                    name: elObj.getName(),
                    value: elObj.getValue(), 
                    valid: elObj.isValid()
                };
            });
        const result = validities.filter((el) => !el.valid).length <= 0;
        return result;
    }

    /*_enableSubmit() {
        this.verify?.classList?.add(this._elMod('verify', 'active'));
        this.verify?.removeAttribute('title');
    }
    async _disableSubmit() {
        this.verify?.classList?.remove(this._elMod('verify', 'active'));
        this.verify?.setAttribute('title', dictionary.getFEMessage('fillInFields'));
    }
    _submitDisabled() {
        return !this.verify.classList.contains(this._elMod('verify', 'active'));
    }*/

    async _migrationStep1() {
        let result = false;
        try {
            openLoader('main');
            const data = {
                ...formToJSON(this.form),
            };
            const firmaDigitale = await apiProvider.getFirmaDigitale();
            data.firmaDigitale = firmaDigitale;
            result = await apiProvider.migrationStep1(data);
            if(result){
                storeManager.emit('userData', result);
            }
        } catch (error) {
            this.ISError = error;
            console.warn('Cannot perform migration step 1', error);
        } finally {
            closeLoader('main');
        }
        
        if (!result) {
            this.error.innerHTML = this?.ISError?.codice
                ? await (dictionary.getFEMessage(this.ISError.codice) || this.ISError.errore)
                : this.root.dataset.genericError;
            this.error.classList.remove(this._elMod('error', 'hidden'));
            window.scrollTo(0,0);
        } else {
            flowManager.next('migration-step2');
        }
    }
}


