import Component from '../../../../../../libs/components/component';
import { apiProvider } from '../../../../../../libs/api-provider';
import { openLoader, closeLoader } from '../../../templates/mt11-loader/script';
import { runTemplate } from '../../../../../../libs/htl-runtime/HTMLRuntime';
import reqTpl from '../../../templates/mt40-customer-care-request/mt40-customer-care-request.html';
import CustomerCareRequest from '../../../templates/mt40-customer-care-request/script';
import paginationTpl from './partials/pagination-tpl.html';
import { dictionary } from '../../../../../../libs/dictionary-provider';
import { emptyElement } from '../../../../../../libs/utils';
import { storeManager } from '../../../../../../libs/store-manager';
import './style.scss';
import { getUserService } from '../../../../../../libs/user-service';

export default class CustomerCare extends Component {
    constructor(name, root) {
        super(name, root);
        this._doLogic();
    }

    async _doLogic() {
        // constants
        this.types = ['ALL', 'NEW', 'IN_GESTIONE', 'IN_SOSPESO,ON_HOLD', 'CLOSED'];
        try {
            this.labels = JSON.parse(this.root.dataset.labels);
        } catch (error) {
            console.warn('Could not parse labels');
            this.labels = {};
        }
        try {
            this.statusMap = JSON.parse(this.root.dataset.statusMap);
            setTimeout(() => {
                storeManager.emit('ccRequestStatusMap', { ...this.statusMap });
            }, 2000);
        } catch (error) {
            console.warn('Could not parse status map');
            this.statusMap = {};
        }
        try {
            this.siteIconMap = JSON.parse(this.root.dataset.siteIconMap);
            setTimeout(() => {
                storeManager.emit('ccRequestSiteIconMap', { ...this.siteIconMap });
            }, 2000);
        } catch (error) {
            console.warn('Could not parse site icon map');
            this.siteIconMap = {};
        }
        this.DATA_SORT_BY = 'data-sort-by';
        this.SORT = {
            LEAST_RECENT: 'leastrecent',
            MOST_RECENT: 'mostrecent',
        };
        // pagination
        this.pagination = new Map(this.types.map((t) => [t, []]));
        this.numPages = new Map(this.types.map((t) => [t, 0]));
        this.currentPage = new Map(this.types.map((t) => [t, 0]));
        this.PAGE_SIZE = parseInt(this.root.dataset.pageSize);
        this.userService = getUserService();

        this._getElements();
        await this._loadCCRequests();
        this._addEventListeners();
        this._addStoreListeners();
    }

    _getElements() {
        this.tabList = this._dEl('tabs');
        this.tabBtns = this._dEl('tabBtn', true);
        this.tabPans = this._dEl('tabPan', true);
        this.sortButtons = this._dEl('sortBy', true);
    }

    async _loadCCRequests() {
        openLoader('main');
        this.tabFocus = this._getTabIndexFromUrl();
        const firstOpenTab = this.tabBtns[this.tabFocus];
        await this._downloadCCRequests();
        firstOpenTab.setAttribute('tabindex', '0');
        this._selectTab(firstOpenTab, this._getTabPanelFromTab(firstOpenTab), true);
        closeLoader('main');
    }

    _addEventListeners() {
        /* tab controls */
        this.tabBtns.forEach((tab) => {
            tab.addEventListener('click', () => {
                if (this._isTabActive(tab)) return;
                // Remove all current selected tabs
                const selectedTabs = [...this.tabList.querySelectorAll('[aria-selected="true"]')];
                selectedTabs?.forEach((t) => {
                    this._selectTab(t, this._getTabPanelFromTab(t), false);
                });
                // Set this tab as selected
                this._selectTab(tab, this._getTabPanelFromTab(tab), true);
            });
        });
        this.tabList.addEventListener('keydown', (e) => {
            if (e.key !== 'ArrowRight' && e.key !== 'ArrowLeft') return;
            this.tabBtns[this.tabFocus].setAttribute('tabindex', '-1');
            if (e.key === 'ArrowRight') {
                this.tabFocus++;
                // if at the end, go to the start
                if (this.tabFocus >= this.tabBtns.length) {
                    this.tabFocus = 0;
                }
            } else if (e.key === 'ArrowLeft') {
                this.tabFocus--;
                // if at the start, move to the end
                if (this.tabFocus < 0) {
                    this.tabFocus = this.tabBtns.length - 1;
                }
            }
            this.tabBtns[this.tabFocus].tabIndex = '0';
            this.tabBtns[this.tabFocus]?.focus();
        });

        /* sort buttons */
        for (let sortButton of this.sortButtons) {
            const sortBy = sortButton.dataset.sortBy;
            const siblingSortBy = sortBy === this.SORT.LEAST_RECENT ? this.SORT.MOST_RECENT : this.SORT.LEAST_RECENT;
            const sibling = sortButton
                .closest(this._el('sort', true))
                .querySelector(`${this._el('sortBy', true)}[${this.DATA_SORT_BY}="${siblingSortBy}"]`);
            const tabPan = sortButton.closest(this._el('tabPan', true));

            sortButton.addEventListener('click', () => {
                // change data-sort-by on the current tab panel
                tabPan.setAttribute(this.DATA_SORT_BY, siblingSortBy);
                // reload requests (sorted) for the current tab panel
                this._renderRequests(1, tabPan);
                // render pagination for the current tab panel (if needed)
                this._renderPagination(tabPan);
                // hide self
                sortButton.style.display = 'none';
                sortButton.tabIndex = '-1';
                sortButton.setAttribute('aria-hidden', 'true');
                // show sibling
                sibling.style.display = 'inline';
                sibling.tabIndex = '0';
                sibling.setAttribute('aria-hidden', 'false');
                // focus sibling
                sibling.focus();
            });
        }
    }

    _addStoreListeners() {
        this._addStoreListener('closedCCRequest', async () => {
            openLoader('main');
            // reload cc requests
            await this._downloadCCRequests();
            closeLoader('main');
        });
    }

    async _downloadCCRequests() {
        try {
            const user = await this.userService.getUser();
            let requestsTemp = await apiProvider.getCaseList({userId: user.utenzaId});
            this.requests = [];
            for(let requestTemp of requestsTemp) {
                this.requests.push({
                    ...requestTemp,
                    site: 'HeyConad | Spesa Online',
                    origin: window.ecommerceDomain,
                    ordersPage: this.root.dataset.orderListPage,
                });
            }

            this._populateTabPans(this.requests);
        } catch (error) {
            console.warn('Could not retrieve customer care requests', error);
        }
    }

    _populateTabPans(requests) {
        if (!requests || requests.length <= 0) {
            console.log('No customer care requests found');
        }

        // get mapped requests
        const requestsMap = new Map(this.types.map((t) => [t, []]));
        for (let req of requests) {
            if (req.status == 'IN_SOSPESO' || req.status == 'ON_HOLD') {
                requestsMap.get('IN_SOSPESO,ON_HOLD').push(req);
            } else {
                if (!this.types.includes(req.status)) continue;
                requestsMap.get(req.status).push(req);
            }
            requestsMap.get('ALL').push(req);
        }

        // sort requests of each panel by least recent
        for (const type of requestsMap.keys()) {
            requestsMap.get(type).sort((reqA, reqB) => {
                return this._compareRequests(reqA, reqB, this.SORT.LEAST_RECENT);
            });
        }

        // paginate requests
        this._paginateMappedRequests(requestsMap);

        // populate tab panels
        for (let i = 0; i < this.tabPans.length; i++) {
            const tabPan = this.tabPans[i];
            if (!tabPan) continue;

            // pre-empty requests wrapper for the current tab panel
            const requestsContainer = tabPan.querySelector(this._el('requests', true));
            if (requestsContainer) emptyElement(requestsContainer);

            const tabType = tabPan?.dataset.type;
            const tabPanSort = tabPan.querySelector(this._el('sort', true));

            if (
                tabType &&
                this.types.includes(tabType) &&
                requestsMap.has(tabType) &&
                requestsMap.get(tabType).length > 0
            ) {
                // show sort buttons for the current tab panel
                tabPanSort.classList.add(this._elMod('sort', 'show'));
                this._showButton(tabPan.querySelector(this._el('sortBy', true)));

                // render customer care requests for the current tab panel
                this._renderRequests(1, tabPan);

                // render pagination for the current tab panel (if needed)
                this._renderPagination(tabPan);
            } else {
                // hide sort buttons for the current tab panel
                tabPanSort.classList.remove(this._elMod('sort', 'show'));
                this._hideButton(tabPan.querySelector(this._el('sortBy', true)));

                // remove pagination for the current tab panel
                this._removePagination(tabPan);

                // no requests for the current tab panel (if needed)
                if (!tabPan.querySelector(this._el('noRequests', true))) {
                    const noRequestsEl = this._dEl('noRequestsTemplate')?.content.cloneNode(true);
                    tabPan.appendChild(noRequestsEl);
                }
            }
        }
    }

    _paginateMappedRequests(requestsMap) {
        if (!(requestsMap instanceof Map)) return;

        // paginate for each type
        for (const type of requestsMap.keys()) {
            const requests = requestsMap.get(type);
            const pagination = this.pagination.get(type);
            pagination.splice(0, pagination.length);

            // paginate requests for current type
            for (const req of requests) {
                pagination.push(req);
            }

            // update numPages and currentPage for current type
            this.numPages.set(type, Math.ceil(pagination.length / this.PAGE_SIZE));
            this.currentPage.set(type, 1);
        }
    }

    _renderRequests(pageIndex, tabPan) {
        if (
            typeof pageIndex !== 'number' ||
            !tabPan ||
            !tabPan?.dataset?.type ||
            !Object.values(this.SORT).includes(tabPan.dataset.sortBy)
        ) {
            console.warn('Invalid params to go to tab panel page');
            return;
        }

        const type = tabPan.dataset.type;
        const sortBy = tabPan.dataset.sortBy;
        const start = (Math.min(pageIndex, this.numPages.get(type)) - 1) * this.PAGE_SIZE;
        const end = start + this.PAGE_SIZE;

        // get requests for the current tab panel and sort them according to sortBy
        const requests = this.pagination.get(type);
        requests.sort((reqA, reqB) => {
            return this._compareRequests(reqA, reqB, sortBy);
        });

        // get requests of the current page
        const page = requests.slice(start, end);
        // empty requests container
        const requestsContainer = tabPan.querySelector(this._el('requests', true));
        emptyElement(requestsContainer);

        // append requests of the current page to current tab panel
        for (const req of page) {
            // get request card

            const reqData = CustomerCareRequest.getCCRequestData(
                `${tabPan.id}-${req.id}`,
                this._el('ccRequest'),
                this.labels,
                this.statusMap,
                this.siteIconMap,
                req
            );
            const reqCard = runTemplate(reqTpl, reqData);
            requestsContainer.append(reqCard);
        }
    }

    _renderPagination(tabPan) {
        if (!tabPan || !tabPan?.dataset?.type || !Object.values(this.SORT).includes(tabPan.dataset.sortBy)) {
            console.warn('Invalid params to render pagination');
            return;
        }

        const type = tabPan.dataset.type;
        const paginationLength = this.numPages.get(type);
        if (!paginationLength || paginationLength <= 0) {
            // no pages to render
            return;
        }

        // get pagination element
        const paginationData = {
            pages: Array.from({ length: paginationLength }, (_, i) => i + 1),
            labels: {
                prev: dictionary.get('Go to previous page'),
                next: dictionary.get('Go to next page'),
                gotopage: dictionary.get('Go to page'),
            },
        };
        const paginationEl = runTemplate(paginationTpl, paginationData, this._el('pagination', true));

        // remove any previous pagination (if present)
        tabPan.querySelector(this._el('pagination', true))?.remove();
        // append pagination to current tab panel requests
        tabPan.append(paginationEl);

        // START apply pagination logic
        // get elements
        const prevPageBtn = paginationEl.querySelector(this._el('prev', true));
        const nextPageBtn = paginationEl.querySelector(this._el('next', true));
        const pageBtns = [...paginationEl.querySelectorAll(this._el('page', true))];

        // add listeners
        prevPageBtn?.addEventListener('click', () => {
            this._sendGoToPage(paginationEl, Math.max(this.currentPage.get(type) - 1, 1));
        });
        nextPageBtn?.addEventListener('click', () => {
            this._sendGoToPage(paginationEl, Math.min(this.currentPage.get(type) + 1, this.numPages.get(type)));
        });
        for (const page of pageBtns) {
            page?.addEventListener('click', () => {
                this._sendGoToPage(paginationEl, page.dataset.page);
            });
        }
        paginationEl?.addEventListener('goToPage', (event) => {
            const targetPage = parseInt(event.data.page);
            this._goToPage(targetPage, tabPan, prevPageBtn, nextPageBtn, pageBtns);
        });

        // mask pagination
        this._maskPagination(1, tabPan, prevPageBtn, nextPageBtn, pageBtns);
        // END apply pagination logic
    }

    _removePagination(tabPan) {
        const paginationEl = tabPan.querySelector(this._el('pagination', true));
        paginationEl?.remove();
    }

    _sendGoToPage(paginationEl, num) {
        if (!paginationEl || !paginationEl.classList.contains(this._el('pagination'))) {
            console.warn('Invalid pagination element to send goToPage event to');
            return;
        }

        const event = new CustomEvent('goToPage');
        event.data = {
            page: num,
        };
        paginationEl?.dispatchEvent(event);
    }

    _goToPage(targetPage, tabPan, prevPageBtn, nextPageBtn, pageBtns) {
        if (!tabPan || !tabPan?.dataset?.type) {
            console.warn('Invalid params to render pagination');
            return;
        }

        const type = tabPan.dataset.type;
        const requestsContainer = tabPan.querySelector(this._el('requests', true));

        emptyElement(requestsContainer);
        this._renderRequests(targetPage, tabPan);

        this.currentPage.set(type, targetPage);
        this._maskPagination(this.currentPage.get(type), tabPan, prevPageBtn, nextPageBtn, pageBtns);

        this._scrollToTop(requestsContainer);
    }

    _maskPagination(targetPage, tabPan, prevPageBtn, nextPageBtn, pageBtns) {
        if (
            !tabPan ||
            !tabPan?.dataset?.type ||
            !this.types.includes(tabPan?.dataset?.type) ||
            !prevPageBtn.classList.contains(this._el('prev')) ||
            !nextPageBtn.classList.contains(this._el('next'))
        ) {
            console.warn('Invalid params to mask pagination');
            return;
        }

        const type = tabPan.dataset.type;
        const numPages = this.numPages.get(type);
        const PAGE_HIDDEN = this._elMod('page', 'hidden');
        const PAGE_CURRENT = this._elMod('page', 'current');

        prevPageBtn.classList.remove(PAGE_HIDDEN);
        nextPageBtn.classList.remove(PAGE_HIDDEN);
        if (targetPage === 1) {
            prevPageBtn.classList.add(PAGE_HIDDEN);
        }
        if (targetPage === numPages) {
            nextPageBtn.classList.add(PAGE_HIDDEN);
        }

        for (const pageBtn of pageBtns) {
            pageBtn.classList.add(PAGE_HIDDEN);
            pageBtn.classList.remove(PAGE_CURRENT);

            const pageNumber = parseInt(pageBtn.dataset.page);
            const prev = Math.max(targetPage - 1, 1);
            const prevPrev = Math.max(prev - 1, 1);
            const next = Math.min(targetPage + 1, numPages);
            const nextNext = Math.min(next + 1, numPages);

            const visiblePages = [1, prevPrev, prev, targetPage, next, nextNext, numPages];
            const dottedPages = [];
            if (prevPrev > 1) dottedPages.push(prevPrev);
            if (nextNext < numPages) dottedPages.push(nextNext);

            // lock current page
            if (pageNumber === targetPage) {
                pageBtn.classList.add(PAGE_CURRENT);
            }
            // show visible pages
            if (visiblePages.includes(pageNumber)) {
                pageBtn.classList.remove(PAGE_HIDDEN);
                pageBtn.tabIndex = '0';
            } else {
                pageBtn.tabIndex = '-1';
            }
            // dot pages
            if (dottedPages.includes(pageNumber)) {
                pageBtn.innerText = '...';
            } else {
                pageBtn.innerText = pageBtn.dataset.page;
            }
        }
    }

    _compareRequests(reqA, reqB, sortBy) {
        if (!reqA.createdDate || !reqB.createdDate) return 0;
        const leastRecent = sortBy === this.SORT.LEAST_RECENT;
        if (reqA.createdDate > reqB.createdDate) return leastRecent ? 1 : -1;
        return leastRecent ? -1 : 1;
    }

    _getTabIndexFromUrl() {
        const urlParams = new URLSearchParams(window.location.search);
        const tab = urlParams.get('tab');
        if (tab && this.types.includes(tab)) return this.types.indexOf(tab);
        return 0;
    }

    _getTabPanelFromTab(tab) {
        return this.root.querySelector(`#${tab.getAttribute('aria-controls')}`);
    }

    _isTabActive(tab) {
        return tab.classList.contains(this._el('activeTab'));
    }

    _selectTab(tab, tabPanel, select = true) {
        if (select == true) {
            tab.setAttribute('aria-selected', 'true');
            tab.classList.add(this._el('activeTab'));
            tabPanel.removeAttribute('hidden');
            tabPanel.setAttribute('aria-hidden', 'false');
        } else {
            tab.setAttribute('aria-selected', 'false');
            tab.classList.remove(this._el('activeTab'));
            tabPanel.setAttribute('hidden', 'true');
            tabPanel.setAttribute('aria-hidden', 'true');
        }
    }

    _showButton(button) {
        if (!button || button.nodeName !== 'BUTTON') return;
        button.style.display = 'inline';
        button.tabIndex = '0';
        button.setAttribute('aria-hidden', 'false');
    }

    _hideButton(button) {
        if (!button || button.nodeName !== 'BUTTON') return;
        button.style.display = 'none';
        button.tabIndex = '-1';
        button.setAttribute('aria-hidden', 'true');
    }

    _scrollToTop(target) {
        /* offset = header overall height */
        const offset = 0;
        const scrollY = Math.max(target.offsetTop, offset) - offset;
        window.scrollTo(0, scrollY);
    }
}
