import ModalComponent from '../../../../../../libs/components/modal-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { register } from '../../../../../../libs/register';
import { storeManager } from '../../../../../../libs/store-manager';
import { informativaManager } from '../../../../../../libs/informativa-manager';
import { formToJSON, jsonToForm } from '../../../../../../libs/form-to-json';
import { apiProvider } from '../../../../../../libs/api-provider';
import { dictionary } from '../../../../../../libs/dictionary-provider';
import { isPositive, checkFormWithoutValidation } from '../../../../../../libs/utils';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import './style.scss';

export default class AddCardStep2 extends ModalComponent {
    constructor(name, root) {
        super(name, root);

        this.form = this._dEl('form');
        this.addCard = this._dEl('addCard');
        this.error = this._dEl('error');
        this.currentPrivacy = null;

        this._initPrivacyText();
        this._addEventListeners();
    }

    /* override */
    async _onOpen() {
        super._onOpen();
        let cardUserData = flowManager.getDataFromFlow();
        let userData = storeManager.get('userData');
        let dataCombined = {
            ...userData,
            ...cardUserData,
        };
        const selectedPdv = storeManager.get('selectedPdv');
        this.currentPrivacy = await informativaManager.getInformativa(dataCombined, selectedPdv);
        this._setInformativa();
        this._addPreselectedConsents(dataCombined);
        this._checkSubmit();
    }

    _addEventListeners() {
        this._dEl('box', true).forEach((box) => {
            box.addEventListener('ecInputChanged', (event) => {
                this._checkRadio(event.target.closest(this._el('box', true)), isPositive(event.data.value));
                this.ps?.update();
                this._checkSubmit();
            });
        });

        this.form.addEventListener('submit', (event) => {
            event.preventDefault();
        });
        this.addCard.addEventListener('click', async (event) => {
            event.preventDefault();

            if (!this.form.checkValidity() || !this._validRadios()) {
                const first = this.form.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
                return;
            }

            await this._addCard();
        });

        /* form errors */
        this.form.addEventListener('ecFormError', (event) => {
            event.preventDefault();
            if (!event.data.errorField) return;
            trackEvent(
                TRACKABLE_EVENT.formError,
                FUNNEL_NAME.insiemeCard,
                FUNNEL_STEP.insertInsiemeCardData,
                null,
                null,
                null,
                {
                    errorField: event.data.errorField,
                    errorText: event.data.errorText,
                }
            );
            event.stopPropagation();
        });
    }

    _initPrivacyText() {
        const privacyText = this._dEl('privacyText');
        const text = privacyText.dataset.text;
        if (!text || text == '') return;
        const linkLabel = privacyText.dataset.linkLabel || dictionary.get('privacy policy');
        if (!text.includes('$privacy')) {
            privacyText.innerText = text;
            return;
        }
        const split = text.split('$privacy');
        const link = document.createElement('a');
        link.href = '#';
        link.classList.add(this._el('privacyLink'));
        link.innerText = linkLabel;
        privacyText.append(split[0], link, split[1]);
    }

    _checkSubmit() {
        if (checkFormWithoutValidation(this.form)) {
            this.addCard.classList.remove(this._elMod('submit', 'disabled'));
        } else {
            this.addCard.classList.add(this._elMod('submit', 'disabled'));
        }
    }

    _checkRadio(box, valid) {
        const boxNotSelected = box.querySelector(this._el('boxNotSelected', true));
        if (!valid) {
            boxNotSelected.classList.add(this._elMod('boxNotSelected', 'show'));
        } else {
            boxNotSelected.classList.remove(this._elMod('boxNotSelected', 'show'));
        }
    }

    _validRadios() {
        return Array.from(this.form.querySelectorAll(`.mt19-radio`))
            .map((radio) => register.getClass(radio))
            .map((obj) => !!obj.isValid())
            .reduce((x, y) => x && y);
    }

    _setInformativa() {
        const privacyLinks = this._dEl('privacyLink', true);
        if (!privacyLinks) {
            console.warn('Missing privacy links.');
            return;
        }

        for (const privacyLink of privacyLinks) {
            privacyLink.href = this.currentPrivacy.linkPdfInformativa;
            privacyLink.title = `informativa ${this.currentPrivacy.version}`;
            privacyLink.target = '_blank';
        }
    }

    _addPreselectedConsents(userDataObj) {
        if (userDataObj) {
            jsonToForm(this.form, userDataObj);
        }
    }

    async _addCard() {
        let result = false;
        try {
            openLoader('main');

            const userData = {
                ...storeManager.get('userData'),
                ...storeManager.get('addCardData'),
                ...formToJSON(this.form),
            };
            const firmaDigitale = await apiProvider.getFirmaDigitale();
            userData.firmaDigitale = firmaDigitale;
            result = await apiProvider.verifyCard(userData);
            result = await apiProvider.addCard(userData);
        } catch (error) {
            this.ISError = error;
            console.warn('Cannot call add card ', error);
            result = false;
        } finally {
            closeLoader('main');
        }

        if (!result) {
            this.error.innerHTML = this?.ISError?.codice
                ? await (dictionary.getFEMessage(this.ISError.codice) || this.ISError.errore)
                : this.root.dataset.genericError;
            this.error.classList.remove(this._elMod('error', 'hidden'));
        } else {
            flowManager.next('card-added');
        }
    }

    _onCancel() {
        flowManager.complete();
    }
}
