import { bolliniPdvManager } from '../../../../../../libs/bollini-pdv-manager';
import ModalComponent from '../../../../../../libs/components/modal-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { storeManager } from '../../../../../../libs/store-manager';
import { FUNNEL_NAME, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import { openLoader } from '../../../templates/mt11-loader/script';
import './style.scss';

export default class MenuMobile extends ModalComponent {
    constructor(name, root) {
        super(name, root);

        this.links = Array.from(this._dEl('link', true));
        this.exitLink = this._dEl('exitLink');

        this._addEventListeners();
        this._addStoreListeners();

        this._setUserName(storeManager.get('userData'));
        this._setActivePage();
    }

    /* override */
    async _onOpen() {
        await super._onOpen();
    }

    _addEventListeners() {
        this.root.addEventListener('click', (event) => {
            const btn = event.target.closest(this._el('navSectionBtn', true));
            if (!btn) return;

            const section = btn.closest(this._el('navSection', true));
            if (!section) return;
            if (section.classList.contains('open')) {
                this._closeDropdown(section);
            } else {
                this._openDropdown(section);
            }
        });

        this.links.forEach((link) => {
            link.addEventListener('click', (event) => {
                if (link.classList.contains('active')) {
                    event.preventDefault();
                    return;
                }

                trackEvent(TRACKABLE_EVENT.clickProfile, FUNNEL_NAME.personalMenu, link.innerText.trim());
                setTimeout(() => {
                    openLoader('main');
                }, 400);
            });
        });

        this.exitLink.addEventListener('click', (event) => {
            event.preventDefault();
            flowManager.startFlow({
                flowName: 'logout',
                flowSteps: [{ name: 'logout' }],
            });
        });
    }

    _openDropdown(navSection) {
        //open style
        navSection.classList.add('open');

        //accessibility
        const btn = navSection.querySelector(this._el('navSectionBtn', true));
        const content = navSection.querySelector(this._el('navSectionContent', true));
        if (content?.style) content.style.visibility = 'visible';
        this._updateAriaAttributes(btn, content, true);
    }

    _closeDropdown(navSection) {
        //open style
        navSection.classList.remove('open');

        //accessibility
        const btn = navSection.querySelector(this._el('navSectionBtn', true));
        const content = navSection.querySelector(this._el('navSectionContent', true));
        this._updateAriaAttributes(btn, content, false);
        setTimeout(() => {
            if (content?.style) content.style.visibility = 'hidden';
        }, 300);
    }

    _updateAriaAttributes(btn, content, isOpen) {
        btn?.setAttribute('aria-expanded', `${isOpen ? 'true' : 'false'}`);
        content?.setAttribute('aria-hidden', `${isOpen ? 'false' : 'true'}`);
    }

    _addStoreListeners() {
        this._addStoreListener('userData', async (path, data) => {
            this._setUserName(data);

            //remove conad card links if not present
            if (!data.conadCard) {
                const ciPlus = Array.from(this.root.querySelectorAll('[data-ci-plus=true]'));
                for (let ci of ciPlus) {
                    ci.style.display = 'none';
                }
            }

            //remove conad bollini links if not enabled pdv or balance test rules
            const isEnabled = await bolliniPdvManager.isEnabled(data.pdvPreferito);
            if (!isEnabled) {
                const bolliniPages = Array.from(this.root.querySelectorAll('[data-bollini-needed=true]'));
                for (let bolliniPage of bolliniPages) {
                    bolliniPage.style.display = 'none';
                }
            }
        });
    }

    _setUserName(user) {
        if (!user) return;
        this.root.querySelector('[data-put-username]').innerText = user.nome;
    }

    _setActivePage() {
        const link = this.root.querySelector(`[data-page-path='${window.pagePath}']`);
        if (!link) return;

        link.classList.add('active');
        const navSection = link.closest(this._el('navSection', true));
        if (!navSection) return;

        this._openDropdown(navSection);
    }
}
