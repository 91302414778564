import Component from '../../../../../libs/components/component';
import { register } from '../../../../../libs/register';
import { animationEnd, buildWaitForEvent, emptyElement, nextTick } from '../../../../../libs/utils';
import './style.scss';

const closeEvent = buildWaitForEvent('ecPopupClose');

export default class Popup extends Component {
    constructor(name, root) {
        super(name, root);

        this.page = register.getClass(document.querySelector('.ms1-page'));
        this.popupName = this.root.dataset.name;
        this.backdrop = this._dEl('backdrop');
        this.content = this._dEl('content');

        this._updateHeight();
        this._addEventListeners();
        if (this.root.dataset.defaultOpen == 'true') {
            this._initDefaultOpen();
        }
    }

    _addEventListeners() {
        /* close on click outside */
        this._addListener(
            'click',
            (event) => {
                if (!event.target.matches(`[data-open-popup='${this.popupName}']`)) return;
                event.preventDefault();
                this.open();
            },
            document
        );
        this.backdrop.addEventListener('click', (event) => {
            event.preventDefault();
            this.cancel();
        });
        this.root.addEventListener('click', (event) => {
            if (!event.target.matches('[data-close-popup]')) return;
            event.preventDefault();
            this.cancel();
        });
        this._addListener(
            'resize',
            () => {
                this._updateHeight();
            },
            window
        );
        this._addListener(
            'scroll',
            () => {
                this._updateHeight();
            },
            document
        );
        /* close popup by pressing the Escape key */
        this.root.addEventListener('keydown', (event) => {
            if (!this.isOpen() || event.key !== 'Escape' || this.isUnclosable()) return;
            this.close();
        });
    }

    _updateHeight() {
        this.root.style.height = `${window.innerHeight}px`;
    }

    async _initDefaultOpen() {
        await nextTick();
        await this.open();
    }

    async open() {
        this._sendBeforeOpenEvent();
        this.root.classList.add(this._mod('open'));
        this.page.setBlockScroll();
        this.content.classList.add(this._elMod('content', 'animate'));
        await animationEnd(this.content);
        this.content.classList.remove(this._elMod('content', 'animate'));
        this._sendOpenEvent();
    }

    isOpen() {
        return this.root.classList.contains(this._mod('open'));
    }

    isUnclosable() {
        return this.backdrop.classList.contains(this._elMod('backdrop', 'unclosable'));
    }
    setUnclosable(unclosable = true) {
        if (typeof unclosable !== 'boolean') return;
        unclosable
            ? this.backdrop.classList.add(this._elMod('backdrop', 'unclosable'))
            : this.backdrop.classList.remove(this._elMod('backdrop', 'unclosable'));
    }

    close() {
        this.root.classList.remove(this._mod('open'));
        this.page.removeBlockScroll();
        this._sendCloseEvent();
        // focus after close (if set)
        const el = this.content.querySelector('[data-focus-after-close]');
        if (!el) return;
        const focusAfterCloseId = el.dataset.focusAfterClose;
        if (!focusAfterCloseId) return;
        document.getElementById(focusAfterCloseId)?.focus();
    }

    cancel() {
        this._sendCancelEvent();
        this.close();
    }

    toggle() {
        if (this.root.classList.contains(this._mod('open'))) {
            this.close();
        } else {
            this.open();
        }
    }

    getPopupName() {
        return this.popupName;
    }

    _sendOpenEvent() {
        const event = new CustomEvent('ecPopupOpen', { bubbles: true });
        this.root.dispatchEvent(event);
    }

    _sendBeforeOpenEvent() {
        const event = new CustomEvent('ecPopupBeforeOpen', { bubbles: true });
        this.root.dispatchEvent(event);
    }

    _sendCloseEvent() {
        const event = new CustomEvent('ecPopupClose', { bubbles: true });
        this.root.dispatchEvent(event);
    }

    _sendCancelEvent() {
        const event = new CustomEvent('ecPopupCancel', { bubbles: true });
        this.root.dispatchEvent(event);
    }

    async openContent(contentEl) {
        if (contentEl) {
            //delete objRef is exists
            if (this.content.objReference) {
                delete this.content.objReference;
            }
            //clear drawer
            emptyElement(this.content);
            //set drawer content
            this.content.appendChild(contentEl);
            //initialize logic if not preset
            register.applyTo(contentEl);
        }

        await this.open();
    }

    async openAndAwaitClosure(contentEl) {
        await this.openContent(contentEl);
        await closeEvent(this.root);
    }

    static getCurrentPopup(element) {
        const popupEl = element.closest('.mt4-popup');
        if (!popupEl) return;
        const popupObj = register.getClass(popupEl);
        if (!popupObj) return;
        return popupObj;
    }

    static findPopup(name) {
        const popupEl = document.querySelector(`.mt4-popup[data-name='${name}']`);
        if (!popupEl || !popupEl.objReference) throw 'Cannot find popup with name ' + name;
        const popup = popupEl.objReference;
        return popup;
    }
}

export const showPopup = async (name, contentEl) => {
    const popup = Popup.findPopup(name);
    await popup.openAndAwaitClosure(contentEl);
};
