import Component from '../../../../../libs/components/component';
import { dictionary } from '../../../../../libs/dictionary-provider';
import './style.scss';

export default class TextfieldLocked extends Component {
    constructor(name, root) {
        super(name, root);
        this.label = this._dEl('label');
        this.value = this._dEl('value');
    }

    setLabel(label) {
        this.label.textContent = dictionary.get(label);
    }

    setValue(value) {
        this.value.textContent = dictionary.get(value);
    }
}
