import Component from '../../../../../../libs/components/component';
import { openLoader, closeLoader } from '../../../templates/mt11-loader/script';
import { apiProvider } from '../../../../../../libs/api-provider';
import './style.scss';


export default class PreviewCoupon extends Component {
    constructor(name, root) {
        super(name, root);
        this.loaderEndpoint = this.root.dataset.endpoint;
        this.content = this._dEl('content');
        this.closeButton = this._dEl('closeContainer');

        openLoader('main');
        this._downloadCoupons();
        this._addEventListeners();
    }

    _addEventListeners() {
        this.closeButton.addEventListener('click', (event) => {
            event.preventDefault();
            parent.document.querySelector(".iframe-coupon-container").remove();
        });
    }

    async _downloadCoupons() {
        const url = this.loaderEndpoint;
        const data = {
            parentResource: window.parent.location.href
        };
        try {
            const html = await apiProvider.loaderGet(url, data);
            this._appendCoupons(html);
        } catch (error) {
            console.error(error);
        } finally {
            closeLoader('main');
        }
    }

    _appendCoupons(coupons) {
        /* parse new coupons */
        const parser = new DOMParser();
        const doc = parser.parseFromString(coupons, 'text/html');
        const newCoupons = doc.querySelectorAll('.mt12-coupon');

        const class_hideContent = this._elMod('content', 'hidden');
        let classList_content = this.content.classList;

        /* case coupons */
        classList_content.remove(class_hideContent);
        /* append new coupons */
        this.content.append(...newCoupons);
    }
}



