import PopupComponent from '../../../../../../libs/components/popup-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import './style.scss';

export default class PswRecovered extends PopupComponent {
    constructor(name, root) {
        super(name, root);

        this.ctaAction = this._dEl('ctaAction');
        this.ctaLink = this.ctaAction.dataset.continueLink;

        this._addEventListeners();
    }

    _onOpen() {
        super._onOpen();
        /* TODO: understand when type of service, prefstore coop and prefstore id are available (never ?) */
        trackEvent(TRACKABLE_EVENT.pageview, FUNNEL_NAME.recoverPsw, FUNNEL_STEP.confirmResetPsw);
    }

    _addEventListeners() {
        this.ctaAction.addEventListener('click', (event) => {
            event.preventDefault();
            if (this.ctaLink) {
                window.location = this.ctaLink;
            } else {
                this.popup.close();
            }
        });
    }

    _onCancel() {
        flowManager.backOrComplete();
    }
}
