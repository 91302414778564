import { apiProvider } from '../../../../../../libs/api-provider';
import WizardComponent from '../../../../../../libs/components/wizard-component';
import { dictionary } from '../../../../../../libs/dictionary-provider';
import { flowManager } from '../../../../../../libs/flow-manager';
import { formToJSON } from '../../../../../../libs/form-to-json';
import { register } from '../../../../../../libs/register';
import { storeManager } from '../../../../../../libs/store-manager';
import { isPositive } from '../../../../../../libs/utils';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import './style.scss';

export default class FormGccStep2 extends WizardComponent {
    constructor(name, root) {
        super(name, root);

        this.error = this._dEl('error');
        this.coop = this.root.dataset.coop;
        this.form = this._dEl('form');
        this.iban = this._dEl('iban');
        this.ibanObj = register.getClass(this.iban);
        this.verifyIban = this._dEl('verifyIban');
        this.privacyBoxes = this._dEl('box', true);
        this.sendRequest = this._dEl('sendRequest');

        this._addEventListeners();
    }

    _addEventListeners() {
        this.form.addEventListener('submit', (event) => {
            event.preventDefault();
            event.stopPropagation();
        });
        /*this.form.addEventListener('ecInputChanged', (event) => {
            event.preventDefault();
            !event.data.valid
                ? this._disableSubmit()
                : this._getFormValidity()
                    ? this._enableSubmit()
                    : this._disableSubmit();
            event.stopPropagation();
        });*/
        this.verifyIban.addEventListener('click', (event) => {
            event.preventDefault();
            this._verifyIban();
        });
        this.privacyBoxes?.forEach((box) => {
            box?.addEventListener('ecInputChanged', (event) => {
                this._checkRadio(box, isPositive(event.data.value));
            });
        });
        this.sendRequest.addEventListener('click', (event) => {
            event.preventDefault();
            if (!this._getFormValidity()) {
                const first = this.form.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
                return;
            }
            /*if (this._submitDisabled()) {
                return;
            }*/
            storeManager.emit('formGccData', formToJSON(this.form), true);
            this._formGccStep2();
        });
    }

    _getFormValidity() {
        if (!this.form.checkValidity()) {
            return false;
        }
        return true;
    }

    /*_enableSubmit() {
        this.sendRequest?.classList?.add(this._elMod('sendRequest', 'active'));
        this.sendRequest?.removeAttribute('title');
    }
    async _disableSubmit() {
        this.sendRequest?.classList?.remove(this._elMod('sendRequest', 'active'));
        this.sendRequest?.setAttribute('title', dictionary.getFEMessage('fillInFields'));
    }
    _submitDisabled() {
        return !this.sendRequest.classList.contains(this._elMod('sendRequest', 'active'));
    }*/

    _checkRadio(box, valid) {
        if (!box) return;
        const boxNotSelected = box.querySelector(this._el('boxNotSelected', true));
        if (!valid) {
            boxNotSelected.classList.add(this._elMod('boxNotSelected', 'show'));
        } else {
            boxNotSelected.classList.remove(this._elMod('boxNotSelected', 'show'));
        }
    }

    async _verifyIban() {
        let result = false;
        try {
            openLoader('main');
            const data = {
                iban: this.ibanObj.getValue(),
                codiceCooperativa: this.coop,
            };
            console.log('Verify IBAN!', data);
            result = await apiProvider.verifyIban(data);
        } catch (error) {
            this.ISError = error;
            console.warn(error);
        } finally {
            closeLoader('main');
        }
        
        if (!result) {
            this.error.innerHTML = this?.ISError?.codice
                ? await (dictionary.getFEMessage(this.ISError.codice) || this.ISError.errore)
                : this.root.dataset.genericError;
            this.error.classList.remove(this._elMod('error', 'hidden'));
            this.ibanObj.setState('error');
        } else {
            if (!this.error.classList.contains(this._elMod('error', 'hidden')))
                this.error.classList.add(this._elMod('error', 'hidden'));
            this.ibanObj.setState('valid');
        }
        return result;
    }

    async _formGccStep2() {
        if (!(await this._verifyIban())) return;
        let result = false;
        try {
            openLoader('main')
            const data = {
                ...storeManager.get('formGccData'),
            };
            /* if radios not selected, default to 'N' */
            if (this.privacyBoxes) {
                if (!data.codicePrivacyMarketing) data.codicePrivacyMarketing = 'N';
                if (!data.codicePrivacyUsiAbitudini) data.codicePrivacyUsiAbitudini = 'N';
                if (!data.codicePrivacyNazionale) data.codicePrivacyNazionale = 'N';
            }
            result = await apiProvider.formGccStep2(data);
        } catch (error) {
            this.ISError = error;
            console.warn(error);
        } finally {
            closeLoader('main');
        }

        if (!result) {
            this.error.innerHTML = this?.ISError?.codice
                ? await (dictionary.getFEMessage(this.ISError.codice) || this.ISError.errore)
                : this.root.dataset.genericError;
            this.error.classList.remove(this._elMod('error', 'hidden'));
            window.scrollTo(0,0);
        } else {
            if (!this.error.classList.contains(this._elMod('error', 'hidden')))
                this.error.classList.add(this._elMod('error', 'hidden'));
            /* show thankyou popup with redirect */
            flowManager.next('gcc-thankyou');
        }
    }
}


