import { runTemplate } from '../../../../../../libs/htl-runtime/HTMLRuntime';
import { DefaultRenderer } from '@googlemaps/markerclusterer';
import mapPinCall from '../mt34-map-pin.html';
import { htmlMapPin } from './html-map-pin';
import { register } from '../../../../../../libs/register';

export class ClusterPinRenderer extends DefaultRenderer {
    constructor(map) {
        super();
        this.map = map;
    }

    /* override */
    render({ count, position, markers }) {
        // deselect all markers of the cluster
        for (const marker of markers) {
            register.getClass(marker.getElem()).setSelected(false);
        }

        const elem = runTemplate(mapPinCall, { aggregator: true, textContent: `${count}` });
        const clusterMapPin = htmlMapPin({
            position: position,
            elem: elem,
            map: this.map,
        });
        register.getClass(elem).setMapParams(this.map, position, clusterMapPin);
        return clusterMapPin;
    }
}
