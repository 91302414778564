import { Mutex } from 'async-mutex';
import { apiProvider } from './api-provider';

class BolliniPdvManager {
    constructor() {
        this.lock = new Mutex();
        this.bollini = null;
        this.bolliniNotCheckPdv = window.bolliniNotCheckPdv || false;
    }

    async isEnabled(pdvPreferito) {
        const release = await this.lock.acquire();
        try {
            const utenzaId = window.initialStore?.userData?.utenzaId;
            if (!utenzaId) return false;

            // check from session
            const sess = localStorage.getItem(`enabled-bollini-${utenzaId}-${pdvPreferito}`);
            if (sess) {
                if (sess == 'enabled') {
                    return true;
                } else if (sess == 'disabled') {
                    return false;
                }
            }

            // check if is enabled
            let enabled = false;
            if (!this.bolliniNotCheckPdv) {
                const enabledPdvs = await apiProvider.getEnabledPdvsBollini();
                if (enabledPdvs.length > 0) enabled |= enabledPdvs.includes(pdvPreferito);
            } else {
                const bollini = await this.getCallBalanceBollini();
                if (bollini) enabled |= true;
            }

            localStorage.setItem(`enabled-bollini-${utenzaId}-${pdvPreferito}`, enabled ? 'enabled' : 'disabled');
            return enabled;
        } catch (e) {
            console.warn('Cannot check if is bollini enabled', e);
        } finally {
            release();
        }
        return false;
    }

    async getCallBalanceBollini() {
        try {
            const bolliniFromCall = await apiProvider.getUserBalanceBollini();
            if (bolliniFromCall && bolliniFromCall != undefined && bolliniFromCall.saldoCumulato != '-1') {
                this.bollini = bolliniFromCall;
                return this.bollini;
            }
        } catch (error) {
            console.error('error during user balance retrieving - ' + error);
        }
        return null;
    }

    getBollini() {
        return this.bollini;
    }
}

const defaultBolliniPdvManage = new BolliniPdvManager();
window.ecDefaultBolliniPdvManage = defaultBolliniPdvManage;
export const bolliniPdvManager = defaultBolliniPdvManage;
