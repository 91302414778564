import WizardComponent from '../../../../../../libs/components/wizard-component';
import { formToJSON } from '../../../../../../libs/form-to-json';
import { register } from '../../../../../../libs/register';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import { delay } from '../../../../../../libs/utils';
import { apiProvider } from '../../../../../../libs/api-provider';
import { flowManager } from '../../../../../../libs/flow-manager';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import { dictionary } from '../../../../../../libs/dictionary-provider';
import './style.scss';

export default class SetNewPsw extends WizardComponent {
    constructor(name, root) {
        super(name, root);

        this.password = this._dEl('password');
        this.passwObj = register.getClass(this.password);
        this.passwordRepeat = this._dEl('passwordRepeat');
        this.passwRepeatObj = register.getClass(this.passwordRepeat);
        this.passwordRules = this._dEl('passwordRules');
        this.passwordMismatch = this._dEl('passwordMismatch');
        this.passwordState = this._dEl('passwordState');
        this.passwordStateOk = this.passwordState.querySelector('.ok');
        this.passwordStateError = this.passwordState.querySelector('.error');

        this.form = this._dEl('form');
        this.submit = this._dEl('submit');

        this.error = this._dEl('error');

        this.okContainer = this._dElMod('container', 'ok');
        this.errorContainer = this._dElMod('container', 'error');

        this._addEventListeners();
        this._addStoreListeners();

        this._runLogic();
    }

    _addEventListeners() {
        this.password.addEventListener('ecInputChanged', () => {
            this._checkShowPasswordRules();
        });
        this.form.addEventListener('ecInputChanged', (event) => {
            /*!event.data.valid
                ? this._disableSubmit()
                : this._checkFormValidity()
                    ? this._enableSubmit()
                    : this._disableSubmit();*/
            this._checkPasswordRepeat();
            event.stopPropagation();
        });
        this.form.addEventListener('submit', (event) => {
            event.preventDefault();
            if (!this.form.checkValidity()) {
                const first = this.form.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
                return;
            }
            /*if (this._submitDisabled()) {
                return;
            }*/
            this._submitNewPsw();
        });

        /* form errors */
        this.form.addEventListener('ecFormError', (event) => {
            event.preventDefault();
            if (!event.data.errorField) return;
            trackEvent(
                TRACKABLE_EVENT.formError, 
                FUNNEL_NAME.recoverPsw, 
                FUNNEL_STEP.setNewPswForm, 
                null, null, null, 
                { 
                    errorField: event.data.errorField,
                    errorText: event.data.errorText,
                }
            );
            event.stopPropagation();
        });
    }

    _addStoreListeners() {}

    _checkFormValidity() {
        if (!this.form.checkValidity()) {
            return false;
        }
        return true;
    }

    _checkShowPasswordRules() {
        if (this.passwObj.getValue()) {
            this.passwordRules.classList.remove(this._elMod('passwordRules', 'hidden'));
        } else {
            this.passwordRules.classList.add(this._elMod('passwordRules', 'hidden'));
        }
        if (this.passwObj.isValid()) {
            this.passwordStateOk.classList.add('active');
            this.passwordStateError.classList.remove('active');
        } else {
            this.passwordStateOk.classList.remove('active');
            this.passwordStateError.classList.add('active');
        }
    }

    _checkPasswordRepeat() {
        if (this.passwObj.getValue() !== '' && 
            this.passwObj.isValid() && 
            this.passwRepeatObj.getValue() !== '' &&
            this.passwRepeatObj.getValue() !== this.passwObj.getValue()) {
            this.passwordMismatch.classList.remove(this._elMod('passwordMismatch', 'hidden'));
            this.passwRepeatObj.setState('error');
            this.passwordRepeat.querySelector('.mt8-textfield__error').style.display = 'none';
            //this._disableSubmit();
        } else {
            this.passwordRepeat.querySelector('.mt8-textfield__error').removeAttribute('style');
            if (!this.passwordMismatch.classList.contains(this._elMod('passwordMismatch', 'hidden')))
                this.passwordMismatch.classList.add(this._elMod('passwordMismatch', 'hidden'));
            if (this.passwRepeatObj.getValue() !== '' && 
                this.passwRepeatObj.getValue() === this.passwObj.getValue()) {
                this.passwRepeatObj.setState('valid');
                //this._enableSubmit();
            }
        }
    }

    /*_enableSubmit() {
        if (this.submit.classList.contains(this._elMod('submit', 'active'))) return;
        this.submit.classList.add(this._elMod('submit', 'active'));
        this.submit.removeAttribute('title');
    }
    async _disableSubmit() {
        this.submit.classList.remove(this._elMod('submit', 'active'));
        this.submit.setAttribute('title', dictionary.getFEMessage('fillInFields'));
    }
    _submitDisabled() {
        return !this.submit.classList.contains(this._elMod('submit', 'active'))
    }*/

    async _submitNewPsw() {
        const data = {
            ...this.params,
            ...formToJSON(this.form)
        };
        try {
            openLoader('main');
            await apiProvider.changePasswordRecReq(data);
            closeLoader('main');
            flowManager.next('psw-recovered');
        } catch (error) {
            const errorText = this.root.dataset.genericError;
            this.error.innerHTML = errorText;
            trackEvent(
                TRACKABLE_EVENT.formError, 
                FUNNEL_NAME.recoverPsw, 
                FUNNEL_STEP.setNewPswForm, 
                null, null, null, 
                { 
                    errorField: dictionary.getFEMessage('formSubmitError'),
                    errorText: errorText,
                }
            );
            this.error.classList.remove(this._elMod('error', 'hidden'));
            closeLoader('main');
        }
    }

    _getParams() {
        const urlParams = new URLSearchParams(window.location.search);
        const params = { ccp: urlParams.get('ccp'), email: urlParams.get('email') };
        return params;
    }

    _showOk() {
        this.okContainer.classList.add(this._elMod('container', 'show'));
    }

    _showError() {
        this.errorContainer.classList.add(this._elMod('container', 'show'));
    }

    async _runLogic() {
        openLoader('main');
        await delay(2000);

        // get and check params
        const params = this._getParams();
        if (!params.ccp || !params.email) {
            console.warn('Invalid request to set new password');
            this._showError();
            closeLoader('main');
            return;
        }

        // validate params
        try {
            await apiProvider.validatePasswordChange(params);
            this._showOk();
            // save valid params
            this.params = params;
        } catch (error) {
            this._showError();
        }

        closeLoader('main');
        /* TODO: understand when type of service, prefstore coop and prefstore id are available (never ?) */
        trackEvent(
            TRACKABLE_EVENT.pageview, 
            FUNNEL_NAME.recoverPsw, 
            FUNNEL_STEP.setNewPswForm
        );
    }
}


