import PopupComponent from '../../../../../../libs/components/popup-component';
import { apiProvider } from '../../../../../../libs/api-provider';
import { storeManager } from '../../../../../../libs/store-manager';
import { emptyElement } from '../../../../../../libs/utils';
import './style.scss';

export default class CloseCCRequest extends PopupComponent {
    constructor(name, root) {
        super(name, root);
        this._doLogic();
    }

    _doLogic() {
        this.reloadCardsOnClose = false;
        this.ctaAction = this._dEl('ctaAction');
        this.successTextEl = this._dEl('successText');
        this.successTextTemplate = this._dEl('successTextTemplate');
        this._addEventListeners();
        this._addStoreListeners();
    }

    _addEventListeners() {
        this.ctaAction?.addEventListener('click', async () => {
            this._showLoading();
            try {
                this.selectedCase = this.request.id;
                await apiProvider.closeCase({ code: this.selectedCase });
                this._showSuccess();
                this.reloadCardsOnClose = true;
            } catch (error) {
                console.warn('Could not close cc request');
                this._hideLoading();
            }
        });
    }

    _addStoreListeners() {
        //intercept flow options and get request data
        this._addStoreListener(/flow\/[^/]+$/g, (path, flow) => {
            if (flow.options.request) this.request = flow.options.request;
            this._hideLoading();
            this._hideSuccess();
        });
    }

    _showLoading() {
        this.root.classList.add(this._mod('loading'));
        this.popup.setUnclosable();
    }

    _hideLoading() {
        this.root.classList.remove(this._mod('loading'));
        this.popup.setUnclosable(false);
    }

    _showSuccess() {
        this._hideLoading();
        this.successTextEl.innerHTML = this.successTextTemplate.innerHTML.replace(
            '{{idSegnalazione}}',
            `<span data-request-id>ID #${this.request.caseNumber}</span>`
        );
        this.root.classList.add(this._mod('success'));
    }

    _hideSuccess() {
        this.root.classList.remove(this._mod('success'));
        emptyElement(this.successTextEl);
    }

    _notifyRequestClosed() {
        storeManager.emit('closedCCRequest', { request: this.request });
    }

    _onClose() {
        super._onClose();
        if (this.reloadCardsOnClose) {
            this._notifyRequestClosed();
        }
    }
}
