/*
 * Copyright 2018 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

/* eslint-disable */

module.exports = function main($) {

  const htl = $.globals;
  let data = $.globals["data"];

  let render;
  render = function* _template_global_render(args) { 
    let title = args[1]['title'] || '';
    let cards = args[1]['cards'] || '';
    let primarylink = args[1]['primaryLink'] || '';
    let secondarylink = args[1]['secondaryLink'] || '';
    let hiddenbyauthor = args[1]['hiddenByAuthor'] || '';
    let $t, $n = args[0];
    $.dom.text($n,"\n\n    ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mc8-cards-icon-text__wrapper", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n\n        \n        ");
    const var_testVariable0 = (!hiddenbyauthor) || (!wcmmode["disabled"]);
    if (var_testVariable0) {
      $.dom.text($n,"\n            ");
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "mc8-cards-icon-text__title", 'attribute');
      $n = $.dom.push($n,$t);
      const var_1 = yield $.xss(title, "html");
      $.dom.append($n, var_1);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n            ");
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "mc8-cards-icon-text__cards", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                \n                ");
      const var_collectionVar2 = $.col.init(cards);
      const var_size3 = $.col.len(var_collectionVar2);
      if (var_size3) {
        for (const var_index4 of $.col.keys(var_collectionVar2)) {
          const card = $.col.get(var_collectionVar2, var_index4);
          const cardlist = $.listInfo(var_index4, var_size3);
          $.dom.text($n,"\n                    ");
          $t = $.dom.create("div",false,false);
          $.dom.attr($t, 'class', "mc8-cards-icon-text__card", 'attribute');
          $n = $.dom.push($n,$t);
          $.dom.text($n,"\n                        ");
          $t = $.dom.create("span",false,false);
          const var_attrValue5 = ("mc8-cards-icon-text__cardIcon ") + (card["icon"]);
          if (!$.col.empty(var_attrValue5)) {
            $.dom.attr($t, 'class', var_attrValue5, 'attribute');
          }
          $n = $.dom.push($n,$t);
          $n = $.dom.pop($n);
          $.dom.text($n,"\n                        ");
          $t = $.dom.create("div",false,false);
          $.dom.attr($t, 'class', "mc8-cards-icon-text__cardContent", 'attribute');
          $n = $.dom.push($n,$t);
          $.dom.text($n,"\n                            ");
          $t = $.dom.create("div",false,false);
          $.dom.attr($t, 'class', "mc8-cards-icon-text__cardTitle", 'attribute');
          $n = $.dom.push($n,$t);
          const var_6 = yield $.xss(card["title"], "html");
          $.dom.append($n, var_6);
          $n = $.dom.pop($n);
          $.dom.text($n,"\n                            ");
          $t = $.dom.create("div",false,false);
          $.dom.attr($t, 'class', "mc8-cards-icon-text__cardText", 'attribute');
          $n = $.dom.push($n,$t);
          const var_7 = yield $.xss(card["text"], "html");
          $.dom.append($n, var_7);
          $n = $.dom.pop($n);
          $.dom.text($n,"\n                        ");
          $n = $.dom.pop($n);
          $.dom.text($n,"\n                    ");
          $n = $.dom.pop($n);
          $.dom.text($n,"\n                ");
        }
      }
      $.dom.text($n,"\n            ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n        ");
    }
    $.dom.text($n,"\n        ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mc8-cards-icon-text__links", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n            \n            ");
    const primarylinkobj = primarylink;
    if (primarylinkobj) {
      $.dom.text($n,"\n                ");
      const var_tagVar8 = yield $.xss(primarylinkobj["element"], "elementNameNoFallback");
      if (var_tagVar8) {
        $t = $.dom.create(var_tagVar8,false,false);
      }
      if (!var_tagVar8) {
        $t = $.dom.create("a",false,false);
      }
      const var_attrMap9 = primarylinkobj["hashMap"];
      const var_attrName_id10 = "id";
      const var_mapContains_id11 = var_attrMap9["id"];
      if (!$.col.empty(var_mapContains_id11)) {
        $.dom.attr($t, var_attrName_id10, var_mapContains_id11, 'attribute');
      }
      if ((var_mapContains_id11) == (null)) {
        const var_attrValue12 = primarylinkobj["uniqueId"];
        if (!$.col.empty(var_attrValue12)) {
          $.dom.attr($t, 'id', var_attrValue12, 'attribute');
        }
      }
      const var_attrValue13 = ("mc8-cards-icon-text__primaryLink ") + (primarylinkobj["classes"]);
      if (!$.col.empty(var_attrValue13)) {
        $.dom.attr($t, 'class', var_attrValue13, 'attribute');
      }
      const var_ignoredAttributes14 = {"id": true, "class": true, };
      for (const var_attrIndex17 of $.col.keys(var_attrMap9)) {
        const var_attrName15 = $.col.get(var_attrMap9, var_attrIndex17);
        const var_attrNameEscaped16 = yield $.xss(var_attrName15, "attributeName");
        if (var_attrNameEscaped16) {
          if (!var_ignoredAttributes14[var_attrName15]) {
            const var_attrContent18 = var_attrMap9[var_attrName15];
            if (!$.col.empty(var_attrContent18)) {
              $.dom.attr($t, var_attrNameEscaped16, var_attrContent18, 'attribute');
            }
          }
        }
      }
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                    ");
      const var_19 = yield $.xss((primarylinkobj["label"]) || ("Continue navigation"), "html");
      $.dom.append($n, var_19);
      $.dom.text($n,"\n                ");
      if (var_tagVar8) {
        $n = $.dom.pop($n);
      }
      if (!var_tagVar8) {
        $n = $.dom.pop($n);
      }
      $.dom.text($n,"\n            ");
    }
    $.dom.text($n,"\n            \n            ");
    const secondarylinkobj = secondarylink;
    if (secondarylinkobj) {
      $.dom.text($n,"\n                ");
      const var_tagVar20 = yield $.xss(secondarylinkobj["element"], "elementNameNoFallback");
      if (var_tagVar20) {
        $t = $.dom.create(var_tagVar20,false,false);
      }
      if (!var_tagVar20) {
        $t = $.dom.create("a",false,false);
      }
      const var_attrMap21 = secondarylinkobj["hashMap"];
      const var_attrName_id22 = "id";
      const var_mapContains_id23 = var_attrMap21["id"];
      if (!$.col.empty(var_mapContains_id23)) {
        $.dom.attr($t, var_attrName_id22, var_mapContains_id23, 'attribute');
      }
      if ((var_mapContains_id23) == (null)) {
        const var_attrValue24 = secondarylinkobj["uniqueId"];
        if (!$.col.empty(var_attrValue24)) {
          $.dom.attr($t, 'id', var_attrValue24, 'attribute');
        }
      }
      const var_attrValue25 = ("mc8-cards-icon-text__secondaryLink ") + (secondarylinkobj["classes"]);
      if (!$.col.empty(var_attrValue25)) {
        $.dom.attr($t, 'class', var_attrValue25, 'attribute');
      }
      const var_ignoredAttributes26 = {"id": true, "class": true, };
      for (const var_attrIndex29 of $.col.keys(var_attrMap21)) {
        const var_attrName27 = $.col.get(var_attrMap21, var_attrIndex29);
        const var_attrNameEscaped28 = yield $.xss(var_attrName27, "attributeName");
        if (var_attrNameEscaped28) {
          if (!var_ignoredAttributes26[var_attrName27]) {
            const var_attrContent30 = var_attrMap21[var_attrName27];
            if (!$.col.empty(var_attrContent30)) {
              $.dom.attr($t, var_attrNameEscaped28, var_attrContent30, 'attribute');
            }
          }
        }
      }
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                    ");
      const var_31 = yield $.xss((secondarylinkobj["label"]) || ("Discover more about the App"), "html");
      $.dom.append($n, var_31);
      $.dom.text($n,"\n                ");
      if (var_tagVar20) {
        $n = $.dom.pop($n);
      }
      if (!var_tagVar20) {
        $n = $.dom.pop($n);
      }
      $.dom.text($n,"\n            ");
    }
    $.dom.text($n,"\n        ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n    ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n");
  };
  $.template('global', 'render', render);

  return $.run(function* () {
    let $t, $n = $.dom.start();
    return $.dom.end();

  });
};
