import ValidableComponent from '../../../../../libs/components/validable-component';
import { dictionary } from '../../../../../libs/dictionary-provider';
import './style.scss';


export default class DatepickerField extends ValidableComponent {
    constructor(name, root) {
        super(name, root);

        this.input = this._dEl('input');
        this.inputTitle = this.input.title;
        this.showCalendar = this._dEl('showCalendar');
        this.error = this._dEl('error');

        //create dContainer
        this.dContainer = document.createElement('div');
        this.dContainer.classList.add('mt23-datepicker-container');
        document.body.appendChild(this.dContainer);

        this._init();
    }

    async _init() {
        const Datepicker = (await import('vanillajs-datepicker')).Datepicker;
        const it = await import('vanillajs-datepicker/locales/it'); 
        Object.assign(Datepicker.locales, it);

        this.datepicker = new Datepicker(this.input, {
            format: 'd-m-yyyy',
            showOnClick: false,
            showOnFocus: false,
            container: this.dContainer,
            autohide: true,
            orientation: 'auto auto',
            language: document.documentElement.lang || 'en',
        });

        this._setAdditionalOptions();
        this.input.title = '';
        this.currentYear = parseInt(new Date().getFullYear());

        this.setValidationContext({
            excludeMinors: this.root.hasAttribute('data-exclude-minors'),
        });
        this._addEventListeners();
    }

    _setAdditionalOptions() {
        if (this.root.hasAttribute('data-future-disabled')) {
            const futureDisabled = (date) => {
                return date.getTime() < new Date().getTime();
            };
            this.datepicker.setOptions({
                beforeShowDay: futureDisabled,
                beforeShowMonth: futureDisabled,
                beforeShowYear: futureDisabled,
                beforeShowDecade: futureDisabled,
            });
        }
        if (this.root.hasAttribute('data-start-from-decades')) {
            this.datepicker.setOptions({
                startView: 3,
            });
        }
    }

    _getInput() {
        return this.input;
    }

    _addEventListeners() {
        ['keyup', 'change', 'input'].forEach((e) => {
            this.input.addEventListener(e, () => {
                this._checkState();
                this._changedInput();
            });
        });
        this.input.addEventListener('changeDate', () => {
            this._checkState();
            this._changedInput();
        });
        this.showCalendar.addEventListener('click', (event) => {
            event.preventDefault();
            this.datepicker.show();
        });
        this.input.addEventListener('focusout', async () => {
            if (this.datepicker.active) return;
            if (this.input.required && this.input.value == '') {
                if (!this.root.classList.contains(this._mod('errorState'))) {
                    this.trackFormError(dictionary.getFEMessage('requiredField'));
                }
                this.setState('error');
                await this._requireField();
            }
            //this._checkState();
            if (this.input.value !== '' && this.root.classList.contains(this._mod('errorState'))) {
                this.trackFormError(this.errorText);
            }
        });

        /* hide default browser error popup on enter pressed */
        this.root.addEventListener(
            'invalid',
            () => {
                if (this.input.required && this.input.value == '') {
                    this.setState('error');
                    this._requireField();
                }
            },
            true
        );
    }

    _requireField() {
        this.setErrorText(dictionary.getFEMessage('requiredField'));
    }

    _setTooltipPosition() {
        this.dContainer.style.top = this._calcTop();
        this.dContainer.style.left = this._calcLeft();
    }

    _calcTop() {
        const rect = this.root.getBoundingClientRect();
        const popHeight = this.dContainer.clientHeight;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        let top = rect.top + scrollTop + rect.height + 8;
        if (top + popHeight > document.documentElement.scrollHeight) {
            const diff = top + popHeight - document.documentElement.scrollHeight;
            top = top - diff - 8;
            top = top >= 0 ? top : 0;
        }
        return top + 'px';
    }

    _calcLeft() {
        const rect = this.root.getBoundingClientRect();
        const popWidth = this.dContainer.clientWidth;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        let left = rect.left + scrollLeft;
        if (left + popWidth > document.documentElement.scrollWidth) {
            const diff = left + popWidth - document.documentElement.scrollWidth;
            left = left - diff - 8;
            left = left >= 0 ? left : 0;
        }
        return left + 'px';
    }

    /* override */
    setErrorText(errorText) {
        super.setErrorText(errorText);
        if (typeof errorText !== 'string' || errorText === '') return;
        this.error.innerText = errorText;
    }

    enable() {
        this.root.classList.remove(this._mod('disabled'));
        this.getInput()?.removeAttribute('disabled');
        this.showCalendar?.removeAttribute('disabled');
    }

    disable() {
        this.root.classList.add(this._mod('disabled'));
        this.getInput()?.setAttribute('disabled', 'true');
        this.showCalendar?.setAttribute('disabled', 'true');
    }

    isDisabled() {
        return this.root.classList.contains(this._mod('disabled'));
    }
}


