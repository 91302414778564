/*
 * Copyright 2018 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

/* eslint-disable */

module.exports = function main($) {

  const htl = $.globals;
  let data = $.globals["data"];

  let render;
  render = function* _template_global_render(args) { 
    let uniqueid = args[1]['uniqueId'] || '';
    let extraclasses = args[1]['extraClasses'] || '';
    let labels = args[1]['labels'] || '';
    let labelsjson = args[1]['labelsJson'] || '';
    let siteicon = args[1]['siteIcon'] || '';
    let statuslabel = args[1]['statusLabel'] || '';
    let createddate = args[1]['createdDate'] || '';
    let request = args[1]['request'] || '';
    let requestjson = args[1]['requestJson'] || '';
    let $t, $n = args[0];
    $.dom.text($n,"\n\n    ");
    $t = $.dom.create("div",false,false);
    const var_attrValue0 = uniqueid;
    if (!$.col.empty(var_attrValue0)) {
      $.dom.attr($t, 'id', var_attrValue0, 'attribute');
    }
    const var_attrValue1 = ("mt40-customer-care-request ") + (extraclasses);
    if (!$.col.empty(var_attrValue1)) {
      $.dom.attr($t, 'class', var_attrValue1, 'attribute');
    }
    const var_attrValue2 = request["id"];
    if (!$.col.empty(var_attrValue2)) {
      $.dom.attr($t, 'data-request-id', var_attrValue2, 'attribute');
    }
    const var_attrValue3 = requestjson;
    if (!$.col.empty(var_attrValue3)) {
      $.dom.attr($t, 'data-request', var_attrValue3, 'attribute');
    }
    const var_attrValue4 = labelsjson;
    if (!$.col.empty(var_attrValue4)) {
      $.dom.attr($t, 'data-labels', var_attrValue4, 'attribute');
    }
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n        ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mt40-customer-care-request__heading", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n            ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mt40-customer-care-request__info", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                ");
    $t = $.dom.create("h3",false,false);
    $.dom.attr($t, 'class', "mt40-customer-care-request__title", 'attribute');
    $n = $.dom.push($n,$t);
    const var_5 = yield $.xss(request["type"] ? request["type"] : request["recordType"], "html");
    $.dom.append($n, var_5);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n                ");
    const var_testVariable6 = request["site"];
    if (var_testVariable6) {
      $t = $.dom.create("p",false,false);
      $.dom.attr($t, 'class', "mt40-customer-care-request__site", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                    ");
      $t = $.dom.create("span",false,false);
      const var_attrValue7 = siteicon;
      if (!$.col.empty(var_attrValue7)) {
        $.dom.attr($t, 'class', var_attrValue7, 'attribute');
      }
      $n = $.dom.push($n,$t);
      $n = $.dom.pop($n);
      const var_8 = yield $.xss(request["site"], "html");
      $.dom.append($n, var_8);
      $.dom.text($n,"\n                ");
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n                ");
    const var_testVariable9 = createddate;
    if (var_testVariable9) {
      $t = $.dom.create("p",false,false);
      $.dom.attr($t, 'class', "mt40-customer-care-request__created", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                    ");
      const var_10 = yield $.xss((labels["createdon"]) || ("Created on"), "html");
      $.dom.append($n, var_10);
      $.dom.text($n,"\n                    ");
      $t = $.dom.create("span",false,false);
      $n = $.dom.push($n,$t);
      const var_11 = yield $.xss(createddate, "html");
      $.dom.append($n, var_11);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n                ");
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n                ");
    const var_testVariable12 = request["status"];
    if (var_testVariable12) {
      $t = $.dom.create("p",false,false);
      $.dom.attr($t, 'class', "mt40-customer-care-request__status", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                    ");
      const var_13 = yield $.xss((labels["requeststatus"]) || ("Request status"), "html");
      $.dom.append($n, var_13);
      $.dom.text($n,"\n                    ");
      $t = $.dom.create("span",false,false);
      const var_attrValue14 = request["status"];
      if (!$.col.empty(var_attrValue14)) {
        $.dom.attr($t, 'data-status', var_attrValue14, 'attribute');
      }
      $n = $.dom.push($n,$t);
      const var_15 = yield $.xss(statuslabel, "html");
      $.dom.append($n, var_15);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n                ");
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n            ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mt40-customer-care-request__buttons", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                ");
    $t = $.dom.create("button",false,false);
    $.dom.attr($t, 'class', "mt40-customer-care-request__btnOpenMob", 'attribute');
    $.dom.attr($t, 'type', "button", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                    ");
    const var_16 = yield $.xss((labels["expandcard"]) || ("Expand card"), "html");
    $.dom.append($n, var_16);
    $.dom.text($n,"\n                ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n                ");
    $t = $.dom.create("button",false,false);
    const var_attrValue17 = (uniqueid) + ("-accBtn");
    if (!$.col.empty(var_attrValue17)) {
      $.dom.attr($t, 'id', var_attrValue17, 'attribute');
    }
    $.dom.attr($t, 'class', "mt40-customer-care-request__btnOpenDsk", 'attribute');
    $.dom.attr($t, 'type', "button", 'attribute');
    const var_attrValue18 = (labels["expandcard"]) || ("Expand card");
    if (!$.col.empty(var_attrValue18)) {
      $.dom.attr($t, 'aria-label', var_attrValue18, 'attribute');
    }
    const var_attrValue19 = (labels["expandcard"]) || ("Expand card");
    if (!$.col.empty(var_attrValue19)) {
      $.dom.attr($t, 'data-label-expand', var_attrValue19, 'attribute');
    }
    const var_attrValue20 = (labels["minimizecard"]) || ("Minimize card");
    if (!$.col.empty(var_attrValue20)) {
      $.dom.attr($t, 'data-label-minimize', var_attrValue20, 'attribute');
    }
    $.dom.attr($t, 'aria-expanded', "false", 'attribute');
    const var_attrValue21 = (uniqueid) + ("-accContent");
    if (!$.col.empty(var_attrValue21)) {
      $.dom.attr($t, 'aria-controls', var_attrValue21, 'attribute');
    }
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                    ");
    $t = $.dom.create("span",false,false);
    $n = $.dom.push($n,$t);
    $n = $.dom.pop($n);
    $t = $.dom.create("span",false,false);
    $.dom.attr($t, 'class', "ec-icon-chevron-down", 'attribute');
    $n = $.dom.push($n,$t);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n                ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n        ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n        ");
    $t = $.dom.create("div",false,false);
    const var_attrValue22 = (uniqueid) + ("-accContent");
    if (!$.col.empty(var_attrValue22)) {
      $.dom.attr($t, 'id', var_attrValue22, 'attribute');
    }
    $.dom.attr($t, 'class', "mt40-customer-care-request__content", 'attribute');
    $.dom.attr($t, 'aria-hidden', "true", 'attribute');
    const var_attrValue23 = (uniqueid) + ("-accBtn");
    if (!$.col.empty(var_attrValue23)) {
      $.dom.attr($t, 'aria-labelledby', var_attrValue23, 'attribute');
    }
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n            \n        ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n    ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n");
  };
  $.template('global', 'render', render);

  return $.run(function* () {
    let $t, $n = $.dom.start();
    $.dom.text($n,"\n");
    return $.dom.end();

  });
};
