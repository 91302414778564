import axios from 'axios';

class EcommerceApiProvider {
    constructor() {
        const serviceLang = document.documentElement.dataset.serviceLang || 'it-it';
        this.baseUrl = `${window.ecommerceDomain}/api/ecommerce/${serviceLang}`;

        this.urlMap = {
            all: this.baseUrl + '.%%.json',
            'order-receipt': this.baseUrl + '.order-receipt.pdf',
        };
    }

    /**
     * Delete cart by id
     * @param {*} param0
     * @returns
     */
    async deleteCart() {
        const data = {};
        return await this._doPost(this._getEndpoint('delete-cart'), data);
    }

    /**
     * Get current cart
     * @returns The user current cart
     */
    async getCart() {
        return await this._doGet(this._getEndpoint('get-cart'));
    }

    /**
     * Get timeslots by store
     * @param {*} param0
     * @returns
     */
    async getTimeslotsByStore({
        dateUTC,
        deliveryAddress,
        numDays,
        pointOfServiceId,
        typeOfService,
        deliveryArea,
        becommerce,
    }) {
        const data = {
            dateUTC,
            deliveryAddress,
            numDays,
            pointOfServiceId,
            typeOfService,
            deliveryArea,
            becommerce,
        };
        return await this._doPost(this._getEndpoint('timeslots'), data);
    }

    /**
     * Get order receipt
     * @param {*} param0
     * @returns
     */
    getOrderReceipt({ orderId, bEcommerce }) {
        return `${this._getEndpoint('order-receipt')}?orderId=${orderId}&bEcommerce=${bEcommerce}`;
    }

    _getEndpoint(name) {
        const url = this.urlMap[name];
        if (url) return url;
        return this.urlMap['all'].replace('%%', name);
    }

    async _doBlobPost(endpoint, body = {}, queryParams) {
        try {
            const params = new URLSearchParams(queryParams);
            const result = await axios.post(endpoint, body, {
                params,
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });
            console.debug(result);
            return result.data;
        } catch (error) {
            console.warn(error);
            throw 'Cannot download blob';
        }
    }

    async _doPost(endpoint, body = {}, queryParams, { unwrap } = { unwrap: true }) {
        try {
            const params = new URLSearchParams(queryParams);
            const result = await axios.post(endpoint, body, {
                params,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });
            console.debug(result);
            if (unwrap) {
                return result?.data?.data;
            } else {
                return result?.data;
            }
        } catch (error) {
            console.warn(error);
            if (unwrap) {
                throw error?.response?.data?.data;
            } else {
                throw error?.response?.data;
            }
        }
    }

    async _doGet(endpoint, queryParams, { unwrap } = { unwrap: true }) {
        try {
            const params = new URLSearchParams(queryParams);
            const result = await axios.get(endpoint, { params, withCredentials: true });
            console.debug(result);
            if (unwrap) {
                return result?.data?.data;
            } else {
                return result?.data;
            }
        } catch (error) {
            console.warn(error);
            if (unwrap) {
                throw error?.response?.data?.data;
            } else {
                throw error?.response?.data;
            }
        }
    }
}

const ecommerceApiProvider = new EcommerceApiProvider();
window.ecApiProvider = ecommerceApiProvider;
export const ecApiProvider = ecommerceApiProvider;
