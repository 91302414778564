import ValidableComponent from '../../../../../libs/components/validable-component';
import { dictionary } from '../../../../../libs/dictionary-provider';
import './style.scss';


export default class Checkbox extends ValidableComponent {
    constructor(name, root) {
        super(name, root);

        this.label = this._dEl('label');
        this.input = this._dEl('input');
        this.error = this._dEl('error');

        this._addEventListeners();
    }

    _getInput() {
        return this.input;
    }

    /* override */
    getValue() {
        return this.input.checked ? this.input.value : null;
    }

    /* override */
    getLabel() {
        return this.label.dataset.label;
    }

    _addEventListeners() {

        this.label.addEventListener('keydown', (event) => {
            if (!(event.key == 'Enter')) return;
            this.label.click();
            this.label.focus();
        });

        this.input.addEventListener('change', (event) => {
            event.preventDefault();
            this._checkState();
            this._changedInput();
        });

        this.root.addEventListener(
            'invalid',
            () => {
                this.setState('error');
                this._requireField();
            },
            true
        );
    }

    _requireField() {
        this.setErrorText(dictionary.getFEMessage('requiredField'));
    }

    isValid() {
        return this.input.required
            ? this.input.validity.valid && !this.input.validity.valueMissing
            : this.input.validity.valid || this.input.validity.valueMissing;
    }

    reset() {
        this.input.checked = false;
        this.setState('');
    }

    /* override */
    setErrorText(errorText) {
        super.setErrorText(errorText);
        if (typeof errorText !== 'string' || errorText === '') return;
        this.error.innerText = errorText;
    }

    enable() {
        this.label.classList.remove(this._elMod('label', 'disabled'));
        this.label.setAttribute('tabindex', '0');
    }

    disable() {
        this.label.classList.add(this._elMod('label', 'disabled'));
        this.label.setAttribute('tabindex', '-1');
    }

    isDisabled() {
        return this.label.classList.contains(this._elMod('label', 'disabled'));
    }
}


