import { getStoreManager } from './store-manager';

export class UserService {
    constructor() {
        this.storeManager = getStoreManager();
    }

    async getUser() {
        await this.storeManager.waitInit();
        return this.storeManager.get('userData');
    }
}

window.rcUserService = new UserService();

/**
 *
 * @returns {UserService} the tracking manager
 */
export const getUserService = () => {
    return window.rcUserService;
};

export const userManager = window.rcUserService;
