import { apiProvider } from '../../../../../../libs/api-provider';
import WizardComponent from '../../../../../../libs/components/wizard-component';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import { delay } from '../../../../../../libs/utils';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import './style.scss';

export default class ConfirmedOperation extends WizardComponent {
    constructor(name, root) {
        super(name, root);

        this.operation = this.root.dataset.name;

        this.okContainer = this._dElMod('container', 'ok');
        this.errorContainer = this._dElMod('container', 'error');
        this.link = this._dEl('link');
        const params = this._getParams();
        if (this.link && params.cb) {
            switch (this.operation) {
                case 'confirmed-profile': {
                    const urlParams = new URLSearchParams({ cb: params.cb });
                    this.link.href = this.link.href + '?' + urlParams.toString();
                    break;
                }
                default: {
                    const url = new URL(params.cb);
                    this.link.href = url.href;
                    break;
                }
            }
        }

        this._addEventListeners();
        this._addStoreListeners();

        this._runLogic();
    }

    _addEventListeners() {}

    _addStoreListeners() {}

    _showOk() {
        this.okContainer.classList.add(this._elMod('container', 'show'));
    }

    _showError() {
        this.errorContainer.classList.add(this._elMod('container', 'show'));
    }

    _getParams() {
        const urlParams = new URLSearchParams(window.location.search);
        const params = {
            cce: urlParams.get('cce'),
            da: urlParams.get('da'),
            a: urlParams.get('a'),
            cca: urlParams.get('cca'),
            socialToken: urlParams.get('socialToken'),
            cb: urlParams.get('cb'),
            updateGccMail: urlParams.get('updateGccMail')
        };
        return params;
    }

    _invalidParams(params, operation) {
        switch (operation) {
            case 'confirmed-profile':
                if (!params.cce || !params.da) {
                    console.warn('Invalid request to confirm profile');
                    this._showError();
                    closeLoader('main');
                    return true;
                }
                break;
            case 'confirmed-social-user-association':
                if (!params.cca || !params.socialToken) {
                    console.warn('Invalid request to confirm social profile association');
                    this._showError();
                    closeLoader('main');
                    return true;
                }
                break;
            case 'confirmed-email-address':
                if (!params.cce || !params.da || !params.a) {
                    console.warn('Invalid request to confirm email change');
                    this._showError();
                    closeLoader('main');
                    return true;
                }
                break;
            case 'confirmed-delete-profile':
                break;
            default:
                console.warn(`Unhandled operation "${this.operation}" in params check`);
                this._showError();
                closeLoader('main');
                return true;
        }
        return false;
    }

    async _runLogic() {
        openLoader('main');
        await delay(2000);

        // get and check params
        const params = this._getParams();
        if (this._invalidParams(params, this.operation)) return;

        // call service
        try {
            switch (this.operation) {
                case 'confirmed-profile': {
                    await apiProvider.confirmProfile(params);
                    trackEvent(
                        TRACKABLE_EVENT.pageview,
                        FUNNEL_NAME.registration,
                        FUNNEL_STEP.confirmProfileActivation
                    );
                    break;
                }
                case 'confirmed-social-user-association': {
                    await apiProvider.confirmSocialUserAssociation(params);
                    trackEvent(TRACKABLE_EVENT.pageview, FUNNEL_NAME.login, FUNNEL_STEP.completedSocialAssociation);
                    break;
                }
                case 'confirmed-email-address': {
                    try {
                        const firmaDigitale = await apiProvider.getFirmaDigitale();
                        params.firmaDigitale = firmaDigitale;
                    } catch (error) {
                        console.warn('Cannot get firma digitale for current user, maybe is not logged');
                    }
                    await apiProvider.confirmEmailAddress(params);
                    break;
                }
                case 'confirmed-delete-profile':
                    trackEvent(TRACKABLE_EVENT.pageview, FUNNEL_NAME.disablingUser, 'Richiesta andata a buon fine');
                    break;
                default:
                    console.warn(`Unhandled operation "${this.operation}" in service call`);
                    closeLoader('main');
                    this._showError();
                    return;
            }
            this._showOk();
        } catch (error) {
            this._showError();
        } finally {
            closeLoader('main');
        }
    }
}


