import { apiProvider } from './api-provider';
import { checkOriginEcommerce } from './utils';
import { checkOriginViaggi } from './utils';
import { Mutex } from 'async-mutex';

class InformativaManager {
    constructor() {
        this.lock = new Mutex();
    }

    async getInformativa(user, pdv, forceEcommerce = false) {
        const release = await this.lock.acquire();
        try {
            // origin Viaggi is the same informative of Ecommerce
            const originEcommerceOrViaggi = forceEcommerce || checkOriginEcommerce() || checkOriginViaggi();

            // if a user has a cooperative but not a card
            let coopUserWithoutCard = (user?.newMappedCooperative != "CND") && !(user?.cartaFedelta ? true : false);

            //show c+ only on registration of dematerialized
            let isCard = user?.dmCard;
            //default coop = "consorzio"
            let coop = user?.codiceCooperativa ? user.codiceCooperativa : 'cnd';
            //if isCard => then use pdv codice cooperativa, we need to show c+ informativa
            if (isCard) {
                coop = pdv?.codiceCooperativa ? pdv.codiceCooperativa : coop;
            }

            // if is default --> return directly
            if (coop.toLowerCase() == 'cnd' && !originEcommerceOrViaggi && !isCard) {
                return window.informativa;
            }

            // get informativa from backend
            try {
                return await apiProvider.getInformativa({
                    cooperativa: coop.toLowerCase(), // force cooperativa lowercase
                    ecommerce: originEcommerceOrViaggi || '0',
                    card: isCard,
                    coopUserWithoutCard: coopUserWithoutCard
                });
            } catch (error) {
                console.warn(
                    `Missing informativa for combination coop: ${coop} - ec: ${originEcommerceOrViaggi} - card: ${isCard} - coopUserWithoutCard: ${coopUserWithoutCard}, calling informative for users with card`
                );
            }

            return window.informativa;
        } finally {
            release();
        }
    }

    getInformativaLinkByUser(user, card = false, forceEcommerce = false) {
        let coop = user?.newMappedCooperative ? user.newMappedCooperative : 'cnd';
        let coopUserWithoutCard = (user?.newMappedCooperative != "CND") && !(user?.cartaFedelta ? true : false);
        return this.getInformativaLink(coop, card, coopUserWithoutCard, forceEcommerce);
    }

    getInformativaLink(coop = 'cnd', card = false, coopUserWithoutCard = false, forceEcommerce = false) {
        const ecommerce = forceEcommerce || checkOriginEcommerce() || checkOriginViaggi();
        return apiProvider.getPrivacyLink(coop, card, coopUserWithoutCard, ecommerce);
    }
}

const defaultInformativaManager = new InformativaManager();
window.ecInformativaManager = defaultInformativaManager;
export const informativaManager = defaultInformativaManager;
