import Component from '../../../../../libs/components/component';
import { dictionary } from '../../../../../libs/dictionary-provider';
import './style.scss';

export default class Store extends Component {
    constructor(name, root) {
        super(name, root);
    }

    setSelected() {
        this.root.classList.add(this._mod('selected'));
        this.root.setAttribute('aria-pressed', 'true');
    }

    unsetSelected() {
        this.root.classList.remove(this._mod('selected'));
        this.root.setAttribute('aria-pressed', 'false');
    }

    static getStoreData(uniqueId, extraClasses, store) {
        let title = null;
        let address = null;
        try {
            title = store.descrizioneInsegna
                .replace(/(\B)[^ ]*/g, (match) => match.toLowerCase())
                .replace(/^[^ ]/g, (match) => match.toUpperCase());
        } catch (error) {
            title = store.descrizioneInsegna;
        }
        try {
            address = `${store.indirizzo}, ${store.cap} ${store.nomeComune}`
                .replace(/(\B)[^ ]*/g, (match) => match.toLowerCase())
                .replace(/^[^ ]/g, (match) => match.toUpperCase());
        } catch (error) {
            `${store.indirizzo}, ${store.cap} ${store.nomeComune}`;
        }

        const res = {
            uniqueId: uniqueId,
            extraClasses: extraClasses,
            name: store.anacanId,
            title: title,
            address: address,
            icon: 'store',
            cartaDematerializzata: store.cartaDematerializzata,
            labelEnableStoreIssueCard: dictionary.get('Enabled to issue of Carta Insieme')
        };

        if (store.distanza)
            res['distance'] = `${store.distanza.toLocaleString('it-IT', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            })} km`;
        return res;
    }
}


