import Component from '../../../../../../libs/components/component';
import { runTemplate } from '../../../../../../libs/htl-runtime/HTMLRuntime';
import contentTpl from './content.html';
import './style.scss';


export default class CardsIconText extends Component {
    constructor(name, root) {
        super(name, root);
        this._doLogic();
    }

    _doLogic() {
        try {
            this.configurationMap = JSON.parse(this.root.dataset.configurationMap);
            console.log('configurationMap cards', this.configurationMap);
        } catch (error) {
            console.warn('Could not parse configuration map');
            return;
        }
        this._addStoreListeners();
    }

    _addStoreListeners() {
        this._addStoreListener('touchpointParams', (path, data) => {
            this._renderContent(data);
            this._updatePrimaryLinkHref();
        });
    }

    _renderContent(touchpointParams) {
        const regType = touchpointParams.regType || 'standard';
        const domain = touchpointParams.domain || 'myconad';
        if (regType == 'standard' && !window.isAuthor) {
            // no card (light user) ==> do NOT show
            return;
        }
        const contentData = {
            ...this.configurationMap[domain],
        };
        const contentEl = runTemplate(contentTpl, contentData, this._el('wrapper', true));
        this.root.append(contentEl);
    }

    _updatePrimaryLinkHref() {
        const primaryLink = this._dEl('primaryLink');
        const urlParams = new URLSearchParams(window.location.search);
        const params = new URLSearchParams({ cb: urlParams.get('cb') });
        primaryLink.href = primaryLink.href + '?' + params.toString();
    }
}
