import PopupComponent from '../../../../../../libs/components/popup-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import './style.scss';

export default class GccCheckEmail extends PopupComponent {
    constructor(name, root) {
        super(name, root);
        this.ctaAction = this._dEl('ctaAction');
        this.ctaCancel = this._dEl('ctaCancel');

        this._addEventListeners();
    }

    _addEventListeners() {
        this.ctaAction.addEventListener('click', (event) => {
            event.preventDefault();
            this._changeMail(true);
        });

        this.ctaCancel.addEventListener('click', (event) => {
            event.preventDefault();
            this._changeMail(false);
        });
    }

    _changeMail(withGcc = false) {
        flowManager.appendDataToFlow({ updateGccMail: withGcc })

        // if in registration, complete flow
        const flowName = flowManager.getCurrentFlowName();
        if(flowName == "checkGccMailRegistration"){
            flowManager.complete();
            return;
        }

        // if in change mail, continue to next step
        flowManager.next("mp19-email-change-proceed-gcc");
    }

    _onCancel() {
        flowManager.backOrComplete();
    }
}


