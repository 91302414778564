import ValidableComponent from '../../../../../libs/components/validable-component';
import { dictionary } from '../../../../../libs/dictionary-provider';
import { gMapsHelper } from '../../../../../libs/gmaps-helper';
import { storeManager } from '../../../../../libs/store-manager';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../libs/tracking-manager-old';
import { debounce, delay, getNextFormFocusableElement } from '../../../../../libs/utils';
import { closeLoader, openLoader } from '../mt11-loader/script';
import './style.scss';

export default class AddressGoogle extends ValidableComponent {
    constructor(name, root) {
        super(name, root);

        this.TYPE_NO_STREET = [
            'locality',
            'sublocality',
            'postal_code',
            'country',
            'administrative_area_level_1',
            'administrative_area_level_2',
            'administrative_area_level_3',
        ];

        this.input = this._dEl('input');
        this.searchBtn = this._dEl('searchBtn');
        this.cancel = this._dEl('cancel');
        this.error = this._dEl('error');
        this.detectLocationContainer = this._dEl('detectLocationContainer');
        this.detectLocationItem = this._dEl('detectLocationItem');
        this.civico = this._dEl('civico');
        this.civicoInput = this.civico?.querySelector('input');

        this.country = this.root.dataset.country;
        this.checkStreet = this.root.dataset.checkStreet == 'true';
        this.value = null;
        this.searchType = null;

        if (this.country.indexOf(',') >= 0) {
            this.country = this.country.split(',');
        }

        this._initGoogleAutocomplete();
        this._hideCivico();
        this._addEventListeners();
    }

    async _initGoogleAutocomplete() {
        const gmaps = await gMapsHelper.getGMaps();
        this.geocoder = new gmaps.Geocoder();
        const options = {
            componentRestrictions: {
                country: this.country,
            },
        };
        if (this.checkStreet) options.types = ['address'];
        this.autocomplete = new gmaps.places.Autocomplete(this.input, options);
        const placeChanged = async () => {
            const currentPlace = this.autocomplete.getPlace();
            if (!(await this._manageState(currentPlace))) return;
            this.value = currentPlace;
            this._changedInput();
            this.searchType = 'Geolocalizzazione';
            storeManager.emit('mt2AddressSearchType', { searchType: this.searchType });
            this._trackRegistrationFunnel(TRACKABLE_EVENT.searchAddress, FUNNEL_STEP.searchAddress, {
                type: this.searchType,
                address: currentPlace.formatted_address,
            });
            this._updateInputs(currentPlace);
            this._toggleCancelAddress();
        };
        this.autocompleteListener = gmaps.event.addListener(this.autocomplete, 'place_changed', placeChanged);
    }

    _hideCivico() {
        this.root.classList.remove(this._mod('showCivico'));
        this.civicoInput?.setAttribute('aria-hidden', 'true');
        this.civicoInput?.setAttribute('tabindex', '-1');
    }

    _showCivico() {
        this.root.classList.add(this._mod('showCivico'));
        this.civicoInput?.setAttribute('tabindex', '0');
        this.civicoInput?.setAttribute('aria-hidden', 'false');
    }

    _addEventListeners() {
        ['input', 'change'].forEach((e) => {
            this.input.addEventListener(e, () => {
                if (this.input.value === '' && !this.cancel.classList.contains(this._elMod('cancel', 'hidden')))
                    this.cancel.classList.add(this._elMod('cancel', 'hidden'));

                if (this.input.value != '') {
                    this.error.innerText = '';
                    this._showCancelAddress();
                    this._hideCivico();
                }
            });
        });

        ['input', 'change', 'focus'].forEach((e) => {
            this.input.addEventListener(e, () => {
                this._showDetectLocation(this.input.value === '');
            });
        });

        this.detectLocationItem?.addEventListener('click', () => {
            this._detectLocation();
        });

        this.input.addEventListener('keydown', async (event) => {
            if (!(event.key == 'Enter')) return;

            //do search
            await this._manualSearch();

            //go to next focusable element when enter pressed
            event.preventDefault();
            const next = getNextFormFocusableElement(event.target);
            if (next) next.focus();
        });

        this.searchBtn.addEventListener('click', () => {
            this._manualSearch();
        });

        this.cancel.addEventListener('click', (event) => {
            event.preventDefault();
            this._trackRegistrationFunnel(TRACKABLE_EVENT.deleteAddress, FUNNEL_STEP.deleteAddress, {
                type: this.searchType || '',
                address: this.value?.formatted_address || this.input.value,
            });
            this.reset();
            this._hideCivico();
            this._changedInput();
            this.root.dispatchEvent(new CustomEvent('cancelledAddress'));
        });

        this.input.addEventListener('focusout', async () => {
            /* add little delay because focusout preceeds click on detectLocationItem */
            await delay(100);
            this._showDetectLocation(false);
            if (this.input.required && this.input.value == '') {
                if (!this.root.classList.contains(this._mod('errorState'))) {
                    this.trackFormError(dictionary.getFEMessage('requiredField'));
                }
                this.setState('error');
                this._requireField();
            }
            if (this.input.value !== '' && this.value == null) {
                if (!this.root.classList.contains(this._mod('errorState'))) {
                    this.trackFormError(dictionary.getFEMessage(`${this.getName()}Invalid`));
                }
                this.setState('error');
                this._invalidField();
            }
        });

        this._addListener(
            'resize',
            () => {
                if (this.cancel.classList.contains(this._elMod('cancel', 'hidden'))) return;
                this._updateInputPaddingRight();
            },
            window
        );

        /* debounced manual search */
        const manualSearchDebounced = debounce(() => {
            this._manualSearch();
        }, 500);
        this.civico.addEventListener('ecInputChanged', (event) => {
            if (!event.data.valid) return;
            manualSearchDebounced();
        });
    }

    _updateInputs(currentPlace) {
        this.input.value = this._getFormattedAddress(currentPlace);
        let civicoFromPlace = null;
        if (this._isTypeStreetAddress(currentPlace.types)) {
            civicoFromPlace = this._getAddressComp(currentPlace.address_components, 'street_number', 'short_name');
            this.civicoInput.value = civicoFromPlace;
        } else {
            this.civicoInput.value = '';
        }
        this._showCivico();
        this.civicoInput?.focus();
    }

    _showDetectLocation(show = true) {
        if (!this.detectLocationContainer) return;
        if (show) {
            if (!this.detectLocationContainer.classList.contains(this._elMod('detectLocationContainer', 'active')))
                this.detectLocationContainer.classList.add(this._elMod('detectLocationContainer', 'active'));
        } else {
            this.detectLocationContainer.classList.remove(this._elMod('detectLocationContainer', 'active'));
        }
    }

    _detectLocation() {
        if (window.navigator.geolocation) {
            openLoader('main');
            window.navigator.geolocation.getCurrentPosition(
                (pos) => {
                    this._locationDetectionSuccess(pos);
                },
                (error) => {
                    this._locationDetectionFailed(error);
                },
                {
                    maximumAge: 3600000 /* 1 hour */,
                    timeout: 3000 /* 3 seconds */,
                    enableHighAccuracy: true,
                }
            );
            closeLoader('main');
        } else {
            console.warn('geolocation not found in navigator object');
        }
    }

    _locationDetectionSuccess(pos) {
        const coords = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
        };
        this.geocoder.geocode({ location: coords }, async (results, status) => {
            if (status === 'OK') {
                const place = results[0];
                this.input.value = place.formatted_address;
                if (!(await this._manageState(place))) {
                    return;
                }
                this.value = place;
                this._changedInput();
                this.searchType = 'Geolocalizzazione';
                storeManager.emit('mt2AddressSearchType', { searchType: this.searchType });
                this._trackRegistrationFunnel(TRACKABLE_EVENT.searchAddress, FUNNEL_STEP.searchAddress, {
                    type: this.searchType,
                    address: place.formatted_address,
                });
                this._toggleCancelAddress();
            } else {
                console.warn(`Geocoder failed with code ${status}`);
            }
        });
    }
    _locationDetectionFailed(error) {
        switch (error.code) {
            case window.GeolocationPositionError.PERMISSION_DENIED:
                console.warn(`Geolocation permission denied. ${error.message}`);
                break;
            case window.GeolocationPositionError.POSITION_UNAVAILABLE:
                console.warn(`Geolocation position not available. ${error.message}`);
                break;
            case window.GeolocationPositionError.TIMEOUT:
                console.warn(`Geolocation request went in timeout. ${error.message}`);
                break;
            default:
                console.warn(`Unknown error ${error.code}: ${error.message}`);
                break;
        }
    }

    _requireField() {
        this.error.innerText = dictionary.getFEMessage('requiredField');
    }

    _invalidField(customText = '') {
        this.error.innerText = customText !== '' ? customText : dictionary.getFEMessage(`${this.getName()}Invalid`);
    }

    _toggleCancelAddress() {
        if (this.input.value !== '') {
            this._showCancelAddress();
        } else {
            this._hideCancelAddress();
        }
    }

    _hideCancelAddress() {
        if (this.cancel.classList.contains(this._elMod('cancel', 'hidden'))) return;
        this.cancel.classList.add(this._elMod('cancel', 'hidden'));
        this.cancel.setAttribute('tabindex', '-1');
        this.input.style.paddingRight = '1rem';
        this.input.style.overflow = 'unset';
    }

    _showCancelAddress() {
        this.cancel.classList.remove(this._elMod('cancel', 'hidden'));
        this.cancel.setAttribute('tabindex', '0');
        this._updateInputPaddingRight();
        this.input.style.overflow = 'hidden';
        this.input.style.textOverflow = 'ellipsis';
    }

    _updateInputPaddingRight() {
        const BREAKPOINT_M = 768;
        const CIVICO_WIDTH_MOB = 100;
        const CIVICO_WIDTH_DSK = 115;
        const civicoWidth = window.innerWidth < BREAKPOINT_M ? CIVICO_WIDTH_MOB : CIVICO_WIDTH_DSK;
        const paddingFromText = 8;
        const paddingFromCancel = 48;
        this.input.style.paddingRight = `${paddingFromCancel + civicoWidth + paddingFromText}px`;
    }

    _trackRegistrationFunnel(event, funnelStep, { type, address }) {
        const typeOfService = storeManager.get('ecRegistrationData')?.typeOfService;
        const originEcommerce = storeManager.get('ecRegistrationData')?.originEcommerce;
        const search = { type, address };
        if (storeManager.get('currentFlow').includes('conad-register')) {
            if (originEcommerce && typeOfService) {
                trackEvent(event, FUNNEL_NAME.registration, funnelStep, typeOfService || '', search);
            } else {
                trackEvent(event, FUNNEL_NAME.registration, funnelStep, '', search);
            }
        }
    }

    _manualSearch() {
        const address =
            this.value && this.civicoInput.value
                ? this._getFormattedAddress(this.value, this.civicoInput.value)
                : this.input.value;
        const geocodeRequest = {
            address: address,
            componentRestrictions: {
                country: this.country,
            },
        };
        this.geocoder.geocode(geocodeRequest, async (results, status) => {
            if (status != window.google.maps.GeocoderStatus.OK || !results || results.length <= 0) {
                console.warn('No place found with geocoder for the given place id');
                return;
            }
            const place = results[0];
            if (!(await this._manageState(place))) {
                return;
            }
            this.value = place;
            this._changedInput();
            this.searchType = 'Manuale';
            storeManager.emit('mt2AddressSearchType', { searchType: this.searchType });
            this._trackRegistrationFunnel(TRACKABLE_EVENT.searchAddress, FUNNEL_STEP.searchAddress, {
                type: this.searchType,
                address: place.formatted_address,
            });
            console.log('manual place', place);
            this._updateInputs(place);
            this._toggleCancelAddress();
        });
    }

    async _manageState(currentPlace) {
        if (!currentPlace || !currentPlace.types || !currentPlace.address_components) {
            /* null address or with wrong country (default invalid error text) */
            await this._invalidField();
            this.trackFormError();
            return false;
        }
        if (this.checkStreet && !this._isTypeStreetAddress(currentPlace.types)) {
            /* address without street or street number */
            this.setState('error');
            if (currentPlace.types.indexOf('route') == -1) {
                /* address without street (default invalid error text) */
                await this._invalidField();
                this.trackFormError();
                return false;
            }
            /* address with street but without street number (custom error text) */
            await this._invalidField(dictionary.getFEMessage('insertStreetNumber'));
        } else {
            this.setState('valid');
        }
        return true;
    }

    _getAddressComp(comps, type, field) {
        const comp = comps.filter((cmp) => cmp.types.includes(type))[0];
        return comp ? comp[field] : '';
    }

    _getFormattedAddress(currentPlace, street_number = '') {
        if (!currentPlace || !currentPlace.types || !currentPlace.address_components) {
            return '';
        }
        const route = this._getAddressComp(currentPlace.address_components, 'route', 'short_name');
        const postal_code = this._getAddressComp(currentPlace.address_components, 'postal_code', 'short_name');
        const locality = this._getAddressComp(currentPlace.address_components, 'locality', 'short_name');
        const country_long = this._getAddressComp(currentPlace.address_components, 'country', 'long_name');
        return (
            route +
            (street_number !== '' ? `, ${street_number}` : '') +
            (postal_code !== '' ? `, ${postal_code}` : '') +
            (locality !== '' ? `, ${locality}` : '') +
            (country_long !== '' ? `, ${country_long}` : '')
        );
    }

    _getInput() {
        return this.input;
    }

    getValue() {
        return this.value;
    }

    isValid() {
        return !this._getInput().required || this.value;
    }

    reset() {
        this._getInput().value = '';
        this.civicoInput.value = '';
        this._hideCancelAddress();
        this._hideCivico();
        this.value = null;
        this.setState('');
    }

    _isTypeStreetAddress(types) {
        if (types && this.TYPE_NO_STREET.some((type) => types.indexOf(type) != -1) ? false : true) {
            return true;
        } else {
            return false;
        }
    }
}
