import { apiProvider } from '../../../../../../libs/api-provider';
import PopupComponent from '../../../../../../libs/components/popup-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { FUNNEL_NAME, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import './style.scss';

export default class DeleteProfile extends PopupComponent {
    constructor(name, root) {
        super(name, root);

        this.ctaAction = this._dEl('ctaAction');
        this.thankYouPageUrl = this.root.dataset.thankYouPageUrl;
        if (!this.thankYouPageUrl) {
            console.warn('Could not get thankYouPageUrl.');
            return;
        }

        this._addEventListeners();
    }

    _onOpen() {
        super._onOpen();

        trackEvent(TRACKABLE_EVENT.pageview, FUNNEL_NAME.disablingUser, 'Banner disattivazione utenza');
    }

    _addEventListeners() {
        this.ctaAction.addEventListener('click', (event) => {
            event.preventDefault();
            this._deleteProfile();
        });
    }

    async _deleteProfile() {
        try {
            openLoader('main');

            trackEvent('clickBannerEliminaProfilo', FUNNEL_NAME.disablingUser, 'Click su banner elimina profilo');

            const result = await apiProvider.deleteUser();
            if (result) {
                // redirect to thank you page
                window.location = this.thankYouPageUrl;
            }
        } catch (error) {
            console.warn(error);
        } finally {
            closeLoader('main');
        }
    }

    _onCancel() {
        flowManager.backOrComplete();
    }
}
