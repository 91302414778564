import Component from '../../../../../libs/components/component';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../libs/tracking-manager-old';
import { copyText } from '../../../../../libs/utils';
import './style.scss';

export default class CouponInstructions extends Component {
    constructor(name, root) {
        super(name, root);
        this.copyTriggerer = this._dEl('onlineShoppingCode');
        this.code = this._dEl('instructionsCouponCode');
        this.SHOW_TOOLTIP = this._mod('showTooltip');
        this._addEventListeners();
    }

    _addEventListeners() {
        if (this._dEl('instructionsOnlineShoppingCode')) {
            this._dEl('instructionsOnlineShoppingCode').addEventListener('click', (event) => {
                event.preventDefault();
                trackEvent(TRACKABLE_EVENT.copyCouponCode, FUNNEL_NAME.couponsWallet, FUNNEL_STEP.copyCouponCode);
                copyText(this._dEl('instructionsCouponCode').textContent);
                this.root.classList.add(this._elMod('instructions', 'showTooltip'));
                setTimeout(() => this.root.classList.remove(this._elMod('instructions', 'showTooltip')), 6300);
            });
        }

        if (this._dEl('instructionsTravelCode')) {
            this._dEl('instructionsTravelCode').addEventListener('click', (event) => {
                event.preventDefault();
                trackEvent(TRACKABLE_EVENT.copyCouponCode, FUNNEL_NAME.couponsWallet, FUNNEL_STEP.copyCouponCode);
                copyText(this._dEl('instructionsTravelCode').textContent);
                this.root.classList.add(this._elMod('instructions', 'showTooltip'));
                setTimeout(() => this.root.classList.remove(this._elMod('instructions', 'showTooltip')), 6300);
            });
        }
    }
}
