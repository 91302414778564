import Component from '../../../../../libs/components/component';
import './style.scss';

export default class Widget extends Component {
    constructor(name, root) {
        super(name, root);
        this.start = this.root.dataset.start;
        this.end = this.root.dataset.end;

        this.startDate = new Date(this.start);
        this.endDate = new Date(this.end);

        this.today = new Date();

        if ((this.today >= this.startDate && this.today <= this.endDate) || 'wcmmode' in this.root.dataset) {
            this.root.classList.remove(this._el('notValid'));
        }
    }
}
