import PopupComponent from '../../../../../../libs/components/popup-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import { checkOriginEcommerce } from '../../../../../../libs/utils';
import './style.scss';

export default class CheckEmail extends PopupComponent {
    constructor(name, root) {
        super(name, root);

        this.textPsw = this._dElMod('text', 'psw');
        this.textEmail = this._dElMod('text', 'email');
        this.textSocial = this._dElMod('text', 'social');
        this.ctaAction = this._dEl('ctaAction');

        this.originEcommerce = checkOriginEcommerce();
        this._addEventListeners();
    }

    /* override */
    _onOpen() {
        super._onOpen();
        if (this.originEcommerce) {
            this.typeOfService = window.accessInfo.typeOfService;
            this.pointOfServiceId = window.accessInfo.pointOfServiceId;
            this.cooperativeId = window.accessInfo.cooperativeId;
            this.userInfo = null;
            if (this.pointOfServiceId && this.cooperativeId) {
                this.userInfo = {
                    coop: this.cooperativeId,
                    store: this.pointOfServiceId,
                };
            }
        }

        // load flow data
        const checkEmailOperation = flowManager.getOptionsFromFlow()?.checkEmailOperation;

        /* track pageview based on checkEmailOperation */
        if (!checkEmailOperation) {
            this._showSocialText();
            return;
        }

        switch (checkEmailOperation) {
            case 'changedEmail':
                this._showEmailText();
                break;
            case 'changedPsw':
                this._showPswText();
                break;
            default:
                this._showSocialText();
                break;
        }
    }

    _addEventListeners() {
        this.ctaAction.addEventListener('click', (event) => {
            event.preventDefault();
            flowManager.complete();
        });
    }

    _showEmailText() {
        this.textEmail.classList.remove(this._elMod('text', 'hidden'));
    }

    _showPswText() {
        this.textPsw.classList.remove(this._elMod('text', 'hidden'));
        if (this.originEcommerce) {
            trackEvent(
                TRACKABLE_EVENT.pageview,
                FUNNEL_NAME.recoverPsw,
                FUNNEL_STEP.confirmSendingResetPswEmail,
                this.typeOfService,
                null,
                this.userInfo
            );
        } else {
            trackEvent(TRACKABLE_EVENT.pageview, FUNNEL_NAME.recoverPsw, FUNNEL_STEP.confirmSendingResetPswEmail);
        }
    }

    _showSocialText() {
        this.textSocial.classList.remove(this._elMod('text', 'hidden'));
    }

    _onCancel() {
        flowManager.complete();
    }
}
