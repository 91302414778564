/*
 * Copyright 2018 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

/* eslint-disable */

module.exports = function main($) {

  const htl = $.globals;
  let data = $.globals["data"];

  (function _template_src_main_webpack_repository_apps_conad_myconad_templates_mt3_store_mt3_store_html(){ 
    let render;
    render = function* _template_src_main_webpack_repository_apps_conad_myconad_templates_mt3_store_mt3_store_html_render(args) { 
      let uniqueid = args[1]['uniqueId'] || '';
      let extraclasses = args[1]['extraClasses'] || '';
      let name = args[1]['name'] || '';
      let title = args[1]['title'] || '';
      let address = args[1]['address'] || '';
      let icon = args[1]['icon'] || '';
      let infos = args[1]['infos'] || '';
      let badge = args[1]['badge'] || '';
      let distance = args[1]['distance'] || '';
      let selected = args[1]['selected'] || '';
      let cartadematerializzata = args[1]['cartaDematerializzata'] || '';
      let labelenablestoreissuecard = args[1]['labelEnableStoreIssueCard'] || '';
      let $t, $n = args[0];
      $.dom.text($n,"\n    ");
      $t = $.dom.create("div",false,false);
      const var_attrValue0 = (uniqueid) + ("-store");
      if (!$.col.empty(var_attrValue0)) {
        $.dom.attr($t, 'id', var_attrValue0, 'attribute');
      }
      const var_attrValue1 = ("mt3-store ") + (extraclasses) + (" ") + (selected ? "mt3-store--selected" : "");
      if (!$.col.empty(var_attrValue1)) {
        $.dom.attr($t, 'class', var_attrValue1, 'attribute');
      }
      $.dom.attr($t, 'tabindex', "0", 'attribute');
      const var_attrValue2 = selected ? "true" : "false";
      if (!$.col.empty(var_attrValue2)) {
        $.dom.attr($t, 'aria-pressed', var_attrValue2, 'attribute');
      }
      const var_attrValue3 = (title) + (", ") + (address);
      if (!$.col.empty(var_attrValue3)) {
        $.dom.attr($t, 'aria-label', var_attrValue3, 'attribute');
      }
      const var_attrValue4 = name;
      if (!$.col.empty(var_attrValue4)) {
        $.dom.attr($t, 'data-store-name', var_attrValue4, 'attribute');
      }
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n            ");
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "mt3-store__wrapperData", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                ");
      $t = $.dom.create("div",false,false);
      const var_attrValue5 = ("mt3-store__img mt3-store__img--") + (icon);
      if (!$.col.empty(var_attrValue5)) {
        $.dom.attr($t, 'class', var_attrValue5, 'attribute');
      }
      $n = $.dom.push($n,$t);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n                ");
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "mt3-store__center", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                    ");
      $t = $.dom.create("h3",false,false);
      $.dom.attr($t, 'class', "mt3-store__title", 'attribute');
      $n = $.dom.push($n,$t);
      const var_6 = yield $.xss(title, "html");
      $.dom.append($n, var_6);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n                    ");
      $t = $.dom.create("p",false,false);
      $.dom.attr($t, 'class', "mt3-store__address", 'attribute');
      $n = $.dom.push($n,$t);
      const var_7 = yield $.xss(address, "html");
      $.dom.append($n, var_7);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n                    ");
      const var_testVariable10 = infos;
      const var_collectionVar8 = $.col.init(infos);
      const var_size9 = $.col.len(var_collectionVar8);
      if (var_size9) {
        if (var_testVariable10) {
          $t = $.dom.create("div",false,false);
          $.dom.attr($t, 'class', "mt3-store__infos", 'attribute');
          $n = $.dom.push($n,$t);
          for (const var_index11 of $.col.keys(var_collectionVar8)) {
            const item = $.col.get(var_collectionVar8, var_index11);
            const itemlist = $.listInfo(var_index11, var_size9);
            $.dom.text($n,"\n                        ");
            $t = $.dom.create("p",false,false);
            $.dom.attr($t, 'class', "mt3-store__info", 'attribute');
            $n = $.dom.push($n,$t);
            const var_12 = yield $.xss(item, "html");
            $.dom.append($n, var_12);
            $n = $.dom.pop($n);
            $.dom.text($n,"\n                    ");
          }
          $n = $.dom.pop($n);
        }
      }
      $.dom.text($n,"\n                    ");
      const var_testVariable13 = badge;
      if (var_testVariable13) {
        $t = $.dom.create("div",false,false);
        const var_attrValue14 = ("mt3-store__badge mt3-store__badge--") + (badge["type"]);
        if (!$.col.empty(var_attrValue14)) {
          $.dom.attr($t, 'class', var_attrValue14, 'attribute');
        }
        $n = $.dom.push($n,$t);
        const var_15 = yield $.xss(badge["text"], "html");
        $.dom.append($n, var_15);
        $n = $.dom.pop($n);
      }
      $.dom.text($n,"\n                ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n                ");
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "mt3-store__right", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                    ");
      const var_testVariable16 = distance;
      if (var_testVariable16) {
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "mt3-store__distance", 'attribute');
        $n = $.dom.push($n,$t);
        const var_17 = yield $.xss(distance, "html");
        $.dom.append($n, var_17);
        $n = $.dom.pop($n);
      }
      $.dom.text($n,"\n                ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n            ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n            ");
      const var_testVariable18 = cartadematerializzata;
      if (var_testVariable18) {
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "mt3-store__informativeText", 'attribute');
        $n = $.dom.push($n,$t);
        $.dom.text($n,"\n                ");
        $t = $.dom.create("span",false,false);
        $.dom.attr($t, 'class', "ec-icon-info", 'attribute');
        $n = $.dom.push($n,$t);
        $n = $.dom.pop($n);
        const var_19 = yield $.xss(labelenablestoreissuecard, "html");
        $.dom.append($n, var_19);
        $.dom.text($n,"\n            ");
        $n = $.dom.pop($n);
      }
      $.dom.text($n,"\n    ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n");
    };
    $.template('src/main/webpack/repository/apps/conad-myconad/templates/mt3-store/mt3-store.html', 'render', render);
  })();

  return $.run(function* () {
    let $t, $n = $.dom.start();
    $.dom.text($n,"\n");
    let store = $.template('src/main/webpack/repository/apps/conad-myconad/templates/mt3-store/mt3-store.html');
    $.dom.text($n,"  \n");
    yield $.call(store["render"], [$n, {"uniqueId": data["uniqueId"], "extraClasses": data["extraClasses"], "name": data["name"], "title": data["title"], "address": data["address"], "icon": data["icon"], "infos": data["infos"], "badge": data["badge"], "distance": data["distance"], "selected": data["selected"], "cartaDematerializzata": data["cartaDematerializzata"], "labelEnableStoreIssueCard": data["labelEnableStoreIssueCard"], }]);
    $.dom.text($n,"\n");
    return $.dom.end();

  });
};
