import Component from '../../../../../../libs/components/component';
import { register } from '../../../../../../libs/register';
import { openLoader, closeLoader } from '../../../templates/mt11-loader/script';
import { apiProvider } from '../../../../../../libs/api-provider';
import './style.scss';

export default class FavoritesFlyer extends Component {
    constructor(name, root) {
        super(name, root);

        this.loadMore = this._dEl('loadMore');
        this.content = this._dEl('content');

        this.favoriteStoreAnacanid = this.root.dataset.favStoreAnacanid;
        this.filter = register.getClass(this._dEl('filter'));
        this.storeFilter = this._dEl('storeFilters') ? register.getClass(this._dEl('storeFilters')) : null;

        /* load initial orders dynamically */
        (async () => {
            this.start = 0;
            this.pendingRequest = true;
            this.cardsOffset = parseInt(this.root.dataset.cardsOffset);

            openLoader('main');
            this._startLoading();
            await this._downloadFavorites();
            this._endLoading();
            closeLoader('main');
        })();

        this._addStoreListeners();
        this._addEventListeners();
    }

    _addEventListeners() {
        this.loadMore?.addEventListener('click', async (event) => {
            event.preventDefault();
            if (!this.pendingRequest) {
                openLoader('main');
                this._startLoading();
                await this._downloadFavorites();
                this._endLoading();
                closeLoader('main');
            }
        });
        this.root.addEventListener('mt10FilterChanged', async (event) => {
            console.info(`[${this.getName()}] selected filter: ${event.data.label} (${event.data.value})`);
            openLoader('main');
            this.start = 0;
            this._startLoading();
            this._emptyContent(this.content);
            await this._downloadFavorites();
            this._endLoading();
            closeLoader('main');
        });
    }

    _addStoreListeners() {
        this._addStoreListener('OnefavoriteRemoved', () => {
            this.start = Math.max(0, this.start - 1);
            this.maxCards--;
            const countContentText = this._dEl('countContentText');
            if (countContentText) countContentText.textContent = this.maxCards;
        });
    }

    _startLoading() {
        this.pendingRequest = true;
    }

    _endLoading() {
        this.loadMore.classList.remove(this._elMod('loadMore', 'loader'));
        if (this.start >= this.maxCards) this.loadMore.classList.add(this._elMod('loadMore', 'hidden'));
        else this.loadMore.classList.remove(this._elMod('loadMore', 'hidden'));
        this.pendingRequest = false;
    }

    async _downloadFavorites() {
        const anacanId = this.storeFilter ? this.storeFilter.getValue() : this.favoriteStoreAnacanid;

        const url = this.root.dataset.loaderEndpoint;
        const data = {
            storeFilter: anacanId,
            filter: this.filter.getValue(),
            start: this.start,
            length: this.cardsOffset,
        };
        try {
            const response = await apiProvider.loaderGet(url, data);
            this._appendFavorites(response);
            this.start = this.start + this.cardsOffset;
        } catch (error) {
            console.error(error);
        }
    }

    _appendFavorites(favorites) {
        const parser = new DOMParser();
        let toAppend = [];
        const doc = parser.parseFromString(favorites, 'text/html');
        const productsCount = doc.querySelector(this._el('countContent', true))?.dataset?.maxContent;
        if (productsCount) this._dEl('countContentText').innerHTML = productsCount;
        this.maxCards = productsCount;
        toAppend = doc.querySelectorAll(this._el('favCard', true));

        const container = this._dEl('content');
        if (container) container.append(...toAppend);
    }

    _emptyContent(container) {
        if (!container || container.objReference || !container.hasChildNodes()) return;
        for (let i = container.childElementCount; i > 0; i--) {
            container.removeChild(container.children[i - 1]);
        }
    }
}
