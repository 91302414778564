import Component from '../../../../../../libs/components/component';
import { apiProvider } from '../../../../../../libs/api-provider';
import './style.scss';

export default class DashboardPreferred extends Component {
    constructor(name, root) {
        super(name, root);

        this.endpoint = this.root.dataset.loaderEndpoint;
        this._initCarousel();
    }

    async _initCarousel() {
        const url = this.endpoint;
        try {
            /* parse new addresses */
            const html = await apiProvider.loaderGet(url);
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');
            const cards = doc.querySelectorAll('.mt27-cards');
            /* append new addresses */
            this.root.append(...cards);
        } catch (error) {
            console.error(error);
        }
    }
}
