/*
 * Copyright 2018 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

/* eslint-disable */

module.exports = function main($) {

  const htl = $.globals;
  let data = $.globals["data"];

  let render;
  render = function* _template_global_render(args) { 
    let pages = args[1]['pages'] || '';
    let labels = args[1]['labels'] || '';
    let $t, $n = args[0];
    $.dom.text($n,"\n\n    ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "mf16-customer-care__pagination", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n        ");
    $t = $.dom.create("button",false,false);
    $.dom.attr($t, 'class', "mf16-customer-care__prev ec-icon-chevron-left", 'attribute');
    $.dom.attr($t, 'type', "button", 'attribute');
    const var_attrValue0 = labels["prev"];
    if (!$.col.empty(var_attrValue0)) {
      $.dom.attr($t, 'title', var_attrValue0, 'attribute');
    }
    const var_attrValue1 = labels["prev"];
    if (!$.col.empty(var_attrValue1)) {
      $.dom.attr($t, 'aria-label', var_attrValue1, 'attribute');
    }
    $n = $.dom.push($n,$t);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n        ");
    const var_collectionVar2 = $.col.init(pages);
    const var_size3 = $.col.len(var_collectionVar2);
    if (var_size3) {
      for (const var_index4 of $.col.keys(var_collectionVar2)) {
        const item = $.col.get(var_collectionVar2, var_index4);
        const itemlist = $.listInfo(var_index4, var_size3);
        $.dom.text($n,"\n            ");
        $t = $.dom.create("button",false,false);
        $.dom.attr($t, 'class', "mf16-customer-care__page", 'attribute');
        $.dom.attr($t, 'type', "button", 'attribute');
        const var_attrValue5 = (labels["gotopage"]) + (" ") + (item);
        if (!$.col.empty(var_attrValue5)) {
          $.dom.attr($t, 'title', var_attrValue5, 'attribute');
        }
        const var_attrValue6 = item;
        if (!$.col.empty(var_attrValue6)) {
          $.dom.attr($t, 'data-page', var_attrValue6, 'attribute');
        }
        $n = $.dom.push($n,$t);
        $.dom.text($n,"\n                ");
        const var_7 = yield $.xss(item, "html");
        $.dom.append($n, var_7);
        $.dom.text($n,"\n            ");
        $n = $.dom.pop($n);
        $.dom.text($n,"\n        ");
      }
    }
    $.dom.text($n,"\n        ");
    $t = $.dom.create("button",false,false);
    $.dom.attr($t, 'class', "mf16-customer-care__next ec-icon-chevron-right", 'attribute');
    $.dom.attr($t, 'type', "button", 'attribute');
    const var_attrValue8 = labels["next"];
    if (!$.col.empty(var_attrValue8)) {
      $.dom.attr($t, 'title', var_attrValue8, 'attribute');
    }
    const var_attrValue9 = labels["next"];
    if (!$.col.empty(var_attrValue9)) {
      $.dom.attr($t, 'aria-label', var_attrValue9, 'attribute');
    }
    $n = $.dom.push($n,$t);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n    ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n");
  };
  $.template('global', 'render', render);

  return $.run(function* () {
    let $t, $n = $.dom.start();
    return $.dom.end();

  });
};
