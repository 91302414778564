import Component from '../../../../../../libs/components/component';
import { register } from '../../../../../../libs/register';
import { openLoader, closeLoader } from '../../../templates/mt11-loader/script';
import { apiProvider } from '../../../../../../libs/api-provider';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import './style.scss';

export default class Coupons extends Component {
    constructor(name, root) {
        super(name, root);
        this.loaderEndpoint = this.root.dataset.endpoint;
        this.initLength = parseInt(this.root.dataset.initLength);
        this.downloadLength = parseInt(this.root.dataset.downloadLength);
        this.countCoupons = this.root.querySelector(`${this._el('countCoupons', true)} > span`);
        this.loadMore = this._dEl('loadMore');
        this.noCoupons = this._dEl('noCoupons');
        this.content = this._dEl('content');
        this.filter = register.getClass(this._dEl('filter'));

        trackEvent(TRACKABLE_EVENT.pageview, FUNNEL_NAME.couponsWallet, FUNNEL_STEP.couponsList);
        openLoader('main');
        this.currentSize = 0;
        this._downloadCoupons(this.currentSize, this.initLength);
        this._addEventListeners();
    }

    _addEventListeners() {
        this.loadMore.addEventListener('click', async () => {
            openLoader('main');
            const lastFocusedCoupon = this.content.querySelector(`${this._el('coupon', true)}:last-child`);
            await this._downloadCoupons(this.currentSize, this.downloadLength);
            setTimeout(() => {
                lastFocusedCoupon?.querySelector('[role="button"]')?.focus();
            }, 500);
        });
        this.root.addEventListener('mt10FilterChanged', async (event) => {
            console.info(`[${this.getName()}] selected filter: ${event.data.label} (${event.data.value})`);
            openLoader('main');
            this.currentSize = 0;
            this._emptyCoupons(this.content);
            await this._downloadCoupons(this.currentSize, this.downloadLength);
        });
    }

    async _downloadCoupons(init, length) {
        const url = this.loaderEndpoint;
        const data = {
            filter: this.filter.getValue(),
            init: init,
            length: length,
            currentSize: this.currentSize,
        };
        try {
            const html = await apiProvider.loaderGet(url, data);
            this._appendCoupons(html);
        } catch (error) {
            console.error(error);
        } finally {
            closeLoader('main');
        }
    }

    _appendCoupons(coupons) {
        /* parse new coupons */
        const parser = new DOMParser();
        const doc = parser.parseFromString(coupons, 'text/html');
        const newCoupons = doc.querySelectorAll('.mt12-coupon');
        const countCoupons = doc.querySelector(this._el('loader', true)).dataset.countCoupons;

        const class_hideLoadMore = this._elMod('loadMore', 'hidden');
        let classList_loadMore = this.loadMore.classList;
        const class_hideContent = this._elMod('content', 'hidden');
        let classList_content = this.content.classList;
        const class_hideNoCoupons = this._elMod('noCoupons', 'hidden');
        let classList_noCoupons = this.noCoupons.classList;

        /* hide/show loadMore */
        if (this.currentSize != 0 && newCoupons.length < this.downloadLength) {
            /* case no more coupons to download next */
            classList_loadMore.add(class_hideLoadMore);
        } else {
            /* case possibly more coupons to download next */
            classList_loadMore.remove(class_hideLoadMore);
        }

        /* hide/show coupons */
        if (this.currentSize + newCoupons.length <= 0) {
            /* case no coupons */
            classList_content.add(class_hideContent);
            classList_noCoupons.remove(class_hideNoCoupons);
            this.countCoupons.textContent = '0';
            classList_loadMore.add(class_hideLoadMore);
        } else {
            /* case coupons */
            classList_content.remove(class_hideContent);
            classList_noCoupons.add(class_hideNoCoupons);
            /* append new coupons */
            this.content.append(...newCoupons);
            /* update current size */
            this.currentSize += newCoupons.length;
            /* hide/show loadMore */
            if (this.currentSize >= countCoupons) {
                classList_loadMore.add(class_hideLoadMore);
            }
            /* update total number of coupons */
            this.countCoupons.textContent = countCoupons;
        }
    }

    _emptyCoupons(container) {
        if (!container || container.objReference || !container.hasChildNodes()) return;
        for (let i = container.childElementCount; i > 0; i--) {
            container.removeChild(container.children[i - 1]);
        }
    }
}
