/*
 * Copyright 2018 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

/* eslint-disable */

module.exports = function main($) {

  const htl = $.globals;
  let data = $.globals["data"];

  let render;
  render = function* _template_global_render(args) { 
    let cart = args[1]['cart'] || '';
    let $t, $n = args[0];
    $.dom.text($n,"\n    ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "ms2-header__cartDataWrapper", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n        ");
    $t = $.dom.create("span",false,false);
    $.dom.attr($t, 'class', "ms2-header__cartData", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n            ");
    const var_0 = yield $.xss($.exec("format", "0.00", {"format": cart["subTotal"]["value"], "locale": "it", }), "html");
    $.dom.append($n, var_0);
    $.dom.text($n," € - [");
    const var_1 = yield $.xss(cart["totalUnitCount"], "html");
    $.dom.append($n, var_1);
    $.dom.text($n,"]\n        ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n        ");
    $t = $.dom.create("span",false,false);
    $.dom.attr($t, 'class', "ms2-header__cartIcon ec-icon-cart", 'attribute');
    $n = $.dom.push($n,$t);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n    ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n");
  };
  $.template('global', 'render', render);

  return $.run(function* () {
    let $t, $n = $.dom.start();
    $.dom.text($n,"\n");
    return $.dom.end();

  });
};
